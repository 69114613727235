import { all, fork } from "redux-saga/effects";

import HomeDataSaga from "./home/saga";
import { PackageSummarySaga, ListingPageDataSaga } from "./listing/saga";
import PackageDetailsSaga from "./details/saga";
import BookingDetailsSaga from "./booking/saga";
import ContactDetailsSaga from "./contact/saga";
import PackageAvailabilitySaga from "./availability/saga";
import AboutUsDataSaga from "./about-us/saga";
import TermsAndConditionDataSaga from "./terms-and-condition/saga";
import CustomerProfileSaga from "./customer/saga";
import GroupBookingDataSaga from "./groupBooking/saga";
import ItineraryListingSaga from "./itinerary/saga";

export default function* rootSaga() {
  yield all([fork(HomeDataSaga)]);
  yield all([fork(PackageSummarySaga)]);
  yield all([fork(ListingPageDataSaga)]);
  yield all([fork(PackageDetailsSaga)]);
  yield all([fork(BookingDetailsSaga)]);
  yield all([fork(ContactDetailsSaga)]);
  yield all([fork(TermsAndConditionDataSaga)]);
  yield all([fork(CustomerProfileSaga)]);
  yield all([fork(PackageAvailabilitySaga)]);
  yield all([fork(AboutUsDataSaga)]);
  yield all([fork(GroupBookingDataSaga)]);
  yield all([fork(ItineraryListingSaga)]);
}
