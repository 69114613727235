import { useEffect, useState } from "react";
import DiscoverCardLayout from "./discover-card-layout/DiscoverCardLayout";
import "./DiscoverInternationalTray.scss";
import ReactGA from "react-ga4";
import { getAnalyticsEnabled } from "../../helpers/backend_helper";

function DiscoverInternationalTray(props: any) {
  const [dataArr, setDataArr] = useState<any>([]);
  const { data } = props;
  useEffect(() => {
    if (data?.folder.files !== 0) {
      data.folder.files.sort((a: any, b: any) => {
        if (a.displayIndex !== null) {
          var value;
          value =
            a.displayIndex > b.displayIndex
              ? 1
              : a.displayIndex < b.displayIndex
              ? -1
              : 0;
          return value;
        }
      });
      setDataArr(data);
    }
  }, [data]);
  const googleAnalyticsFunction = () => {
    if (getAnalyticsEnabled()) {
      ReactGA.event({
        action: `discover_international_tray_homepage`,
        category: `homepage`,
      });
    }
  };
  return (
    <div className="discover-international-section">
      {dataArr.length !== 0 && dataArr?.folder?.files.length !== 0 && (
        <div onClick={() => googleAnalyticsFunction()} className="section">
          <h2 className="head">{dataArr.collectionType}</h2>
          <div
            className="discover-international"
            style={{
              background: `url(${
                dataArr?.folder.url + "/" + dataArr?.folder?.files[0]?.fileName
              }) no-repeat center center / cover`,
            }}
          >
            <span className="background-overlay"></span>
            {dataArr?.folder?.files[0] && (
              <div className="left">
                <DiscoverCardLayout
                  data={dataArr?.folder?.files[0]}
                  folderUrl={dataArr?.folder.url}
                />
              </div>
            )}
            {dataArr?.folder?.files[1] && (
              <div className="right">
                <DiscoverCardLayout
                  data={dataArr?.folder?.files[1]}
                  folderUrl={dataArr?.folder.url}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default DiscoverInternationalTray;
