// src/store/reducers.ts

import { combineReducers } from "redux";

import HomeDataReducer from "./home/reducer";
import {
  PackageSummaryReducer,
  ListingPageDataReducer,
} from "./listing/reducer";
import PackageDetailsReducer from "./details/reducer";
import BookingDetailsReducer from "./booking/reducer";
import ContactDetailsReducer from "./contact/reducer";
import PackageAvailabilityReducer from "./availability/reducer";
import TermsAndConditionDataReducer from "./terms-and-condition/reducer";
import CustomerProfileReducer from "./customer/reducer";
import AboutUsDataReducer from "./about-us/reducer";
import GroupBookingDataReducer from "./groupBooking/reducer";
import RoomDetailReducer from "./rooms/reducer";
import ItineraryListingReducer from "./itinerary/reducer";

const rootReducer = combineReducers({
  HomeDataReducer,
  PackageSummaryReducer,
  ListingPageDataReducer,
  PackageDetailsReducer,
  BookingDetailsReducer,
  ContactDetailsReducer,
  PackageAvailabilityReducer,
  TermsAndConditionDataReducer,
  CustomerProfileReducer,
  AboutUsDataReducer,
  GroupBookingDataReducer,
  RoomDetailReducer,
  ItineraryListingReducer,
});

export default rootReducer;
