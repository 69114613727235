import moment from "moment";
import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import "./PaymentSuccess.scss";

interface CustomizedState {
  packageData: any;
  startDate: any;
  endDate: any;
  orderId: string;
}

function PaymentSuccess() {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as CustomizedState;
  // const { packageData } = state;

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Payment Success | Voyaah";
  }, []);

  const navigateToHome = () => {
    navigate(`/`);
  };

  return (
    <div className="inner-page details-page">
      <div className="section">
        <div className="breadcrum">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>›</li>
            <li>Payment Successful</li>
          </ul>
        </div>

        <div className="payment-section-blk">
          <div className="img">
            <img
              src={require("../../assets/images/success.png")}
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="head">Payment Successful</div>
          <div className="content">
            <p>
              {`All done, you are going to ${state?.packageData?.packageValues?.destination}! We will send you the booking
              confirmation in next 24 hours`}
            </p>
            <div className="date-location">
              <div className="image">
                <img
                  src={
                    state?.packageData?.packageValues?.mainImage ||
                    require("../../assets/images/image24.png")
                  }
                  alt=""
                  className="img-fluid"
                />
              </div>
              <div className="detail">
                <p className="head">{`${state?.packageData?.packagename}`}</p>
                <p className="des">
                  <span>Date</span>{" "}
                  {`${moment(state?.startDate).format("MMM DD")} to ${moment(
                    state?.endDate
                  ).format("MMM DD, YYYY")}`}
                </p>
                <p className="des">
                  <span>Location</span>{" "}
                  {`${state?.packageData?.packageValues?.destination}`}
                </p>
                <p className="des">
                  <span>Order Id</span> {`${state?.orderId}`}
                </p>
              </div>
            </div>
            <p>We will send you the booking confirmation in next 24 hours.</p>

            <div className="green-btn" onClick={navigateToHome}>
              Explore More
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentSuccess;
