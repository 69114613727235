import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import regexData from "../../constants/regexData";
import {
  getNumberFromEmail,
  regsterUserFirebase,
  registerUsingPhoneNumber,
  saveUserDetailsToFirestore,
  addDocFirestore
} from "../../helpers/firebase";

const RegisterForm = (props: any) => {
  const { setShowLoadingAnim } = props;

  const [showPasswordField, setShowPasswordField] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [partnerID, setPartnerID] = useState("");
  const [registerVoyaah, setRegisterVoyaah] = useState(true);
  const [credentials, setCredentials] = useState("");
  const [expandForm, setExpandForm] = useState(false);
  const [counter, setCounter] = useState(0);
  const [numberSentTo, setNumberSentTo] = useState("");
  const [appVerified, setAppVerified] = useState(false);
  const [otpTry, setOtpTry] = useState(0);

  const emailRef = useRef<HTMLInputElement>(null);
  const emailContainerRef = useRef<HTMLDivElement>(null);
  const emailErrorRef = useRef<HTMLParagraphElement>(null);
  const nameRef = useRef<HTMLInputElement>(null);
  const nameContainerRef = useRef<HTMLDivElement>(null);
  const nameErrorRef = useRef<HTMLParagraphElement>(null);
  const numberRef = useRef<HTMLInputElement>(null);
  const numberContainerRef = useRef<HTMLDivElement>(null);
  const numberErrorRef = useRef<HTMLParagraphElement>(null);
  const partnerIdRef = useRef<HTMLInputElement>(null);
  const partnerIdContainerRef = useRef<HTMLDivElement>(null);
  const partnerIdErrorRef = useRef<HTMLParagraphElement>(null);
  const otpErrorRef = useRef<HTMLParagraphElement>(null);
  const navigate = useNavigate();
  
  useEffect(() => {
    if(props.registrationData && props.registrationData.name) {
      setName(props.registrationData.name);
      setEmail(props.registrationData.email);
      setPhoneNumber(props.registrationData.phoneNumber);
      setPartnerID(props.registrationData.partnerID);
      setRegisterVoyaah(props.registrationData.registerVoyaah);
    }
  }, [props.registrationData]);

  const handleContinueClick = () => {
    const partnerUser = {
      authProvider: "local",
      name,
      email,
      phoneNumber,
      channelOfSale: {
        "channelOfSale.ClubVistara": partnerID,
      }
    };
    sessionStorage.setItem("partnerUser", JSON.stringify(partnerUser));
    sessionStorage.setItem("email", email);
    navigate(`/`);
    // const userData = {
    //   authProvider: "local",
    //   name,
    //   email,
    //   phoneNumber,
    // }
    // window.dispatchEvent(new Event("storage"));
    // regsterUserFirebase(userData).then(result => {
    //   if(result.length !== 0) {
    //   result.forEach(doc => {
    //       if (doc.exists() && doc.data().email === email && doc.data().phoneNumber === phoneNumber) {
    //         saveUserDetailsToFirestore(userData);
    //         sessionStorage.setItem("partnerUser", JSON.stringify(partnerUser));
    //         sessionStorage.setItem("email", email);
    //         navigate(`/staycations`);
    //     } else if(doc.exists() && doc.data().email === email && doc.data().phoneNumber !== phoneNumber) {
    //       emailContainerRef?.current?.classList.add("error");
    //       emailErrorRef?.current?.classList.add("show");
    //       if (emailErrorRef?.current?.innerHTML) {
    //         emailErrorRef.current.innerHTML = "This email already used please use different email address";
    //       }
    //       emailRef?.current?.focus();
    //     } else if(doc.exists() && doc.data().email !== email && doc.data().phoneNumber === phoneNumber) {
    //       numberContainerRef?.current?.classList.add("error");
    //       numberErrorRef?.current?.classList.add("show");
    //       if (numberErrorRef?.current?.innerHTML) {
    //         numberErrorRef.current.innerHTML = "This phone number already used please use different phone number";
    //       }
    //     numberRef?.current?.focus();
    //     } else {
    //       alert('else');
    //       addDocFirestore(userData);
    //       sessionStorage.setItem("partnerUser", JSON.stringify(partnerUser));
    //       sessionStorage.setItem("email", partnerUser.email);
    //       navigate(`/staycations`);
    //     }
    //   });
    // } else {
    //   addDocFirestore(userData);
    //   sessionStorage.setItem("partnerUser", JSON.stringify(partnerUser));
    //   sessionStorage.setItem("email", partnerUser.email);
    //   navigate(`/staycations`);
    // }
    // });
  };

  const clearError = () => {
    nameContainerRef?.current?.classList.remove("error");
    nameErrorRef?.current?.classList.remove("show");
    emailContainerRef?.current?.classList.remove("error");
    emailErrorRef?.current?.classList.remove("show");
    numberContainerRef?.current?.classList.remove("error");
    numberErrorRef?.current?.classList.remove("show");
    partnerIdContainerRef?.current?.classList.remove("error");
    partnerIdErrorRef?.current?.classList.remove("show");
  };

  const validateDetails = () => {
    const nameRegex = new RegExp(regexData.name);
    const emailRegex = new RegExp(regexData.email);
    const numberRegex = new RegExp(regexData.mobileNumberCompact);
    const partnerRegex = new RegExp(regexData.cubVistaraPartnerID);

    clearError();

    switch (true) {
      case !name:
        nameContainerRef?.current?.classList.add("error");
        nameErrorRef?.current?.classList.add("show");
        if (nameErrorRef?.current?.innerHTML) {
          nameErrorRef.current.innerHTML = "Enter first name";
        }
        nameRef?.current?.focus();
        break;

      case !nameRegex.test(name):
        nameContainerRef?.current?.classList.add("error");
        nameErrorRef?.current?.classList.add("show");
        if (nameErrorRef?.current?.innerHTML) {
          nameErrorRef.current.innerHTML = "Enter valid Name";
        }
        nameRef?.current?.focus();
        break;

      case !email:
        emailContainerRef?.current?.classList.add("error");
        emailErrorRef?.current?.classList.add("show");
        if (emailErrorRef?.current?.innerHTML) {
          emailErrorRef.current.innerHTML = "Enter an email";
        }
        emailRef?.current?.focus();
        break;

      case !emailRegex.test(email):
        emailContainerRef?.current?.classList.add("error");
        emailErrorRef?.current?.classList.add("show");
        if (emailErrorRef?.current?.innerHTML) {
          emailErrorRef.current.innerHTML = "Enter valid email";
        }
        emailRef?.current?.focus();
        break;

      case !phoneNumber:
        numberContainerRef?.current?.classList.add("error");
        numberErrorRef?.current?.classList.add("show");
        if (numberErrorRef?.current?.innerHTML) {
          numberErrorRef.current.innerHTML = "Enter an mobile number";
        }
        numberRef?.current?.focus();
        break;

      case !numberRegex.test(phoneNumber):
        numberContainerRef?.current?.classList.add("error");
        numberErrorRef?.current?.classList.add("show");
        if (numberErrorRef?.current?.innerHTML) {
          numberErrorRef.current.innerHTML = "Enter valid mobile number";
        }
        numberRef?.current?.focus();
        break;

      case !partnerID:
        partnerIdContainerRef?.current?.classList.add("error");
        partnerIdErrorRef?.current?.classList.add("show");
        if (partnerIdErrorRef?.current?.innerHTML) {
          partnerIdErrorRef.current.innerHTML = "Enter an ID";
        }
        partnerIdRef?.current?.focus();
        break;

      case !partnerRegex.test(partnerID):
        partnerIdContainerRef?.current?.classList.add("error");
        partnerIdErrorRef?.current?.classList.add("show");
        if (partnerIdErrorRef?.current?.innerHTML) {
          partnerIdErrorRef.current.innerHTML = "Enter a valid ID";
        }
        partnerIdRef?.current?.focus();
        break;

      default:
        handleContinueClick()
        // const dataVal = {
        //   name,
        //   email,
        //   phoneNumber,
        //   partnerID,
        //   registerVoyaah
        // }
        // if(registerVoyaah) handleContinueClick();
        // else {
        //   props.setRecaptcha();
        //   props.setOtpFunction(dataVal);
        //   handleOtpRequest();
        // }
        break;
    }
  };

  const handleOtpRequest = () => {
    props.setRecaptcha();
    clearError();
    getUserPhoneNumber();
    setShowLoadingAnim(true);
    registerUsingPhoneNumber(phoneNumber, window.appVerifier).then(() => {
      setShowLoadingAnim(false);
      props.setCounterFunction(60);
    })
  };

const getUserPhoneNumber = async () => {
  const emailRegex = new RegExp(regexData.email);
  const numberRegex = new RegExp(regexData.mobileNumberCompact);
  let phoneNumber = "";
  if (emailRegex.test(credentials)) {
      phoneNumber = await getNumberFromEmail(credentials);
      // phoneNumber = await getEmailFromNumber(credentials, "")
  }

  if (numberRegex.test(credentials)) {
      phoneNumber = credentials;
  }

  setNumberSentTo(phoneNumber.substring(6, 10));
  return phoneNumber;
};


  return (
    <>
      <div>
        {!showPasswordField && (
          <div className="form">
            <div className="form-fields" ref={nameContainerRef}>
              <label>Name</label>
              <input
                ref={nameRef}
                type="text"
                placeholder="Enter your name"
                value={name}
                maxLength={50}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <p className="error-msg" ref={nameErrorRef}>
                Error Text
              </p>
            </div>
            <div className="form-fields" ref={emailContainerRef}>
              <label>Email</label>
              <input
                ref={emailRef}
                type="text"
                placeholder="Enter your email"
                value={email}
                maxLength={50}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <p className="error-msg" ref={emailErrorRef}>
                Error Text
              </p>
            </div>
            <div className="form-fields" ref={numberContainerRef}>
              <label>Phone Number</label>
              <input
                ref={numberRef}
                type="text"
                placeholder="Enter your phone number"
                value={phoneNumber}
                maxLength={50}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
              />
              <p className="error-msg" ref={numberErrorRef}>
                Error Text
              </p>
            </div>
            <div className="form-fields" ref={partnerIdContainerRef}>
              <label>Partner ID</label>
              <input
                ref={partnerIdRef}
                type="text"
                placeholder="Enter your partner id (e.g Club Vistara id)"
                value={partnerID}
                maxLength={50}
                onChange={(e) => setPartnerID(e.target.value)}
                required
              />
              <p className="error-msg" ref={partnerIdErrorRef}>
                Error Text
              </p>
            </div>
            {/* <div className="form-fields no-bg">
              <div className="register-left" onClick={() => {setRegisterVoyaah(!registerVoyaah)}}>
                <input checked={registerVoyaah} type="checkbox" name="remember" className="checkbox" />{" "}
                Register With Voyaah
              </div>
            </div> */}
            <div className="form-fields no-bg" style={{display: 'flex'}}>
              <input
                type="button"
                value={registerVoyaah ? "Browse Packages" :"Get OTP"}
                className="green-btn login"
                onClick={() => validateDetails()}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default RegisterForm;
