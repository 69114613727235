import React from "react";
import _ from "underscore";

import "./Details.scss";

function Highlights(props: any) {
  const { highlights } = props;

  const getIcon = (data: any) => {
    try {
      return require(`../../assets/images/icons/highlights/${data.icon}.png`);
    } catch (error) {
      return require(`../../assets/images/icons/highlights/default.png`);
    }
  };

  return (
    <>
      {highlights && (
        <>
          <h2 className="head">Highlights</h2>
          <ul className="inline">
            {_.compact(highlights).length > 0 &&
              _.compact(highlights)?.map((item: any, index: number) => {
                return (
                  <li key={`package_highlights_${index}`}>
                    <span style={{ width: "20px" }}>
                      <img src={getIcon(item?.icon)} alt="" className="img-fluid" />
                    </span>
                    {item.title}
                  </li>
                );
              })}
            {_.compact(highlights).length === 0 && (
              <li key={`package_highlights_na`}>{"Not Listed"}</li>
            )}
          </ul>
        </>
      )}
    </>
  );
}

export default Highlights;
