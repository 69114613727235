// @flow
import React from "react";
import {
  Navigate,
  BrowserRouter as Router,
  useLocation,
  useRoutes,
} from "react-router-dom";

import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";

import Home from "./containers/Home";
import Faq from "./containers/faq/Faq";
import Error from "./containers/error/Error";
import Login from "./containers/login/Login";
import Review from "./containers/review/Review";
import Profile from "./containers/profile/Profile";
import Listing from "./containers/listing/Listing";
import AboutUs from "./containers/aboutUs/AboutUs";
import Details from "./containers/details/Details";
import SiteMap from "./containers/sitemap/Sitemap";
import Register from "./containers/register/Register";
import TermsOfUse from "./containers/termsOfUse/TermsOfUse";
import CookiePolicy from "./containers/cookiePolicy/CookiePolicy";
import PartnerLogin from "./containers/partnerLogin/PartnerLogin";
import GroupBooking from "./containers/groupBooking/GroupBooking";
import PasswordReset from "./containers/passwordReset/PasswordReset";
import PrivacyPolicy from "./containers/privacyPolicy/PrivacyPolicy";
import PaymentCapture from "./containers/paymentCapture/PaymentCapture";
import ForgotPassword from "./containers/forgotPassword/ForgotPassword";
import PaymentSuccess from "./containers/paymentSuccess/PaymentSuccess";
import CustomItinerary from "./containers/customItinerary/CustomItinerary";
import UnderConstruction from "./containers/underConstruction/UnderConstruction";
import TermsAndConditions from "./containers/termsAndConditions/TermsAndConditions";
import CustomItineraryView from "./containers/customItinerary/CustomItineraryView";
import CustomItineraryEdit from "./containers/customItinerary/CustomItineraryEdit";
import ReviewCopy from "./containers/review/ReviewCopy";

const RouteConfig = () => {
  let routes = useRoutes([
    {
      path: "/",
      element: (
        <PrivateRoute>
          <Home />
        </PrivateRoute>
      ),
    },
    {
      path: "/login",
      element: (
        <PrivateRoute>
          <Login />
        </PrivateRoute>
      ),
    },
    {
      path: "/register",
      element: (
        <PrivateRoute>
          <Register />
        </PrivateRoute>
      ),
    },
    {
      path: "/forgot-password",
      element: (
        <PrivateRoute>
          <ForgotPassword />
        </PrivateRoute>
      ),
    },
    {
      path: "/password-reset",
      element: (
        <PrivateRoute>
          <PasswordReset />
        </PrivateRoute>
      ),
    },
    {
      path: "/holidays/:pageNo",
      element: (
        <PrivateRoute>
          <Listing />
        </PrivateRoute>
      ),
    },
    {
      path: "/staycations/:pageNo",
      element: (
        <PrivateRoute>
          <Listing />
        </PrivateRoute>
      ),
    },
    {
      path: "/custom-itinerary",
      element: (
        <PrivateRoute>
          <CustomItinerary />
        </PrivateRoute>
      ),
    },
    {
      path: "/custom-itinerary/:id",
      element: (
        <PrivateRoute>
          <CustomItineraryView />
        </PrivateRoute>
      ),
    },
    {
      path: "/custom-itinerary/:id/edit",
      element: (
        <PrivateRoute>
          <CustomItineraryEdit />
        </PrivateRoute>
      ),
    },
    {
      path: "/domestic/:pageNo",
      element: (
        <PrivateRoute>
          <Listing />
        </PrivateRoute>
      ),
    },
    {
      path: "/international/:pageNo",
      element: (
        <PrivateRoute>
          <Listing />
        </PrivateRoute>
      ),
    },
    {
      path: "/packages/:id",
      element: (
        <PrivateRoute>
          <Details />
        </PrivateRoute>
      ),
    },
    {
      path: "/review",
      element: (
        <PrivateRoute>
          {/* <Review /> */}
          <ReviewCopy />
        </PrivateRoute>
      ),
    },
    {
      path: "/about-us",
      element: (
        <PrivateRoute>
          <AboutUs />
        </PrivateRoute>
      ),
    },
    {
      path: "/group-booking",
      element: (
        <PrivateRoute>
          <GroupBooking />
        </PrivateRoute>
      ),
    },
    {
      path: "/terms-of-use",
      element: (
        <PrivateRoute>
          <TermsOfUse />
        </PrivateRoute>
      ),
    },
    {
      path: "/privacy-policy",
      element: (
        <PrivateRoute>
          <PrivacyPolicy />
        </PrivateRoute>
      ),
    },
    {
      path: "/faq",
      element: (
        <PrivateRoute>
          <Faq />
        </PrivateRoute>
      ),
    },
    {
      path: "/news",
      element: (
        <PrivateRoute>
          <UnderConstruction />
        </PrivateRoute>
      ),
    },
    {
      path: "/partners",
      element: (
        <PrivateRoute>
          <UnderConstruction />
        </PrivateRoute>
      ),
    },
    { path: "/partner-login", element: <PartnerLogin /> },
    {
      path: "/media",
      element: (
        <PrivateRoute>
          <UnderConstruction />
        </PrivateRoute>
      ),
    },
    {
      path: "/blogs",
      element: (
        <PrivateRoute>
          <UnderConstruction />
        </PrivateRoute>
      ),
    },
    {
      path: "/error",
      element: (
        <PrivateRoute>
          <Error />
        </PrivateRoute>
      ),
    },
    {
      path: "/my-profile",
      element: (
        <PrivateRoute>
          <Profile />
        </PrivateRoute>
      ),
    },
    {
      path: "/payment-success",
      element: (
        <PrivateRoute>
          <PaymentSuccess />{" "}
        </PrivateRoute>
      ),
    },
    {
      path: "/payment-capture",
      element: (
        <PrivateRoute>
          <PaymentCapture />
        </PrivateRoute>
      ),
    },
    {
      path: "/search/:searchQuery/:pageNo",
      element: (
        <PrivateRoute>
          <Listing />
        </PrivateRoute>
      ),
    },
    {
      path: "/cookie-policy",
      element: (
        <PrivateRoute>
          <CookiePolicy />
        </PrivateRoute>
      ),
    },
    {
      path: "/terms-and-conditions",
      element: (
        <PrivateRoute>
          <TermsAndConditions />
        </PrivateRoute>
      ),
    },
    {
      path: "/site-overview",
      element: (
        <PrivateRoute>
          <SiteMap />
        </PrivateRoute>
      ),
    },
    {
      path: "*",
      element: (
        <PrivateRoute>
          <Error />
        </PrivateRoute>
      ),
    },
  ]);
  return routes;
};

function App() {
  return (
    <Router>
      <Header />
      <RouteConfig />
      <Footer />
    </Router>
  );
}

export default App;

const PrivateRoute = (props: { children: React.ReactNode }): JSX.Element => {
  const { children } = props;
  const location = useLocation();

  const isPartnerSite = process.env.REACT_APP_PARTNER_SITE;
  const partnerUserData = JSON.parse(
    sessionStorage.getItem("partnerUser") || "{}"
  );
  let isLoggedIn = true;
  if (
    isPartnerSite &&
    isPartnerSite === "true" &&
    !partnerUserData.channelOfSale
  ) {
    isLoggedIn = false;
  }

  return isLoggedIn ? (
    <>{children}</>
  ) : (
    <Navigate
      replace={true}
      to="/partner-login"
      state={{ from: `${location.pathname}${location.search}` }}
    />
  );
};
