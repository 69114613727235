import { useState, useRef, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom";
import regexData from "../../constants/regexData";
import {
    logInWithEmailAndPassword,
    signInUsingOtp,
    getNumberFromEmail,
    verifyOtpAfterLogin,
    checkLoginCredential
} from "../../helpers/firebase";

const LoginForm = (props: any) => {
    const { setShowLoadingAnim } = props

    const [expandForm, setExpandForm] = useState(false);
    const [showPasswordField, setShowPasswordField] = useState(false);
    const [counter, setCounter] = useState(0);
    const [credentials, setCredentials] = useState("");
    const [showPasswordText, setShowPasswordText] = useState(false);
    const [password, setPassword] = useState("");
    const [otp, setOtp] = useState("");
    const [numberSentTo, setNumberSentTo] = useState("");
    const [otpTry, setOtpTry] = useState(0);
    const [partnerID, setPartnerID] = useState("");

    const emailRef = useRef<HTMLInputElement>(null);
    const emailContainerRef = useRef<HTMLDivElement>(null);
    const emailErrorRef = useRef<HTMLParagraphElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);
    const passwordContainerRef = useRef<HTMLDivElement>(null);
    const passwordErrorRef = useRef<HTMLParagraphElement>(null);
    const loginErrorRef = useRef<HTMLParagraphElement>(null);
    const otpErrorRef = useRef<HTMLParagraphElement>(null);
    const partnerIdRef = useRef<HTMLInputElement>(null);
    const partnerIdContainerRef = useRef<HTMLDivElement>(null);
    const partnerIdErrorRef = useRef<HTMLParagraphElement>(null);
    const navigate = useNavigate();

    useEffect(() => {
        let timer = setInterval(() => { }, 1000);
        if (counter > 0) {
            timer = setInterval(() => setCounter(counter - 1), 1000);
        }
        // counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);

    const clearError = () => {
        emailContainerRef?.current?.classList.remove("error");
        emailErrorRef?.current?.classList.remove("show");
        passwordContainerRef?.current?.classList.remove("error");
        passwordErrorRef?.current?.classList.remove("show");
        loginErrorRef?.current?.classList.remove("show");
        partnerIdErrorRef?.current?.classList.remove("show");
    };

    const validateDetailsAndLoginWithPassword = () => {
        clearError();

        switch (true) {
            case !partnerID:
                partnerIdContainerRef?.current?.classList.add("error");
                partnerIdErrorRef?.current?.classList.add("show");
                if (partnerIdErrorRef?.current?.innerHTML) {
                  partnerIdErrorRef.current.innerHTML = "Enter an ID";
                }
                partnerIdRef?.current?.focus();
                break;

            case !password:
                passwordContainerRef?.current?.classList.add("error");
                passwordErrorRef?.current?.classList.add("show");
                if (passwordErrorRef?.current?.innerHTML) {
                    passwordErrorRef.current.innerHTML = "Enter password";
                }
                passwordRef?.current?.focus();
                break;

            default:
                logInWithEmailAndPassword(credentials, password).then((data: any) => {
                    if(data && data.user) {
                        const partnerData = {
                            authProvider: "local",
                            name: data.user.displayName,
                            email: data.user.email,
                            phoneNumber: data.user.phoneNumber,
                            channelOfSale: {
                                "channelOfSale.ClubVistara": partnerID,
                              },
                            uid: data.user.uid
                        }
                        sessionStorage.setItem("partnerUser", JSON.stringify(partnerData));
                        sessionStorage.setItem("email", data.user.email);
                        navigate(`/`);
                    }
                }).catch((err) => {
                    if (err.message === "Firebase: Error (auth/user-not-found).") {
                        loginErrorRef?.current?.classList.add("show");
                        if (loginErrorRef?.current?.innerHTML) {
                            loginErrorRef.current.innerHTML = "You aren't registered yet.";
                        }
                    } else if (err.message === "Firebase: Error (auth/wrong-password).") {
                        loginErrorRef?.current?.classList.add("show");
                        if (loginErrorRef?.current?.innerHTML) {
                            loginErrorRef.current.innerHTML = "You entered Wrong Credentials";
                        }
                    } else if (
                        err.message ===
                        "Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests)."
                    ) {
                        loginErrorRef?.current?.classList.add("show");
                        if (loginErrorRef?.current?.innerHTML) {
                            loginErrorRef.current.innerHTML =
                                "We are unable to authenticate. Please try after sometime.";
                        }
                    } else {
                        loginErrorRef?.current?.classList.add("show");
                        if (loginErrorRef?.current?.innerHTML) {
                            loginErrorRef.current.innerHTML = err.message;
                        }
                    }
                });
                break;
        }
    };

    const handleOtpRequest = () => {
        const numberRegex = new RegExp(regexData.mobileNumberCompact);
        const emailRegex = new RegExp(regexData.email);
        const partnerRegex = new RegExp(regexData.cubVistaraPartnerID);

        clearError();

        switch (true) {
            case !partnerID:
                partnerIdContainerRef?.current?.classList.add("error");
                partnerIdErrorRef?.current?.classList.add("show");
                if (partnerIdErrorRef?.current?.innerHTML) {
                  partnerIdErrorRef.current.innerHTML = "Enter an ID";
                }
                partnerIdRef?.current?.focus();
                break;
        
              case !partnerRegex.test(partnerID):
                partnerIdContainerRef?.current?.classList.add("error");
                partnerIdErrorRef?.current?.classList.add("show");
                if (partnerIdErrorRef?.current?.innerHTML) {
                  partnerIdErrorRef.current.innerHTML = "Enter a valid ID";
                }
                partnerIdRef?.current?.focus();
                break;

            case !credentials:
                emailContainerRef?.current?.classList.add("error");
                emailErrorRef?.current?.classList.add("show");
                if (emailErrorRef?.current?.innerHTML) {
                    emailErrorRef.current.innerHTML = "Enter your credentials";
                }
                emailRef?.current?.focus();
                break;

            case !numberRegex.test(credentials) && !emailRegex.test(credentials):
                emailContainerRef?.current?.classList.add("error");
                emailErrorRef?.current?.classList.add("show");
                if (emailErrorRef?.current?.innerHTML) {
                    emailErrorRef.current.innerHTML = "Enter a valid credentials.";
                }
                emailRef?.current?.focus();
                break;

            default:
                //Sign in using otp
                getUserPhoneNumber();
                setShowLoadingAnim(true);
                signInUsingOtp(credentials, window.appVerifier)
                    .then((userExist) => {
                        setShowLoadingAnim(false);
                        if (!userExist) {
                            loginErrorRef?.current?.classList.add("error");
                            loginErrorRef?.current?.classList.add("show");
                            if (loginErrorRef?.current?.innerHTML) {
                                loginErrorRef.current.innerHTML =
                                    "You aren't registered yet...";
                            }
                        } else if (userExist) {
                            setExpandForm(true);
                            setCounter(60);
                        }
                    })
                    .catch((error) => {
                        setShowLoadingAnim(false);
                        alert(error.message);
                    });
                break;
        }
    };

    const goBack = () => {
        setCounter(0);
        setExpandForm(false);
    };

    function onVerifyOtpSubmit() {
        clearError();
        if (otp === "") {
            otpErrorRef?.current?.classList.add("error");
            otpErrorRef?.current?.classList.add("show");
            if (otpErrorRef?.current?.innerHTML) {
                otpErrorRef.current.innerHTML =
                    "Please enter OTP you received on provided number.";
            }
        } else {
            verifyOtpAfterLogin(otp, credentials, partnerID).then(() => {
                localStorage.setItem("voyaahCustomerLoggedIn", "true");
                navigate("/");
            }).catch(function (error) {
                if (
                    error.message === "Firebase: Error (auth/invalid-verification-code)."
                ) {
                    otpErrorRef?.current?.classList.add("error");
                    otpErrorRef?.current?.classList.add("show");
                    if (otpErrorRef?.current?.innerHTML) {
                        otpErrorRef.current.innerHTML = "Invalid Code";
                    }
                }
            });
        }
    }

    const resendOTP = () => {
        // setAppVerified(false);
        if (window.appVerifier && otpTry < 3) {
            setCounter(30);
            signInUsingOtp(credentials, window.appVerifier);
        } else {
            otpErrorRef?.current?.classList.add("error");
            otpErrorRef?.current?.classList.add("show");
            if (otpErrorRef?.current?.innerHTML) {
                otpErrorRef.current.innerHTML =
                    "Please try signing up with different phone number or email ID.";
                setExpandForm(false);
                setOtpTry(0);
            }
        }
    };

    const handleSignInWithPassword = async () => {
        const emailRegex = new RegExp(regexData.email);
        const numberRegex = new RegExp(regexData.mobileNumberCompact);
        const partnerRegex = new RegExp(regexData.cubVistaraPartnerID);

        clearError();

        switch (true) {
            case !credentials:
                emailContainerRef?.current?.classList.add("error");
                emailErrorRef?.current?.classList.add("show");
                if (emailErrorRef?.current?.innerHTML) {
                    emailErrorRef.current.innerHTML = "Enter your credentials";
                }
                emailRef?.current?.focus();
                break;

            case !emailRegex.test(credentials) && !numberRegex.test(credentials):
                emailContainerRef?.current?.classList.add("error");
                emailErrorRef?.current?.classList.add("show");
                if (emailErrorRef?.current?.innerHTML) {
                    emailErrorRef.current.innerHTML = "Enter valid credentials";
                }
                emailRef?.current?.focus();
                break;

                case !partnerID:
                    partnerIdContainerRef?.current?.classList.add("error");
                    partnerIdErrorRef?.current?.classList.add("show");
                    if (partnerIdErrorRef?.current?.innerHTML) {
                      partnerIdErrorRef.current.innerHTML = "Enter an ID";
                    }
                    partnerIdRef?.current?.focus();
                    break;
            
                  case !partnerRegex.test(partnerID):
                    partnerIdContainerRef?.current?.classList.add("error");
                    partnerIdErrorRef?.current?.classList.add("show");
                    if (partnerIdErrorRef?.current?.innerHTML) {
                      partnerIdErrorRef.current.innerHTML = "Enter a valid ID";
                    }
                    partnerIdRef?.current?.focus();
                    break;

            default:
            await checkLoginCredential(credentials).then(result => {
                if(result.length !== 0) {
                result.forEach(doc => {
                    if (doc.exists() && doc.data().email === credentials ) {
                    sessionStorage.setItem("email", credentials);
                    setShowPasswordField(true);
                    // navigate(`/staycations`);
                } else {
                    emailContainerRef?.current?.classList.add("error");
                    emailErrorRef?.current?.classList.add("show");
                    if (emailErrorRef?.current?.innerHTML) {
                        emailErrorRef.current.innerHTML = "This email is not register yet";
                    }
                    emailRef?.current?.focus();
                }
                });
            } else {
                emailContainerRef?.current?.classList.add("error");
                emailErrorRef?.current?.classList.add("show");
                if (emailErrorRef?.current?.innerHTML) {
                    emailErrorRef.current.innerHTML = "This email is not register yet";
                }
                emailRef?.current?.focus();
            }
            });
                // setShowPasswordField(!handleSignInWithPasswordhandleSignInWithPassword);
                break;
        }
    };

    const getUserPhoneNumber = async () => {
        const emailRegex = new RegExp(regexData.email);
        const numberRegex = new RegExp(regexData.mobileNumberCompact);
        let phoneNumber = "";
        if (emailRegex.test(credentials)) {
            phoneNumber = await getNumberFromEmail(credentials);
            // phoneNumber = await getEmailFromNumber(credentials, "")
        }

        if (numberRegex.test(credentials)) {
            phoneNumber = credentials;
        }
        setNumberSentTo(phoneNumber.substring(6, 10));
        return phoneNumber;
    };

    return (
        <>
            {!expandForm && (
                <div>
                    {!showPasswordField && (
                        <div className="form">
                            <div className="form-fields" ref={emailContainerRef}>
                                <label>Email or Phone Number</label>
                                <input
                                    ref={emailRef}
                                    type="text"
                                    placeholder="Enter Email or Phone Number"
                                    value={credentials}
                                    maxLength={50}
                                    onChange={(e) => setCredentials(e.target.value)}
                                    required
                                />
                                <p className="error-msg" ref={emailErrorRef}>
                                    Error Text
                                </p>
                            </div>
                            <div className="form-fields" ref={partnerIdContainerRef}>
                                <label>Partner ID</label>
                                <input
                                    ref={partnerIdRef}
                                    type="text"
                                    placeholder="Enter your partner id (e.g Club Vistara id)"
                                    value={partnerID}
                                    maxLength={50}
                                    onChange={(e) => setPartnerID(e.target.value)}
                                    required
                                />
                                <p className="error-msg" ref={partnerIdErrorRef}>
                                    Error Text
                                </p>
                            </div>
                            <div className="form-fields no-bg">
                                <input
                                    type="button"
                                    value="Sign In with Password"
                                    className="green-btn login"
                                    style={{ marginRight: "20px" }}
                                    onClick={() => handleSignInWithPassword()}
                                />
                                <input
                                    type="button"
                                    value="Sign In with OTP"
                                    className="green-btn login"
                                    onClick={() => handleOtpRequest()}
                                />
                            </div>
                            <div className="form-fields no-bg">
                                <p className="error-msg" ref={loginErrorRef}>
                                    Error Text
                                </p>
                            </div>
                            <div className="form-fields no-bg">
                                <div className="left">
                                    <input
                                        type="checkbox"
                                        name="remember"
                                        className="checkbox"
                                    />{" "}
                                    Remember me
                                </div>
                                <div className="right">
                                    <Link to="/forgot-password">Forgot Password</Link>
                                </div>
                            </div>
                            {/* <div className="form-fields no-bg">
                <div className="right-align">
                  <p onClick={handleOtpRequest}>Sign-In using OTP</p>
                </div>
              </div> */}
                        </div>
                    )}
                </div>
            )}
            {!expandForm && (
                <div>
                    {showPasswordField && (
                        <div className="form">
                            <div className="form-fields" ref={passwordContainerRef}>
                                <label>Password</label>
                                <input
                                    type={showPasswordText ? "text" : "password"}
                                    placeholder="Enter Password"
                                    value={password}
                                    ref={passwordRef}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <span className="icon">
                                    <img
                                        src={require("../../assets/images/eye-icon.png")}
                                        alt=""
                                        className="img-fluid"
                                        onClick={() => setShowPasswordText(!showPasswordText)}
                                    />
                                </span>
                                <p className="error-msg" ref={passwordErrorRef}>
                                    Error Text
                                </p>
                            </div>
                            <div className="form-fields no-bg" style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                <p className="error-msg" ref={loginErrorRef}>
                                    Error Text
                                </p>
                                <input
                                    type="button"
                                    value="Sign In"
                                    className="green-btn"
                                    onClick={() => validateDetailsAndLoginWithPassword()}
                                />
                            </div>
                            <div className="form-fields no-bg">
                                <div className="right-align">
                                    <p
                                        onClick={() =>
                                            setShowPasswordField(!showPasswordField)
                                        }
                                    >
                                        Change E-mail/Phone Number
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
            {expandForm && (
                <div>
                    <div className="form-fields" ref={emailContainerRef}>
                        <label>OTP sent to number ending with {numberSentTo}.</label>
                        <input
                            ref={emailRef}
                            type="text"
                            placeholder="Enter OTP sent to your phone"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            required
                            maxLength={6}
                        />
                        <p className="error-msg" ref={emailErrorRef}>
                            Error Text
                        </p>
                    </div>
                    {/* <div className="form-fields no-bg">
                <input
                  type="button"
                  value="Change Phone Number"
                  className="gray-btn mr-5"
                  onClick={goBack}
                />
              </div> */}
                    <div className="form-fields no-bg">
                        <button
                            className="resend-btn"
                            onClick={resendOTP}
                            disabled={counter > 0}
                            color="green"
                        >
                            Resend OTP{" "}
                            {counter > 0 && (
                                <span style={{ color: "green", fontWeight: "bold" }}>
                                    in {counter} sec
                                </span>
                            )}
                        </button>
                    </div>
                    <div className="form-fields no-bg" style={{ flexDirection: 'column', alignItems: 'center' }}>
                        <p className="error-msg" ref={otpErrorRef}>
                            Error Text
                        </p>
                        <input
                            type="button"
                            value="Submit"
                            className="green-btn"
                            onClick={onVerifyOtpSubmit}
                        />
                    </div>
                    <div className="form-fields no-bg">
                        <div className="right-align">
                            <p onClick={goBack}>Change E-mail/Phone Number</p>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default LoginForm;