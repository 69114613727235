import React, { useEffect, useState } from "react";
import { createImageAltText } from "../../helpers/utils";
import _ from "underscore";

import "./Details.scss";

function RoomTypesContainer(props: any) {
  const { imageArr, roomDescription } = props;

  const [shownImageIndex, setShownImageIndex] = useState(0);

  useEffect(() => {
    setShownImageIndex(0);
  }, [imageArr]);

  const updateImage = (data: string) => {
    if (data === "left" && shownImageIndex > 0) {
      setShownImageIndex(shownImageIndex - 1);
    } else if (data === "left" && shownImageIndex === 0) {
      setShownImageIndex(_.size(imageArr) - 1);
    } else if (data === "right" && shownImageIndex < _.size(imageArr) - 1) {
      setShownImageIndex(shownImageIndex + 1);
    } else if (data === "right" && shownImageIndex == _.size(imageArr) - 1) {
      setShownImageIndex(0);
    }
  };

  return (
    <>
      {imageArr?.[shownImageIndex] && (
        <div className="image room-image-div">
          <div className="nav-left" onClick={() => updateImage("left")}>
            {/* {shownImageIndex === 0 && <div className="left-disabled"></div>} */}
            {/* {shownImageIndex !== 0 &&  */}
            <div className="left-active"></div>
            {/* } */}
          </div>
          <img
            src={imageArr[shownImageIndex].imageUrl}
            // src="https://www.jagranimages.com/images/newimg/22082024/22_08_2024-rohit_sharma_7_23782400.jpg"
            alt={createImageAltText(imageArr[shownImageIndex]?.imageCaption)}
            className="img-fluid room-type-image"
            style={{ objectFit: "cover", background: "rgba(0,0,0,0.2)" }}
          />
          <div className="nav-right" onClick={() => updateImage("right")}>
            {/* {shownImageIndex === _.size(imageArr) - 1 && (
              <div className="right-disabled"></div>
            )}
            {shownImageIndex !== _.size(imageArr) - 1 && ( */}
            <div className="right-active"></div>
            {/* )} */}
          </div>
        </div>
      )}
      {roomDescription && 
      <p
        className="description-show-full-text"
        dangerouslySetInnerHTML={{
          __html: roomDescription,
        }}
      />}
    </>
  );
}

export default RoomTypesContainer;
