import React, { useState, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import {
    auth,
    verifyOtpRegistration,
    registerUsingPhoneNumber
} from "../../helpers/firebase";

const LoginForm = (props: any) => {
    const [otp, setOtp] = useState("");
    const [appVerified, setAppVerified] = useState(false);
    const [otpTry, setOtpTry] = useState(0);

    const emailRef = useRef<HTMLInputElement>(null);
    const emailContainerRef = useRef<HTMLDivElement>(null);
    const emailErrorRef = useRef<HTMLParagraphElement>(null);
    const passwordContainerRef = useRef<HTMLDivElement>(null);
    const passwordErrorRef = useRef<HTMLParagraphElement>(null);
    const loginErrorRef = useRef<HTMLParagraphElement>(null);
    const otpErrorRef = useRef<HTMLParagraphElement>(null);
    const navigate = useNavigate();

    const clearError = () => {
        emailContainerRef?.current?.classList.remove("error");
        emailErrorRef?.current?.classList.remove("show");
        passwordContainerRef?.current?.classList.remove("error");
        passwordErrorRef?.current?.classList.remove("show");
        loginErrorRef?.current?.classList.remove("show");
    };

    function onVerifyOtpSubmit() {
        clearError();
        if (otp === "") {
            otpErrorRef?.current?.classList.add("error");
            otpErrorRef?.current?.classList.add("show");
            if (otpErrorRef?.current?.innerHTML) {
                otpErrorRef.current.innerHTML =
                    "Please enter OTP you received on provided number.";
            }
        } else {
            verifyOtpRegistration(otp, props.registrationData).then(() => {
                navigate("/");
                // const emailData = sessionStorage.getItem("email");
                // const partnerUserData = sessionStorage.getItem("partnerUser");
                // if(emailData && partnerUserData) {
                //     navigate("/staycations");
                // }
            }).catch(function (error) {
                if (
                    error.message === "Firebase: Error (auth/invalid-verification-code)."
                ) {
                    otpErrorRef?.current?.classList.add("error");
                    otpErrorRef?.current?.classList.add("show");
                    if (otpErrorRef?.current?.innerHTML) {
                        otpErrorRef.current.innerHTML = "Invalid Code";
                    }
                }
            });
        }
    }

    const resendOTP = () => {
        props.setRecaptcha();
        setAppVerified(false);
        if (window.appVerifier && otpTry < 3) {
            props.setCounterFunction(30);
              registerUsingPhoneNumber(props.registrationData.phoneNumber, window.appVerifier);
        } else {
            otpErrorRef?.current?.classList.add("error");
            otpErrorRef?.current?.classList.add("show");
            if (otpErrorRef?.current?.innerHTML) {
                otpErrorRef.current.innerHTML =
                    "Please try signing up with different phone number or email ID.";
                setOtpTry(0);
            }
        }
    };

    return (
        <>
        <div>
            <div className="form-fields" ref={emailContainerRef}>
                {/* <label>OTP sent to number ending with {numberSentTo}.</label> */}
                <input
                    ref={emailRef}
                    type="text"
                    placeholder="Enter OTP sent to your phone"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    required
                    maxLength={6}
                />
                <p className="error-msg" ref={emailErrorRef}>
                    Error Text
                </p>
            </div>
            <div className="form-fields no-bg">
                <button
                    className="resend-btn"
                    onClick={resendOTP}
                    disabled={props.counter > 0}
                    color="green"
                >
                    Resend OTP{" "}
                    {props.counter > 0 && (
                        <span style={{ color: "green", fontWeight: "bold" }}>
                            in {props.counter} sec
                        </span>
                    )}
                </button>
            </div>
            <div className="form-fields no-bg" style={{ flexDirection: 'column', alignItems: 'center' }}>
                <p className="error-msg" ref={otpErrorRef}>
                    Error Text
                </p>
                <input
                    type="button"
                    value="Submit"
                    className="green-btn"
                    onClick={onVerifyOtpSubmit}
                />
            </div>
            <div className="form-fields no-bg">
                <div className="right-align">
                    <p onClick={props.changePhoneNumber}>Change Phone Number</p>
                </div>
            </div>
        </div>
        </>
    );
}

export default LoginForm;