import { takeLatest, put, call } from "redux-saga/effects";

import { GET_GROUP_BOOKING_DATA } from "./actionTypes";

import { getGroupBookingDataSuccess, getGroupBookingDataFail } from "./actions";

import { getGroupBookingData } from "../../helpers/backend_helper";

function* onGetGroupBookingData({
  payload: pageUrl,
  customerProfileId,
  accountId,
}) {
  try {
    const response = yield call(
      getGroupBookingData,
      pageUrl,
      customerProfileId,
      accountId
    );
    yield put(getGroupBookingDataSuccess(response));
  } catch (error) {
    yield put(getGroupBookingDataFail(error.response));
  }
}

function* GroupBookingDataSaga() {
  yield takeLatest(GET_GROUP_BOOKING_DATA, onGetGroupBookingData);
}

export default GroupBookingDataSaga;
