import { takeLatest, put, call } from "redux-saga/effects";

import { GET_TERMSANDCONDITION_DATA } from "./actionTypes";

import {
  getTermsAndConditionDataSuccess,
  getTermsAndConditionDataFail,
} from "./actions";

import { getTermsAndConditionData } from "../../helpers/backend_helper";

function* onGetTermsAndConditionData({
  payload: pageUrl,
  customerProfileId,
  accountId,
}) {
  try {
    const response = yield call(
      getTermsAndConditionData,
      pageUrl,
      customerProfileId,
      accountId
    );
    yield put(getTermsAndConditionDataSuccess(response));
  } catch (error) {
    yield put(getTermsAndConditionDataFail(error.response));
  }
}

function* TermsAndConditionDataSaga() {
  yield takeLatest(GET_TERMSANDCONDITION_DATA, onGetTermsAndConditionData);
}

export default TermsAndConditionDataSaga;
