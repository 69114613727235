import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ReactPixel from 'react-facebook-pixel';

import "./UnderConstruction.scss";

function UnderConstruction() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Under Construction | Voyaah";
    ReactPixel.fbq('init', process.env.REACT_APP_FBPIXEL_ID);
    ReactPixel.fbq('track', 'PageView');
  }, []);

  return (
    <div className="inner-page details-page">
      <div className="section">
        <div className="breadcrum">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>›</li>
            <li>Under Construction</li>
          </ul>
        </div>

        <div className="error-blk">
          <div className="image">
            <img
              src={require("../../assets/images/under-construction.png")}
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="head">Under Construction</div>
          <div className="content">
            <p>
              We'r sorry, the page you requested could not be found. Please go
              back to the <Link to="/">homepage</Link>!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UnderConstruction;
