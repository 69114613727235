import React, { useEffect, useState } from "react";
import _ from "underscore";
import ReactPixel from 'react-facebook-pixel';

import PendingPaymentsCard from "./PendingPaymentsCard";

import "./PendingPayments.scss";

function PendingPayments() {
  const [pendingPaymentsData] = useState<any[]>([1]);

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactPixel.fbq('init', process.env.REACT_APP_FBPIXEL_ID);
    ReactPixel.fbq('track', 'PageView');
  }, []);

  return (
    <>
      <div className="my-account-content">
        <div className="my-booking-blk">
          {pendingPaymentsData.length > 0 &&
            pendingPaymentsData?.map((index: any) => {
              return <PendingPaymentsCard key={index} />;
            })}
          {pendingPaymentsData.length === 0 && (
            <div className="info-txt">No record found</div>
          )}
        </div>
      </div>
    </>
  );
}

export default PendingPayments;
