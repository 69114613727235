import {useEffect, useState} from 'react';
import CardLayout from "../card-layout/CardLayout";
import "./DiscoverTray.scss";

function DiscoverTray(props: any) {
  const [dataArr, setDataArr] = useState<any>([]);
  const { data, campaign } = props;
  useEffect(() => {
    if(data?.folder.files !== 0) {
    data.folder.files.sort((a: any, b: any) => {
      if(a.displayIndex !== null) {
        var value;
        value = (a.displayIndex > b.displayIndex) ? 1 : (a.displayIndex < b.displayIndex) ? -1 : 0;
        return value;
    }
    });
    setDataArr(data);
  }
  }, [data]);
  return (
    <div className="deals-section">
      {dataArr.length !== 0 && dataArr?.folder?.files.length !== 0 &&
        <div className="section">
          <h2 className="heading">{dataArr.collectionType}</h2>
          <div className="deals-gallery discover">
            <ul>
              {dataArr?.folder?.files?.map((item: any, index: number) => {
                return (
                  <li key={index}>
                    <CardLayout cardType={"discover"} data={item} folderUrl={dataArr?.folder.url} />
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      }
    </div>
  );
}

export default DiscoverTray;
