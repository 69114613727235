import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import awsConfig from "../../configs/aws";
import regexData from "../../constants/regexData";
import { strings } from "../../constants/strings";
import { Helmet } from "react-helmet";
import ReactPixel from "react-facebook-pixel";
import TestimonialLayout from "../../components/testimonial-layout/TestimonialLayout";
import { getContactDetails } from "../../store/contact/actions";
import "./GroupBooking.scss";
import { getGroupBookingData } from "../../store/groupBooking/actions";
import { createCustomerProfile } from "../../store/customer/actions";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../helpers/firebase";

function GroupBooking() {
  const dispatch = useDispatch();
  const [user, loading] = useAuthState(auth);
  const screenWidth = window.innerWidth;
  const [basePath] = useState(`${awsConfig.s3Path}group-booking/`);
  const [corporateName, setCorporateName] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [groupSize, setGroupSize] = useState("");
  const [preferredLocation, setPreferredLocation] = useState("");
  const [message, setMessage] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [groupBookingData, setGroupBookingData] = useState<any>({});
  const [officialPartner, setOfficialPartner] = useState<any>([]);
  const [clientLoves, setClientLoves] = useState<any>([]);
  const [glimpses, setGlimpses] = useState<any>([]);

  const [metaTags, setMetaTags] = useState<any[]>([]);
  const [breadcrumbs, setBreadcrumbs] = useState<any[]>([]);

  const { customerProfileResponse } = useSelector(
    (state: any) => state.CustomerProfileReducer
  );

  const { response } = useSelector((state: any) => state.ContactDetailsReducer);

  const { groupBookingResponse } = useSelector(
    (state: any) => state.GroupBookingDataReducer
  );

  useEffect(() => {
    setGroupBookingData(groupBookingResponse);
    document.title =
      groupBookingResponse !== undefined
        ? `${groupBookingResponse.titleForPageTab}`
        : "";

    if (groupBookingResponse && groupBookingResponse.mediaCollection) {
      groupBookingResponse?.mediaCollection.map((item: any) => {
        if (item?.layout === "cardType.4X4Gallery") {
          if (item?.folder.files !== 0) {
            item.folder.files.sort((a: any, b: any) => {
              if (a.displayIndex !== null) {
                let value;
                value =
                  a.displayIndex > b.displayIndex
                    ? 1
                    : a.displayIndex < b.displayIndex
                    ? -1
                    : 0;
                return value;
              }
            });
            setOfficialPartner(item);
          }
        } else if (item?.layout === "cardType.TestimonialLayout") {
          if (item?.folder.files !== 0) {
            item.folder.files.sort((a: any, b: any) => {
              if (a.displayIndex !== null) {
                let value;
                value =
                  a.displayIndex > b.displayIndex
                    ? 1
                    : a.displayIndex < b.displayIndex
                    ? -1
                    : 0;
                return value;
              }
            });
            setClientLoves(item);
          }
        } else if (item?.layout === "cardType.3X3Gallery") {
          if (item?.folder.files !== 0) {
            item.folder.files.sort((a: any, b: any) => {
              if (a.displayIndex !== null) {
                let value;
                value =
                  a.displayIndex > b.displayIndex
                    ? 1
                    : a.displayIndex < b.displayIndex
                    ? -1
                    : 0;
                return value;
              }
            });
            setGlimpses(item);
          }
        }
      });
    }

    if (groupBookingResponse?.metaTags) {
      formatMetatagsForPackage(groupBookingResponse.metaTags);
    }

    if (groupBookingResponse?.breadcrumbs) {
      formatBreadcrumbsForPackage(groupBookingResponse.breadcrumbs);
    }
  }, [groupBookingResponse]);

  useEffect(() => {
    if (customerProfileResponse != undefined) {
      const customerTracker = localStorage.getItem("voyaahCustomerTracker");
      const customerConsentStore = localStorage.getItem(
        "voyaahCustomerConsent"
      );

      if (!customerTracker) {
        const customerProfile = {
          id: customerProfileResponse,
        };

        localStorage.setItem(
          "voyaahCustomerTracker",
          JSON.stringify(customerProfile)
        );
      }

      if (!customerConsentStore) {
        const customerConsent = {
          essentialCookieEnabled: true,
          analyticsCookieEnabled: true,
          personalizationCookieEnabled: true,
        };

        localStorage.setItem(
          "voyaahCustomerConsent",
          JSON.stringify(customerConsent)
        );
      }
    }
  }, [customerProfileResponse]);

  useEffect(() => {
    let customerTracker = localStorage.getItem("voyaahCustomerTracker");
    if (customerTracker == null && !loading) {
      const consentTracker = {
        customerProfileId: null,
        accountId: user?.uid ?? null,
        essentialConsent: true,
        analyticsConsent: true,
        personalizationConsent: true,
      };

      dispatch(createCustomerProfile(consentTracker));
    }
  }, [dispatch, loading]);

  useEffect(() => {
    const localStorageData = localStorage.getItem("voyaahCustomerTracker");
    let customerProfileId = "";
    if (localStorageData) {
      customerProfileId = JSON.parse(localStorageData!).id;
    }
    if (!loading) {
      dispatch(
        getGroupBookingData("group-booking", customerProfileId, user?.uid ?? "")
      );
    }
  }, [dispatch, loading]);

  function formatMetatagsForPackage(metaTags: any) {
    const metaTagArray: any[] = [];
    metaTags.forEach((metaTag: { tag: any; value: any }) => {
      const metaTagObject = { key: metaTag.tag, value: metaTag.value };
      metaTagArray.push(metaTagObject);
    });

    setMetaTags(metaTagArray);
  }

  function formatBreadcrumbsForPackage(breadcrumbs: any) {
    const breadcrumbsArray: any[] = [];
    breadcrumbs.forEach((breadcrumb: { name: any; url: any }) => {
      const breadcrumbObject = { name: breadcrumb.name, url: breadcrumb.url };
      breadcrumbsArray.push(breadcrumbObject);
    });

    setBreadcrumbs(breadcrumbsArray);
  }

  const corporateNameContainerRef = useRef<HTMLDivElement>(null);
  const corporateNameInputRef = useRef<HTMLInputElement>(null);
  const corporateNameErrorRef = useRef<HTMLParagraphElement>(null);
  const nameContainerRef = useRef<HTMLDivElement>(null);
  const nameInputRef = useRef<HTMLInputElement>(null);
  const nameErrorRef = useRef<HTMLParagraphElement>(null);
  const phoneNumberContainerRef = useRef<HTMLDivElement>(null);
  const phoneNumberInputRef = useRef<HTMLInputElement>(null);
  const phoneNumberErrorRef = useRef<HTMLParagraphElement>(null);
  const emailContainerRef = useRef<HTMLDivElement>(null);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const emailErrorRef = useRef<HTMLParagraphElement>(null);
  const groupSizeContainerRef = useRef<HTMLDivElement>(null);
  const groupSizeInputRef = useRef<HTMLInputElement>(null);
  const groupSizeErrorRef = useRef<HTMLParagraphElement>(null);
  const preferredLocationContainerRef = useRef<HTMLDivElement>(null);
  const preferredLocationInputRef = useRef<HTMLInputElement>(null);
  const preferredLocationErrorRef = useRef<HTMLParagraphElement>(null);
  const messageContainerRef = useRef<HTMLDivElement>(null);
  const messageInputRef = useRef<HTMLTextAreaElement>(null);
  const messageErrorRef = useRef<HTMLParagraphElement>(null);

  const { loader } = useSelector((state: any) => state.ContactDetailsReducer);
  useEffect(() => {
    window.scrollTo(0, 0);
    ReactPixel.fbq("init", process.env.REACT_APP_FBPIXEL_ID);
    ReactPixel.fbq("track", "PageView");
  }, []);

  const createRequest = () => {
    const data = {
      name: name,
      email: email,
      phoneNumber: phoneNumber,
      message: `Hi, ${name} from ${corporateName} is interested in Group Booking. Message: ${message} They are looking package for ${groupSize} pax at ${preferredLocation}. You can contact them on ${phoneNumber} or ${email}. Thanks!`,
      userNotifyType: "support.groupBooking",
    };
    dispatch(getContactDetails(data));
  };

  useEffect(() => {
    if (response && response.message) {
      setShowSuccessMessage(true);
      setCorporateName("");
      setName("");
      setPhoneNumber("");
      setEmail("");
      setGroupSize("");
      setPreferredLocation("");
      setMessage("");
    }
  }, [response]);

  const clearError = () => {
    corporateNameContainerRef.current?.classList.remove("error");
    corporateNameErrorRef.current?.classList.remove("show");
    nameContainerRef.current?.classList.remove("error");
    nameErrorRef.current?.classList.remove("show");
    phoneNumberContainerRef.current?.classList.remove("error");
    phoneNumberErrorRef.current?.classList.remove("show");
    emailContainerRef.current?.classList.remove("error");
    emailErrorRef.current?.classList.remove("show");
    groupSizeContainerRef.current?.classList.remove("error");
    groupSizeErrorRef.current?.classList.remove("show");
    preferredLocationContainerRef.current?.classList.remove("error");
    preferredLocationErrorRef.current?.classList.remove("show");
    messageContainerRef.current?.classList.remove("error");
    messageErrorRef.current?.classList.remove("show");
  };

  const validate = () => {
    const nameRegex = new RegExp(regexData.name);
    const numberRegex = new RegExp(regexData.mobileNumber);
    const emailRegex = new RegExp(regexData.email);

    clearError();

    switch (true) {
      case !corporateName:
        corporateNameContainerRef.current?.classList.add("error");
        corporateNameErrorRef.current?.classList.add("show");
        corporateNameErrorRef.current!.innerHTML = "Enter corporate name";
        corporateNameInputRef.current?.focus();
        break;

      case !name:
        nameContainerRef.current?.classList.add("error");
        nameErrorRef.current?.classList.add("show");
        nameErrorRef.current!.innerHTML = "Enter name";
        nameInputRef.current?.focus();
        break;

      case !nameRegex.test(name):
        nameContainerRef.current?.classList.add("error");
        nameErrorRef.current?.classList.add("show");
        nameErrorRef.current!.innerHTML = "Enter valid name";
        nameInputRef.current?.focus();
        break;

      case !phoneNumber:
        phoneNumberContainerRef.current?.classList.add("error");
        phoneNumberErrorRef.current?.classList.add("show");
        phoneNumberErrorRef.current!.innerHTML = "Enter mobile";
        phoneNumberInputRef.current?.focus();
        break;

      case !numberRegex.test(phoneNumber):
        phoneNumberContainerRef.current?.classList.add("error");
        phoneNumberErrorRef.current?.classList.add("show");
        phoneNumberErrorRef.current!.innerHTML = "Enter valid mobile";
        phoneNumberInputRef.current?.focus();
        break;

      case !email:
        emailContainerRef.current?.classList.add("error");
        emailErrorRef.current?.classList.add("show");
        emailErrorRef.current!.innerHTML = "Enter email";
        emailInputRef.current?.focus();
        break;

      case !emailRegex.test(email):
        emailContainerRef.current?.classList.add("error");
        emailErrorRef.current?.classList.add("show");
        emailErrorRef.current!.innerHTML = "Enter valid email";
        emailInputRef.current?.focus();
        break;

      case !groupSize:
        groupSizeContainerRef.current?.classList.add("error");
        groupSizeErrorRef.current?.classList.add("show");
        groupSizeErrorRef.current!.innerHTML = "Enter group size";
        groupSizeInputRef.current?.focus();
        break;

      case !preferredLocation:
        preferredLocationContainerRef.current?.classList.add("error");
        preferredLocationErrorRef.current?.classList.add("show");
        preferredLocationErrorRef.current!.innerHTML =
          "Enter preferred location";
        preferredLocationInputRef.current?.focus();
        break;

      case !message:
        messageContainerRef.current?.classList.add("error");
        messageErrorRef.current?.classList.add("show");
        messageErrorRef.current!.innerHTML = "Enter message";
        messageInputRef.current?.focus();
        break;

      default:
        createRequest();
        break;
    }
  };

  return (
    <>
      {groupBookingData && (
        <Helmet>
          {groupBookingData.keywords !== "" && (
            <meta name="keywords" content={groupBookingData.keywords} />
          )}
          {metaTags.length > 0 &&
            metaTags.map((metaTag, index) => {
              return <meta property={metaTag.key} content={metaTag.value} />;
            })}
          {breadcrumbs.length > 0 && (
            <script type="application/ld+json">
              {`{
                  "@context": "https://schema.org",
                  "@type": "BreadcrumbList",
                  "itemListElement": ${JSON.stringify(
                    breadcrumbs.map((item, index) => ({
                      "@type": "ListItem",
                      position: index + 1,
                      name: item.name,
                      item: item.url,
                    }))
                  )}}`}
            </script>
          )}
        </Helmet>
      )}
      {groupBookingData && (
        <div className="inner-page details-page">
          <div className="group-booking-banner">
            <div className="background-overlay"></div>
            {groupBookingData.mediaCollection &&
            groupBookingData.mediaCollection[0] &&
            groupBookingData.mediaCollection[0].folder?.files[0] ? (
              <img
                src={`${groupBookingData.mediaCollection[0].folder?.url}/${groupBookingData.mediaCollection[0].folder?.files[0].fileName}`}
                alt=""
                className="img-fluid"
              />
            ) : (
              <img
                src={require("../../assets/images/group-booking-banner_new.jpg")}
                alt=""
                className="img-fluid"
              />
            )}
          </div>
          <div className="section">
            <div className="breadcrum group-booking">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>›</li>
                <li>{groupBookingData.name}</li>
              </ul>
            </div>
          </div>

          <div>
            <div className="section">
              <div className="group-booking-blk">
                <h1 className="head">{groupBookingData.title}</h1>
                <p
                  className="content align"
                  dangerouslySetInnerHTML={{
                    __html: groupBookingData.description,
                  }}
                />
              </div>
            </div>
          </div>

          {groupBookingResponse &&
            groupBookingResponse.contentSection &&
            groupBookingResponse.contentSection[0] &&
            (groupBookingResponse.contentSection[0].contentItem[0] ||
              groupBookingResponse.contentSection[0].contentItem[1]) && (
              <div>
                <div className="section">
                  <div className="group-booking-blk">
                    {groupBookingResponse.contentSection[0] && (
                      <h2 className="head">
                        {groupBookingResponse.contentSection[0]?.name}
                      </h2>
                    )}
                    {groupBookingResponse.contentSection[0].contentItem !==
                      null && (
                      <div
                        style={
                          screenWidth > 600 ? styles.desktopContentSection : {}
                        }
                      >
                        {groupBookingResponse.contentSection[0]
                          .contentItem[0] ? (
                          <p
                            className="content"
                            dangerouslySetInnerHTML={{
                              __html:
                                groupBookingResponse.contentSection[0]
                                  .contentItem[0].content,
                            }}
                          />
                        ) : (
                          ""
                        )}
                        {groupBookingResponse.contentSection[0]
                          .contentItem[1] ? (
                          <p
                            className="content"
                            style={
                              screenWidth < 768 ? styles.contentMobile : {}
                            }
                            dangerouslySetInnerHTML={{
                              __html:
                                screenWidth > 768
                                  ? `${groupBookingResponse.contentSection[0].contentItem[1].content}`
                                  : `<div style="margin-top: -13px;">${groupBookingResponse.contentSection[0].contentItem[1].content}<div>`,
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          {officialPartner.length !== 0 &&
            officialPartner.folder.files.length !== 0 && (
              <div className="group-booking gray-bg">
                <div className="section">
                  <div className="group-booking-blk partners">
                    <h2 className="head">{officialPartner?.collectionType}</h2>
                    <div className="partners-blk">
                      {officialPartner.folder.files.map((imageData: any) => {
                        return (
                          <div className="partners-content">
                            <div className="image">
                              <img
                                src={`${
                                  officialPartner.folder.url +
                                  "/" +
                                  imageData.fileName
                                }`}
                                alt={imageData.fileName}
                                className="img-fluid"
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            )}

          <div>
            <div className="section">
              <div className="group-booking-blk">
                <h2 className="head">{strings.groupBooking.contactUs}</h2>
                <div className="form-fields">
                  <div className="form" ref={corporateNameContainerRef}>
                    <label>Corporate Name</label>
                    <input
                      ref={corporateNameInputRef}
                      type="text"
                      placeholder="Voyaah"
                      value={corporateName}
                      onChange={(e) => setCorporateName(e.target.value)}
                      maxLength={50}
                    />
                    <p className="error-msg" ref={corporateNameErrorRef}>
                      Error Text
                    </p>
                  </div>
                  <div className="form" ref={nameContainerRef}>
                    <label>Your Name</label>
                    <input
                      ref={nameInputRef}
                      type="text"
                      placeholder="John Doe"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      maxLength={50}
                    />
                    <p className="error-msg" ref={nameErrorRef}>
                      Error Text
                    </p>
                  </div>
                  <div className="form" ref={phoneNumberContainerRef}>
                    <label>Mobile Number</label>
                    <input
                      ref={phoneNumberInputRef}
                      type="text"
                      placeholder="9876543210"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      maxLength={10}
                    />
                    <p className="error-msg" ref={phoneNumberErrorRef}>
                      Error Text
                    </p>
                  </div>
                  <div className="form" ref={emailContainerRef}>
                    <label>Email ID</label>
                    <input
                      ref={emailInputRef}
                      type="text"
                      placeholder="john.deo@gmail.com"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      maxLength={50}
                    />
                    <p className="error-msg" ref={emailErrorRef}>
                      Error Text
                    </p>
                  </div>
                  <div className="form" ref={groupSizeContainerRef}>
                    <label>Group Size</label>
                    <input
                      ref={groupSizeInputRef}
                      type="text"
                      placeholder="10"
                      value={groupSize}
                      onChange={(e) => setGroupSize(e.target.value)}
                      maxLength={5}
                    />
                    <p className="error-msg" ref={groupSizeErrorRef}>
                      Error Text
                    </p>
                  </div>
                  <div className="form" ref={preferredLocationContainerRef}>
                    <label>Preferred Location</label>
                    <input
                      ref={preferredLocationInputRef}
                      type="text"
                      placeholder="Goa"
                      value={preferredLocation}
                      onChange={(e) => setPreferredLocation(e.target.value)}
                      maxLength={20}
                    />
                    <p className="error-msg" ref={preferredLocationErrorRef}>
                      Error Text
                    </p>
                  </div>
                  <div className="clear"></div>
                  <div className="form w100" ref={messageContainerRef}>
                    <label>Message</label>
                    <textarea
                      ref={messageInputRef}
                      placeholder="Your Message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      maxLength={250}
                    />
                    <p className="error-msg" ref={messageErrorRef}>
                      Error Text
                    </p>
                  </div>

                  <div className="button-form">
                    <input
                      type="submit"
                      value="Submit"
                      className="submit"
                      onClick={validate}
                    />
                  </div>
                  <div className="form-fields mb0 tac">
                    For any enquires you can also reach us at{" "}
                    <span>022 - 48930225</span> or write to us at{" "}
                    <span>info@voyaah.com</span>
                  </div>
                  {showSuccessMessage && (
                    <div className="form-fields tac">
                      <span>
                        Thank you for contacting us. We will reach you.
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {clientLoves.length !== 0 && clientLoves.folder?.files ? (
            clientLoves.folder.files.length > 1 ? (
              <TestimonialLayout
                key={`testimonial_layout`}
                data={clientLoves}
              />
            ) : (
              clientLoves.folder.files.length === 1 && (
                <div>
                  <div className="section">
                    <div className="group-booking-blk">
                      <div className="head text-center">
                        {clientLoves?.collectionType}
                      </div>
                      <div className="testimonial-section">
                        <div className="testimonial-blk">
                          <div className="testimonial-blk-left">
                            <div className="vcenter">
                              <div className="user-pic">
                                <img
                                  src={`${clientLoves.folder.url}/${clientLoves.folder.files[0].fileName}`}
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                              <div className="user-name">
                                {clientLoves.folder.files &&
                                  clientLoves.folder.files[0].cardAttributes
                                    .title}
                              </div>
                              <div className="user-designation">
                                {clientLoves.folder.files &&
                                  clientLoves.folder.files[0].cardAttributes
                                    .subTitle}
                              </div>
                            </div>
                          </div>
                          <div className="testimonial-blk-right">
                            <div className="user-testimonial">
                              <span className="top-quotes"></span>
                              <span className="testimonial-message">
                                {clientLoves.folder.files &&
                                  clientLoves.folder.files[0].cardAttributes
                                    .highlightText}
                              </span>
                              <span className="bottom-quotes"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )
          ) : (
            ""
          )}

          {glimpses.length !== 0 && glimpses.folder?.files?.length !== 0 && (
            <div>
              <div className="section">
                <div className="group-booking-blk glimpses">
                  <div className="subhead">{glimpses?.collectionType}</div>
                  <div className="our-client-love-us">
                    {glimpses.folder.files.map((data: any) => {
                      return (
                        <ul>
                          <li>
                            <img
                              src={`${
                                glimpses.folder.url + "/" + data.fileName
                              }`}
                              alt=""
                              className="img-fluid"
                            />
                          </li>
                        </ul>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {loader && (
        <div className="loader-container">
          <div className="loader">
            <Bars color="#00BFFF" height={50} width={100} />
          </div>
        </div>
      )}
    </>
  );
}

const styles = {
  GroupBookingListColumn: { columns: 2 },
  serviceListText: { fontSize: "1em" },
  imageStyle: { height: "120px", width: "210px" },
  desktopContentSection: {
    display: "flex",
    // reason why i mentioned width 100% we cant set particular width in pixel format bcz the content is html content
    width: window.innerWidth >= 1440 ? "1000px": "100%",  
    // marginLeft: "-35px",
  },
  contentMobile: { marginTop: "-55px",width: "100%", },
};

export default GroupBooking;
