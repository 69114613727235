import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import FlipCountdown from "../flip-countdown/FlipCountdown";
import ReactGA from "react-ga4";
import ProgressiveImage from "react-progressive-graceful-image";
import { getAnalyticsEnabled } from "../../helpers/backend_helper";
import "./HeroCard.scss";
import { modifyURLWithBlurTag } from "../../helpers/utils";

function HeroCard(props: any) {
  const navigate = useNavigate();
  const screenWidth = window.innerWidth;
  const { data, campaigns } = props;
  const [campaignObj, setCampaignObj] = useState<any>();

  const [showTimer, setShowTimer] = useState(false);
  const [showTime, setShowTime] = useState(false);
  const [bannerImage, setBannerImage] = useState("");
  const [flipClass, setFlipClass] = useState("small");

  useEffect(() => {
    if (
      data?.cardAttributes?.campaignCode &&
      data?.cardAttributes?.campaignCode?.length > 0 &&
      campaigns.length > 0
    ) {
      let obj = campaigns.find(
        (campaign: { campaignCode: string }) =>
          campaign.campaignCode === data?.cardAttributes?.campaignCode
      );
      if (obj) {
        const endDate = new Date(obj.endsAt);
        const todaysDate = new Date();
        var duration =
          Math.abs(endDate.getTime() - todaysDate.getTime()) / 36e5;

        setCampaignObj(obj);

        if (duration > 0 && duration < 192) {
          setShowTimer(true);
          if (duration > 0 && duration < 72) {
            setShowTime(true);
          } else if (duration > 72 && duration < 192) {
            setShowTime(false);
          }
        } else {
          setShowTimer(false);
          setShowTime(false);
        }
      }
    }

    if (screenWidth > 320 && screenWidth < 767) {
      setBannerImage(data?.url);
      setFlipClass("extra-small");
      // setBannerImage(data?.images.mobilePImageUrl);
    } else if (screenWidth > 767) {
      setBannerImage(data?.url);
      setFlipClass("small");
    }
  }, [data]);
  const navigateToCTA = () => {
    if (props.data?.cardAttributes.ctaUrl) {
      googleAnalyticsFunction("banner cta");
      sessionStorage.setItem("homePageScrollPosition", `${window.pageYOffset}`);
      // navigate(`${`/search/${props.data?.cardAttributes.ctaUrl}`}`);
    }
  };

  const googleAnalyticsFunction = (data: any) => {
    if (getAnalyticsEnabled()) {
      ReactGA.event({
        action: `homepage ${data}`,
        category: `homepage`,
      });
    }
  };
  return (
    <div
      className="main-banner"
      onClick={navigateToCTA}
      style={{ cursor: "pointer", marginBottom: "30px" }}
    >
      <a href={`/search/${props.data?.cardAttributes.ctaUrl}`}>
        <div className="images">
          <div className="background-overlay"></div>
          <ProgressiveImage
            src={bannerImage}
            placeholder={modifyURLWithBlurTag(bannerImage)}
          >
            {(src, loading) => (
              <img
                fetchpriority="high"
                src={`${props.folderUrl + "/" + data.fileName}`} //{src}
                alt={props.data?.cardAttributes.altText}
                className={`img-fluid banner-image${
                  loading ? " loading" : " loaded"
                }`}
                style={{ height: "500px", width: "100%" }}
              />
            )}
          </ProgressiveImage>
          <div style={{ pointerEvents: "none" }} className="section">
            <div className="left-text">
              <h1 className="head">{props.data?.cardAttributes.title}</h1>
              <p className="normal-text" style={{ color: "white" }}>
                {props.data?.cardAttributes.subTitle}
              </p>
              <p className="limited">
                <img src={data?.persuasionIcon} alt="" className="img-fluid" />
                {data?.persuasionText}
              </p>
              {campaignObj && showTimer && (
                <FlipCountdown
                  hideYear
                  hideMonth
                  hideDay={showTime}
                  hideHour={!showTime}
                  hideMinute={!showTime}
                  hideSecond={!showTime}
                  size={flipClass}
                  titlePosition="bottom"
                  dayTitle="DAYS"
                  hourTitle="HRS"
                  minuteTitle="MIN"
                  secondTitle="SEC"
                  endAt={campaignObj?.endsAt}
                />
              )}
              <div className="green-btn">
                {props.data?.cardAttributes.ctaUrl &&
                  props.data?.cardAttributes.ctaText && (
                    <span
                    // onClick={() => navigateToCTA}
                    >
                      {props.data?.cardAttributes.ctaText}
                    </span>
                  )}
              </div>
            </div>
            {data?.rightContent && (
              <>
                {data?.rightContent?.type === "text" && (
                  <div
                    onClick={() =>
                      googleAnalyticsFunction("banner cta - right")
                    }
                    className="right-text"
                    dangerouslySetInnerHTML={{
                      __html: data?.rightContent?.contentHTML,
                    }}
                  ></div>
                )}
                {data?.rightContent?.type === "video" && (
                  <video controls className="right-video">
                    <source
                      src="https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/360/Big_Buck_Bunny_360_10s_1MB.mp4"
                      type="video/mp4"
                    ></source>
                  </video>
                )}
              </>
            )}
          </div>
        </div>
      </a>
    </div>
  );
}

export default HeroCard;
