import {
  GET_ABOUTUS_DATA,
  GET_ABOUTUS_DATA_SUCCESS,
  GET_ABOUTUS_DATA_FAIL,
} from "./actionTypes";

export const getAboutUsData = (
  pageUrl: any,
  customerProfileId: string,
  accountId: string
) => {
  return {
    type: GET_ABOUTUS_DATA,
    payload: pageUrl,
    customerProfileId: customerProfileId,
    accountId: accountId,
  };
};

export const getAboutUsDataSuccess = (data: any) => {
  return {
    type: GET_ABOUTUS_DATA_SUCCESS,
    payload: data,
  };
};

export const getAboutUsDataFail = (error: any) => {
  return {
    type: GET_ABOUTUS_DATA_FAIL,
    payload: error,
  };
};
