import { takeLatest, put, call } from "redux-saga/effects";

import { GET_ABOUTUS_DATA } from "./actionTypes";

import { getAboutUsDataSuccess, getAboutUsDataFail } from "./actions";

import { getAboutUsData } from "../../helpers/backend_helper";

function* onGetAboutUsData({ payload: pageUrl, customerProfileId, accountId }) {
  try {
    const response = yield call(
      getAboutUsData,
      pageUrl,
      customerProfileId,
      accountId
    );
    // const response = yield call(getAboutUsData);
    yield put(getAboutUsDataSuccess(response));
  } catch (error) {
    yield put(getAboutUsDataFail(error.response));
  }
}

function* AboutUsDataSaga() {
  yield takeLatest(GET_ABOUTUS_DATA, onGetAboutUsData);
}

export default AboutUsDataSaga;
