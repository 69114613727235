import { observer } from "mobx-react-lite";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
const MealPlanValidationComponent = observer(
  ({
    closeModal,
    openDialog,
    validationMessage,
  }: {
    closeModal: any;
    openDialog:any,
    validationMessage: string;
  }) => {
    return (
      <Dialog
        open={openDialog}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
      >
        <DialogContent sx={{ backgroundColor: "#222222" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <button
              className="confirmation-cancel-btn"
              style={{ alignSelf: "flex-end" }}
              onClick={() => closeModal(false)}
            >
              &#x2715;
            </button>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <DialogContentText
                id="alert-dialog-description"
                variant="h5"
                sx={{ color: "#fff", textAlign: "center", padding: "30px 0px" }}
              >
                {validationMessage}
              </DialogContentText>
              <button
                className="confirmation-okay-btn"
                style={{
                  color: "white",
                  marginBottom: "12px",
                  padding: "12px 25px",
                }}
                onClick={() => closeModal(false)}
              >
                Okay
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
);

export default MealPlanValidationComponent;
