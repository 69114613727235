import {
    POST_CONTACT_DETAILS,
    POST_CONTACT_DETAILS_SUCCESS,
    POST_CONTACT_DETAILS_FAIL,
    CLEAR_CONTACT_DETAILS,
} from "./actionTypes";

const initialState = {
    response: {},
    loader: false,
    error: {
        message: "",
    },
};

const ContactDetailsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case POST_CONTACT_DETAILS:
            state = { ...state, loader: true };
            break;
        case POST_CONTACT_DETAILS_SUCCESS:
            state = { ...state, response: action.payload, loader: false };
            break;
        case POST_CONTACT_DETAILS_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loader: false,
            };
            break;
        case CLEAR_CONTACT_DETAILS:
            state = { ...state, response: {}, loader: false };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default ContactDetailsReducer;