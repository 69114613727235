import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";

const ValidationComponent = observer(
  ({
    closeModal,
    validationMessage,
    showLoginButton,
    showCloseButton,
  }: {
    closeModal: any;
    validationMessage: string;
    showLoginButton: boolean;
    showCloseButton: boolean;
  }) => {
    const navigate = useNavigate();
    return (
      <div className="confirmation-overlay">
        <div className="confirmation-popup">
          <div className="confirmation-cancel">
            {showCloseButton && (
              <button
                className="confirmation-cancel-btn"
                onClick={() => closeModal(false)}
              >
                &#x2715;
              </button>
            )}
          </div>
          <div className="confirmation-content">
            <div className="confirmation-content-text">
              <p className="content-text-first">{validationMessage}</p>
            </div>
          </div>
          <div className="validation-btn-div">
            {showCloseButton && (
              <button
                className="confirmation-okay-btn"
                style={{ color: "white" }}
                onClick={() => closeModal(false)}
              >
                Go back
              </button>
            )}
            {showLoginButton && (
              <button
                className="confirmation-okay-btn"
                style={{ color: "white" }}
                onClick={() => navigate(`/login`)}
              >
                Login / Sign up
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default ValidationComponent;
