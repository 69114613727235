export const CREATE_CUSTOMERPROFILE = "CREATE_CUSTOMERPROFILE";
export const CREATE_CUSTOMERPROFILE_SUCCESS = "CREATE_CUSTOMERPROFILE_SUCCESS";
export const CREATE_CUSTOMERPROFILE_FAIL = "CREATE_CUSTOMERPROFILE_FAIL";
export const UPDATE_CUSTOMERPROFILE = "UPDATE_CUSTOMERPROFILE";
export const UPDATE_CUSTOMERPROFILE_SUCCESS = "UPDATE_CUSTOMERPROFILE_SUCCESS";
export const UPDATE_CUSTOMERPROFILE_FAIL = "UPDATE_CUSTOMERPROFILE_FAIL";
export const SAVE_CUSTOMERINTERESTS = "SAVE_CUSTOMERINTERESTS";
export const SAVE_CUSTOMERINTERESTS_FAIL = "SAVE_CUSTOMERINTERESTS_FAIL";
export const SAVE_CUSTOMERINTERESTS_SUCCESS = "SAVE_CUSTOMERINTERESTS_SUCCESS";
export const GET_CUSTOMERINTERESTS = "GET_CUSTOMERINTERESTS";
export const GET_CUSTOMERINTERESTS_FAIL = "GET_CUSTOMERINTERESTS_FAIL";
export const GET_CUSTOMERINTERESTS_SUCCESS = "GET_CUSTOMERINTERESTS_SUCCESS";
