import {
  GET_PACKAGE_SUMMARY,
  GET_PACKAGE_SUMMARY_SUCCESS,
  GET_PACKAGE_SUMMARY_FAIL,
  GET_LISTING_PAGE_DATA,
  GET_LISTING_PAGE_DATA_SUCCESS,
  GET_LISTING_PAGE_DATA_FAIL,
} from "./actionTypes";

export const getPackageSummary = (data: any) => {
  return {
    type: GET_PACKAGE_SUMMARY,
    payload: data,
  };
};

export const getPackageSummarySuccess = (packages: any) => {
  return {
    type: GET_PACKAGE_SUMMARY_SUCCESS,
    payload: packages,
  };
};

export const getPackageSummaryFail = (error: any) => {
  return {
    type: GET_PACKAGE_SUMMARY_FAIL,
    payload: error,
  };
};

export const getListingPageData = (
  pageUrl: any,
  customerProfileId: string,
  accountId: string
) => {
  return {
    type: GET_LISTING_PAGE_DATA,
    payload: pageUrl,
    customerProfileId: customerProfileId,
    accountId: accountId,
  };
};

export const getListingPageDataSuccess = (data: any) => {
  return {
    type: GET_LISTING_PAGE_DATA_SUCCESS,
    payload: data,
  };
};

export const getListingPageDataFail = (error: any) => {
  return {
    type: GET_LISTING_PAGE_DATA_FAIL,
    payload: error,
  };
};
