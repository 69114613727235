import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../helpers/firebase";
import { createCustomerProfile } from "../../store/customer/actions";
import "./CookiePolicy.scss";
import ReactSwitch from "react-switch";
import { useDispatch, useSelector } from "react-redux";

function CookiePolicy() {
  const [user, loading] = useAuthState(auth);
  const consentState = localStorage.getItem("voyaahCustomerConsent");

  let initialEssentialCookie = true;
  let initialAnalyticsCookie = true;
  let initialPersonalizationCookie = true;

  if (consentState) {
    const jsonConsent = JSON.parse(consentState);
    initialEssentialCookie = jsonConsent.essentialCookieEnabled;
    initialAnalyticsCookie = jsonConsent.analyticsCookieEnabled;
    initialPersonalizationCookie = jsonConsent.personalizationCookieEnabled;
  }

  const [essentialCookieEnabled, setEssentialCookieEnabled] = useState(
    initialEssentialCookie
  );
  const [analyticsCookieEnabled, setAnalyticsCookieEnabled] = useState(
    initialAnalyticsCookie
  );
  const [personalizationCookieEnabled, setPersonalizationCookieEnabled] =
    useState(initialPersonalizationCookie);

  let dispatch = useDispatch();

  const { customerProfileResponse } = useSelector(
    (state: any) => state.CustomerProfileReducer
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Cookie Policy | Voyaah";
    ReactPixel.fbq("init", process.env.REACT_APP_FBPIXEL_ID);
    ReactPixel.fbq("track", "PageView");
  }, [loading]);

  useEffect(() => {
    if (customerProfileResponse !== undefined) {
      const customerProfile = {
        id: customerProfileResponse,
      };

      const customerConsent = {
        essentialCookieEnabled: essentialCookieEnabled,
        analyticsCookieEnabled: analyticsCookieEnabled,
        personalizationCookieEnabled: personalizationCookieEnabled,
      };

      localStorage.removeItem("voyaahCustomerTracker");
      localStorage.removeItem("voyaahCustomerConsent");

      localStorage.setItem(
        "voyaahCustomerTracker",
        JSON.stringify(customerProfile)
      );

      localStorage.setItem(
        "voyaahCustomerConsent",
        JSON.stringify(customerConsent)
      );
    }
  }, [
    essentialCookieEnabled,
    analyticsCookieEnabled,
    personalizationCookieEnabled,
    customerProfileResponse,
  ]);

  function saveConsentPreferences() {
    let customerTracker = localStorage.getItem("voyaahCustomerTracker");
    let customerProfileId = "";
    if (customerTracker) {
      customerProfileId = JSON.parse(customerTracker!).id;
    }

    const consentTracker = {
      customerProfileId: customerProfileId ?? null,
      accountId: user?.uid ?? null,
      essentialConsent: essentialCookieEnabled,
      analyticsConsent: analyticsCookieEnabled,
      personalizationConsent: personalizationCookieEnabled,
    };

    dispatch(createCustomerProfile(consentTracker));
  }

  return (
    <div className="inner-page details-page">
      <div className="section">
        <div className="breadcrum">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>›</li>
            <li>Cookie Policy</li>
          </ul>
        </div>

        <div className="inner-heading">
          <div className="head text-center">Cookie Policy</div>
        </div>

        <div className="privacy-blk">
          {/* <p className="bold">Purpose</p> */}
          <p>
            This Cookie Policy (“Policy”) is a part of and must be read along
            with the Terms of Use and the Privacy Policy. Terms used but not
            defined in this Policy shall have the meanings ascribed to them in
            the Terms of Use and the Privacy Policy. This Policy applies to the
            Sales Channels, any website, branded pages on third-party platforms
            (such as Facebook, LinkedIn, Twitter, and YouTube), and applications
            accessed or used through the Sales Channels, etc. which are operated
            by us or on our behalf. By using the Sales Channels, you consent to
            our use of cookies in accordance with this Policy. If you do not
            agree to our use of cookies, you should set your browser settings
            accordingly or terminate your use of the Sales Channels. Disabling
            cookies may impact your user experience while on the Sales Channels.
          </p>

          <p className="bold">About Cookies</p>
          <p>
            Cookies are small pieces of text stored by a website you visit in
            your browser and subsequently sent by your web browser in every
            request to the website. A cookie file is stored in your web browser
            and allows the concerned website or a third-party to recognize you
            and make your next visit easier more personalised. Cookies allow us
            to serve you better and more efficiently, and to personalize your
            experience on the Sales Channels.
          </p>

          <p className="bold">Personalize your Voyaah Experience</p>
          <div className="consent-popup-body">
            <div>
              <div className="consent-popup-body-toggle">
                <ReactSwitch
                  onColor="#2E8B98"
                  disabled
                  className="toggle-switch"
                  checked={essentialCookieEnabled}
                  onChange={(value) => {
                    setEssentialCookieEnabled(value);
                  }}
                />
                <h3>Essential</h3>
              </div>
              <p>
                These Cookies are essential to provide you with services
                available through the Sales Channels and to enable you to use
                some of its features. They help to authenticate users and
                prevent fraudulent use of user accounts. Without these cookies,
                the services that you have asked for cannot be provided, and we
                use these cookies only to provide you with those services.
              </p>
            </div>
            <div>
              <div className="consent-popup-body-toggle">
                <ReactSwitch
                  onColor="#2E8B98"
                  className="toggle-switch"
                  checked={analyticsCookieEnabled}
                  onChange={(value) => {
                    setAnalyticsCookieEnabled(value);
                  }}
                />
                <h3>Analytics</h3>
              </div>
              <p>
                We may use web analytics services on the Sales Channels, such as
                services provided by Google Analytics. These services to help us
                analyse how the Sales Channels is being used by you and other
                visitors. Web analytics services use performance cookies to
                track customer’s interactions. For example, by using cookies,
                such a service can inform us which pages our users view, which
                pages are most popular, what time of day certain section of the
                Sales Channels are most visited, whether a visitor has been to
                the Sales Channels before, what website referred the visitor to
                the Sales Channels, and other similar information. All of this
                information is anonymized. Web analytics services collect the
                information to evaluate your use of the Sales Channels and, in
                certain cases, to assist in online marketing, etc.
              </p>
            </div>
            <div>
              <div className="consent-popup-body-toggle">
                <ReactSwitch
                  onColor="#2E8B98"
                  className="toggle-switch"
                  checked={personalizationCookieEnabled}
                  onChange={(value) => setPersonalizationCookieEnabled(value)}
                />
                <h3>Personalization</h3>
              </div>
              <p>
                These cookies help us to learn what you're interested in so we
                can show you relevant content while you choose holidays on our
                site.
              </p>
            </div>
          </div>
          <div className="consent-popup-footer">
            <button
              className="save-preferences-btn"
              onClick={saveConsentPreferences}
            >
              Save preferences
            </button>
          </div>

          <p className="bold">How we use Cookies</p>
          <p>We use Cookies for the following purposes:</p>

          <ul>
            <li>
              Advertising. We use cookies to help us to display advertisements
              which are relevant to you.
            </li>
            <li>
              Analysis. We use cookies to help us to analyse the use and
              performance of our website and services.
            </li>
            <li>
              Authentication. We use cookies to identify you when you visit the
              Sales Channels and as you navigate the Sales Channels.
            </li>
            <li>
              Cookie consent. We use cookies to store your preferences in
              relation to the use of cookies more generally.
            </li>
            <li>
              Personalisation. We use cookies to store information about your
              preferences and to personalise the Sales Channels for you.
            </li>
            <li>
              Security. We use cookies as an element of the security measures
              used to protect user accounts, including preventing fraudulent use
              of login credentials, and to protect the Sales Channels generally.
            </li>
            <li>
              Status. We use cookies to help us to determine if you are logged
              into the Sales Channels.
            </li>
          </ul>

          <p className="bold">Types of Cookies we use</p>
          <p>
            Cookies may be either “persistent” cookies or “session” cookies: a
            persistent cookie will be stored by a web browser and will remain
            valid until its set expiry date, unless deleted by the user before
            the expiry date; a session cookie, on the other hand, will expire at
            the end of the user session, that is, when the web browser is
            closed. We use both Session and Persistent Cookies for the purposes
            set out below:
          </p>

          <p className="bold">Essential Cookies</p>
          <p>Type: Session Cookies</p>
          <p>Administered by: Us</p>
          <p>
            Purpose: These Cookies are essential to provide you with services
            available through the Sales Channels and to enable you to use some
            of its features. They help to authenticate users and prevent
            fraudulent use of user accounts. Without these cookies, the services
            that you have asked for cannot be provided, and we use these cookies
            only to provide you with those services.
          </p>

          <p className="bold">Acceptance Cookies</p>
          <p>Type: Persistent Cookies</p>
          <p>Administered by: Us</p>
          <p>
            Purpose: These Cookies identify if users have accepted the use of
            cookies on the Sales Channels.
          </p>

          <p className="bold">Functionality Cookies</p>
          <p>Type: Persistent Cookies</p>
          <p>Administered by: Us</p>
          <p>
            Purpose: These Cookies allow us to remember choices you make when
            you use the Sales Channels, such as remembering your login details
            or language preferences. The purpose of these cookies is to provide
            you with a more personal experience and to avoid you having to
            re-enter your preferences every time you use the Sales Channels.
          </p>

          <p className="bold">How and when are Cookies delivered</p>
          <p>
            We use cookies across various areas of the Sales Channels, such as
            on our website, mobile sites and mobile applications. Any browser
            visiting these areas will receive cookies from us which helps us
            identify you when you return. Web beacons, tags and scripts may be
            used in the Sales Channels or in our emails to help us to deliver
            cookies. We may perform analysis based on the use of these
            technologies and generate reports on an individual and aggregated
            basis concerning parameters such as visit counts, understand usage
            and campaign effectiveness and determine whether an email has been
            opened and acted upon.
          </p>

          <p className="bold">Use of cookies for analytics</p>
          <p>
            We may use web analytics services on the Sales Channels, such as
            services provided by Google Analytics. These services to help us
            analyse how the Sales Channels is being used by you and other
            visitors. Web analytics services use performance cookies to track
            customer’s interactions. For example, by using cookies, such a
            service can inform us which pages our users view, which pages are
            most popular, what time of day certain section of the Sales Channels
            are most visited, whether a visitor has been to the Sales Channels
            before, what website referred the visitor to the Sales Channels, and
            other similar information. All of this information is anonymized.
            Web analytics services collect the information to evaluate your use
            of the Sales Channels and, in certain cases, to assist in online
            marketing, etc.
          </p>

          <p className="bold">Third-party cookies</p>
          <p>
            Third-parties (advertising networks and providers of external
            services like web traffic analysis services) may also use cookies on
            the Sales Channels. The names of cookies, pixels and other
            technologies may change over time. We suggest that you review the
            respective privacy policies for these external services to help you
            understand what data these organisations hold about you and how they
            process such information.
          </p>

          <p className="bold">Controlling cookies</p>
          <p>
            Most browsers allow you to control cookies through their settings
            preferences. However, if you choose to turn off these cookies, you
            will still see advertising on the internet but it may not be
            tailored to your interests. It does not mean that you won't be
            served any advertisements whilst you are online. Whilst we have
            mentioned most of the third-parties involved in using targeting or
            advertising cookies in the preceding section, the list is not
            exhaustive and is subject to change. Therefore, even if you choose
            to turn off all the third-party cookies listed in section, you may
            still receive some tailored advertisements and recommendations.
          </p>
          <p>
            There are a number of ways you can manage what cookies are set on
            your devices. Essential cookies, however, cannot be disabled. If you
            do not allow certain cookies to be installed, the website may not be
            accessible to you and/or the performance, features, or services of
            the website may be compromised. You can also manage this type of
            cookie in the privacy settings on the web browser you are using.
            Please note that if you use your browser settings to block all
            cookies you may not be able to access parts of our or others'
            websites. Please see below for more information. The browser
            settings for changing your cookies settings are usually found in the
            'options' or 'preferences' menu of your internet browser. In order
            to understand these settings, the following links may be helpful.
          </p>

          <ul>
            <li>
              Chrome: https://support.google.com/chrome/answer/95647?hl=en{" "}
            </li>
            <li>
              Firefox:
              https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences
            </li>
            <li>
              Opera: http://www.opera.com/help/tutorials/security/cookies/
            </li>
            <li>
              Internet Explorer:
              https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies
            </li>
            <li>Safari: https://support.apple.com/kb/PH21411</li>
            <li>
              Edge:
              https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy
            </li>
          </ul>

          <p>
            Alternatively, you may use the 'Help' option in your internet
            browser for more details on managing cookies.
          </p>

          <p className="bold">More information</p>
          <ul>
            <li>
              More information about cookies, including how to see what cookies
              have been set and how to manage and delete them, can be found on
              www.allaboutcookies.org
            </li>
            <li>
              More information about opting out of receiving interest-based ads
              from other companies can be found at optout.aboutads.info and
              www.networkadvertising.org/choices. In addition, certain
              third-party advertising networks such as Facebook (pixels) and
              Google permit users to opt out of or customize preferences
              associated with your internet browsing. To learn more about this
              feature, please visit:
            </li>
            <li>Google: https://policies.google.com/privacy</li>
            <li>Facebook: https://developers.facebook.com/docs/privacy/</li>
          </ul>

          <p className="bold">Contact us</p>
          <ul>
            <li>
              This Website is owned and operated by Trip Stories Private
              Limited.
            </li>
            <li>
              We are registered in India under company identification number
              U63090MH2020PTC337853, and our registered office address is: Plot
              646, 1304 Flr,13 wing B, Rustomjee Oriana CHSL MIG Colony,Gandhi
              Nagar, Rd No.7 near Mhada office Bandra Mumbai 400051
            </li>
            <li>
              You can contact us (a) by post: at our registered office address
              provided above; (b) by email: on [*]; (c) by telephone: on the
              contact number published on our website from time to time; (d) by
              Website: by using our website contact form on [*]
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default CookiePolicy;
