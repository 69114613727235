import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuthState } from "react-firebase-hooks/auth";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import ReactPixel from "react-facebook-pixel";
import { themes, location, property } from "../../helpers/utils";

import {
  auth,
  getUserDetailsFromFirestore,
  saveUserDetailsToFirestore,
  uploadImageToStorage,
} from "../../helpers/firebase";
import regexData from "../../constants/regexData";

import {
  saveCustomerInterests,
  getCustomerInterests,
} from "../../store/customer/actions";

import "./MyProfile.scss";

function MyProfile() {
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const [birthDate, setBirthDate] = useState("MM/DD/YYYY");
  const [previewDate, setPreviewDate] = useState("MM/DD/YYYY");
  const [user, loading] = useAuthState(auth);
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState("");
  const [previewName, setPreviewName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [gender, setGender] = useState("Not yet mentioned");
  const [previewGender, setPreviewGender] = useState("Not yet mentioned");
  const [userPic, setUserPic] = useState(
    "https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg"
  );
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();

  const fNameRef = useRef(null);
  const fNameContainerRef = useRef(null);
  const fNameErrorRef = useRef(null);

  useEffect(() => {
    const localStorageData = localStorage.getItem("voyaahCustomerTracker");
    let customerProfileId = "";
    if (localStorageData && user) {
      customerProfileId = JSON.parse(localStorageData).id;
      dispatch(getCustomerInterests(customerProfileId, user.uid));
    }
  }, [dispatch]);

  const { customerInterestResponse } = useSelector(
    (state) => state.CustomerProfileReducer
  );

  const [profileInterests, setProfileInterests] = useState({
    location: [],
    themes: [],
    property: [],
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactPixel.fbq("init", process.env.REACT_APP_FBPIXEL_ID);
    ReactPixel.fbq("track", "PageView");
  }, []);

  useEffect(() => {
    if (customerInterestResponse != undefined) {
      setProfileInterests(customerInterestResponse);
    }
  }, [customerInterestResponse]);

  useEffect(() => {
    if (!selectedFile) {
      setPreview(user?.photoURL);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    return () => {
      URL.revokeObjectURL(objectUrl);
    };
  }, [selectedFile, user]);

  useEffect(() => {
    if (loading) return;
    if (!user) navigate("/register");
  }, [user, loading, navigate]);

  useEffect(() => {
    if (user?.uid) {
      const getUserDoc = async () => {
        const userDetails = await getUserDetailsFromFirestore();
        userDetails.forEach((doc) => {
          setName(doc.data().name);
          setPreviewName(doc.data().name);
          const num = `+91 ${doc.data().phoneNumber}`;
          setPhoneNumber(num);
          setEmail(doc.data().email);
          setGender(doc.data().gender);
          setPreviewGender(doc.data().gender);
          const date = moment(doc.data().birthDate).format("MMMM DD YYYY");
          setBirthDate(date);
          setPreviewDate(date);
        });
      };
      getUserDoc();
      dispatch(getCustomerInterests("", user.uid));
    }
  }, [user]);

  useEffect(() => {
    if (user?.photoURL) {
      setUserPic(user.photoURL);
      setPreview(user.photoURL);
    }
  }, [user]);

  const clearError = () => {
    fNameContainerRef.current.classList.remove("error");
    fNameErrorRef.current.classList.remove("show");
  };

  const handleSaveDetails = () => {
    clearError();
    const nameRegex = new RegExp(regexData.name);
    if (!previewName) {
      fNameContainerRef.current.classList.add("error");
      fNameErrorRef.current.classList.add("show");
      fNameErrorRef.current.innerHTML = "Enter your name";
      fNameRef.current.focus();
    } else if (!nameRegex.test(previewName)) {
      fNameContainerRef.current.classList.add("error");
      fNameErrorRef.current.classList.add("show");
      fNameErrorRef.current.innerHTML = "Enter valid name";
      fNameRef.current.focus();
    } else {
      const updatedDetails = {
        name: previewName,
        birthDate: moment(previewDate).format("MM/DD/YYYY"),
        gender: previewGender,
        email: email,
      };
      saveUserDetailsToFirestore(updatedDetails);
      setName(previewName);
      setBirthDate(moment(previewDate).format("MMMM DD YYYY"));
      setGender(previewGender);
      setIsEditing(false);
      if (selectedFile) {
        const objectUrl = URL.createObjectURL(selectedFile);
        setUserPic(objectUrl);
        uploadImageToStorage(selectedFile, user).then((url) => {
          setPreview(url);
        });
      }
    }

    const localStorageData = localStorage.getItem("voyaahCustomerTracker");
    let customerProfileId = "";
    if (localStorageData) {
      customerProfileId = JSON.parse(localStorageData).id;
    }

    const customerInterestsObj = {
      accountId: user?.uid,
      customerProfileId: customerProfileId,
      themes: profileInterests.themes,
      location: profileInterests.location,
      property: profileInterests.property,
    };

    dispatch(saveCustomerInterests(customerInterestsObj));
  };

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    setSelectedFile(e.target.files[0]);
  };

  const handleEditProfile = () => {
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setPreviewDate(birthDate);
    setPreviewGender(gender);
    setPreview(userPic);
    setPreviewName(name);
    setIsEditing(false);
  };

  const handleInterestsChange = (key, value, isAdd) => {
    let existingData = { ...profileInterests };
    let existingValues = [];
    if (isAdd) {
      existingValues = existingData[key];
      existingValues.push(value);
    } else {
      existingValues = existingData[key].filter((x) => x !== value);
    }
    existingData[key] = [...existingValues];
    existingData[key] = existingData[key].filter(
      (value) => existingData[key].indexOf(value) !== value
    );
    setProfileInterests(existingData);
  };

  return (
    <>
      {!isEditing && (
        <div className="my-account-content">
          <div className="profile-pic-blk">
            <div className="picture">
              <span className="pic">
                <img src={userPic} alt="" className="img-fluid" />
              </span>
            </div>
            <div
              className="picture-edit"
              onClick={() => {
                handleEditProfile();
              }}
            >
              <img
                src={require("../../assets/images/edit-profile.png")}
                alt=""
                className="img-fluid"
              />{" "}
              Edit Profile
            </div>
          </div>
          <div className="profile-edit">
            <div className="form-fields">
              <label>Name</label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                maxLength={50}
                disabled={!isEditing}
              />
            </div>
            <div className="form-fields">
              <label>Email</label>
              <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={!isEditing}
              />
            </div>
            <div className="form-fields">
              <label>Gender</label>
              <input type="text" value={gender} disabled={!isEditing} />
            </div>
            <div className="form-fields">
              <label>Date of birth</label>
              <input type="text" value={birthDate} disabled={!isEditing} />
            </div>
            <div className="form-fields">
              <label>Phone number</label>
              <input
                type="text"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                disabled={!isEditing}
              />
            </div>
            <div className="form-fields">
              <label>Upload ID Proof</label>
              <span className="no-data">Not Provided</span>
            </div>
          </div>
          <div className="customer-interests">
            <h4 className="interests-heading">
              Select the option that interests you
            </h4>
            <h4 className="interests-heading">Themes</h4>
            <div className="checkboxes-div">
              {themes.map((item) => (
                <div key={item.code}>
                  <label className="interests-label">
                    <input
                      type="checkbox"
                      id={item.code}
                      value={item.description}
                      disabled={!isEditing}
                      checked={profileInterests["themes"]?.includes(item.code)}
                      onChange={(e) => {
                        handleInterestsChange(
                          "themes",
                          item.code,
                          e?.target.checked
                        );
                      }}
                    />
                    {item.description}
                  </label>
                </div>
              ))}
            </div>
            <h4 className="interests-heading">Locations</h4>
            <div className="checkboxes-div">
              {location.map((item) => (
                <div key={item.code}>
                  <label className="interests-label">
                    <input
                      type="checkbox"
                      id={item.code}
                      value={item.description}
                      disabled={!isEditing}
                      checked={profileInterests["location"]?.includes(
                        item.code
                      )}
                      onChange={(e) => {
                        handleInterestsChange(
                          "location",
                          item.code,
                          e?.target.checked
                        );
                      }}
                    />
                    {item.description}
                  </label>
                </div>
              ))}
            </div>
            <h4 className="interests-heading">Properties</h4>
            <div className="checkboxes-div">
              {property.map((item) => (
                <div key={item.code}>
                  <label className="interests-label">
                    <input
                      type="checkbox"
                      id={item.code}
                      value={item.description}
                      disabled={!isEditing}
                      checked={profileInterests["property"]?.includes(
                        item.code
                      )}
                      onChange={(e) => {
                        handleInterestsChange(
                          "property",
                          item.code,
                          e?.target.checked
                        );
                      }}
                    />
                    {item.description}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {isEditing && (
        <div className="my-account-content">
          <div className="profile-pic-blk">
            <div className="picture">
              <span className="pic">
                <img
                  src={preview ? preview : userPic}
                  alt={require("../../assets/images/user-img.png")}
                  className="img-fluid"
                />
              </span>
              <span className="edit">
                <input
                  type="file"
                  name="pic1"
                  id="pic1"
                  className="invisible-button"
                  onChange={onSelectFile}
                />
                <label for="pic1">
                  <img
                    src={require("../../assets/images/edit-profile.png")}
                    alt=""
                    className="img-fluid mt8"
                  />
                </label>
              </span>
            </div>
            <div className="picture-edit-cancel" onClick={handleCancelEdit}>
              Cancel
            </div>
            <div className="picture-edit" onClick={handleSaveDetails}>
              <img
                src={require("../../assets/images/edit-profile.png")}
                alt=""
                className="img-fluid"
              />
              Save
            </div>
          </div>
          <div className="profile-edit">
            <div className="form-fields" ref={fNameContainerRef}>
              <label>Name</label>
              <input
                ref={fNameRef}
                type="text"
                value={previewName}
                onChange={(e) => setPreviewName(e.target.value)}
                maxLength={50}
              />
              <p className="error-msg" ref={fNameErrorRef}>
                Error Text
              </p>
            </div>
            <div className="form-fields">
              <label>Email</label>
              <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled
              />
            </div>
            <div className="form-fields">
              <div class="dropdown">
                <label>Gender</label>
                <input type="dropdown" value={previewGender} />
                <div className="dropdown-content">
                  <a onClick={() => setPreviewGender("Male")}>Male</a>
                  <a onClick={() => setPreviewGender("Female")}>Female</a>
                  <a onClick={() => setPreviewGender("Other")}>Other</a>
                </div>
              </div>
            </div>
            <div className="form-fields">
              <label>Date of birth</label>
              <DatePicker
                maxDate={new Date()}
                onSelect={(date) =>
                  // setBirthDate(moment(date).format("MM/DD/YYYY"))
                  setPreviewDate(moment(date).format("MM/DD/YYYY"))
                }
                placeholderText={moment(previewDate).format("MMMM DD YYYY")}
                showYearDropdown
                showMonthDropdown
                dropdownMode="select"
                openToDate={new Date(previewDate)}
              />
            </div>
            <div className="form-fields">
              <label>Phone number</label>
              <input
                type="text"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                disabled
              />
            </div>
            <div className="form-fields">
              <label>Upload ID Proof</label>
              <span className="no-data">Not Provided</span>
            </div>
          </div>
          <div className="customer-interests">
            <h4 className="interests-heading">
              Select the option that interests you
            </h4>
            <h4 className="interests-heading">Themes</h4>
            <div className="checkboxes-div">
              {themes.map((item) => (
                <div key={item.code}>
                  <label className="interests-label">
                    <input
                      type="checkbox"
                      id={item.code}
                      value={item.description}
                      checked={profileInterests["themes"]?.includes(item.code)}
                      onChange={(e) => {
                        handleInterestsChange(
                          "themes",
                          item.code,
                          e?.target.checked
                        );
                      }}
                    />
                    {item.description}
                  </label>
                </div>
              ))}
            </div>
            <h4 className="interests-heading">Locations</h4>
            <div className="checkboxes-div">
              {location.map((item) => (
                <div key={item.code}>
                  <label className="interests-label">
                    <input
                      type="checkbox"
                      id={item.code}
                      value={item.description}
                      checked={profileInterests["location"]?.includes(
                        item.code
                      )}
                      onChange={(e) => {
                        handleInterestsChange(
                          "location",
                          item.code,
                          e?.target.checked
                        );
                      }}
                    />
                    {item.description}
                  </label>
                </div>
              ))}
            </div>
            <h4 className="interests-heading">Properties</h4>
            <div className="checkboxes-div">
              {property.map((item) => (
                <div key={item.code}>
                  <label className="interests-label">
                    <input
                      type="checkbox"
                      id={item.code}
                      value={item.description}
                      checked={profileInterests["property"]?.includes(
                        item.code
                      )}
                      onChange={(e) => {
                        handleInterestsChange(
                          "property",
                          item.code,
                          e?.target.checked
                        );
                      }}
                    />
                    {item.description}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default MyProfile;
