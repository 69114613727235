import { SET_ROOM_DATA } from "./action";

const initialState = {
  roomDetails: {},
  error: {
    message: "",
  },
};

const RoomDetailReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_ROOM_DATA:
      state = {
        ...state,
        roomDetails: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default RoomDetailReducer;
