import { Link } from "react-router-dom";
import "./DiscoverCardLayout.scss";

function DiscoverCardLayout(props: any) {
  const { data } = props;
  const screenWidth = window.innerWidth;
  return (
    <>
      {data && (
        <a
          href={
            data?.cardAttributes?.ctaUrl !== null &&
            data?.cardAttributes?.ctaUrl
          }
        >
          <div
            className="discover-blk"
            style={{
              background: `url(${
                props.folderUrl + "/" + data?.fileName
              }) no-repeat center center / cover`,
              marginLeft: screenWidth < 767 ? "-10px" : "",
            }}
          >
            <div className="background-overlay"></div>
            <div className="discover-container">
              <div style={styles.textColor} className="heading">
                {data?.cardAttributes.subTitle}
              </div>
              <div style={styles.textColor} className="offer">
                {data?.cardAttributes.title}
              </div>
              <p>{data?.cardAttributes?.highlightText}</p>
              <div
                onClick={() => {
                  sessionStorage.setItem(
                    "homePageScrollPosition",
                    `${window.pageYOffset}`
                  );
                }}
                className="green-btn-input"
              >
                <Link
                  to={
                    data?.cardAttributes?.ctaUrl !== null &&
                    data?.cardAttributes?.ctaUrl
                  }
                >
                  {data?.cardAttributes?.ctaText &&
                    data?.cardAttributes?.ctaText}
                </Link>
              </div>
            </div>
          </div>
        </a>
      )}
    </>
  );
}

const styles = {
  textColor: {
    color: "white",
  },
};

export default DiscoverCardLayout;
