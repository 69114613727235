import {
  GET_HOME_DATA,
  GET_HOME_DATA_SUCCESS,
  GET_HOME_DATA_FAIL,
} from "./actionTypes";

export const getHomeData = (customerProfileId: string, accountId: string) => {
  return {
    type: GET_HOME_DATA,
    payload: customerProfileId,
    accountId: accountId,
  };
};

export const getHomeDataSuccess = (data: any) => {
  return {
    type: GET_HOME_DATA_SUCCESS,
    payload: data,
  };
};

export const getHomeDataFail = (error: any) => {
  return {
    type: GET_HOME_DATA_FAIL,
    payload: error,
  };
};
