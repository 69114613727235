import {
    GET_PACKAGE_AVAILABILITY,
    GET_PACKAGE_AVAILABILITY_SUCCESS,
    GET_PACKAGE_AVAILABILITY_FAIL,
    CLEAR_PACKAGE_AVAILABILITY,
} from "./actionTypes";

const initialState = {
    packageAvailability: [],
    packageAvailabilityLoader: true,
    error: {
        message: "",
    },
};

const PackageAvailabilityReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_PACKAGE_AVAILABILITY:
            state = { ...state, packageAvailabilityLoader: true };
            break;
        case GET_PACKAGE_AVAILABILITY_SUCCESS:
            state = { ...state, packageAvailability: action.payload, packageAvailabilityLoader: false };
            break;
        case GET_PACKAGE_AVAILABILITY_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                packageAvailabilityLoader: false,
            };
            break;
        case CLEAR_PACKAGE_AVAILABILITY:
            state = { ...state, packageAvailability: [], packageAvailabilityLoader: false };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default PackageAvailabilityReducer;