import {
  GET_GROUP_BOOKING_DATA,
  GET_GROUP_BOOKING_DATA_SUCCESS,
  GET_GROUP_BOOKING_DATA_FAIL,
} from "./actionTypes";

export const getGroupBookingData = (
  pageUrl: any,
  customerProfileId: string,
  accountId: string
) => {
  return {
    type: GET_GROUP_BOOKING_DATA,
    payload: pageUrl,
    customerProfileId: customerProfileId,
    accountId: accountId,
  };
};

export const getGroupBookingDataSuccess = (data: any) => {
  return {
    type: GET_GROUP_BOOKING_DATA_SUCCESS,
    payload: data,
  };
};

export const getGroupBookingDataFail = (error: any) => {
  return {
    type: GET_GROUP_BOOKING_DATA_FAIL,
    payload: error,
  };
};
