import { useEffect, useState, useRef } from "react";
import _ from "underscore";
import "react-toastify/dist/ReactToastify.css";
import ItineraryCard from "./ItineraryCard";
import "./Details.scss";
import { htmlToText } from "html-to-text";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { amenitiesIconMapping } from "../../constants/iconMapping";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

function Itinerary(props) {
  const { dayWiseItinerary } = props;
  const [showListCount, setShowListCount] = useState(5);
  const [showViewMore, setShowViewMore] = useState(false);
  const [pdfHeight, setPdfHeight] = useState(false);
  const pdfData = props.pdfData;
  useEffect(() => {
    if (_.size(dayWiseItinerary) > 5) {
      setShowViewMore(true);
    }
  }, [dayWiseItinerary]);

  const updateListCount = () => {
    const listSize = _.size(dayWiseItinerary);
    if (listSize - showListCount < 5) {
      setShowListCount(listSize);
      setShowViewMore(false);
    } else if (listSize > showListCount) {
      setShowListCount(showListCount + 5);
    }
  };

  const sendMail = () => {
    try {
      if (navigator.canShare !== undefined) {
        navigator.share({
          text: "Hey, I found the beautiful package. Let's pack-up and explore.",
          url: window.location.href,
        });
      } else {
        const mailto = `mailto:?subject=Let's pack-up and explore&body=Hey,%0D%0A%0D%0AI found the beautiful package.%0D%0A${window.location.href}.%0D%0A%0D%0ALet's Explore`;
        window.location.href = mailto;
      }
    } catch (error) {
      console.log("Sharing failed!", error);
    }
  };

  const widths = [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0.2796875, 0.2765625, 0.3546875, 0.5546875, 0.5546875,
    0.8890625, 0.665625, 0.190625, 0.3328125, 0.3328125, 0.3890625, 0.5828125,
    0.2765625, 0.3328125, 0.2765625, 0.3015625, 0.5546875, 0.5546875, 0.5546875,
    0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875,
    0.2765625, 0.2765625, 0.584375, 0.5828125, 0.584375, 0.5546875, 1.0140625,
    0.665625, 0.665625, 0.721875, 0.721875, 0.665625, 0.609375, 0.7765625,
    0.721875, 0.2765625, 0.5, 0.665625, 0.5546875, 0.8328125, 0.721875,
    0.7765625, 0.665625, 0.7765625, 0.721875, 0.665625, 0.609375, 0.721875,
    0.665625, 0.94375, 0.665625, 0.665625, 0.609375, 0.2765625, 0.3546875,
    0.2765625, 0.4765625, 0.5546875, 0.3328125, 0.5546875, 0.5546875, 0.5,
    0.5546875, 0.5546875, 0.2765625, 0.5546875, 0.5546875, 0.221875, 0.240625,
    0.5, 0.221875, 0.8328125, 0.5546875, 0.5546875, 0.5546875, 0.5546875,
    0.3328125, 0.5, 0.2765625, 0.5546875, 0.5, 0.721875, 0.5, 0.5, 0.5,
    0.3546875, 0.259375, 0.353125, 0.5890625,
  ];
  const avg = 0.5279276315789471;
  function measureText(str, fontSize, scalingfactor) {
    return (
      Array.from(str).reduce(
        (acc, cur) => acc + (widths[cur.charCodeAt(0)] ?? avg),
        0
      ) *
      fontSize *
      scalingfactor
    );
  }
  function getNlines(
    para,
    numberOfLines,
    fontSize,
    fontScalingfactor,
    widthInPx
  ) {
    // first get plain text
    var lineList = htmlToText(para, { wordwrap: false }).split("\n");
    var finalLineList = [];
    // check if any line is greater than 1 line based on widthInPx
    for (var line of lineList) {
      // skip blank lines
      if (line.length == 0) {
        continue;
      }
      //split at word and sentense breaks
      var strToSplit = line.replace(/[.,;]/g, " ");
      var strings = strToSplit.split(" ");
      var wordSizes = [];
      var words = [...strings];
      // get size of each word
      for (let item of strings) {
        wordSizes.push(measureText(item + " ", fontSize, fontScalingfactor));
        words.push(item);
      }
      var lines = 1;
      var dividedLine = "";
      var lineWidthUsed = 0;
      // keep on building a line till its within width
      for (let i = 0; i < wordSizes.length; i++) {
        dividedLine = dividedLine.concat(words.shift());
        dividedLine = dividedLine.concat(" ");
        if (lineWidthUsed + wordSizes[i] > widthInPx) {
          lines++;
          lineWidthUsed = wordSizes[i];
          finalLineList.push(dividedLine);
          dividedLine = "";
        } else {
          lineWidthUsed = lineWidthUsed + wordSizes[i];
        }
      }
      // cater to line smaller than width
      if (dividedLine.length > 0) finalLineList.push(dividedLine);
    }
    // pick the number of lines from plain text
    var subList = [];
    var subHtmlList = [];
    if (finalLineList.length >= numberOfLines) {
      subList = finalLineList.slice(0, numberOfLines);
    } else {
      subList = finalLineList;
    }
    // the regular expressions doesnt work across newlines, so remove the same
    let escapedPara = para?.split("\n").join();
    // calculate the size of string to take from para
    var endIndex = 0;
    try {
      for (var str of subList) {
        // remove double spaces
        var temp = str.trim().split(" ");
        // construct a regular expression by replacing spaces by .*? so that it can match any html tag, space, comma or full stop
        var regExprstr = "";
        for (var i = 0; i < temp.length - 1; i++) {
          if (temp[i].trim().length > 0)
            regExprstr =
              regExprstr +
              temp[i]
                .trim()
                .replace("*", "")
                .replace(/[.*+?^${}()|[\]\\]/g, "\\$&") +
              ".*?";
        }
        regExprstr =
          regExprstr +
          temp[temp.length - 1]
            .trim()
            .replace("*", "")
            .replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        var re = new RegExp(regExprstr, "i");
        // get the first index and size of matched string to calculate the length to skip
        var firstIndex = escapedPara.search(re);
        var resArray = escapedPara.match(re);
        if (resArray) {
          var subindex = firstIndex + resArray[0].length;
          endIndex = endIndex + subindex;
          escapedPara = escapedPara?.substring(subindex);
        }
      }
      return (subHtmlList = para?.substring(0, endIndex));
    } catch (e) {
      console.log(e);
    }
  }

  function numLines(para, fontSize, scalingfactor, widthInPx) {
    const plainText = htmlToText(para, { wordwrap: false });
    const planTextLines = plainText.split("\n");
    var totalLines = 0;
    for (let para of planTextLines) {
      if (para.length == 0) {
        continue;
      }
      var strToSplit = para.replace(/[.,;]/g, " ");
      var strings = strToSplit.split(" ");
      var wordSizes = [];
      for (let item of strings) {
        wordSizes.push(measureText(item, fontSize, scalingfactor));
      }
      var lines = 1;
      var lineWidthUsed = 0;
      for (let i = 0; i < wordSizes.length; i++) {
        if (lineWidthUsed + wordSizes[i] > widthInPx) {
          lines++;
          lineWidthUsed = wordSizes[i];
        } else {
          lineWidthUsed = lineWidthUsed + wordSizes[i];
        }
      }
      totalLines = totalLines + lines;
    }
    return totalLines;
  }

  const generatePdf = async () => {
    const s3Path = `https://${process.env.REACT_APP_MEDIA_S3_BUCKET_BASE_URL}/${process.env.REACT_APP_MEDIA_S3_BUCKET}/static-content/pdf-icons/`;
    setPdfHeight(true);
    var fonts = {
      Sen: {
        normal: `${s3Path}google-font/Sen-Regular.ttf`,
        bold: `${s3Path}google-font/Sen-Bold.ttf`,
      },
      ptSans: {
        normal: `${s3Path}google-font/PTSans-Regular.ttf`,
        bold: `${s3Path}google-font/PTSans-Bold.ttf`,
        italic: `${s3Path}google-font/PTSans-Italic.ttf`,
        boldItalic: `${s3Path}google-font/PTSans-BoldItalic.ttf`,
      },
    };
    var calculatedValues = {
      highlightString: "",
      inclusionOdd: [],
      inclusionEven: [],
    };
    async function preProcessHomePage(pdfData) {
      const highlightArray = [];
      for (var item of pdfData.highlights) {
        highlightArray.push(item.title);
      }
      calculatedValues.highlightString = highlightArray.join("  |  ");
      const inclusions = [];
      for (var i = 0; i < pdfData.inclusions.length; i++) {
        if (i % 2 === 0) {
          calculatedValues.inclusionOdd.push(pdfData.inclusions[i].name);
        } else {
          calculatedValues.inclusionEven.push(pdfData.inclusions[i].name);
        }
      }
    }
    var twitterImageIcon = await getImage(`${s3Path}x.png`, 28, 28);
    var youtubeImageicon = await getImage(`${s3Path}youtube.png`, 28, 28);
    var instagramImageIcon = await getImage(`${s3Path}Instagram.png`, 28, 28);
    var faceImageIcon = await getImage(`${s3Path}facebook.png`, 28, 28);
    var webImageIcon = await getImage(`${s3Path}web.png`, 28, 28);
    var emailImageIcon = await getImage(`${s3Path}mail.png`, 28, 28);

    var mainImageData = "";
    try {
      // mainImageData = `${pdfData.mediaCollections[0].images[1].imageUrl}?not-from-cache-please`;
      mainImageData = await resizeAndCropImage(
        `${pdfData.mediaCollections[0].images[1].imageUrl}?not-from-cache-please`,
        612,
        350
        // 278,
      );
    } catch (e) {
      mainImageData =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNkYPhfDwAChwGA60e6kgAAAABJRU5ErkJggg==";
      console.log(e);
    }

    var docDefinition = {
      defaultStyle: {
        font: "ptSans",
      },
      pageSize: "LETTER",
      pageMargins: [0, 0, 0, 0],
      pageOrientation: "portrait",
      content: [],
      styles: {
        watermarkStyle: {
          absolutePosition: { x: 400, y: 10 },
          margin: [0, -100, 0, 0],
        },
        title: {
          alignment: "left",
          bold: true,
          fontSize: 30,
          color: "black",
        },
        pdfDescription: {
          align: "center",
          fontSize: 12,
          color: "black",
        },
        header: {
          fontSize: 25,
          color: "black",
          alignment: "center",
        },
        tableExample: {
          margin: [0, 10, 0, 0],
        },
        tableInclusionExample: {
          margin: [20, -290, 0, 0],
        },
        amenitiesContentTable: {
          margin: [20, 20, 0, 10],
        },
        travelAccomodationAmenitiesFacilitiesCanvas: {
          margin: [0, 100, 0, 0],
        },
      },
      images: {
        mainImage: mainImageData,
        logoImage: {
          url: `${s3Path}voyaah-com-logo.png`,
        },
        waterMarkImage: {
          url: `${s3Path}V-02.png`,
        },
        twitterIcon: twitterImageIcon,
        youtubeIcon: youtubeImageicon,
        facebookIcon: faceImageIcon,
        instagramIcon: instagramImageIcon,
        emailIcon: emailImageIcon,
        webIcon: webImageIcon,
      },
    };
    async function pushFirstPage(docDefinition) {
      var titleItem = {
        font: "Sen",
        text: pdfData?.name.toUpperCase(),
        style: "title",
        relativePosition: { x: 12, y: 35 },
      };
      var mainImageItem = {
        image: "mainImage",
        width: 612,
        height: 278,
        alignment: "center",
        relativePosition: { x: 0, y: 70 },
      };
      var logoImageItem = {
        image: "logoImage",
        width: 90,
        relativePosition: { x: 500, y: 4 },
      };
      var highlightsHeaderBackgroundColor = {
        canvas: [
          {
            type: "rect",
            x: 210,
            y: 330,
            w: 190,
            h: 35,
            alignment: "center",
            color: "black",
            r: 17,
          },
        ],
      };
      var highlightsHeader = {
        text: "HIGHLIGHTS",
        style: "header",
        alignment: "center",
        bold: true,
        fontSize: 21,
        color: "white",
        absolutePosition: { x: 0, y: 335 },
      };
      var noHighlights = {
        text: "No Highlight Found",
        color: "grey",
        alignment: "center",
        fontSize: 18,
        margin: [0, 25, 0, 0],
      };
      var highlightTopMargin =
        calculatedValues.highlightString.length > 150 ? 10 : 20;
      var highlightFontSize =
        calculatedValues.highlightString.length > 150 ? 15 : 18;
      var highlightItem = {
        text: calculatedValues.highlightString,
        color: "black",
        alignment: "center",
        fontSize: highlightFontSize,
        margin: [0, highlightTopMargin, 0, 0],
      };
      var inclusionHeaderBackgroundColor = {
        absolutePosition: { x: 210, y: 443 },
        canvas: [
          {
            type: "rect",
            x: 0,
            y: 0,
            w: 190,
            h: 35,
            alignment: "center",
            color: "black",
            r: 17,
          },
        ],
      };
      var inclusionsHeader = {
        text: "INCLUSION",
        style: "header",
        alignment: "center",
        bold: true,
        color: "white",
        fontSize: 21,
        absolutePosition: { x: 0, y: 446 },
      };
      var inclusionsBackgroundItem = {
        absolutePosition: { x: 0, y: 460 },
        canvas: [
          {
            type: "rect",
            x: 0,
            y: 0,
            w: 612,
            h: 331, //350,
            color: "#DDD",
          },
        ],
      };
      var inclusionsItem = {
        style: "tableInclusionExample",
        layout: "noBorders",
        absolutePosition: { x: 20, y: 490 },
        table: {
          widths: [280, 280],
          body: [
            [
              {
                ul: calculatedValues.inclusionOdd,
                color: "black",
              },
              {
                ul: calculatedValues.inclusionEven,
                color: "black",
              },
            ],
          ],
        },
      };
      docDefinition.content.push(titleItem);
      docDefinition.content.push(logoImageItem);
      docDefinition.content.push(mainImageItem);
      docDefinition.content.push(highlightsHeaderBackgroundColor);
      docDefinition.content.push(highlightsHeader);
      if (calculatedValues.highlightString === "")
        docDefinition.content.push(noHighlights);
      else docDefinition.content.push(highlightItem);
      docDefinition.content.push(inclusionsBackgroundItem);
      docDefinition.content.push(inclusionHeaderBackgroundColor);
      docDefinition.content.push(inclusionsHeader);
      docDefinition.content.push(inclusionsItem);
    }

    async function forItineraryFunction(docDefinition) {
      var itineraryHeaderItem = {
        text: "ITINERARY",
        style: "header",
        alignment: "left",
        fontSize: 35,
        bold: true,
        margin: [20, 15, 0, 2],
        pageBreak: "before",
      };
      var logoImageItem = {
        image: "waterMarkImage",
        width: 28,
        // height: 28,
        relativePosition: { x: 560, y: -35 },
      };
      docDefinition.content.push(itineraryHeaderItem);
      docDefinition.content.push(logoImageItem);
      for (var i = 0; i < pdfData.itineraryItems.length; i++) {
        var itineraryItem = {};
        var numTitleLines = numLines(
          pdfData.itineraryItems[i].title,
          20,
          1,
          320
        );
        var bodyLines = 10;
        if (numTitleLines > 1) bodyLines = bodyLines - 2 * (numTitleLines - 1);
        var getLengthString = getNlines(
          pdfData.itineraryItems[i].description,
          bodyLines,
          12,
          1,
          320
        );
        var convertText = htmlToText(getLengthString, { wordwrap: false });
        var numLine = numLines(convertText, 12, 1, 320);
        var gap = ((bodyLines - numLine) / 2) * 13;
        var yPosition = 20 + gap;
        if (!(i % 2)) {
          itineraryItem = {
            style: "tableExample",
            layout: "noBorders",
            table: {
              widths: [238, 350], // Adjust the width of the column as needed
              heights: [220],
              body: [
                [
                  {
                    width: 255, //238,
                    height: 220,
                    margin: [0, 0, 0, 0],
                    stack: [
                      {
                        image: `itinerary${i}img`,
                        width: 245,
                        height: 210,
                        relativePosition: { x: 0, y: 0 },
                      },
                      {
                        canvas: [
                          {
                            type: "rect", // Type of shape (rectangle in this example)
                            x: 0, // X-coordinate of the top-left corner of the shape
                            y: 5, // Y-coordinate of the top-left corner of the shape
                            w: 80, // Width of the shape
                            h: 20, // Height of the shape
                            r: 15,
                            color: "black",
                          },
                        ],
                        relativePosition: { x: 10, y: 0 },
                      },
                      {
                        color: "white",
                        text: `DAY ${i + 1}`,
                        fontSize: 15,
                        relativePosition: {
                          x: 30,
                          y: 6,
                        },
                      },
                    ],
                  },
                  {
                    width: 350,
                    height: 220,
                    margin: [-1, 0, 0, 0],
                    stack: [
                      {
                        svg: '<svg viewBox="0 0 370 210"><path d="M0,5 h330 q10,0 20,20 v160 q0,10 -20,20 h-330 z" fill="#DDD"><svg>',
                        relativePosition: {
                          x: 0,
                          y: 0,
                        },
                      },
                      {
                        margin: [10, 0, 20, 0],
                        text: [
                          {
                            text: `${pdfData.itineraryItems[i].title}\n`,
                            fontSize: 20,
                            bold: true,
                          },
                          { text: convertText, fontSize: 12 },
                        ],
                        relativePosition: {
                          x: 0,
                          y: yPosition,
                        },
                      },
                    ],
                  },
                ],
              ],
            },
          };
          if (i % 6 == 2) {
            itineraryItem.pageBreak = "after";
          }
        } else {
          itineraryItem = {
            style: "tableExample",
            layout: "noBorders",
            table: {
              widths: [359, 250],
              heights: [220],
              body: [
                [
                  {
                    width: 340,
                    height: 220,
                    margin: [20, 0, 0, 0],
                    stack: [
                      {
                        svg: `<svg viewBox="0 0 370 210">
								  <defs> <clipPath id="round-corner">
								  <rect x="0" y="6" width="370" height="200" rx="20" ry="20"/>
								  </clipPath></defs>
								  <rect width="350" height="260" clip-path="url(#round-corner)"
								  style="stroke: rgb(255, 255, 255); opacity: 0.8; fill: #DDD;"></rect>
								  <svg>`,
                        relativePosition: {
                          x: 0,
                          y: 0,
                        },
                      },
                      {
                        margin: [10, 0, 10, 0],
                        text: [
                          {
                            text: [
                              {
                                text: `${pdfData.itineraryItems[i].title}\n`,
                                fontSize: 20,
                                bold: true,
                              },
                              { text: convertText, fontSize: 12 },
                            ],
                          },
                        ],
                        relativePosition: {
                          x: 0,
                          y: yPosition,
                        },
                      },
                    ],
                  },
                  {
                    width: 255,
                    height: 220,
                    stack: [
                      {
                        image: `itinerary${i}img`,
                        width: 245,
                        height: 210,
                      },
                      {
                        canvas: [
                          {
                            type: "rect", // Type of shape (rectangle in this example)
                            x: 0, // X-coordinate of the top-left corner of the shape
                            y: -205, // Y-coordinate of the top-left corner of the shape
                            w: 80, // Width of the shape
                            h: 20, // Height of the shape
                            r: 15,
                            color: "black", // Color of the shape
                          },
                        ],
                      },
                      {
                        color: "white",
                        text: `DAY ${i + 1}`,
                        fontSize: 15,
                        relativePosition: {
                          x: 20,
                          y: -203,
                        },
                      },
                    ],
                    margin: [0, 0, 0, 0],
                  },
                ],
              ],
            },
          };
          if (i % 6 == 5) {
            itineraryItem.pageBreak = "after";
          }
        }
        docDefinition.content.push(itineraryItem);
        var plainText = {
          text: " ",
        };
        docDefinition.content.push(plainText);
        var item = "itinerary" + i + "img";
        var itineraryImage = "";
        try {
          itineraryImage = await resizeAndCropImage(
            `${pdfData.itineraryItems[i].imagePath}?not-from-cache-please`,
            245,
            210
          );
        } catch (e) {
          itineraryImage =
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNkYPhfDwAChwGA60e6kgAAAABJRU5ErkJggg==";
          console.log(e);
        }
        docDefinition.images[item] = itineraryImage;
      }
      if (pdfData.itineraryItems.length % 3 != 0) {
        var itineraryEndItem = {
          text: "****** Scroll down for accommodation details ******",
          alignment: "center",
          fontSize: 13,
          pageBreak: "after", // Add page break before the new content
        };
        docDefinition.content.push(itineraryEndItem);
      }
    }

    async function pushAccomodationPages(docDefinition) {
      const accomodations = pdfData.itinerary;
      for (var i = 0; i < accomodations.length; i++) {
        var titleWidth = measureText(accomodations[i].property.name, 20, 1);
        var accomodationTitleItem = {
          text: "TRAVEL ACCOMODATION\n",
          style: "header",
          alignment: "left",
          fontSize: 35,
          bold: true,
          margin: [20, 10, 0, 10],
        };
        var logoImageItem = {
          image: "waterMarkImage",
          width: 28,
          // height: 28,
          relativePosition: { x: 560, y: -45 },
        };
        if (i == 0) {
          docDefinition.content.push(accomodationTitleItem);
          docDefinition.content.push(logoImageItem);
        }
        var accomodationMainImageItem = {};
        var accomodationTitlePosition =
          300 - (accomodations[i].property.name.length + 62 / 2) * 2;
        // 260 - accomodations[i].property.name.length * 3;
        if (i == 0) {
          accomodationMainImageItem = {
            stack: [
              {
                image: `accomodation${i}image`,
                alignment: "center",
                width: 612,
                height: 310,
                margin: [0, 0, 0, 0],
              },
              {
                canvas: [
                  {
                    type: "rect",
                    // x: -10,
                    x: accomodationTitlePosition, //190,
                    y: -18,
                    // y: -100,
                    w: titleWidth + 30, // page width
                    h: 35,
                    color: "black",
                    r: 10,
                  },
                ],
              },
              {
                text: accomodations[i].property.name, // htmlToText(accomodations[i].property.description, { wordwrap: false }),
                alignment: "left",
                fontSize: 20,
                relativePosition: { x: accomodationTitlePosition + 20, y: -32 }, //: 207
                // relativePosition: { x: 10, y: -95 },
                color: "white",
                normal: true,
                // margin: [15, 0, 15, 15],
              },
            ],
          };
        } else {
          accomodationMainImageItem = {
            stack: [
              {
                image: `accomodation${i}image`,
                alignment: "center",
                width: 612,
                height: 360,
                margin: [0, 0, 0, 0],
              },
              {
                canvas: [
                  {
                    type: "rect",
                    // x: -10,
                    // y: -100,
                    x: accomodationTitlePosition, //190,
                    y: -18,
                    w: titleWidth + 30, // page width
                    h: 35,
                    color: "black",
                    r: 10,
                  },
                ],
              },
              {
                text: accomodations[i].property.name, // htmlToText(accomodations[i].property.description, { wordwrap: false }),
                alignment: "left",
                fontSize: 20,
                // relativePosition: { x: 10, y: -95 },
                relativePosition: { x: accomodationTitlePosition + 20, y: -32 }, //x: 207
                color: "white",
                normal: true,
              },
            ],
          };
        }
        docDefinition.content.push(accomodationMainImageItem);
        var item = "accomodation" + i + "image";
        var accomodationImage = "";
        try {
          var height = 370;
          if (i == 0) height = 310;
          accomodationImage = await resizeAndCropImage(
            `${accomodations[i].roomTypes[0].roomType.images[0].imageUrl}?not-from-cache-please`,
            612,
            height
          );
        } catch (e) {
          accomodationImage =
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNkYPhfDwAChwGA60e6kgAAAABJRU5ErkJggg==";
          console.log(e);
        }
        docDefinition.images[item] = accomodationImage;

        var convertText = htmlToText(accomodations[i].property.description, {
          wordwrap: false,
        });
        var numLine = numLines(
          accomodations[i].property.description,
          12,
          1.0,
          600
        );
        var gap = (12 - numLine) / 2;
        var topmargin = gap * 11 - 15; //-10 I have added extra
        var accomodationDescriptionItem = {
          text: { text: convertText, fontSize: 12 },
          alignment: "center",
          normal: true,
          margin: [30, topmargin, 30, 10],
        };

        var amenities = [];
        for (var item of accomodations[i].roomTypes[0].roomType.amenities)
          amenities.push(item.name);
        var facilities = [];
        for (item of accomodations[i].property.facilities)
          facilities.push(item.name);
        var accomodationAmenitiesFacilitiesList = {
          stack: [
            {
              absolutePosition: { x: 0, y: 550 },
              canvas: [
                {
                  style: "travelAccomodationAmenitiesFacilitiesCanvas",
                  type: "rect",
                  x: 0,
                  y: 0,
                  w: 612,
                  h: 241,
                  color: "#DDD",
                },
              ],
            },
            {
              absolutePosition: { x: -10, y: 538 },
              canvas: [
                {
                  type: "rect",
                  // x: 0,
                  x: 60,
                  y: -2,
                  w: 196,
                  h: 30,
                  color: "black",
                  r: 10,
                },
              ],
            },
            {
              absolutePosition: { x: 83, y: 540 }, //y: 543
              text: "ROOM AMENITIES",
              alignment: "left",
              fontSize: 16,
              color: "white",
            },
            {
              absolutePosition: { x: 416, y: 538 },
              canvas: [
                {
                  type: "rect",
                  // x: 10,
                  x: -50,
                  y: -2,
                  w: 196,
                  h: 30,
                  r: 10,
                  color: "black",
                },
              ],
            },
            {
              absolutePosition: { x: 400, y: 540 }, //x: 466
              text: "HOTEL FACILITIES",
              fontSize: 16,
              color: "white",
            },
          ],
        };
        // Amenities & facilities table implementation
        const getIcon = (text) => {
          const textVar = text.toLowerCase();
          let bestMatch = "";
          let bestMatchIndex = 0;
          var matchingIcons = amenitiesIconMapping.filter((icon) =>
            textVar?.includes(icon.keyword)
          );
          for (let i = 0; i < matchingIcons.length; i++) {
            if (matchingIcons[i].keyword.length > bestMatch.length) {
              bestMatch = matchingIcons[i].keyword;
              bestMatchIndex = i;
            }
          }
          if (matchingIcons.length === 0)
            return require(`../../assets/images/icons/highlights/default.png`);
          return require(`../../assets/images/icons/amenities/${matchingIcons[bestMatchIndex].icon}`);
        };

        const getFacilitiesIcon = (text) => {
          const textVar = text.toLowerCase();
          let bestMatch = "";
          let bestMatchIndex = 0;
          let matchingIcons = amenitiesIconMapping.filter((icon) =>
            textVar?.includes(icon.keyword)
          );
          for (let i = 0; i < matchingIcons.length; i++) {
            if (matchingIcons[i].keyword !== undefined) {
              if (matchingIcons[i].keyword.length > bestMatch.length) {
                bestMatch = matchingIcons[i].keyword;
                bestMatchIndex = i;
              }
            }
          }
          if (matchingIcons.length === 0)
            return require(`../../assets/images/icons/highlights/default.png`);
          return require(`../../assets/images/icons/amenities/${matchingIcons[bestMatchIndex].icon}`);
        };

        var rows = [];
        var length =
          accomodations[i].roomTypes[0].roomType.amenities.length >
          accomodations[i].property.facilities.length
            ? accomodations[i].roomTypes[0].roomType.amenities.length
            : accomodations[i].property.facilities.length;
        var amenities = [];
        var amenitiesIcons = [];
        for (var item of accomodations[i].roomTypes[0].roomType.amenities) {
          amenities.push(item.name);
          var amenitiesIconItem = {
            image: getIcon(item.name),
            width: 15,
            height: 15,
            alignment: "center",
            margin: [0, 3, 0, 2],
          };
          amenitiesIcons.push(amenitiesIconItem);
        }
        var facilities = [];
        var facilitiesIcons = [];
        for (item of accomodations[i].property.facilities) {
          facilities.push(item.name);
          var facilitiesIconItem = {
            image: getFacilitiesIcon(item.name),
            width: 15,
            height: 15,
            alignment: "center",
            margin: [0, 3, 0, 2],
          };
          facilitiesIcons.push(facilitiesIconItem);
        }
        const numVal = 7;
        for (var j = 0; j < length && j < numVal; j++) {
          var amenitiesIcon1 = "";
          var facilitiesIcon1 = "";
          var amenitiesTitle1 = "";
          var facilitiesTitle1 = "";
          var amenitiesIcon2 = "";
          var facilitiesIcon2 = "";
          var amenitiesTitle2 = "";
          var facilitiesTitle2 = "";
          if (j < amenities.length) {
            amenitiesIcon1 = amenitiesIcons[j];
            amenitiesTitle1 = amenities[j];
            if (j + numVal < amenities.length) {
              amenitiesIcon2 = amenitiesIcons[j + numVal];
              amenitiesTitle2 = amenities[j + numVal];
            }
          }
          if (j < facilities.length) {
            facilitiesIcon1 = facilitiesIcons[j];
            facilitiesTitle1 = facilities[j];
            if (j + numVal < facilities.length) {
              facilitiesIcon2 = facilitiesIcons[j + numVal];
              facilitiesTitle2 = facilities[j + numVal];
            }
          }
          rows.push([
            amenitiesIcon1,
            { text: amenitiesTitle1, fontSize: 11, margin: [0, 3, 0, 0] },
            amenitiesIcon2,
            { text: amenitiesTitle2, fontSize: 11, margin: [0, 3, 0, 0] },
            facilitiesIcon1,
            { text: facilitiesTitle1, fontSize: 11, margin: [0, 3, 0, 0] },
            facilitiesIcon2,
            { text: facilitiesTitle2, fontSize: 11, margin: [0, 3, 0, 0] },
          ]);
        }
        var amenitiesContent = {
          style: "amenitiesContentTable",
          absolutePosition: { x: 30, y: 573 },
          layout: "noBorders",
          table: {
            widths: [12, 135, 12, 125, 12, 115, 12, 145],
            body: rows,
          },
        };
        // ends of table

        var blankspace = {
          text: " ",
          color: "white",
          pageBreak: "after",
        };
        docDefinition.content.push(accomodationDescriptionItem);
        docDefinition.content.push(accomodationAmenitiesFacilitiesList);
        docDefinition.content.push(amenitiesContent);
        docDefinition.content.push(blankspace);
      }
    }
    async function termsAndConfitionFunction(docDefinition) {
      var termsAndConditionContent = getNlines(
        pdfData.termsAndConditions,
        17,
        12,
        1,
        600
      );
      var textVar = htmlToText(termsAndConditionContent, {
        wordwrap: false,
      });
      var regExpression = textVar.replace(/^\s*[\r\n]/gm, " ");
      var termsAndConditionHeaderBackground = {
        canvas: [
          {
            pageBreak: "before",
            type: "rect",
            x: 0,
            y: 75,
            w: 612, // page width
            h: 85,
            color: "rgb(96 97 97)",
          },
        ],
      };
      var termsAndConditionHeader = {
        text: "TERMS & CONDITIONS",
        fontSize: 35,
        bold: true,
        color: "white",
        alignment: "center",
        margin: [0, -85, 0, 0],
        //   absolutePosition: { x: 45, y: 75 }
      };
      var height = numLines(regExpression, 12, 1.4, 560) * 15 + 30;
      var termsAndConditionContentBackground = {
        canvas: [
          {
            type: "rect",
            x: 0,
            y: -4,
            w: 612, // page width
            h: height,
            color: "#DDD",
          },
        ],
      };
      var topMargin = 10 - height;
      var termsAndConditionContent = {
        text: regExpression, //htmlToText(pdfData.termsAndConditions, { wordwrap: false }),
        // regExpression
        fontSize: 12,
        alignment: "center",
        margin: [10, topMargin, 10, 10],
        // relativePosition: { x: 20, y: -290 },
      };
      var termsAndConditionStack = {
        absolutePosition: { x: 0, y: 400 },
        stack: [
          {
            image: "logoImage",
            width: 300,
            alignment: "center",
            margin: [0, 5, 0, 15],
          },
          {
            text: "a unit of Trip Stories Pvt Ltd",
            alignment: "center",
            fontSize: 17,
            bold: true,
          },
          {
            text: "Lower Parel, Mumbai | Indiranagar, Bengaluru",
            alignment: "center",
            fontSize: 17,
            margin: [5, 10, 5, 10],
          },
          {
            text: "For more details: (022) 48930225",
            alignment: "center",
            fontSize: 17,
            margin: [18, 0, 0, 40],
          },
          {
            alignment: "center",
            // absolutePosition: { x: 0, y: 750 },
            columns: [
              {
                width: 105,
                text: [{ text: `` }],
                normal: true,
                margin: [10, 0, 0, 0],
              },
              {
                width: 19,
                height: 19,
                image: `webIcon`,
                margin: [23, 0, 0, 0],
              },
              {
                width: 120,
                text: [
                  {
                    text: `voyaah.com`,
                    fontSize: 13,
                    bold: true,
                    link: "https://voyaah.com",
                  },
                ],
                normal: true,
                margin: [0, 0, 30, 0],
              },
              {
                width: 19,
                height: 19,
                image: `emailIcon`,
                margin: [0, 0, -3, 0],
              },
              {
                width: 130,
                text: [{ text: `info@voyaah.com`, fontSize: 13, bold: true }],
                normal: true,
                margin: [0, 0, 10, 0],
              },
              {
                width: 15,
                height: 15,
                image: `facebookIcon`,
                margin: [10, 0, 1, 0],
              },
              {
                width: 120,
                text: [
                  {
                    text: `voyaahholidays`,
                    fontSize: 13,
                    bold: true,
                    link: "https://www.facebook.com/voyaahholidays/",
                  },
                ],
                normal: true,
                margin: [0, -2, 20, 0],
              },
            ],
          },
          {
            alignment: "center",
            columns: [
              {
                width: 105,
                text: [{ text: `` }],
                normal: true,
                margin: [10, 10, 0, 0],
              },
              {
                width: 15,
                height: 15,
                image: `instagramIcon`,
                margin: [10, 10, -2, 0],
                alignment: "center",
              },
              {
                width: 120,
                text: [
                  {
                    text: `voyaah_holidays`,
                    fontSize: 13,
                    bold: true,
                    link: "https://www.instagram.com/voyaah_holidays/",
                  },
                ],
                normal: true,
                margin: [-10, 8, 0, 0],
              },
              {
                width: 15,
                height: 15,
                image: "twitterIcon",
                margin: [10, 10, -5, 0],
                alignment: "center",
              },
              {
                width: 120,
                text: [
                  {
                    text: `voyaah-holidays`,
                    fontSize: 13,
                    bold: true,
                    link: "https://twitter.com/Voyaah_holidays",
                  },
                ],
                normal: true,
                margin: [-10, 8, 0, 0],
              },
              {
                width: 15,
                height: 15,
                image: "youtubeIcon",
                margin: [10, 10, -15, 0],
                alignment: "center",
              },
              {
                width: 120,
                text: [
                  {
                    text: `voyaah_holidays`,
                    fontSize: 13,
                    bold: true,
                    link: "https://www.youtube.com/@Voyaah_holidays",
                  },
                ],
                normal: true,
                margin: [-10, 8, -20, 0],
              },
            ],
          },
        ],
      };
      docDefinition.content.push(termsAndConditionHeaderBackground);
      docDefinition.content.push(termsAndConditionHeader);
      docDefinition.content.push(termsAndConditionContentBackground);
      docDefinition.content.push(termsAndConditionContent);
      if (height > 300) {
        termsAndConditionStack.pageBreak = "before";
      }
      docDefinition.content.push(termsAndConditionStack);
    }
    async function getImage(imageURL, width, height) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "Anonymous";
        img.onload = () => {
          const canvas = document.createElement("canvas");
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);
          const dataURL = canvas.toDataURL();
          resolve(dataURL);
        };
        img.onerror = reject;
        img.src = imageURL;
      });
    }

    function resizeAndCropImage(url, width, height) {
      width = width * 2;
      height = height * 2;
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "Anonymous";
        img.onload = () => {
          const canvas = document.createElement("canvas");
          let newWidth = width;
          let newHeight = height;
          const aspectRatio = img.width / img.height;
          const wScaleRatio = width / img.width;
          const htScaleRatio = height / img.height;
          let scaleRatio =
            wScaleRatio > htScaleRatio ? wScaleRatio : htScaleRatio;
          newWidth = img.width * scaleRatio;
          newHeight = img.height * scaleRatio;
          canvas.width = newWidth;
          canvas.height = newHeight;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, newWidth, newHeight);

          const croppedCanvas = document.createElement("canvas");
          croppedCanvas.height = height;
          let cropLeft = 0;
          let cropTop = 0;
          if (newWidth > width) cropLeft = (newWidth - width) / 2;
          if (newHeight > height) cropTop = (newHeight - height) / 2;

          croppedCanvas.width = width;

          const croppedCtx = croppedCanvas.getContext("2d");
          croppedCtx.drawImage(
            canvas,
            cropLeft,
            cropTop,
            width,
            height,
            0,
            0,
            width,
            height
          );
          const croppedDataURL = croppedCanvas.toDataURL();
          resolve(croppedDataURL);
        };
        img.onerror = reject;
        img.src = url;
      });
    }

    await preProcessHomePage(pdfData);
    await pushFirstPage(docDefinition);
    await forItineraryFunction(docDefinition);
    await pushAccomodationPages(docDefinition);
    await termsAndConfitionFunction(docDefinition);
    await pdfMake.createPdf(docDefinition, null, fonts).download();
    setPdfHeight(false);
  };

  return (
    <>
      <div
        className="head"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "6px 0px",
        }}
      >
        <h3 className="intenary-title">Itinerary</h3>
        <div className="action-btns">
          <span className="share" onClick={sendMail}>
            <img
              src={require("../../assets/images/share.png")}
              alt="image"
              className="img-fluid"
            />
            <span className="act-btn-txt">share</span>
          </span>
          {pdfHeight ? (
            <span className="print">
              <img
                src={require("../../assets/LoadingGif.gif")}
                alt=""
                style={{ height: "20px" }}
              />
              <span className="act-btn-txt">Loading...</span>
            </span>
          ) : (
            <span className="print" onClick={() => generatePdf(pdfData)}>
              <img
                src={require("../../assets/images/download.png")}
                alt=""
                className="img-fluid"
              />
              <span className="act-btn-txt">Download</span>
            </span>
          )}
          <span
            className="print"
            onClick={() => {
              window.print();
            }}
          >
            <img
              src={require("../../assets/images/print.png")}
              alt=""
              className="img-fluid"
            />
            <span className="act-btn-txt">Print</span>
          </span>
        </div>
      </div>
      <div className="itinerary-blk" id="packageItinerary">
        {dayWiseItinerary?.slice(0, showListCount)?.map((item, index) => {
          return (
            <ItineraryCard item={item} key={`item_${index}`} index={index} />
          );
        })}
        {showViewMore && (
          <div
            className="info-txt view-more"
            style={{ margin: "8px" }}
            onClick={() => updateListCount()}
          >
            View More
          </div>
        )}
      </div>
    </>
  );
}

export default Itinerary;
