import { takeLatest, put, call } from "redux-saga/effects";

import { GET_PACKAGE_SUMMARY, GET_LISTING_PAGE_DATA } from "./actionTypes";

import {
  getPackageSummarySuccess,
  getPackageSummaryFail,
  getListingPageDataSuccess,
  getListingPageDataFail,
} from "./actions";

import {
  getPackageSummary,
  getListingPageData,
} from "../../helpers/backend_helper";

function* onGetPackageSummary({ payload: data }) {
  try {
    const response = yield call(getPackageSummary, data);
    yield put(getPackageSummarySuccess(response));
  } catch (error) {
    yield put(getPackageSummaryFail(error.response));
  }
}

function* PackageSummarySaga() {
  yield takeLatest(GET_PACKAGE_SUMMARY, onGetPackageSummary);
}

function* onGetListingPageData({
  payload: pageUrl,
  customerProfileId,
  accountId,
}) {
  try {
    const response = yield call(
      getListingPageData,
      pageUrl,
      customerProfileId,
      accountId
    );
    yield put(getListingPageDataSuccess(response));
  } catch (error) {
    yield put(getListingPageDataFail(error.response));
  }
}

function* ListingPageDataSaga() {
  yield takeLatest(GET_LISTING_PAGE_DATA, onGetListingPageData);
}

export { PackageSummarySaga, ListingPageDataSaga };
