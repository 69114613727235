import React from "react";
import "./VideoCardLayout.scss";
import ReactGA from "react-ga4";
import { getAnalyticsEnabled } from "../../../helpers/backend_helper";

function VideoCardLayout(props: any) {
  const { data, posterUrl } = props;
  const googleAnalyticsFunction = () => {
    if (getAnalyticsEnabled()) {
      ReactGA.event({
        action: `${props.data.cardAttributes.title} video`,
        category: `homepage`,
      });
    }
  };
  return (
    <>
      {data && (
        <div className="discover-block">
          <div className="blk">
            <div
              onClick={() => googleAnalyticsFunction()}
              style={{ position: "relative", maxWidth: "370px" }}
            >
              <video poster={posterUrl} controls playsInline muted>
                {/* `${data.folder.url + '/' + item.fileName}` */}
                <source src={props.videoUrl} type="video/mp4"></source>
              </video>
            </div>
          </div>
          <div className="outer-text">{props.data.cardAttributes.title}</div>
        </div>
      )}
    </>
  );
}

export default VideoCardLayout;
