import React, { useEffect, useState } from "react";
import VideoCardLayout from "./video-card-layout/VideoCardLayout";
import { useNavigate } from "react-router-dom";
import "./VideoTray.scss";

function VideoTray(props: any) {
  const { data } = props;
  const navigate = useNavigate();
  const [backgrounImage, setBackgroundImage] = useState<any>([]);
  const [dataArr, setDataArr] = useState<any>([]);

  const navigateToCTA = () => {
    sessionStorage.setItem("homePageScrollPosition", `${window.pageYOffset}`);
    navigate(`/search/${data.folder.files[0].cardAttributes.ctaUrl}`);
    // navigate(`/search/${props.data.cardAttributes.ctaUrl}`);
  };
  useEffect(() => {
    setBackgroundImage(
      data.folder.files.filter((data: any) => data.displayIndex === 1)
    );
  }, []);

  useEffect(() => {
    if (data?.folder.files !== 0) {
      data.folder.files.sort((a: any, b: any) => {
        if (a.displayIndex !== null) {
          var value;
          value =
            a.displayIndex > b.displayIndex
              ? 1
              : a.displayIndex < b.displayIndex
              ? -1
              : 0;
          return value;
        }
      });
      setDataArr(data);
    }
  }, [data]);
  return (
    <>
      {dataArr.length !== 0 && dataArr.folder.files.length !== 0 && (
        <div
          className="discover-section"
          style={{
            background:
              backgrounImage.length > 0
                ? `url(${dataArr.folder.url}/${backgrounImage[0].fileName}) no-repeat center center/cover`
                : `url(${dataArr.folder.url}/${dataArr.folder.files[0].fileName})  no-repeat center center/cover`,
          }}
        >
          <span className="background-overlay"></span>
          <div className="section position-relative">
            <div className="discover-world">
              <div className="discover-left">
                <div className="heading">
                  {dataArr.folder.files[0].cardAttributes.title}
                </div>
                <p>{dataArr.folder.files[0].cardAttributes.subTitle}</p>
                <div className="green-btn">
                  <span onClick={navigateToCTA}>
                    {dataArr.folder.files[0].cardAttributes.ctaText
                      ? dataArr.folder.files[0].cardAttributes.ctaText
                      : "View Brands"}
                  </span>
                </div>
              </div>
              <div className="discover-right">
                {dataArr.folder.files.map((item: any, index: number) => {
                  return (
                    item.cardAttributes.itemType === "mediaItem.videoCard" && (
                      <VideoCardLayout
                        key={index}
                        data={item}
                        videoUrl={`${dataArr.folder.url + "/" + item.fileName}`}
                      />
                    )
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default VideoTray;
