import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import _ from "underscore";
import "./AboutUs.scss";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import ReactPixel from "react-facebook-pixel";
import { getAboutUsData } from "../../store/about-us/actions";
import { createCustomerProfile } from "../../store/customer/actions";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../helpers/firebase";

function AboutUs() {
  let dispatch = useDispatch();
  const [user, loading] = useAuthState(auth);
  const [aboutUsData, setAboutUsData] = useState<any>();
  const [ourCollaboration, setOurCollaboration] = useState<any>([]);
  const [corporatePartner, setCorporatePartner] = useState<any>([]);
  const [enoughAbout, setEnoughAbout] = useState<any>([]);
  const screenWidth = window.innerWidth;
  const navigate = useNavigate();

  const { customerProfileResponse } = useSelector(
    (state: any) => state.CustomerProfileReducer
  );

  const [metaTags, setMetaTags] = useState<any[]>([]);
  const [breadcrumbs, setBreadcrumbs] = useState<any[]>([]);

  useEffect(() => {
    if (customerProfileResponse != undefined) {
      const customerTracker = localStorage.getItem("voyaahCustomerTracker");
      const customerConsentStore = localStorage.getItem(
        "voyaahCustomerConsent"
      );

      if (!customerTracker) {
        const customerProfile = {
          id: customerProfileResponse,
        };

        localStorage.setItem(
          "voyaahCustomerTracker",
          JSON.stringify(customerProfile)
        );
      }

      if (!customerConsentStore) {
        const customerConsent = {
          essentialCookieEnabled: true,
          analyticsCookieEnabled: true,
          personalizationCookieEnabled: true,
        };

        localStorage.setItem(
          "voyaahCustomerConsent",
          JSON.stringify(customerConsent)
        );
      }
    }
  }, [customerProfileResponse]);

  function formatMetatagsForPackage(metaTags: any) {
    const metaTagArray: any[] = [];
    metaTags.forEach((metaTag: { tag: any; value: any }) => {
      const metaTagObject = { key: metaTag.tag, value: metaTag.value };
      metaTagArray.push(metaTagObject);
    });

    setMetaTags(metaTagArray);
  }

  function formatBreadcrumbsForPackage(breadcrumbs: any) {
    const breadcrumbsArray: any[] = [];
    breadcrumbs.forEach((breadcrumb: { name: any; url: any }) => {
      const breadcrumbObject = { name: breadcrumb.name, url: breadcrumb.url };
      breadcrumbsArray.push(breadcrumbObject);
    });

    setBreadcrumbs(breadcrumbsArray);
  }

  useEffect(() => {
    let customerTracker = localStorage.getItem("voyaahCustomerTracker");
    if (customerTracker == null && !loading) {
      const consentTracker = {
        customerProfileId: null,
        accountId: user?.uid ?? null,
        essentialConsent: true,
        analyticsConsent: true,
        personalizationConsent: true,
      };

      dispatch(createCustomerProfile(consentTracker));
    }
  }, [dispatch, loading]);

  useEffect(() => {
    const localStorageData = localStorage.getItem("voyaahCustomerTracker");
    let customerProfileId = "";
    if (localStorageData) {
      customerProfileId = JSON.parse(localStorageData!).id;
    }

    if (!loading) {
      dispatch(getAboutUsData("about-us", customerProfileId, user?.uid ?? ""));
    }
  }, [dispatch, loading]);

  const { response } = useSelector((state: any) => state.AboutUsDataReducer);

  useEffect(() => {
    setAboutUsData(response);
    document.title =
      response !== undefined ? `${response.titleForPageTab}` : ""; //"Terms And Conditions | Voyaah";
  }, [response]);

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactPixel.fbq("init", process.env.REACT_APP_FBPIXEL_ID);
    ReactPixel.fbq("track", "PageView");
  }, []);

  useEffect(() => {
    const positionData = sessionStorage.getItem("aboutUsScrollPosition");
    const footerPositionData = sessionStorage.getItem("footerScrollPostion");
    if (footerPositionData) {
      window.scrollTo(0, +`${footerPositionData}`);
    } else if (positionData) {
      window.scrollTo(0, +`${positionData}`);
    } else {
      window.scrollTo(0, 0);
    }
  });

  const navigateToCTA = (item: any) => {
    navigate(item);
  };
  useEffect(() => {
    if (aboutUsData && aboutUsData.contentSection) {
      aboutUsData.contentSection.map((contentData: any) => {
        if (contentData.sectionKey === "media-collection") {
          if (aboutUsData && aboutUsData.mediaCollection) {
            aboutUsData?.mediaCollection.map((item: any) => {
              if (contentData && contentData.contentItem) {
                contentData.contentItem.filter((data: any) => {
                  if (item && data.mediaFile) {
                    if (
                      item.layout === "cardType.mediaCoverage" &&
                      item.folder.id === data.mediaFile.folderId
                    ) {
                      data.folderUrl = item.folder.url;
                      return data;
                    }
                  }
                });
              }
            });
          }
        }
      });
    }
    if (aboutUsData && aboutUsData.mediaCollection) {
      aboutUsData?.mediaCollection.map((item: any) => {
        if (
          item &&
          item.collectionKey === "our-collaborations" &&
          item.layout === "cardType.4X4Gallery"
        ) {
          if (item?.folder.files !== 0) {
            item.folder.files.sort((a: any, b: any) => {
              if (a.displayIndex !== null) {
                let value;
                value =
                  a.displayIndex > b.displayIndex
                    ? 1
                    : a.displayIndex < b.displayIndex
                    ? -1
                    : 0;
                return value;
              }
            });
            setOurCollaboration(item.folder.files);
          }
        } else if (
          item &&
          item.collectionKey === "our-corporate-partners" &&
          item.layout === "cardType.4X4Gallery"
        ) {
          if (item?.folder.files !== 0) {
            item.folder.files.sort((a: any, b: any) => {
              if (a.displayIndex !== null) {
                let value;
                value =
                  a.displayIndex > b.displayIndex
                    ? 1
                    : a.displayIndex < b.displayIndex
                    ? -1
                    : 0;
                return value;
              }
            });
            setCorporatePartner(item.folder.files);
          }
        } else if (item && item.layout === "cardType.3X3Gallery") {
          if (item?.folder.files !== 0) {
            item.folder.files.sort((a: any, b: any) => {
              if (a.displayIndex !== null) {
                let value;
                value =
                  a.displayIndex > b.displayIndex
                    ? 1
                    : a.displayIndex < b.displayIndex
                    ? -1
                    : 0;
                return value;
              }
            });
            setEnoughAbout(item.folder.files);
          }
        }
      });
    }

    if (aboutUsData && aboutUsData.metaTags) {
      formatMetatagsForPackage(aboutUsData.metaTags);
    }

    if (aboutUsData && aboutUsData.breadcrumbs) {
      formatBreadcrumbsForPackage(aboutUsData.breadcrumbs);
    }
  }, [aboutUsData]);
  const dataLength = aboutUsData ? Object.keys(aboutUsData).length : 0;

  return (
    <div className="inner-page details-page">
      {aboutUsData && dataLength !== 0 && (
        <Helmet>
          {aboutUsData.keywords !== "" && (
            <meta name="keywords" content={aboutUsData.keywords} />
          )}
          {metaTags.length > 0 &&
            metaTags.map((metaTag, index) => {
              return <meta property={metaTag.key} content={metaTag.value} />;
            })}
          {breadcrumbs.length > 0 && (
            <script type="application/ld+json">
              {`{
                  "@context": "https://schema.org",
                  "@type": "BreadcrumbList",
                  "itemListElement": ${JSON.stringify(
                    breadcrumbs.map((item, index) => ({
                      "@type": "ListItem",
                      position: index + 1,
                      name: item.name,
                      item: item.url,
                    }))
                  )}}`}
            </script>
          )}
        </Helmet>
      )}
      {aboutUsData !== undefined && (
        <div className="section">
          <div className="breadcrum">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>›</li>
              <li>{aboutUsData.name}</li>
            </ul>
          </div>

          <div className="inner-heading about-us">
            <div className="head text-center">{aboutUsData.title}</div>
          </div>

          <div className="about-us-blk text-center">
            <p
              dangerouslySetInnerHTML={{
                __html: aboutUsData.description,
              }}
            />
          </div>
        </div>
      )}

      {aboutUsData !== undefined &&
        dataLength !== 0 &&
        aboutUsData?.contentSection &&
        aboutUsData?.contentSection.length !== 0 &&
        aboutUsData?.contentSection.map((item: any) => {
          return (
            item &&
            item.sectionKey === "media-collection" && (
              <div className="white-bg">
                <div className="section">
                  <div className="media-coverage">
                    <h2 className="head text-center">{item?.name}</h2>
                    <div className="media-coverage-blk">
                      {item.contentItem.map(
                        (contentItem: any, index: number) => {
                          return (
                            <>
                              <div
                                className="media-details"
                                key={`media_details_${index}`}
                              >
                                <div className="image">
                                  <div className="media-logo">
                                    <img
                                      src={
                                        contentItem.folderUrl &&
                                        contentItem.mediaFile &&
                                        `${contentItem?.folderUrl}/${contentItem.mediaFile.fileName}`
                                      }
                                      alt={contentItem.fileName}
                                      className="img-fluid"
                                    />
                                  </div>
                                </div>
                                <div className="desc">
                                  <div className="head">{contentItem.name}</div>
                                  <p
                                    className="content"
                                    dangerouslySetInnerHTML={{
                                      __html: contentItem.content,
                                    }}
                                  />
                                  {/* <div className="content">{contentItem.cardAttributes.subTitle}</div> */}
                                  <div className="more">
                                    <a
                                      href={contentItem.ctaUrl}
                                      rel="noreferrer"
                                      target="_blank"
                                    >
                                      {contentItem.ctaText}
                                    </a>
                                  </div>
                                </div>
                              </div>
                              {item.contentItem.length - 1 === index
                                ? ""
                                : index % 2 !== 0 && (
                                    <div className="splitter"></div>
                                  )}
                            </>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )
          );
        })}

      {aboutUsData !== undefined &&
      dataLength !== 0 &&
      aboutUsData?.contentSection &&
      aboutUsData?.contentSection.length !== 0
        ? aboutUsData?.contentSection.map((item: any, index: number) => {
            return (
              item &&
              item.sectionKey === "why-voyaah" &&
              item.contentItem.length !== 0 && (
                <div className="black-bg p0">
                  <div className="section">
                    <div className="group-booking-blk">
                      <h2 className="subhead">{item.name}</h2>
                      <div
                        style={
                          item.contentItem[1] && screenWidth > 768
                            ? styles.whyVoyaahColumn
                            : {}
                        }
                        className="group-booking-list-blk"
                      >
                        {item.contentItem[0] ? (
                          <p
                            dangerouslySetInnerHTML={{
                              __html: `<div style="margin-top:-15px;">${item.contentItem[0].content}</div>`,
                            }}
                          />
                        ) : (
                          ""
                        )}
                        {item.contentItem[1] ? (
                          <p
                            dangerouslySetInnerHTML={{
                              __html:
                                screenWidth > 768
                                  ? `${item.contentItem[1].content}`
                                  : `<div style="margin-top: -13px;">${item.contentItem[1].content}<div>`,
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )
            );
          })
        : ""}

      {aboutUsData !== undefined &&
      dataLength !== 0 &&
      aboutUsData?.mediaCollection &&
      aboutUsData?.mediaCollection.length !== 0
        ? aboutUsData?.mediaCollection.map((item: any) => {
            return item.collectionKey === "our-collaborations" &&
              ourCollaboration.length !== 0 &&
              item.layout === "cardType.4X4Gallery" ? (
              <div className="gray-bg about-us">
                <div className="section">
                  <div className="partners collaborations">
                    <h2 className="head">{item.collectionType}</h2>
                    <div
                      className={`partners-blk ${
                        screenWidth < 768 ? "mobile-scroll" : ""
                      }`}
                    >
                      <div
                        style={
                          screenWidth < 768
                            ? styles.ourCollaborationMobileScreen
                            : {}
                        }
                      >
                        {ourCollaboration?.map(
                          (fileItem: any, index: number) => {
                            return (
                              <div
                                className="partners-content"
                                key={`partners_content_${index}`}
                              >
                                <div className="image">
                                  <img
                                    src={`${item.folder.url}/${fileItem.fileName}`}
                                    alt={fileItem.fileName}
                                    className="img-fluid"
                                  />
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : item.collectionKey === "our-corporate-partners" &&
              corporatePartner.length !== 0 &&
              item.layout === "cardType.4X4Gallery" ? (
              <div className="gray-bg about-us">
                <div className="section">
                  <div className="partners">
                    <h2 className="head">{item.collectionType}</h2>
                    <div
                      className={`partners-blk ${
                        screenWidth < 768 ? "mobile-scroll" : ""
                      }`}
                    >
                      <div
                        style={
                          screenWidth < 768
                            ? styles.ourCorporateMobileScreen
                            : {}
                        }
                      >
                        {corporatePartner?.map(
                          (fileItem: any, index: number) => {
                            return (
                              <div
                                className="partners-content"
                                key={`corporate_partners_${index}`}
                              >
                                <div className="image">
                                  <img
                                    src={`${item.folder.url}/${fileItem.fileName}`}
                                    alt={fileItem.fileName}
                                    className="img-fluid"
                                  />
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              item.layout === "cardType.3X3Gallery" &&
              enoughAbout.length !== 0 && (
                <div className="black-bg">
                  <div className="section">
                    <div className="partners">
                      <div className="head">{item.collectionType}</div>
                      <div className="partners-blk">
                        {enoughAbout?.map((fileItem: any, index: number) => {
                          return (
                            <div
                              className="partners-content explore"
                              key={`about_partners_${index}`}
                            >
                              <div
                                className="image link"
                                onClick={() => {
                                  navigateToCTA(fileItem.cardAttributes.ctaUrl);
                                  sessionStorage.setItem(
                                    "aboutUsScrollPosition",
                                    `${window.pageYOffset}`
                                  );
                                  sessionStorage.removeItem(
                                    "footerScrollPostion"
                                  );
                                }}
                              >
                                <img
                                  src={`${item.folder.url}/${fileItem.fileName}`}
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                              <div
                                className="content link"
                                onClick={() => {
                                  navigateToCTA(fileItem.cardAttributes.ctaUrl);
                                }}
                              >
                                {fileItem.cardAttributes.title}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              )
            );
          })
        : ""}
    </div>
  );
}

const styles = {
  imageHeight: { height: "120px" },
  whyVoyaahColumn: { columns: 2 },
  ourCollaborationMobileScreen: { width: "max-content" },
  ourCorporateMobileScreen: { width: "max-content" },
};

export default AboutUs;
