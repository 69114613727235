import {
    GET_BOOKING_DETAILS,
    GET_BOOKING_DETAILS_SUCCESS,
    GET_BOOKING_DETAILS_FAIL,
    CLEAR_BOOKING_DETAILS,
    GET_BOOKING_LIST,
    GET_BOOKING_LIST_SUCCESS,
    GET_BOOKING_LIST_FAIL,
    UPLOAD_BOOKING_DOCUMENT,
    UPLOAD_BOOKING_DOCUMENT_SUCCESS,
    UPLOAD_BOOKING_DOCUMENT_FAIL,
    GET_CLIENT_IP,
    GET_CLIENT_IP_SUCCESS,
    GET_CLIENT_IP_FAIL,
    UPDATE_BOOKING_STATUS,
    UPDATE_BOOKING_STATUS_SUCCESS,
    UPDATE_BOOKING_STATUS_FAIL,
    CLEAR_BOOKING_STATUS,
    GET_PAYMENT_DETAILS,
    GET_PAYMENT_DETAILS_SUCCESS,
    GET_PAYMENT_DETAILS_FAIL,
} from "./actionTypes";

const initialState = {
    booking: {},
    bookingList: [],
    bookingDocument: [],
    paymentUrl: {},
    paymentDetails: {},
    clientIp: {},
    bookingLoader: false,
    bookingStatusResponse: {},
    error: {
        message: "",
    },
    bookingErrorObj:{}
};

const BookingDetailsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_BOOKING_DETAILS:
            state = { ...state, bookingLoader: true };
            break;
        case GET_BOOKING_DETAILS_SUCCESS:
            state = { ...state, booking: action.payload, bookingLoader: false };
            break;
        case GET_BOOKING_DETAILS_FAIL:
            state = {
                ...state,
                bookingErrorObj: {
                    message: action.payload.data.message,
                    statusCode:action.payload.status
                },
                bookingLoader: false,
            };
            break;
        case CLEAR_BOOKING_DETAILS:
            // state = { ...state, booking: {}, bookingLoader: false, };
            state = { ...state, bookingLoader: false,bookingErrorObj:{} };
            break;
        case GET_BOOKING_LIST:
            state = { ...state, bookingLoader: true };
            break;
        case GET_BOOKING_LIST_SUCCESS:
            state = { ...state, bookingList: action.payload, bookingLoader: false };
            break;
        case GET_BOOKING_LIST_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                bookingLoader: false,
            };
            break;
        case UPLOAD_BOOKING_DOCUMENT:
            state = { ...state, bookingLoader: true };
            break;
        case UPLOAD_BOOKING_DOCUMENT_SUCCESS:
            state = { ...state, bookingDocument: action.payload, bookingLoader: false };
            break;
        case UPLOAD_BOOKING_DOCUMENT_FAIL:
            state = {
                ...state,
                bookingErrorObj: {
                    message: action.payload.data.message,
                    statusCode:action.payload.status
                },
                bookingLoader: false,
            };
            break;
        case GET_CLIENT_IP:
            state = { ...state, bookingLoader: true };
            break;
        case GET_CLIENT_IP_SUCCESS:
            state = { ...state, clientIp: action.payload, bookingLoader: false };
            break;
        case GET_CLIENT_IP_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                bookingLoader: false,
            };
            break;
        case UPDATE_BOOKING_STATUS:
            state = { ...state, bookingLoader: true };
            break;
        case UPDATE_BOOKING_STATUS_SUCCESS:
            state = { ...state, bookingStatusResponse: action.payload, bookingLoader: false };
            break;
        case UPDATE_BOOKING_STATUS_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                bookingLoader: false,
            };
            break;
        case CLEAR_BOOKING_STATUS:
            state = { ...state, bookingStatusResponse: {}, bookingLoader: false };
            break;
        case GET_PAYMENT_DETAILS:
            state = { ...state, bookingLoader: true };
            break;
        case GET_PAYMENT_DETAILS_SUCCESS:
            state = { ...state, paymentDetails: action.payload, bookingLoader: false };
            break;
        case GET_PAYMENT_DETAILS_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                bookingLoader: false,
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default BookingDetailsReducer;