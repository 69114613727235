import { useEffect, useState } from "react";
import PackageCardLayout from "./package-card-layout/PackageCardLayout";
import "./PackageTray.scss";
import ReactGA from "react-ga4";
import { getAnalyticsEnabled } from "../../helpers/backend_helper";

function PackageTray(props: any) {
  const screenWidth = window.innerWidth;
  const [dataArr, setDataArr] = useState<any>([]);
  const { data } = props;
  useEffect(() => {
    if (data?.folder.files !== 0) {
      data.folder.files.sort((a: any, b: any) => {
        if (a.displayIndex !== null) {
          var value;
          value =
            a.displayIndex > b.displayIndex
              ? 1
              : a.displayIndex < b.displayIndex
              ? -1
              : 0;
          return value;
        }
      });
      setDataArr(data);
    }
  }, [data]);
  const googleAnalyticsFunction = () => {
    if (getAnalyticsEnabled()) {
      ReactGA.event({
        action: `homepage_discover-${data?.richText}`,
        category: `homepage`,
      });
    }
  };
  return (
    <div className="packages-section">
      {dataArr.length !== 0 && dataArr.folder?.files?.length !== 0 && (
        <div onClick={() => googleAnalyticsFunction()} className="section">
          <h2 className="heading">{dataArr.collectionType}</h2>
          <div
            style={screenWidth < 767 ? styles.packageBlockMobileView : {}}
            className="package-block"
          >
            {dataArr.folder.files.map((item: any, index: number) => {
              if (!(index % 2)) {
                return (
                  <div
                    style={
                      screenWidth < 767 ? styles.packageBlockMobileView70 : {}
                    }
                    className="w70"
                  >
                    <PackageCardLayout
                      data={item}
                      folderUrl={dataArr.folder.url}
                    />
                  </div>
                );
              } else {
                return (
                  <div
                    style={
                      screenWidth < 767 ? styles.packageBlockMobileView30 : {}
                    }
                    className="w30"
                  >
                    <PackageCardLayout
                      data={item}
                      folderUrl={dataArr.folder.url}
                    />
                  </div>
                );
              }
            })}
          </div>
        </div>
      )}
    </div>
  );
}
const styles = {
  packageBlockMobileView: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    marginLeft: "-5px",
  },
  packageBlockMobileView70: {
    width: "165px",
    height: "170px",
    paddingRight: "20px",
  },
  packageBlockMobileView30: {
    width: "165px",
    height: "170px",
    paddingRight: "20px",
  },
};

export default PackageTray;
