import { takeLatest, put, call } from "redux-saga/effects";

import { POST_CONTACT_DETAILS } from "./actionTypes";

import {
    getContactDetailsSuccess,
    getContactDetailsFail,
} from "./actions";

import { getContactDetails } from "../../helpers/backend_helper";

function* onGetContactDetails({ payload: data }) {
    try {
        const response = yield call(getContactDetails, data);
        yield put(getContactDetailsSuccess(response));
    } catch (error) {
        yield put(getContactDetailsFail(error.response));
    }
}

function* ContactDetailsSaga() {
    yield takeLatest(POST_CONTACT_DETAILS, onGetContactDetails);
}

export default ContactDetailsSaga;