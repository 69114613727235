import { takeLatest, put, call } from "redux-saga/effects";

import {
  CREATE_CUSTOMERPROFILE,
  UPDATE_CUSTOMERPROFILE,
  SAVE_CUSTOMERINTERESTS,
  GET_CUSTOMERINTERESTS,
} from "./actionTypes";

import {
  createCustomerProfileSuccess,
  createCustomerProfileFail,
  updateCustomerProfileSuccess,
  updateCustomerProfileFail,
  saveCustomerInterestsSuccess,
  saveCustomerInterestsFail,
  getCustomerInterestsSuccess,
  getCustomerInterestsFail,
} from "./actions";

import {
  createCustomerProfile,
  updateCustomerProfile,
  saveCustomerInterests,
  getCustomerInterests,
} from "../../helpers/backend_helper";

function* onCreateCustomerProfile({ payload: data }) {
  try {
    const response = yield call(createCustomerProfile, data);
    yield put(createCustomerProfileSuccess(response));
  } catch (error) {
    yield put(createCustomerProfileFail(error.response));
  }
}

function* onUpdateCustomerProfile({ payload: data }) {
  try {
    const response = yield call(updateCustomerProfile, data);
    yield put(updateCustomerProfileSuccess(response));
  } catch (error) {
    yield put(updateCustomerProfileFail(error.response));
  }
}

function* onSaveCustomerInterests({ payload: data }) {
  try {
    const response = yield call(saveCustomerInterests, data);
    yield put(saveCustomerInterestsSuccess(response));
  } catch (error) {
    yield put(saveCustomerInterestsFail(error.response));
  }
}

function* onGetCustomerInterests({ payload: customerProfileId, accountId }) {
  try {
    const response = yield call(
      getCustomerInterests,
      customerProfileId,
      accountId
    );
    yield put(getCustomerInterestsSuccess(response));
  } catch (error) {
    yield put(getCustomerInterestsFail(error.response));
  }
}

function* CustomerProfileSaga() {
  yield takeLatest(CREATE_CUSTOMERPROFILE, onCreateCustomerProfile);
  yield takeLatest(SAVE_CUSTOMERINTERESTS, onSaveCustomerInterests);
  yield takeLatest(GET_CUSTOMERINTERESTS, onGetCustomerInterests);
  yield takeLatest(UPDATE_CUSTOMERPROFILE, onUpdateCustomerProfile);
}

export default CustomerProfileSaga;
