import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import _ from "underscore";
import ReactPixel from 'react-facebook-pixel';

import { faqData } from "../../constants/faqData";

import "./Faq.scss";

function Faq() {
  const [categoryData, setCategoryData] = useState<string[]>([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedCategoryData, setSelectedCategoryData] = useState<any>([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "FAQ | Voyaah";
    ReactPixel.fbq('init', process.env.REACT_APP_FBPIXEL_ID);
    ReactPixel.fbq('track', 'PageView');
  }, []);

  useEffect(() => {
    let cd: string[] = _.map(faqData, function (a) {
      return a.category;
    });
    setCategoryData(cd);
    setSelectedCategory(faqData[0].category);
    setSelectedCategoryData(faqData[0].data);
  }, []);

  const handleCategoryClick = (item: any) => {
    setSelectedCategory(item);
    var scd = _.findWhere(faqData, {
      category: item,
    });
    setSelectedCategoryData(scd?.data);
  };

  const handleExpandClick = (item: any) => {
    let newCategoryData = _.map(selectedCategoryData, function (a) {
      if (a.id === item.id) a.isExpand = !a.isExpand;
      return a;
    });
    setSelectedCategoryData(newCategoryData);
  };

  return (
    <div className="inner-page details-page">
      <div className="section">
        <div className="breadcrum">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>›</li>
            <li>FAQ</li>
          </ul>
        </div>

        <div className="inner-heading">
          <div className="head text-center">Frequently Asked Question</div>
        </div>

        <div className="faq-blk">
          <div className="search-blk">
            <input type="text" placeholder="Input your queries" name="" />
            <span>Search</span>
          </div>

          <div className="faq-tabs-blk">
            <ul>
              {categoryData.map((item: any, index: number) => {
                return (
                  <li key={`cat-${index}`}>
                    <span
                      className={item === selectedCategory ? "active" : ""}
                      onClick={() => handleCategoryClick(item)}
                    >
                      {item}
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>

          <div className="faq-tabs-head">{selectedCategory}</div>

          {selectedCategoryData.map((item: any) => {
            return (
              <div className="faq-acc" key={item.id}>
                <div
                  className={`faq-acc-head ${item.isExpand ? "active" : ""}`}
                >
                  {item.title}{" "}
                  <span onClick={() => handleExpandClick(item)}></span>
                </div>
                <div
                  className={`faq-acc-content ${
                    item.isExpand ? "show" : "hide"
                  }`}
                >
                  {item.details}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Faq;
