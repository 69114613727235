import React, { useEffect, useState } from "react";
import _ from "underscore";
import "./Details.scss";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { createImageAltText } from "../../helpers/utils";

function ImageGallery(props: any) {
  const {
    packageData,
    mediaCollectionData,
    showImageGalleryPopup,
    updateShowImageGalleryPopup,
  } = props;
  const screenWidth = window.innerWidth;
  const [imageArr, setImageArr] = useState<any>([]);

  useEffect(() => {
    setImageArr(packageData?.packageValues?.additionalImages);
  }, [packageData]);

  return (
    <>
      {mediaCollectionData &&
        mediaCollectionData?.length > 0 &&
        mediaCollectionData?.map((collection: any, index: number) => {
          if (collection.collectionType === "Additional") {
            return screenWidth <= 767 ? (
              collection.images ? (
                <div
                  style={{
                    marginBottom: "-5px",
                    marginRight: "-25px",
                    marginLeft: "-25px",
                  }}
                >
                  <OwlCarousel
                    className="owl-theme"
                    items={1}
                    // loop={true}
                    lazyLoad={true}
                    autoplay={true}
                    loop={collection.images.length > 1}
                    nav={true}
                    autoplayHoverPause={true}
                    dots={false}
                  >
                    {collection.images.map((item: any) => {
                      return (
                        <div>
                          <img
                            src={item && item.imageUrl} //{imageArr && imageArr[0]}
                            alt={createImageAltText(item?.imageCaption)}
                            className="img-fluid"
                            style={{
                              width: "70vh",
                              aspectRatio: "4/3",
                              objectFit: "cover",
                              // minHeight: "20vh",
                              // height: "20vh",
                            }}
                            //"contain"
                          />
                        </div>
                      );
                    })}
                  </OwlCarousel>
                </div>
              ) : (
                <div>Loading</div>
              )
            ) : (
              <div className="details-gallery">
                <div className="left">
                  <div className="image">
                    <img
                      // src={require("../../assets/images/image12.png")}
                      src={collection?.images[0]?.imageUrl}
                      alt={createImageAltText(
                        collection?.images[0]?.imageCaption
                      )}
                      className="img-fluid image-gallery-main"
                    />
                  </div>
                </div>
                <div className="right">
                  <div className="image">
                    <img
                      // src={require("../../assets/images/image13.png")}
                      src={collection?.images?.[1]?.imageUrl}
                      alt={createImageAltText(
                        collection?.images[1]?.imageCaption
                      )}
                      className="img-fluid image-gallery-sub"
                    />
                  </div>
                  <div
                    className="image no-margin link"
                    onClick={() => {
                      updateShowImageGalleryPopup(!showImageGalleryPopup);
                    }}
                  >
                    <img
                      // src={require("../../assets/images/image13.png")}
                      src={collection?.images?.[2]?.imageUrl}
                      alt={createImageAltText(
                        collection?.images[2]?.imageCaption
                      )}
                      className="img-fluid image-gallery-sub"
                    />
                    <span className="view-all">View All</span>
                    {/* <span className="layer"></span> */}
                  </div>
                </div>
              </div>
            );
          } else {
            return <></>;
          }
        })}
    </>
  );
}

export default ImageGallery;
