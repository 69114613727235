import itineraryPlanStore from "src/store/itineraryPlanStore";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { CustomItineraryShare, EditIcon } from "src/icons/coreIcons";

const ItineraryCard = ({ item }: { item: any }) => {
  const navigate = useNavigate();
  const getDestinationNames = (visitDestinations: any[]) => {
    if (visitDestinations) {
      return visitDestinations.map((destination: any, index: number) => {
        if (index === visitDestinations.length - 1) {
          return destination?.city?.name;
        } else {
          return `${destination?.city?.name} - `;
        }
      });
    } else {
      return "";
    }
  };
  return (
    <div className="my-booking-section">
      <div className="my-booking">
        <div className="image">
          <img
            src={
              item?.daywiseItinerary &&
              item?.daywiseItinerary[0]?.imagePath &&
              item?.daywiseItinerary[0]?.imagePath.length > 0
                ? `https://${
                    process.env.REACT_APP_CONTENT_S3_BUCKET
                  }.s3.amazonaws.com/original_images/${item?.daywiseItinerary[0]?.imagePath.substring(
                    item?.daywiseItinerary[0]?.imagePath?.indexOf("_") + 1
                  )}`
                : require("../../assets/images/my-booking.png")
            }
            alt=""
            className="img-fluid"
          />
        </div>
        <div className="content">
          <div className="subhead-itinerary">{item.name}</div>

          <div>
            {item.visitDestinations && (
              <p className="cities-itinerary-list">
                {getDestinationNames(item.visitDestinations)}
              </p>
            )}
          </div>

          <div
            style={{
              marginTop: "20px",
            }}
          >
            <div className="number">{`${
              item?.extractedJson.numAdults
                ? item?.extractedJson.numAdults
                : "0"
            } ${item?.extractedJson.numAdults > 1 ? "Adults" : "Adult"} ${
              item?.extractedJson.numChildren > 0
                ? "& " +
                  item?.extractedJson.numChildren +
                  (item?.extractedJson.numChildren > 1 ? " Children" : " Child")
                : ""
            }`}</div>
            <div className="amount lato-font">{`Itinerary Date: ${moment(
              item?.extractedJson.fromDate
            ).format("DD/MM/YYYY")} - ${moment(
              item?.extractedJson.toDate
            ).format("DD/MM/YYYY")}`}</div>
          </div>
          {!item.isOpportunityCreated && (
            <div className="itinerary-card-btn-div">
              <button
                className="itinerary-plan-button-plain"
                onClick={() => {
                  itineraryPlanStore.setStateToInitialForm();
                  navigate(`/custom-itinerary/${item.planReference}/edit`, {
                    state: item.planReference,
                  });
                }}
              >
                <EditIcon />
                Manage
              </button>{" "}
              <button
                className="itinerary-plan-button-plain"
                onClick={() => {
                  itineraryPlanStore.setStateToInitialForm();
                  navigate(`/custom-itinerary/${item.planReference}`);
                }}
              >
                <CustomItineraryShare />
                Share
              </button>
            </div>
          )}
          {item.isOpportunityCreated && (
            <p className="cities-itinerary-list">
              Status: {item.status?.length > 0 ? item.status : "Submitted"}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ItineraryCard;
