import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import ReactGA from "react-ga4";
import { getAnalyticsEnabled } from "./helpers/backend_helper";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "./assets/css/style.scss";
import "./assets/css/base-style.scss";
import "react-datepicker/dist/react-datepicker.css";

import { Provider } from "react-redux";

import store from "./store";

if (getAnalyticsEnabled()) {
  const measurementId = process.env.REACT_APP_GA_MEASUREMENT_ID || "";
  ReactGA.initialize(measurementId);
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>
  // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
