export const sortData = [
  "Newly added",
  "Voyaah Recommended",
  "Best Selling",
  "Discount",
  "Price low to high",
  "Price high to low",
  "A to Z",
  "Z to A",
];

export const internationalTags = [
  "Maldives",
  "Singapore",
  "Dubai",
  "Bali",
  "Thailand",
  "Vietnam",
  "SouthAfrica",
  "Kenya",
  "Egypt",
  "location.Asia",
  "location.Europe",
  "location.NA",
  "location.SA",
  "location.Africa",
  "location.ANZ",
];

export const domesticTags = [
  "Goa",
  "Kerala",
  "Rajasthan",
  "location.SI",
  "location.NI",
  "location.WI",
  "location.EI",
];

export const filterData = [
  {
    title: "Price",
    isExpand: false,
    data: [
      {
        title: "Rs 10,000 & Below",
        isSelected: false,
        minValue: 0,
        maxValue: 10000,
      },
      {
        title: "Rs 10,001 to Rs 25,000",
        isSelected: false,
        minValue: 10001,
        maxValue: 25000,
      },
      {
        title: "Rs 25,001 to Rs 50,000",
        isSelected: false,
        minValue: 25001,
        maxValue: 50000,
      },
      {
        title: "Rs 50,001 to Rs 1,00,000",
        isSelected: false,
        minValue: 50001,
        maxValue: 100000,
      },
      {
        title: "Rs 1,00,001 to Rs 2,00,000",
        isSelected: false,
        minValue: 100001,
        maxValue: 200000,
      },
      {
        title: "Rs 2,00,001 to Rs 3,00,000",
        isSelected: false,
        minValue: 200001,
        maxValue: 300000,
      },
      {
        title: "Rs 3,00,001 to Rs 5,00,000",
        isSelected: false,
        minValue: 300001,
        maxValue: 500000,
      },
    ],
  },
  {
    title: "Property Type",
    isExpand: false,
    data: [
      {
        title: "4 Star",
        isSelected: false,
        value: "4",
        type: "hotelStarRating",
      },
      {
        title: "5 Star",
        isSelected: false,
        value: "5",
        type: "hotelStarRating",
      },
      {
        title: "Luxury",
        isSelected: false,
        value: "propertyType.Luxury",
        type: "propertyType",
      },
      {
        title: "Boutique",
        isSelected: false,
        value: "propertyType.Boutique",
        type: "propertyType",
      },
      {
        title: "Hotels",
        isSelected: false,
        value: "propertyType.Hotel",
        type: "propertyType",
      },
      {
        title: "Resorts",
        isSelected: false,
        value: "propertyType.Resort",
        type: "propertyType",
      },
      {
        title: "Villas",
        isSelected: false,
        value: "propertyType.Villa",
        type: "propertyType",
      },
      {
        title: "Homestay",
        isSelected: false,
        value: "propertyType.Homestay",
        type: "propertyType",
      },
      {
        title: "Glamping",
        isSelected: false,
        value: "propertyType.Glamping",
        type: "propertyType",
      },
    ],
  },
  {
    title: "Themes",
    isExpand: false,
    data: [
      {
        title: "Hills & Mountains",
        isSelected: false,
        value: "theme.Hills",
      },
      {
        title: "Beaches",
        isSelected: false,
        value: "theme.Beaches",
      },
      {
        title: "Backwaters",
        isSelected: false,
        value: "theme.Backwaters",
      },
      {
        title: "Wildlife & Safari",
        isSelected: false,
        value: "theme.Wildlifes",
      },
      {
        title: "Adventures",
        isSelected: false,
        value: "theme.Adventures",
      },
      {
        title: "Wellness",
        isSelected: false,
        value: "theme.Wellness",
      },
      {
        title: "Romance / Honeymoon",
        isSelected: false,
        value: "theme.Romance",
      },
      {
        title: "Cruises",
        isSelected: false,
        value: "theme.Cruises",
      },
      {
        title: "Family Friendly",
        isSelected: false,
        value: "theme.Family",
      },
      {
        title: "Uber Luxury",
        isSelected: false,
        value: "theme.Luxury",
      },
      {
        title: "Unique Stays",
        isSelected: false,
        value: "theme.Unique",
      },
      {
        title: "City Breaks",
        isSelected: false,
        value: "theme.City",
      },
      {
        title: "Small Groups",
        isSelected: false,
        value: "theme.SmallGroups",
      },
      {
        title: "Rail Journeys/Discoveries",
        isSelected: false,
        value: "theme.Rail",
      },
    ],
  },
];

export const PropertyOverLayFilterData = [
  {
    title: "Property Type",
    isExpand: false,
    data: [
      {
        title: "1 Star",
        isSelected: false,
        value: "propertyType.1Star",
        type: "hotelStarRating",
      },
      {
        title: "2 Star",
        isSelected: false,
        value: "propertyType.2Star",
        type: "hotelStarRating",
      },
      {
        title: "3 Star",
        isSelected: false,
        value: "propertyType.3Star",
        type: "hotelStarRating",
      },
      {
        title: "4 Star",
        isSelected: false,
        value: "propertyType.4Star",
        type: "hotelStarRating",
      },
      {
        title: "5 Star",
        isSelected: false,
        value: "propertyType.5Star",
        type: "hotelStarRating",
      },
      {
        title: "4 Star Luxury",
        isSelected: false,
        value: "propertyType.4StarLuxury",
        type: "hotelStarRating",
      },
      {
        title: "5 Star Luxury",
        isSelected: false,
        value: "propertyType.5StarLuxury",
        type: "hotelStarRating",
      },
      {
        title: "Luxury",
        isSelected: false,
        value: "propertyType.Luxury",
        type: "propertyType",
      },
      {
        title: "Boutique",
        isSelected: false,
        value: "propertyType.Boutique",
        type: "propertyType",
      },
      {
        title: "Hotels",
        isSelected: false,
        value: "propertyType.Hotel",
        type: "propertyType",
      },
      {
        title: "Resorts",
        isSelected: false,
        value: "propertyType.Resort",
        type: "propertyType",
      },
      {
        title: "Villas",
        isSelected: false,
        value: "propertyType.Villa",
        type: "propertyType",
      },
      {
        title: "Homestay",
        isSelected: false,
        value: "propertyType.Homestay",
        type: "propertyType",
      },
      {
        title: "Glamping",
        isSelected: false,
        value: "propertyType.Glamping",
        type: "propertyType",
      },
      {
        title: "Aparthotel - 1 Star",
        isSelected: false,
        value: "propertyType.ApartHotel1Star",
        type: "propertyType",
      },
      {
        title: "Aparthotel - 2 Star",
        isSelected: false,
        value: "propertyType.ApartHotel2Star",
        type: "propertyType",
      },
      {
        title: "Aparthotel - 3 Star",
        isSelected: false,
        value: "propertyType.ApartHotel3Star",
        type: "propertyType",
      },
      {
        title: "Aparthotel - 4 Star",
        isSelected: false,
        value: "propertyType.ApartHotel4Star",
        type: "propertyType",
      },
      {
        title: "Aparthotel - 5 Star",
        isSelected: false,
        value: "propertyType.ApartHotel5Star",
        type: "propertyType",
      },
    ],
  },
  {
    title: "Themes",
    isExpand: false,
    data: [
      {
        title: "Hills & Mountains",
        isSelected: false,
        value: "theme.Hills",
      },
      {
        title: "Beaches",
        isSelected: false,
        value: "theme.Beaches",
      },
      {
        title: "Backwaters",
        isSelected: false,
        value: "theme.Backwaters",
      },
      {
        title: "Wildlife & Safari",
        isSelected: false,
        value: "theme.Wildlifes",
      },
      {
        title: "Adventures",
        isSelected: false,
        value: "theme.Adventures",
      },
      {
        title: "Wellness",
        isSelected: false,
        value: "theme.Wellness",
      },
      {
        title: "Romance / Honeymoon",
        isSelected: false,
        value: "theme.Romance",
      },
      {
        title: "Cruises",
        isSelected: false,
        value: "theme.Cruises",
      },
      {
        title: "Family Friendly",
        isSelected: false,
        value: "theme.Family",
      },
      {
        title: "Uber Luxury",
        isSelected: false,
        value: "theme.Luxury",
      },
      {
        title: "Unique Stays",
        isSelected: false,
        value: "theme.Unique",
      },
      {
        title: "City Breaks",
        isSelected: false,
        value: "theme.City",
      },
      {
        title: "Small Groups",
        isSelected: false,
        value: "theme.SmallGroups",
      },
      {
        title: "Rail Journeys/Discoveries",
        isSelected: false,
        value: "theme.Rail",
      },
    ],
  },
];

export const themeFilterData = [
  {
    title: "Themes",
    isExpand: true,
    data: [
      {
        title: "Hills & Mountains",
        isSelected: false,
        value: "theme.Hills",
      },
      {
        title: "Beaches",
        isSelected: false,
        value: "theme.Beaches",
      },
      {
        title: "Backwaters",
        isSelected: false,
        value: "theme.Backwaters",
      },
      {
        title: "Wildlife & Safari",
        isSelected: false,
        value: "theme.Wildlifes",
      },
      {
        title: "Adventures",
        isSelected: false,
        value: "theme.Adventures",
      },
      {
        title: "Wellness",
        isSelected: false,
        value: "theme.Wellness",
      },
      {
        title: "Romance / Honeymoon",
        isSelected: false,
        value: "theme.Romance",
      },
      {
        title: "Cruises",
        isSelected: false,
        value: "theme.Cruises",
      },
      {
        title: "Family Friendly",
        isSelected: false,
        value: "theme.Family",
      },
      {
        title: "Uber Luxury",
        isSelected: false,
        value: "theme.Luxury",
      },
      {
        title: "Unique Stays",
        isSelected: false,
        value: "theme.Unique",
      },
      {
        title: "City Breaks",
        isSelected: false,
        value: "theme.City",
      },
      {
        title: "Small Groups",
        isSelected: false,
        value: "theme.SmallGroups",
      },
      {
        title: "Rail Journeys/Discoveries",
        isSelected: false,
        value: "theme.Rail",
      },
    ],
  },
];

export const locationFilterData = {
  title: "Location",
  isExpand: false,
  data: [
    {
      title: "Domestic",
      isSelected: false,
      data: [
        {
          title: "Goa",
          isSelected: false,
          value: "Goa",
        },
        {
          title: "Kerala",
          isSelected: false,
          value: "Kerala",
        },
        {
          title: "Rajasthan",
          isSelected: false,
          value: "Rajasthan",
        },
        {
          title: "South India",
          isSelected: false,
          value: "location.SI",
        },
        {
          title: "North India",
          isSelected: false,
          value: "location.NI",
        },
        {
          title: "West India",
          isSelected: false,
          value: "location.WI",
        },
        {
          title: "East India",
          isSelected: false,
          value: "location.EI",
        },
      ],
    },
    {
      title: "International",
      isSelected: false,
      data: [
        {
          title: "Maldives",
          isSelected: false,
          value: "Maldives",
        },
        {
          title: "Singapore",
          isSelected: false,
          value: "Singapore",
        },
        {
          title: "Dubai",
          isSelected: false,
          value: "Dubai",
        },
        {
          title: "Bali",
          isSelected: false,
          value: "Bali",
        },
        {
          title: "Thailand",
          isSelected: false,
          value: "Thailand",
        },
        {
          title: "Vietnam",
          isSelected: false,
          value: "Vietnam",
        },
        {
          title: "South Africa",
          isSelected: false,
          value: "SouthAfrica",
        },
        {
          title: "Kenya",
          isSelected: false,
          value: "Kenya",
        },
        {
          title: "Egypt",
          isSelected: false,
          value: "Egypt",
        },
        {
          title: "Asia",
          isSelected: false,
          value: "location.Asia",
        },
        {
          title: "Europe",
          isSelected: false,
          value: "location.Europe",
        },
        {
          title: "North America",
          isSelected: false,
          value: "location.NA",
        },
        {
          title: "South America",
          isSelected: false,
          value: "location.SA",
        },
        {
          title: "Africa",
          isSelected: false,
          value: "location.Africa",
        },
        {
          title: "Australia & New Zealand",
          isSelected: false,
          value: "location.ANZ",
        },
      ],
    },
  ],
};

export const domesticFilterData = {
  title: "Domestic",
  isExpand: false,
  data: [
    {
      title: "Goa",
      isSelected: false,
      value: "Goa",
    },
    {
      title: "Kerala",
      isSelected: false,
      value: "Kerala",
    },
    {
      title: "Rajasthan",
      isSelected: false,
      value: "Rajasthan",
    },
    {
      title: "South India",
      isSelected: false,
      value: "location.SI",
    },
    {
      title: "North India",
      isSelected: false,
      value: "location.NI",
    },
    {
      title: "West India",
      isSelected: false,
      value: "location.WI",
    },
    {
      title: "East India",
      isSelected: false,
      value: "location.EI",
    },
  ],
};

export const internationalFilterData = {
  title: "International",
  isExpand: false,
  data: [
    {
      title: "Maldives",
      isSelected: false,
      value: "Maldives",
    },
    {
      title: "Singapore",
      isSelected: false,
      value: "Singapore",
    },
    {
      title: "Dubai",
      isSelected: false,
      value: "Dubai",
    },
    {
      title: "Bali",
      isSelected: false,
      value: "Bali",
    },
    {
      title: "Thailand",
      isSelected: false,
      value: "Thailand",
    },
    {
      title: "Vietnam",
      isSelected: false,
      value: "Vietnam",
    },
    {
      title: "South Africa",
      isSelected: false,
      value: "SouthAfrica",
    },
    {
      title: "Kenya",
      isSelected: false,
      value: "Kenya",
    },
    {
      title: "Egypt",
      isSelected: false,
      value: "Egypt",
    },
    {
      title: "Asia",
      isSelected: false,
      value: "location.Asia",
    },
    {
      title: "Europe",
      isSelected: false,
      value: "location.Europe",
    },
    {
      title: "North America",
      isSelected: false,
      value: "location.NA",
    },
    {
      title: "South America",
      isSelected: false,
      value: "location.SA",
    },
    {
      title: "Africa",
      isSelected: false,
      value: "location.Africa",
    },
    {
      title: "Australia & New Zealand",
      isSelected: false,
      value: "location.ANZ",
    },
  ],
};
