export const faqData = [
    {
        category: "General Enquire",
        data: [
            {
                id: "general_enquire_1",
                isExpand: true,
                title: "Lorem Ipsum",
                details:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur sit amet elit pulvinar, maximus tortor quis, molestie orci. Ut tempor, ante quis finibus gravida, metus risus ultricies libero, vel pharetra metus sapien vel elit. Suspendisse mattis erat eget pulvinar maximus. Nam quis convallis magna, ut fermentum risus. Vivamus congue mauris in massa viverra, at rhoncus augue viverra. Vestibulum malesuada ante lacinia elit consectetur, eu placerat nunc iaculis. Mauris at mauris augue. Suspendisse sit amet diam convallis, ornare quam nec, auctor sapien.",
            },
            {
                id: "general_enquire_2",
                isExpand: false,
                title: "Lorem Ipsum",
                details:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur sit amet elit pulvinar, maximus tortor quis, molestie orci. Ut tempor, ante quis finibus gravida, metus risus ultricies libero, vel pharetra metus sapien vel elit. Suspendisse mattis erat eget pulvinar maximus. Nam quis convallis magna, ut fermentum risus. Vivamus congue mauris in massa viverra, at rhoncus augue viverra. Vestibulum malesuada ante lacinia elit consectetur, eu placerat nunc iaculis. Mauris at mauris augue. Suspendisse sit amet diam convallis, ornare quam nec, auctor sapien.",
            },
            {
                id: "general_enquire_3",
                isExpand: false,
                title: "Lorem Ipsum",
                details:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur sit amet elit pulvinar, maximus tortor quis, molestie orci. Ut tempor, ante quis finibus gravida, metus risus ultricies libero, vel pharetra metus sapien vel elit. Suspendisse mattis erat eget pulvinar maximus. Nam quis convallis magna, ut fermentum risus. Vivamus congue mauris in massa viverra, at rhoncus augue viverra. Vestibulum malesuada ante lacinia elit consectetur, eu placerat nunc iaculis. Mauris at mauris augue. Suspendisse sit amet diam convallis, ornare quam nec, auctor sapien.",
            },
            {
                id: "general_enquire_4",
                isExpand: false,
                title: "Lorem Ipsum",
                details:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur sit amet elit pulvinar, maximus tortor quis, molestie orci. Ut tempor, ante quis finibus gravida, metus risus ultricies libero, vel pharetra metus sapien vel elit. Suspendisse mattis erat eget pulvinar maximus. Nam quis convallis magna, ut fermentum risus. Vivamus congue mauris in massa viverra, at rhoncus augue viverra. Vestibulum malesuada ante lacinia elit consectetur, eu placerat nunc iaculis. Mauris at mauris augue. Suspendisse sit amet diam convallis, ornare quam nec, auctor sapien.",
            },
        ],
    },
    {
        category: "Features",
        data: [
            {
                id: "features_1",
                isExpand: false,
                title: "Lorem Ipsum",
                details:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur sit amet elit pulvinar, maximus tortor quis, molestie orci. Ut tempor, ante quis finibus gravida, metus risus ultricies libero, vel pharetra metus sapien vel elit. Suspendisse mattis erat eget pulvinar maximus. Nam quis convallis magna, ut fermentum risus. Vivamus congue mauris in massa viverra, at rhoncus augue viverra. Vestibulum malesuada ante lacinia elit consectetur, eu placerat nunc iaculis. Mauris at mauris augue. Suspendisse sit amet diam convallis, ornare quam nec, auctor sapien.",
            },
            {
                id: "features_2",
                isExpand: false,
                title: "Lorem Ipsum",
                details:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur sit amet elit pulvinar, maximus tortor quis, molestie orci. Ut tempor, ante quis finibus gravida, metus risus ultricies libero, vel pharetra metus sapien vel elit. Suspendisse mattis erat eget pulvinar maximus. Nam quis convallis magna, ut fermentum risus. Vivamus congue mauris in massa viverra, at rhoncus augue viverra. Vestibulum malesuada ante lacinia elit consectetur, eu placerat nunc iaculis. Mauris at mauris augue. Suspendisse sit amet diam convallis, ornare quam nec, auctor sapien.",
            },
            {
                id: "features_3",
                isExpand: false,
                title: "Lorem Ipsum",
                details:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur sit amet elit pulvinar, maximus tortor quis, molestie orci. Ut tempor, ante quis finibus gravida, metus risus ultricies libero, vel pharetra metus sapien vel elit. Suspendisse mattis erat eget pulvinar maximus. Nam quis convallis magna, ut fermentum risus. Vivamus congue mauris in massa viverra, at rhoncus augue viverra. Vestibulum malesuada ante lacinia elit consectetur, eu placerat nunc iaculis. Mauris at mauris augue. Suspendisse sit amet diam convallis, ornare quam nec, auctor sapien.",
            },
        ],
    },
    {
        category: "Pricing",
        data: [
            {
                id: "pricing_1",
                isExpand: false,
                title: "Lorem Ipsum",
                details:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur sit amet elit pulvinar, maximus tortor quis, molestie orci. Ut tempor, ante quis finibus gravida, metus risus ultricies libero, vel pharetra metus sapien vel elit. Suspendisse mattis erat eget pulvinar maximus. Nam quis convallis magna, ut fermentum risus. Vivamus congue mauris in massa viverra, at rhoncus augue viverra. Vestibulum malesuada ante lacinia elit consectetur, eu placerat nunc iaculis. Mauris at mauris augue. Suspendisse sit amet diam convallis, ornare quam nec, auctor sapien.",
            },
            {
                id: "pricing_2",
                isExpand: false,
                title: "Lorem Ipsum",
                details:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur sit amet elit pulvinar, maximus tortor quis, molestie orci. Ut tempor, ante quis finibus gravida, metus risus ultricies libero, vel pharetra metus sapien vel elit. Suspendisse mattis erat eget pulvinar maximus. Nam quis convallis magna, ut fermentum risus. Vivamus congue mauris in massa viverra, at rhoncus augue viverra. Vestibulum malesuada ante lacinia elit consectetur, eu placerat nunc iaculis. Mauris at mauris augue. Suspendisse sit amet diam convallis, ornare quam nec, auctor sapien.",
            },
        ],
    },
    {
        category: "Cancellation",
        data: [
            {
                id: "cancellation_1",
                isExpand: false,
                title: "Lorem Ipsum",
                details:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur sit amet elit pulvinar, maximus tortor quis, molestie orci. Ut tempor, ante quis finibus gravida, metus risus ultricies libero, vel pharetra metus sapien vel elit. Suspendisse mattis erat eget pulvinar maximus. Nam quis convallis magna, ut fermentum risus. Vivamus congue mauris in massa viverra, at rhoncus augue viverra. Vestibulum malesuada ante lacinia elit consectetur, eu placerat nunc iaculis. Mauris at mauris augue. Suspendisse sit amet diam convallis, ornare quam nec, auctor sapien.",
            },
        ],
    },
];