import {
  CREATE_CUSTOMERPROFILE,
  CREATE_CUSTOMERPROFILE_FAIL,
  CREATE_CUSTOMERPROFILE_SUCCESS,
  UPDATE_CUSTOMERPROFILE,
  UPDATE_CUSTOMERPROFILE_FAIL,
  UPDATE_CUSTOMERPROFILE_SUCCESS,
  SAVE_CUSTOMERINTERESTS,
  SAVE_CUSTOMERINTERESTS_FAIL,
  SAVE_CUSTOMERINTERESTS_SUCCESS,
  GET_CUSTOMERINTERESTS,
  GET_CUSTOMERINTERESTS_FAIL,
  GET_CUSTOMERINTERESTS_SUCCESS,
} from "./actionTypes";

export const createCustomerProfile = (data: any) => {
  return {
    type: CREATE_CUSTOMERPROFILE,
    payload: data,
  };
};

export const createCustomerProfileSuccess = (data: any) => {
  return {
    type: CREATE_CUSTOMERPROFILE_SUCCESS,
    payload: data,
  };
};

export const createCustomerProfileFail = (error: any) => {
  return {
    type: CREATE_CUSTOMERPROFILE_FAIL,
    payload: error,
  };
};

export const updateCustomerProfile = (data: any) => {
  return {
    type: UPDATE_CUSTOMERPROFILE,
    payload: data,
  };
};

export const updateCustomerProfileSuccess = (data: any) => {
  return {
    type: UPDATE_CUSTOMERPROFILE_SUCCESS,
    payload: data,
  };
};

export const updateCustomerProfileFail = (error: any) => {
  return {
    type: UPDATE_CUSTOMERPROFILE_FAIL,
    payload: error,
  };
};

export const saveCustomerInterests = (data: any) => {
  return {
    type: SAVE_CUSTOMERINTERESTS,
    payload: data,
  };
};

export const saveCustomerInterestsSuccess = (data: any) => {
  return {
    type: SAVE_CUSTOMERINTERESTS_SUCCESS,
    payload: data,
  };
};

export const saveCustomerInterestsFail = (error: any) => {
  return {
    type: SAVE_CUSTOMERINTERESTS_FAIL,
    payload: error,
  };
};

export const getCustomerInterests = (
  customerProfileId: string,
  accountId: string
) => {
  return {
    type: GET_CUSTOMERINTERESTS,
    payload: customerProfileId,
    accountId: accountId,
  };
};

export const getCustomerInterestsSuccess = (data: any) => {
  return {
    type: GET_CUSTOMERINTERESTS_SUCCESS,
    payload: data,
  };
};

export const getCustomerInterestsFail = (error: any) => {
  return {
    type: GET_CUSTOMERINTERESTS_FAIL,
    payload: error,
  };
};
