import {
  CREATE_CUSTOMERPROFILE,
  CREATE_CUSTOMERPROFILE_FAIL,
  CREATE_CUSTOMERPROFILE_SUCCESS,
  UPDATE_CUSTOMERPROFILE,
  UPDATE_CUSTOMERPROFILE_FAIL,
  UPDATE_CUSTOMERPROFILE_SUCCESS,
  SAVE_CUSTOMERINTERESTS,
  SAVE_CUSTOMERINTERESTS_FAIL,
  SAVE_CUSTOMERINTERESTS_SUCCESS,
  GET_CUSTOMERINTERESTS,
  GET_CUSTOMERINTERESTS_FAIL,
  GET_CUSTOMERINTERESTS_SUCCESS,
} from "./actionTypes";

const initialState = {
  customerProfileResponse: undefined,
  updateCustomerProfileResponse: undefined,
  customerInterestResponse: undefined,
  loader: false,
  error: {
    message: "",
  },
};

const CustomerProfileReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CREATE_CUSTOMERPROFILE:
      state = { ...state, loader: true };
      break;
    case CREATE_CUSTOMERPROFILE_SUCCESS:
      state = {
        ...state,
        customerProfileResponse: action.payload,
        loader: false,
      };
      break;
    case CREATE_CUSTOMERPROFILE_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loader: false,
      };
      break;
    case UPDATE_CUSTOMERPROFILE:
      state = { ...state, loader: true };
      break;
    case UPDATE_CUSTOMERPROFILE_SUCCESS:
      state = {
        ...state,
        customerProfileResponse: undefined,
        updateCustomerProfileResponse: action.payload,
        loader: false,
      };
      break;
    case UPDATE_CUSTOMERPROFILE_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loader: false,
      };
      break;
    case SAVE_CUSTOMERINTERESTS:
      state = { ...state, loader: true };
      break;
    case SAVE_CUSTOMERINTERESTS_SUCCESS:
      state = {
        ...state,
        customerInterestResponse: action.payload,
        loader: false,
      };
      break;
    case SAVE_CUSTOMERINTERESTS_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loader: false,
      };
      break;
    case GET_CUSTOMERINTERESTS:
      state = { ...state, loader: true };
      break;
    case GET_CUSTOMERINTERESTS_SUCCESS:
      state = {
        ...state,
        customerInterestResponse: action.payload,
        loader: false,
      };
      break;
    case GET_CUSTOMERINTERESTS_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loader: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default CustomerProfileReducer;
