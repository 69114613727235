import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactPixel from 'react-facebook-pixel';

import "./SiteMap.scss";

function SiteMap() {
  const websiteUrl = `https://${process.env.REACT_APP_MEDIA_S3_BUCKET_BASE_URL}/${process.env.REACT_APP_WEBSITE_BUCKET}/sitemap.json`

  const baseUrl = process.env.REACT_APP_BASE_URL
  const [sitemapData, setSiteMapData] = useState({
    static_contents: [],
    staycation_list: new Map<string, any[]>(),
    search_urls: [],
    holiday_list: new Map<string, any[]>(),
  })

  const [isLoader, setIsLoader] = useState(true)
  const [isErrorOccured, setErrorOccured] = useState(false)


  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "SiteMap | Voyaah";
    ReactPixel.fbq('init', process.env.REACT_APP_FBPIXEL_ID);
    ReactPixel.fbq('track', 'PageView');
  }, []);

  useEffect(() => {

  },[sitemapData, isErrorOccured])

  useEffect(() => {
    const headersObj = {
      "Access-Control-Allow-Origin": '*',
      "Content-Type": "application/xml; charset=utf-8"
  };
    if(isLoader) {
      fetch(websiteUrl, {headers: headersObj})
      .then(res => res.json())
      .then(data => {
        setSiteMapData(data)
        setIsLoader(false)
      })
    .catch(function(error) {
      setErrorOccured(true);
    });
    }

  })


  function trimText(text: string) {
    return (text.split("/")[0].charAt(0).toUpperCase() + text.split("/")[0].slice(1)).replaceAll('-', ' ')
  }

  function pascalCase(text: string) {
    return (text.charAt(0).toUpperCase() + text.split("/")[0].slice(1))
  }

  function setFooterScrolling() {
    sessionStorage.removeItem("footerScrollPostion");
    sessionStorage.removeItem("scrollPosition");

  }

  return (
    <div className="inner-page details-page sitemap-css">
      <div className="sitemap-section sitemap-css">
        <div className="breadcrum sitemap-css">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>›</li>
            <li>Voyaah's Site Map</li>
          </ul>
        </div>

        {sitemapData && !isErrorOccured &&
        <>
     
        <div className="sitemap-division sitemap-css">
          <div className="sitemap-sub-division sitemap-css" >
            <div className="subhead sitemap-css" style={{marginLeft: '-18px', marginBottom: '2.5rem'}}> About Voyaah</div>
            {sitemapData?.static_contents?.map((static_page:any) => 
              <div className="line-item">
                  <a className="sitemap-links" key={static_page} onClick={() => setFooterScrolling()} 
                  href={`${baseUrl}/${static_page}`}>{trimText(static_page)}</a>
              </div>
            )}
            </div>
            <div className="sitemap-sub-division sitemap-css" >
            <div className="subhead sitemap-css" style={{marginLeft: '-18px', marginBottom: '2.5rem'}}> Top Destinations</div>

              {sitemapData?.search_urls?.map((search_url:any) => 
                <div className="line-item sitemap-css">
                    <a className="sitemap-links sitemap-css" key={search_url} onClick={() => setFooterScrolling()} 
                    href={`${baseUrl}/search/${search_url}`}>
                      {trimText(search_url)}</a>
                </div>
              )}
            </div>
            </div>

              <div className="sitemap-division sitemap-css">
              <div className="subhead"> Our Staycations</div>
              { Object.entries(sitemapData?.staycation_list).map( ([key, value])=> (
                <>
                <div className="section-division sitemap-css">
                    <div className="division-head sitemap-css"> {pascalCase(key)}</div>
    
                    { value?.map( (staycation_item : any) => (
                      <div className="inner-division sitemap-css">     
                        <a className="sitemap-links sitemap-css" onClick={() => setFooterScrolling()} 
                        key={staycation_item?.reference} href={`${baseUrl}/packages/${staycation_item.reference}`}>
                          {staycation_item?.name}</a>
                    </div>
                    ))
                  }
                  </div>       
                </>
              ))
              }
              </div>

              <div className="sitemap-division sitemap-css">
              <div className="subhead"> Our Holidays</div>
              { Object.entries(sitemapData?.holiday_list).map( ([key, value])=> (
                <>
                <div className="section-division sitemap-css">
                    <div className="division-head sitemap-css"> {pascalCase(key)}</div>
    
                    { value?.map( (holiday_item : any) => (
                      <div className="inner-division sitemap-css">     
                        <a className="sitemap-links sitemap-css" onClick={() => setFooterScrolling()} 
                        key={holiday_item?.reference} href={`${baseUrl}/packages/${holiday_item.reference}`}>{holiday_item?.name}</a>
                    </div>
                    ))
                  }
                  </div>       
                </>
              ))
              }
              </div>
        </>
        }

        {!sitemapData ||  isErrorOccured &&
        <><div className="error-blk">
          <div className="image">
            <img
              src={require("../../assets/images/under-construction.png")}
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="head">Under Construction</div>
          <div className="content">
            <p>
              We'r sorry, the page you requested could not be found. Please go
              back to the <Link to="/">homepage</Link>!
            </p>
          </div>
        </div> 
        </> 
        }
      </div>
    </div>
  );
}

export default SiteMap;
