import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import _, { any, filter } from "underscore";
import { Bars } from "react-loader-spinner";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import { Helmet } from "react-helmet";
import { getAnalyticsEnabled } from "../../helpers/backend_helper";
import ListingCardLayout from "../../components/listing-card-layout/ListingCardLayout";

import {
  getPackageSummary,
  getListingPageData,
} from "../../store/listing/actions";

import {
  sortData as sd,
  filterData as fd,
  locationFilterData as lfd,
  domesticFilterData as dfd,
  internationalFilterData as ifd,
  internationalTags,
  domesticTags,
} from "../../constants/listingData";

import "./Listing.scss";
import { capitalizeFirstLetter, mapSortParam } from "../../helpers/utils";
import { Pagination } from "../../components/pagination-tray/Pagination";

import { createCustomerProfile } from "../../store/customer/actions";

import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../helpers/firebase";

interface CustomizedState {
  pageTitle: string;
}

function Listing() {
  const screenWidth = window.innerWidth;
  let params = useParams();
  const location = useLocation();
  const [user, loading] = useAuthState(auth);
  const pathname = location.pathname;
  const state = location.state as CustomizedState;
  let searchParams = new URLSearchParams(location.search);
  const fetchSelectedSortParam = searchParams.get("sortBy");
  const fetchCurrentPage = params?.pageNo?.substring(4);
  //searchParams.get("pageNo");
  const fetchLocationParam = searchParams.get("locations");
  const fetchPriceMinParam = searchParams.get("priceMin");
  const fetchPriceMaxParam = searchParams.get("priceMax");
  const fetchPropertyTypeParam = searchParams.get("propertyTypes");
  const fetchThemesParam = searchParams.get("themes");
  const fetchHotelRatingsParam = searchParams.get("ratings");

  const [breadcrumbTitle, setBreadcrumbTitle] = useState("");
  const [title, setTitle] = useState("");
  const [sortData] = useState(sd);
  const [selectedSort, setSelectedSort] = useState("Voyaah Recommended");
  const [openSort, setOpenSort] = useState(false);
  const [filterData, setFilterData] = useState<any[]>([]);
  const [listData, setListData] = useState<any>([0]);
  const [defaultListCount] = useState(20);
  const [showListCount, setShowListCount] = useState(defaultListCount);
  const [showViewMore, setShowViewMore] = useState(true);
  const [noRecordMessage, setNoRecordMessage] = useState("No record found");
  const [isFilterCollapse, setIsFilterCollapse] = useState(false);

  const [packageType, setPackageType] = useState("");
  const [searchString, setSearchString] = useState("");

  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [propertyType, setPropertyType] = useState<any[]>([]);
  const [tags, setTags] = useState<any[]>([]);
  const [destination, setDestination] = useState<any[]>([]);
  const [hotelStarRating, setHotelStarRating] = useState<any[]>([]);
  const [priceType, setPriceType] = useState<any[]>([]);
  const [propertyArr, setpropertyArr] = useState<any[]>([]);
  const [tagArr, setTagArr] = useState<any[]>([]);
  const [destinationArr, setDestinationArr] = useState<any[]>([]);
  const [hotelStartRatingArr, setHotelStarRatingArr] = useState<any[]>([]);
  const [filterListData, setFilterListData] = useState<any[]>([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [sortParam, setSortParam] = useState("sort.VoyaahRecommended");
  //Pagination states
  const [pageNumber, setPageNumber] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [urlQueryParams, setURLQueryParams] = useSearchParams();
  const navigate = useNavigate();
  const [pagePath, setPagePath] = useState("");

  const [metaTags, setMetaTags] = useState<any[]>([]);
  const [breadcrumbs, setBreadcrumbs] = useState<any[]>([]);
  const [keywords, setKeywords] = useState<any>("");

  const isAnalyticsEnabled = getAnalyticsEnabled();

  let dispatch = useDispatch();

  const { customerProfileResponse } = useSelector(
    (state: any) => state.CustomerProfileReducer
  );

  useEffect(() => {
    if (isAnalyticsEnabled) {
      ReactGA.event({
        action: `${window.location.pathname} Listing Page`,
        category: `listing_page`,
      });
    }

    document.title = "Listing | Voyaah";
    if (screenWidth > 320 && screenWidth < 767) {
      setIsFilterCollapse(true);
    } else if (screenWidth > 767) {
      setIsFilterCollapse(false);
    }

    ReactPixel.fbq("init", process.env.REACT_APP_FBPIXEL_ID);
    ReactPixel.fbq("track", "PageView");
  }, []);

  useEffect(() => {
    if (customerProfileResponse != undefined) {
      const customerTracker = localStorage.getItem("voyaahCustomerTracker");
      const customerConsentStore = localStorage.getItem(
        "voyaahCustomerConsent"
      );

      if (!customerTracker) {
        const customerProfile = {
          id: customerProfileResponse,
        };

        localStorage.setItem(
          "voyaahCustomerTracker",
          JSON.stringify(customerProfile)
        );
      }

      if (!customerConsentStore) {
        const customerConsent = {
          essentialCookieEnabled: true,
          analyticsCookieEnabled: true,
          personalizationCookieEnabled: true,
        };

        localStorage.setItem(
          "voyaahCustomerConsent",
          JSON.stringify(customerConsent)
        );
      }
    }
  }, [customerProfileResponse]);

  useEffect(() => {
    let customerTracker = localStorage.getItem("voyaahCustomerTracker");
    if (customerTracker == null && !loading) {
      const consentTracker = {
        customerProfileId: null,
        accountId: user?.uid ?? null,
        essentialConsent: true,
        analyticsConsent: true,
        personalizationConsent: true,
      };

      dispatch(createCustomerProfile(consentTracker));
    }
  }, [dispatch, loading]);

  useEffect(() => {
    const localStorageData = localStorage.getItem("voyaahCustomerTracker");
    let customerProfileId = "";
    if (localStorageData) {
      customerProfileId = JSON.parse(localStorageData!).id;
    }
    if (pathname.includes("/holiday") && !loading) {
      dispatch(
        getListingPageData("holidays", customerProfileId, user?.uid ?? "")
      );
    } else if (pathname.includes("/staycations") && !loading) {
      dispatch(
        getListingPageData("staycations", customerProfileId, user?.uid ?? "")
      );
    }
  }, [dispatch, pathname, state, loading]);

  const { listingPageData } = useSelector(
    (state: any) => state.ListingPageDataReducer
  );

  function formatMetatagsForPackage(metaTags: any) {
    const metaTagArray: any[] = [];
    metaTags.forEach((metaTag: { tag: any; value: any }) => {
      const metaTagObject = { key: metaTag.tag, value: metaTag.value };
      metaTagArray.push(metaTagObject);
    });

    setMetaTags(metaTagArray);
  }

  function formatBreadcrumbsForPackage(breadcrumbs: any) {
    const breadcrumbsArray: any[] = [];
    breadcrumbs.forEach((breadcrumb: { name: any; url: any }) => {
      const breadcrumbObject = { name: breadcrumb.name, url: breadcrumb.url };
      breadcrumbsArray.push(breadcrumbObject);
    });

    setBreadcrumbs(breadcrumbsArray);
  }

  useEffect(() => {
    if (listingPageData && listingPageData.keywords) {
      setKeywords(listingPageData.keywords);
    }

    if (listingPageData && listingPageData.metaTags) {
      formatMetatagsForPackage(listingPageData.metaTags);
    }

    if (listingPageData && listingPageData.breadcrumbs) {
      formatBreadcrumbsForPackage(listingPageData.breadcrumbs);
    }
  }, [listingPageData]);

  useEffect(() => {
    const getScrollPosition = sessionStorage.getItem("scrollPosition");
    const footerPositionData = sessionStorage.getItem("footerScrollPostion");
    if (footerPositionData) {
      window.scrollTo(0, +`${footerPositionData}`);
    } else if (getScrollPosition) {
      window.scrollTo({
        top: +`${getScrollPosition}`,
      });
    } else if (filterOpen === false) {
      window.scrollTo({
        top: 0,
      });
    }
  });

  useEffect(() => {
    /* Sync/Restore Price Filters */
    if (filterData[0]) {
      _.each(filterData[0].data, function (filterValue: any) {
        if (fetchPriceMinParam !== null && fetchPriceMaxParam !== null) {
          if (
            filterValue.minValue >= fetchPriceMinParam &&
            filterValue.maxValue <= fetchPriceMaxParam
          ) {
            filterValue.isSelected = true;
          }
        } else {
          filterValue.isSelected = false;
        }
      });
    }
    /* Sync/Restore PropertyType Filters*/
    if (filterData[1]) {
      _.each(filterData[1].data, function (propertyTypes: any) {
        if (
          fetchPropertyTypeParam !== null &&
          propertyTypes.type === "propertyType"
        ) {
          _.each(
            fetchPropertyTypeParam.split(","),
            (propertyParams: string) => {
              if (propertyTypes.value === propertyParams) {
                propertyTypes.isSelected = true;
              }
            }
          );
        } else if (
          fetchHotelRatingsParam !== null &&
          propertyTypes.type === "hotelStarRating"
        ) {
          _.each(
            fetchHotelRatingsParam.split(","),
            (propertyParams: string) => {
              if (propertyTypes.value === propertyParams) {
                propertyTypes.isSelected = true;
              }
            }
          );
        } else {
          propertyTypes.isSelected = false;
        }
      });
    }
    /* Sync/Restore Theme Filters */
    if (filterData[2]) {
      _.each(filterData[2].data, function (themeTypes: any) {
        if (fetchThemesParam !== null) {
          _.each(fetchThemesParam.split(","), (themeParams: string) => {
            if (themeTypes.value === themeParams) {
              themeTypes.isSelected = true;
            }
          });
        } else {
          themeTypes.isSelected = false;
        }
      });
    }
    /* Sync/Restore location Filter */
    if (filterData[3]) {
      if (pathname !== "/domestic" && pathname !== "/international") {
        _.each(filterData[3].data, function (location) {
          _.each(location.data, function (subLocation) {
            if (fetchLocationParam !== null) {
              _.each(
                fetchLocationParam.split(","),
                (locationParams: string) => {
                  if (subLocation.value === locationParams) {
                    subLocation.isSelected = true;
                  }
                }
              );
            } else {
              subLocation.isSelected = false;
            }
          });
        });
      } else {
        _.each(filterData[3].data, function (location) {
          if (fetchLocationParam !== null) {
            _.each(fetchLocationParam.split(","), (locationParams: string) => {
              if (location.value === locationParams) {
                location.isSelected = true;
              }
            });
          } else {
            location.isSelected = false;
          }
        });
      }
    }
    setFilterData(filterData);
  }, [filterListData]);

  useEffect(() => {
    const fetchFilterListData = JSON.parse(
      `${sessionStorage.getItem("filterListData")}`
    );
    setFilterListData(fetchFilterListData);
    const trackingId = searchParams.get("tracking_id");
    if (trackingId && isAnalyticsEnabled) {
      ReactGA.event({
        action: "tracking_" + trackingId,
        category: "listing_page",
        label: pathname,
      });
    }
  }, [location]);

  const clearDefaultData = () => {
    setBreadcrumbTitle("");
    setTitle("");
    setPackageType("");
    setSearchString("");
    setMinPrice(0);
    setMaxPrice(0);
    setPropertyType([]);
    setHotelStarRating([]);
    setTags([]);
    setDestination([]);
    setFilterData([]);
    setNoRecordMessage("No record found");
    if (!pathname.startsWith("/search/")) {
      setSelectedSort("Voyaah Recommended");
    }
    setPageNumber(1);
    setListData([]);
    setCurrentPage(1);

    if (screenWidth > 320 && screenWidth < 767) {
      setIsFilterCollapse(true);
    } else if (screenWidth > 767) {
      setIsFilterCollapse(false);
    }
  };

  useEffect(() => {
    clearDefaultData();

    const displayName = searchParams.get("display_name");
    let filterDataObj: any[] = JSON.parse(JSON.stringify(fd));
    let apiQuerystring = `pageSize=${defaultListCount}`;
    switch (true) {
      case pathname.includes("/holiday"):
        localStorage.setItem(
          "listNav",
          `{"title": "Holiday Packages", "link": "${pathname}"}`
        );
        setBreadcrumbTitle("Holiday Packages");
        setTitle("Holidays");
        setPagePath("/holidays");
        filterDataObj.push(lfd);
        setFilterData(filterDataObj);
        setPackageType("packageType.Holiday");
        apiQuerystring += `${
          apiQuerystring ? "&" : ""
        }packageType=packageType.Holiday`;
        break;

      case pathname.includes("/staycations"):
        localStorage.setItem(
          "listNav",
          `{"title": "Staycation Packages", "link": "${pathname}"}`
        );
        setBreadcrumbTitle("Staycation Packages");
        setTitle("Staycations");
        setPagePath("/staycations");
        filterDataObj.push(lfd);
        setFilterData(filterDataObj);
        setPackageType("packageType.Staycation");
        apiQuerystring += `${
          apiQuerystring ? "&" : ""
        }packageType=packageType.Staycation`;
        break;

      case pathname.includes("/domestic"):
        localStorage.setItem(
          "listNav",
          `{"title": "Domestic", "link": "${pathname}"}`
        );
        setBreadcrumbTitle("Domestic");
        setTitle("Domestic");
        setPagePath("/domestic");
        filterDataObj.push(dfd);
        setFilterData(filterDataObj);
        //If there are no Location Filters from previous page (e.g First page load)
        if (!searchParams.has("locations")) {
          setDestination([...domesticTags]);
          apiQuerystring += `${
            apiQuerystring ? "&" : ""
          }locations=${domesticTags}`;
        }
        break;

      case pathname.includes("/international"):
        localStorage.setItem(
          "listNav",
          `{"title": "International", "link": "${pathname}"}`
        );
        setBreadcrumbTitle("International");
        setTitle("International");
        setPagePath("/international");
        filterDataObj.push(ifd);
        setFilterData(filterDataObj);
        //If there are no Location Filters from previous page (e.g First page load)
        if (!searchParams.has("locations")) {
          apiQuerystring += `${
            apiQuerystring ? "&" : ""
          }locations=${internationalTags}`;
          setDestination([...internationalTags]);
        }
        break;

      case pathname.startsWith("/search/"):
        localStorage.setItem(
          "listNav",
          `{"title": "Search", "link": "${pathname}"}`
        );
        setBreadcrumbTitle("Search");
        const searchDisplayName = displayName
          ? displayName
          : params.searchQuery;
        setTitle(
          "Search results for " + capitalizeFirstLetter(searchDisplayName)
        );
        setPagePath(`/search/${params.searchQuery}`);
        setFilterData(filterDataObj);
        setNoRecordMessage(
          "We found no results for " +
            capitalizeFirstLetter(searchDisplayName) +
            ". Please try with a different keyword."
        );
        if (params.searchQuery) {
          setSearchString(params.searchQuery);
        }
        apiQuerystring += `${apiQuerystring ? "&" : ""}queryString=${
          params.searchQuery
        }`;
        break;

      default:
        break;
    }
    //Appending url params to api query string
    if (searchParams.has("locations") && fetchLocationParam) {
      setDestination(fetchLocationParam.split(","));
      apiQuerystring += `${
        apiQuerystring ? "&" : ""
      }locations=${fetchLocationParam}`;
    }
    if (searchParams.has("ratings") && fetchHotelRatingsParam) {
      setHotelStarRating(fetchHotelRatingsParam.split(","));
      apiQuerystring += `${
        apiQuerystring ? "&" : ""
      }ratings=${fetchHotelRatingsParam}`;
    }
    if (searchParams.has("priceMin") && fetchPriceMinParam) {
      setMinPrice(parseInt(fetchPriceMinParam));
      apiQuerystring += `${
        apiQuerystring ? "&" : ""
      }priceMin=${fetchPriceMinParam}`;
    }
    if (searchParams.has("priceMax") && fetchPriceMaxParam) {
      setMaxPrice(parseInt(fetchPriceMaxParam));
      apiQuerystring += `${
        apiQuerystring ? "&" : ""
      }priceMax=${fetchPriceMaxParam}`;
    }
    if (searchParams.has("propertyTypes") || searchParams.has("themes")) {
      if (fetchPropertyTypeParam)
        setPropertyType(fetchPropertyTypeParam.split(","));
      if (fetchThemesParam) setPropertyType(fetchThemesParam.split(","));
      apiQuerystring += `${apiQuerystring ? "&" : ""}tags=${
        fetchPropertyTypeParam ? fetchPropertyTypeParam : ""
      }${fetchThemesParam ? fetchThemesParam : ""}`;
    }
    if (fetchCurrentPage) {
      apiQuerystring += `&pageNo=${fetchCurrentPage}`;
      setCurrentPage(parseInt(fetchCurrentPage));
    }
    if (fetchSelectedSortParam) {
      setSelectedSort(mapSortParam(fetchSelectedSortParam));
      apiQuerystring += `&sortBy=${fetchSelectedSortParam}`;
    } else {
      setSelectedSort("Voyaah Recommended");
      apiQuerystring += `&sortBy=sort.VoyaahRecommended`;
    }

    const localStorageData = localStorage.getItem("voyaahCustomerTracker");
    if (localStorageData) {
      const customerProfileId = JSON.parse(localStorageData!).id;
      apiQuerystring += `&customerProfileId=${customerProfileId}`;
    } else {
      apiQuerystring += `&customerProfileId=`;
    }

    apiQuerystring += `&accountId=${user?.uid ?? ""}`;
    dispatch(getPackageSummary(apiQuerystring));
  }, [dispatch, pathname, params, state]);

  const { packages, loader } = useSelector(
    (state: any) => state.PackageSummaryReducer
  );

  useEffect(() => {
    var newListData = _.map(packages.packageList, function (a: any) {
      return a;
    });
    var referenceListData = [];
    //Concatenate only if page number is > 1 and current list (listData) is showing fewer items than total (record count)
    if (_.size(listData) < packages.recordCount && pageNumber > 1) {
      referenceListData = listData.concat(newListData);
    } else {
      referenceListData = newListData;
    }

    setListData(referenceListData);

    if (
      _.size(newListData) < defaultListCount ||
      referenceListData.length === packages.recordCount
    ) {
      setShowViewMore(false);
    } else {
      setShowViewMore(true);
    }
  }, [packages]);

  const handleSortItemClick = (item: any) => {
    sessionStorage.removeItem("scrollPosition");
    sessionStorage.removeItem("showListCount");
    sessionStorage.removeItem("detailPropertyData");
    if (isAnalyticsEnabled) {
      ReactGA.event({
        action: `${window.location.pathname}_${item}_Sorting`,
        category: `listing_page`,
      });
    }

    setSelectedSort(item);
    setOpenSort(!openSort);
    //Empty the listData so that new sorting items appear on top
    setListData([]);
    setCurrentPage(1);

    sortListData(item);
    sessionStorage.setItem("selectedSortItem", item);
    sessionStorage.removeItem("footerScrollPostion");
  };

  const sortListData = (item: any) => {
    let _sortParam = "";
    switch (item) {
      case "Newly added":
        _sortParam = "sort.NewlyAdded";
        break;
      case "Best Selling":
        _sortParam = "sort.BestSelling";
        break;
      case "Voyaah Recommended":
        _sortParam = "sort.VoyaahRecommended";
        break;
      case "Discount":
        _sortParam = "sort.Discount";
        break;
      case "Price low to high":
        _sortParam = "sort.PriceLowToHigh";
        break;
      case "Price high to low":
        _sortParam = "sort.PriceHighToLow";
        break;
      case "A to Z":
        _sortParam = "sort.NameAtoZ";
        break;
      case "Z to A":
        _sortParam = "sort.NameZtoA";
        break;
      default:
        break;
    }
    setSortParam(_sortParam);
    urlQueryParams.set("sortBy", `${_sortParam}`);
    setURLQueryParams(urlQueryParams);
    // getAPIQueryParams(
    //   minPrice,
    //   maxPrice,
    //   propertyType,
    //   hotelStarRating,
    //   tags,
    //   destination,
    //   _sortParam, // Sort
    //   1 // Page Number
    // );
  };

  const handleFilterExpand = (item: any) => {
    setFilterOpen(true);
    removeSessionStorage();
    let itemIndex = filterData.findIndex(
      (obj: any) => obj.title === item.title
    );
    let filterDataObj = [...filterData];
    filterDataObj[itemIndex].isExpand = !item.isExpand;
    setFilterData(filterDataObj);
  };

  const handleItemClick = (item: any, data: any) => {
    let itemIndex = filterData.findIndex(
      (obj: any) => obj.title === item.title
    );
    let dataIndex = filterData[itemIndex].data.findIndex(
      (obj: any) => obj.title === data.title
    );
    let filterDataObj = [...filterData];
    filterDataObj[itemIndex].data[dataIndex].isSelected = !data.isSelected;
    if (data.data) {
      filterDataObj[itemIndex].data[dataIndex].data = _.map(
        filterDataObj[itemIndex].data[dataIndex].data,
        function (a) {
          a.isSelected = filterDataObj[itemIndex].data[dataIndex].isSelected;
          return a;
        }
      );
    }
    setFilterData(filterDataObj);
  };

  const handleSubItemClick = (item: any, data: any, subData: any) => {
    let itemIndex = filterData.findIndex(
      (obj: any) => obj.title === item.title
    );
    let dataIndex = filterData[itemIndex].data.findIndex(
      (obj: any) => obj.title === data.title
    );
    let subDataIndex = filterData[itemIndex].data[dataIndex].data.findIndex(
      (obj: any) => obj.title === subData.title
    );
    let filterDataObj = [...filterData];
    filterDataObj[itemIndex].data[dataIndex].data[subDataIndex].isSelected =
      !subData.isSelected;
    setFilterData(filterDataObj);
  };

  useEffect(() => {
    //Price Filters
    if (filterData[0]) {
      const priceFilter = _.compact(
        _.map(filterData[0].data, function (b) {
          if (b.isSelected) {
            return { min: b.minValue, max: b.maxValue };
          }
        })
      );
      if (priceFilter && priceFilter.length > 0) {
        setMinPrice(_.min(_.pluck(priceFilter, "min")));
        setMaxPrice(_.max(_.pluck(priceFilter, "max")));
      }
    }

    //Property Type Filters
    if (filterData[1]) {
      let newPropertyTypeList: any[] = [];
      let newHotelStarRatingList: any[] = [];
      _.each(filterData[1].data, function (b) {
        if (b.type === "propertyType") {
          if (b.isSelected) {
            if (!_.contains(newPropertyTypeList, b.value))
              newPropertyTypeList.push(b.value);
          } else {
            if (_.contains(newPropertyTypeList, b.value))
              newPropertyTypeList = _.without(newPropertyTypeList, b.value);
          }
        } else if (b.type === "hotelStarRating") {
          if (b.isSelected) {
            if (!_.contains(newHotelStarRatingList, b.value))
              newHotelStarRatingList.push(b.value);
          } else {
            if (_.contains(newHotelStarRatingList, b.value))
              newHotelStarRatingList = _.without(
                newHotelStarRatingList,
                b.value
              );
          }
        }
      });
      setPropertyType(newPropertyTypeList);
      setHotelStarRating(newHotelStarRatingList);
    }

    //Theme Filters
    if (filterData[2]) {
      let newThemeList: any[] = tags;
      _.each(filterData[2].data, function (b) {
        if (b.isSelected) {
          if (!_.contains(newThemeList, b.value)) newThemeList.push(b.value);
        } else {
          if (_.contains(newThemeList, b.value))
            newThemeList = _.without(newThemeList, b.value);
        }
      });
      setTags(newThemeList);
    }

    if (filterData[3]) {
      let newDestinationList: any[] = [];
      _.each(filterData[3].data, function (b) {
        _.each(b.data, function (c) {
          if (c.isSelected) {
            if (!_.contains(newDestinationList, c.value))
              newDestinationList.push(c.value);
          } else {
            if (_.contains(newDestinationList, c.value))
              newDestinationList = _.without(newDestinationList, c.value);
          }
        });
      });
      if (
        !pathname.includes("/international") &&
        !pathname.includes("/domestic")
      ) {
        setDestination(newDestinationList);
      }
    }
  }, [filterData]);

  const handleFilterClick = (landingPage: number) => {
    let newMinPrice = 0;
    let newMaxPrice = 0;
    let filterPriceData = priceType;
    setListData([]);
    const priceFilter = _.compact(
      _.map(filterData[0].data, function (b) {
        if (b.isSelected) {
          return {
            min: b.minValue,
            max: b.maxValue,
            isSelected: b.isSelected,
            title: b.title,
          };
        }
      })
    );
    if (priceFilter && priceFilter.length > 0) {
      filterPriceData = priceFilter;
      newMinPrice = _.min(_.pluck(priceFilter, "min"));
      setMinPrice(newMinPrice);
      newMaxPrice = _.max(_.pluck(priceFilter, "max"));
      setMaxPrice(newMaxPrice);
    } else {
      setMinPrice(0);
      setMaxPrice(0);
    }

    let filterPropertyTypeData = JSON.parse(JSON.stringify(propertyArr));
    let filterHotelStarRatingData = JSON.parse(
      JSON.stringify(hotelStartRatingArr)
    );
    let newPropertyTypeList = propertyType;
    let newHotelStarRatingList = hotelStarRating;
    _.each(filterData[1].data, function (b) {
      if (b.type === "propertyType") {
        if (b.isSelected) {
          if (!_.contains(filterPropertyTypeData, b)) {
            filterPropertyTypeData.push(b);
          }
          if (!_.contains(newPropertyTypeList, b.value)) {
            newPropertyTypeList.push(b.value);
          }
        } else {
          if (_.contains(filterPropertyTypeData, b)) {
            if (filterPropertyTypeData.length > 0) {
              filterPropertyTypeData.filter(
                (value: any) => value.isSelected === false
              );
            }
            filterPropertyTypeData = _.without(filterPropertyTypeData, b);
          }
          if (_.contains(newPropertyTypeList, b.value)) {
            newPropertyTypeList = _.without(newPropertyTypeList, b.value);
          }
        }
      } else if (b.type === "hotelStarRating") {
        if (b.isSelected) {
          if (!_.contains(filterHotelStarRatingData, b)) {
            filterHotelStarRatingData.push(b);
          }
          if (!_.contains(newHotelStarRatingList, b.value)) {
            newHotelStarRatingList.push(b.value);
          }
        } else {
          if (_.contains(filterHotelStarRatingData, b)) {
            if (filterHotelStarRatingData.length > 0) {
              filterHotelStarRatingData.filter(
                (value: any) => value.isSelected === false
              );
            }
            filterHotelStarRatingData = _.without(filterHotelStarRatingData, b);
          }
          if (_.contains(newHotelStarRatingList, b.value))
            newHotelStarRatingList = _.without(newHotelStarRatingList, b.value);
        }
      }
    });

    setPropertyType(newPropertyTypeList);
    setHotelStarRating(newHotelStarRatingList);
    let newThemeList = tags;
    let filterThemeTypeList = JSON.parse(JSON.stringify(tagArr));
    if (filterData[2].title === "Themes") {
      _.each(filterData[2].data, function (b) {
        if (b.isSelected) {
          if (!_.contains(filterThemeTypeList, b)) {
            filterThemeTypeList.push(b);
          }
          if (!_.contains(newThemeList, b.value)) {
            newThemeList.push(b.value);
          }
        } else {
          if (_.contains(filterThemeTypeList, b)) {
            if (filterThemeTypeList.length > 0) {
              filterThemeTypeList.filter(
                (value: any) => value.isSelected === false
              );
            }
            filterThemeTypeList = _.without(filterThemeTypeList, b);
          }
          if (_.contains(newThemeList, b.value))
            newThemeList = _.without(newThemeList, b.value);
        }
      });
      setTags(newThemeList);
    }

    let newDestinationList = destination;
    let filterDestinationList = JSON.parse(JSON.stringify(destinationArr));
    _.each(filterData[3]?.data, function (b) {
      if (b.data) {
        _.each(b.data, function (c) {
          if (c.isSelected) {
            if (!_.contains(filterDestinationList, c)) {
              filterDestinationList.push(c);
            }
            if (!_.contains(newDestinationList, c.value)) {
              newDestinationList.push(c.value);
            }
          } else {
            if (_.contains(filterDestinationList, c)) {
              if (filterDestinationList.length > 0) {
                filterDestinationList.filter(
                  (value: any) => value.isSelected === false
                );
              }
              filterDestinationList = _.without(filterDestinationList, c);
            }
            if (_.contains(newDestinationList, c.value))
              newDestinationList = _.without(newDestinationList, c.value);
          }
        });
      } else {
        if (b.isSelected) {
          if (!_.contains(filterDestinationList, b)) {
            filterDestinationList.push(b);
          }
          if (!_.contains(newDestinationList, b.value))
            newDestinationList.push(b.value);
        } else {
          if (_.contains(filterDestinationList, b)) {
            if (filterDestinationList.length > 0) {
              filterDestinationList.filter(
                (value: any) => value.isSelected === false
              );
            }
            filterDestinationList = _.without(filterDestinationList, b);
          }
          if (_.contains(newDestinationList, b.value))
            newDestinationList = _.without(newDestinationList, b.value);
        }
      }
    });
    setDestination(newDestinationList);
    if (
      filterPriceData.length > 0 ||
      filterPropertyTypeData.length > 0 ||
      filterHotelStarRatingData.length > 0 ||
      filterThemeTypeList.length > 0 ||
      filterDestinationList.length > 0
    ) {
      const array = filterPriceData.concat(
        filterPropertyTypeData,
        filterHotelStarRatingData,
        filterThemeTypeList,
        filterDestinationList
      );
      sessionStorage.setItem("filterListData", JSON.stringify(array));
      sessionStorage.removeItem("footerScrollPostion");
    } else if (
      filterPriceData.length === 0 &&
      filterPropertyTypeData.length === 0 &&
      filterHotelStarRatingData.length === 0 &&
      filterThemeTypeList.length === 0 &&
      filterDestinationList.length === 0
    ) {
      sessionStorage.removeItem("filterListData");
    }
    setPageNumber(1); // Need to check whether to sync this with currentPage, in case of package List concat issues
    setCurrentPage(landingPage);
    getAPIQueryParams(
      newMinPrice,
      newMaxPrice,
      newPropertyTypeList,
      newHotelStarRatingList,
      newThemeList,
      newDestinationList,
      sortParam, // sortList
      landingPage // Page Number
    );
    navigate(`${pagePath}/page${landingPage}?${urlQueryParams.toString()}`, {
      replace: true,
    });
    window.scrollTo({
      top: 0,
    });
  };

  const getAPIQueryParams = (
    newMinPrice: any,
    newMaxPrice: any,
    newPropertyTypes: any[],
    newHotelStarRating: any[],
    newTags: any[],
    newDestinations: any[],
    sortParam: string,
    pNumber: number = 1
  ) => {
    // if (pathname === "/domestic" || pathname === "/international") {
    //   if (newMinPrice === 0 && newMaxPrice === 0 && newPropertyTypes.length === 0 && newHotelStarRating.length === 0 && newTags.length === 0 && newDestinations.length === 0) {
    //     switch (pathname) {
    //       case "/domestic":
    //         newDestinations = domesticTags
    //         break;
    //       case "/international":
    //         newDestinations = internationalTags
    //         break;
    //     }
    //   }
    // }
    let newApiQuerystring = `pageSize=${defaultListCount}`;
    if (packageType) {
      newApiQuerystring += `${
        newApiQuerystring ? "&" : ""
      }packageType=${packageType}`;
    }
    if (searchString) {
      newApiQuerystring += `${
        newApiQuerystring ? "&" : ""
      }queryString=${searchString}`;
    }
    if (newMinPrice >= 0) {
      urlQueryParams.set("priceMin", `${newMinPrice}`);
      newApiQuerystring += `${
        newApiQuerystring ? "&" : ""
      }priceMin=${newMinPrice}`;
    }
    if (newMaxPrice) {
      urlQueryParams.set("priceMax", `${newMaxPrice}`);
      newApiQuerystring += `${
        newApiQuerystring ? "&" : ""
      }priceMax=${newMaxPrice}`;
    } else {
      urlQueryParams.delete("priceMax");
    }
    if (newPropertyTypes.length > 0) {
      if (newPropertyTypes.length > 0) {
        urlQueryParams.set("propertyTypes", `${newPropertyTypes.join(",")}`);
      }
      newApiQuerystring += `${
        newApiQuerystring ? "&" : ""
      }propertyTypes=${newPropertyTypes.join(",")}`;
    } else {
      urlQueryParams.delete("propertyTypes");
    }
    if (newTags.length > 0) {
      if (newTags.length > 0)
        urlQueryParams.set("themes", `${newTags.join(",")}`);
      newApiQuerystring += `${
        newApiQuerystring ? "&" : ""
      }themes=${newTags.join(",")}`;
    } else {
      urlQueryParams.delete("themes");
    }
    if (newHotelStarRating.length > 0) {
      urlQueryParams.set("ratings", `${newHotelStarRating.join(",")}`);
      newApiQuerystring += `${
        newApiQuerystring ? "&" : ""
      }ratings=${newHotelStarRating.join(",")}`;
    } else {
      urlQueryParams.delete("ratings");
    }
    if (newDestinations.length > 0) {
      urlQueryParams.set("locations", `${newDestinations.join(",")}`);
      newApiQuerystring += `${
        newApiQuerystring ? "&" : ""
      }locations=${newDestinations.join(",")}`;
    } else {
      urlQueryParams.delete("locations");
    }
    if (pNumber) {
      // urlQueryParams.set("pageNo", `${pNumber}`)
      newApiQuerystring += `${newApiQuerystring ? "&" : ""}pageNo=${pNumber}`;
    }
    if (sortParam) {
      urlQueryParams.set("sortBy", `${sortParam}`);
      newApiQuerystring += `${newApiQuerystring ? "&" : ""}sortBy=${sortParam}`;
    }
    setCurrentPage(pNumber);
    setURLQueryParams(urlQueryParams);
    if (isAnalyticsEnabled) {
      ReactGA.event({
        action: `filterQuery_${JSON.stringify(newApiQuerystring)}`,
        category: "listing_page",
      });
    }

    // dispatch(getPackageSummary(newApiQuerystring));
  };

  const handleBreadcrum = () => {
    if (isAnalyticsEnabled) {
      ReactGA.event({
        action: `${window.location.pathname} breadcrumb nav`,
        category: `listing_page`,
      });
    }
  };

  const openSortFunction = () => {
    setOpenSort(!openSort);
    removeSessionStorage();
  };

  const removeSessionStorage = () => {
    sessionStorage.removeItem("scrollPosition");
    sessionStorage.removeItem("showListCount");
    sessionStorage.removeItem("detailPropertyData");
    // sessionStorage.removeItem("currentPage");
  };

  useEffect(() => {
    const sortMenu = sessionStorage.getItem("closeSortMenu");
    if (sortMenu) {
      setOpenSort(false);
      sessionStorage.removeItem("closeSortMenu");
    }
  }, []);

  const handlePageChange = (action: any) => {
    window.scrollTo({
      top: 0,
    });
    sessionStorage.removeItem("scrollPosition");
    let _pNumb = currentPage;
    switch (true) {
      case action === "prev":
        setCurrentPage(currentPage - 1);
        _pNumb--;
        break;
      case action === "next":
        setCurrentPage(currentPage + 1);
        _pNumb++;
        break;
      case !isNaN(action):
        setCurrentPage(action);
        _pNumb = action;
        break;
      default:
        break;
    }
    // sessionStorage.setItem("currentPage", `${_pNumb}`);
    // urlQueryParams.set("pageNo", `${_pNumb}`)
    // setURLQueryParams(urlQueryParams)
    // navigate(`${pagePath}/page${_pNumb}?${urlQueryParams.toString()}`)
  };

  return (
    <>
      <div className="inner-page">
        {listingPageData && (
          <Helmet>
            {listingPageData.keywords !== "" && (
              <meta name="keywords" content={keywords} />
            )}
            {metaTags.length > 0 &&
              metaTags.map((metaTag, index) => {
                return <meta property={metaTag.key} content={metaTag.value} />;
              })}
            {breadcrumbs.length > 0 && (
              <script type="application/ld+json">
                {`{
                  "@context": "https://schema.org",
                  "@type": "BreadcrumbList",
                  "itemListElement": ${JSON.stringify(
                    breadcrumbs.map((item, index) => ({
                      "@type": "ListItem",
                      position: index + 1,
                      name: item.name,
                      item: item.url,
                    }))
                  )}}`}
              </script>
            )}
          </Helmet>
        )}
        <div className="section">
          <div className="breadcrum">
            <ul>
              <li>
                <Link onClick={() => handleBreadcrum()} to="/">
                  Home
                </Link>
              </li>
              <li>›</li>
              <li>
                <span dangerouslySetInnerHTML={{ __html: breadcrumbTitle }} />
              </li>
            </ul>
          </div>

          <div className="inner-heading">
            <h1 className="head" dangerouslySetInnerHTML={{ __html: title }} />
            <div className="filter-section">
              <div className="sort-by">
                <ul>
                  <li className={openSort ? "active" : ""}>
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        openSortFunction();
                      }}
                    >
                      <span>Sort by:</span> {selectedSort}
                      <span className="arrow">
                        <img
                          src={require("../../assets/images/down-arrow.png")}
                          alt=""
                          className="img-fluid"
                        />
                      </span>
                    </div>
                    <ul>
                      {sortData.map((item: any, index: number) => {
                        return (
                          <li
                            key={`sort_${index}`}
                            onClick={() => handleSortItemClick(item)}
                          >
                            {item}
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div onClick={() => setOpenSort(false)} className="filter-results">
            <div className="filter-by">
              <div className="head-container">
                <div className="head">
                  Filter By
                  <span
                    className={`arrow ${!isFilterCollapse ? "active" : ""}`}
                  >
                    <img
                      src={require("../../assets/images/filter-arrow.png")}
                      alt=""
                      className="img-fluid"
                      onClick={() => {
                        setIsFilterCollapse(!isFilterCollapse);
                        removeSessionStorage();
                      }}
                    />
                  </span>
                </div>
              </div>
              {!isFilterCollapse && (
                <>
                  {filterData.map((item: any, index: number) => {
                    return (
                      <div className="acc-blk" key={`filter_${index}`}>
                        <div
                          className="acc-head"
                          onClick={() => handleFilterExpand(item)}
                        >
                          {item.title}
                          <span
                            className={`arrow ${item.isExpand ? "active" : ""}`}
                          >
                            <img
                              src={require("../../assets/images/filter-arrow.png")}
                              alt=""
                              className="img-fluid"
                            />
                          </span>
                        </div>
                        {item.isExpand && (
                          <div className="acc-content">
                            <ul>
                              {item.data.map((data: any, index: number) => {
                                return (
                                  <li key={`filter_item_${index}`}>
                                    <span
                                      className={`checkbox ${
                                        data.isSelected ? "active" : ""
                                      }`}
                                      onClick={() =>
                                        handleItemClick(item, data)
                                      }
                                    ></span>
                                    <span
                                      onClick={() =>
                                        handleItemClick(item, data)
                                      }
                                    >
                                      {data.title}
                                    </span>

                                    {data.data && (
                                      <ul>
                                        {data.data.map(
                                          (subData: any, index: number) => {
                                            return (
                                              <li
                                                key={`filter_sub_item_${index}`}
                                              >
                                                <span
                                                  className={`checkbox ${
                                                    subData.isSelected
                                                      ? "active"
                                                      : ""
                                                  }`}
                                                  onClick={() =>
                                                    handleSubItemClick(
                                                      item,
                                                      data,
                                                      subData
                                                    )
                                                  }
                                                ></span>
                                                <span
                                                  onClick={() =>
                                                    handleSubItemClick(
                                                      item,
                                                      data,
                                                      subData
                                                    )
                                                  }
                                                >
                                                  {subData.title}
                                                </span>
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    )}
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        )}
                      </div>
                    );
                  })}
                  <div
                    className="apply-btn"
                    onClick={() => handleFilterClick(1)}
                  >
                    Apply
                  </div>
                </>
              )}
            </div>
            <div className="filter-results-data">
              {listData
                .slice(0, showListCount)
                .map((item: any, index: number) => {
                  return (
                    <ListingCardLayout
                      key={`list_${index}`}
                      data={item}
                      showListCount={showListCount}
                    />
                  );
                })}
              {currentPage ===
                Math.ceil(packages.recordCount / defaultListCount) && (
                <div className="info-txt">That's all for now</div>
              )}
              {listData.length === 0 && loader === false && (
                <div className="info-txt">{noRecordMessage}</div>
              )}
              {listData.length > 0 && (
                <Pagination
                  urlQueryParams={urlQueryParams}
                  pathName={pagePath}
                  onPageChange={handlePageChange}
                  currentPage={currentPage}
                  totalRecords={packages.recordCount}
                  listCount={defaultListCount}
                  isPathsEnabled={true}
                />
              )}
            </div>
          </div>
        </div>
        <div className="bottom-inner-image"></div>
      </div>

      {loader && (
        <div onClick={() => setOpenSort(false)} className="loader-container">
          <div className="loader">
            <Bars color="#00BFFF" height={50} width={100} />
          </div>
        </div>
      )}
    </>
  );
}

export default Listing;
