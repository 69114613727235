import React from "react";

export const CalendarIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <rect x="4" y="5" width="16" height="16" rx="2"></rect>
      <line x1="16" y1="3" x2="16" y2="7"></line>
      <line x1="8" y1="3" x2="8" y2="7"></line>
      <line x1="4" y1="11" x2="20" y2="11"></line>
      <line x1="11" y1="15" x2="12" y2="15"></line>
      <line x1="12" y1="15" x2="12" y2="18"></line>
    </svg>
  );
};

export const CancelIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-x"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <line x1={18} y1={6} x2={6} y2={18}></line>
      <line x1={6} y1={6} x2={18} y2={18}></line>
    </svg>
  );
};

export const ClearIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-circle-x"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
      <path d="M10 10l4 4m0 -4l-4 4"></path>
    </svg>
  );
};

export const DeleteIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-trash active"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <line x1="4" y1="7" x2="20" y2="7"></line>
      <line x1="10" y1="11" x2="10" y2="17"></line>
      <line x1="14" y1="11" x2="14" y2="17"></line>
      <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
      <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
    </svg>
  );
};

export const EditIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-edit"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1"></path>
      <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z"></path>
      <path d="M16 5l3 3"></path>
    </svg>
  );
};

export const MapIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-map-2"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <line x1="18" y1="6" x2="18" y2="6.01"></line>
      <path d="M18 13l-3.5 -5a4 4 0 1 1 7 0l-3.5 5"></path>
      <polyline points="10.5 4.75 9 4 3 7 3 20 9 17 15 20 21 17 21 15"></polyline>
      <line x1="9" y1="4" x2="9" y2="17"></line>
      <line x1="15" y1="15" x2="15" y2="20"></line>
    </svg>
  );
};

export const PlusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-plus"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <line x1="12" y1="5" x2="12" y2="19"></line>
      <line x1="5" y1="12" x2="19" y2="12"></line>
    </svg>
  );
};

export const SaveIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-check"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M5 12l5 5l10 -10"></path>
    </svg>
  );
};

export const SearchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <circle cx="10" cy="10" r="7"></circle>
      <line x1="21" y1="21" x2="15" y2="15"></line>
    </svg>
  );
};

export const ArrowLeftIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-arrow-big-left-filled"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path
        d="M20 15h-8v3.586a1 1 0 0 1 -1.707 .707l-6.586 -6.586a1 1 0 0 1 0 -1.414l6.586 -6.586a1 1 0 0 1 1.707 .707v3.586h8a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1z"
        fill="currentColor"
      ></path>
    </svg>
  );
};

export const ArrowRightIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-arrow-big-right-filled"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path
        d="M4 9h8v-3.586a1 1 0 0 1 1.707 -.707l6.586 6.586a1 1 0 0 1 0 1.414l-6.586 6.586a1 1 0 0 1 -1.707 -.707v-3.586h-8a1 1 0 0 1 -1 -1v-4a1 1 0 0 1 1 -1z"
        fill="currentColor"
      ></path>
    </svg>
  );
};

export const ArrowUpIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-arrow-big-right-filled"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      style={{ height: "50%", transform: "rotate(-90deg)" }}
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path
        d="M4 9h8v-3.586a1 1 0 0 1 1.707 -.707l6.586 6.586a1 1 0 0 1 0 1.414l-6.586 6.586a1 1 0 0 1 -1.707 -.707v-3.586h-8a1 1 0 0 1 -1 -1v-4a1 1 0 0 1 1 -1z"
        fill="currentColor"
      ></path>
    </svg>
  );
};

export const ArrowDownIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-arrow-big-right-filled"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      style={{ height: "50%", transform: "rotate(90deg)" }}
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path
        d="M4 9h8v-3.586a1 1 0 0 1 1.707 -.707l6.586 6.586a1 1 0 0 1 0 1.414l-6.586 6.586a1 1 0 0 1 -1.707 -.707v-3.586h-8a1 1 0 0 1 -1 -1v-4a1 1 0 0 1 1 -1z"
        fill="currentColor"
      ></path>
    </svg>
  );
};

export const CopyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-copy"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M8 8m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z"></path>
      <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2"></path>
    </svg>
  );
};

export const InfoCircleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-info-circle"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
      <path d="M12 8l.01 0"></path>
      <path d="M11 12l1 0l0 4l1 0"></path>
    </svg>
  );
};

export const AlertTriangleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-alert-triangle"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M12 9v2m0 4v.01"></path>
      <path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"></path>
    </svg>
  );
};

export const AlertCircleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-alert-circle"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
      <path d="M12 8l0 4"></path>
      <path d="M12 16l.01 0"></path>
    </svg>
  );
};

export const PopOutIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-external-link"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M11 7h-5a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-5"></path>
      <path d="M10 14l10 -10"></path>
      <path d="M15 4l5 0l0 5"></path>
    </svg>
  );
};

export const DestinationIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-brand-apple-arcade"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M12 5m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
      <path d="M20 12.5v4.75a.734 .734 0 0 1 -.055 .325a.704 .704 0 0 1 -.348 .366l-5.462 2.58a5 5 0 0 1 -4.27 0l-5.462 -2.58a.705 .705 0 0 1 -.401 -.691l0 -4.75"></path>
      <path d="M4.431 12.216l5.634 -2.332a5.065 5.065 0 0 1 3.87 0l5.634 2.332a.692 .692 0 0 1 .028 1.269l-5.462 2.543a5.064 5.064 0 0 1 -4.27 0l-5.462 -2.543a.691 .691 0 0 1 .028 -1.27z"></path>
      <path d="M12 7l0 6"></path>
    </svg>
  );
};

export const CurrencyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M16.7 8a3 3 0 0 0 -2.7 -2h-4a3 3 0 0 0 0 6h4a3 3 0 0 1 0 6h-4a3 3 0 0 1 -2.7 -2" />
      <path d="M12 3v3m0 12v3" />
    </svg>
  );
};

export const LocationIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-map-pin-filled"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path
        d="M18.364 4.636a9 9 0 0 1 .203 12.519l-.203 .21l-4.243 4.242a3 3 0 0 1 -4.097 .135l-.144 -.135l-4.244 -4.243a9 9 0 0 1 12.728 -12.728zm-6.364 3.364a3 3 0 1 0 0 6a3 3 0 0 0 0 -6z"
        strokeWidth="0"
        fill="currentColor"
      />
    </svg>
  );
};

export const LocationAdd = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="icon icon-tabler icons-tabler-outline icon-tabler-map-pin-plus"
      cursor={"pointer"}
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
      <path d="M12.794 21.322a2 2 0 0 1 -2.207 -.422l-4.244 -4.243a8 8 0 1 1 13.59 -4.616" />
      <path d="M16 19h6" />
      <path d="M19 16v6" />
    </svg>
  );
};

export const CollapseUp = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-layout-navbar-collapse"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M4 18v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" />
      <path d="M4 9h16" />
      <path d="M10 16l2 -2l2 2" />
    </svg>
  );
};

export const CollapseDown = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-layout-bottombar-collapse"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M20 6v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2z" />
      <path d="M20 15h-16" />
      <path d="M14 8l-2 2l-2 -2" />
    </svg>
  );
};

export const RefreshIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-reload"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M19.933 13.041a8 8 0 1 1 -9.925 -8.788c3.899 -1 7.935 1.007 9.425 4.747" />
      <path d="M20 4v5h-5" />
    </svg>
  );
};

export const ExpandMore = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-viewport-wide"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M10 12h-7l3 -3m0 6l-3 -3" />
      <path d="M14 12h7l-3 -3m0 6l3 -3" />
      <path d="M3 6v-3h18v3" />
      <path d="M3 18v3h18v-3" />
    </svg>
  );
};

export const ExpandLess = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-viewport-narrow"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M3 12h7l-3 -3m0 6l3 -3" />
      <path d="M21 12h-7l3 -3m0 6l-3 -3" />
      <path d="M9 6v-3h6v3" />
      <path d="M9 18v3h6v-3" />
    </svg>
  );
};

export const InformationIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="icon icon-tabler icons-tabler-outline icon-tabler-info-square-rounded"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M12 9h.01" />
      <path d="M11 12h1v4h1" />
      <path d="M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z" />
    </svg>
  );
};

export const FlightTakeOff = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="icon icon-tabler icons-tabler-outline icon-tabler-plane-departure"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M14.639 10.258l4.83 -1.294a2 2 0 1 1 1.035 3.863l-14.489 3.883l-4.45 -5.02l2.897 -.776l2.45 1.414l2.897 -.776l-3.743 -6.244l2.898 -.777l5.675 5.727z" />
      <path d="M3 21h18" />
    </svg>
  );
};

export const CustomItineraryShare = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5915 7.48677C9.83553 7.62942 7.25888 8.77666 5.28198 10.7536C3.16557 12.87 2 15.6741 2 18.6493V22.7597L3.48898 19.3272C5.22813 15.8618 8.75497 13.5642 12.5915 13.3504V18.0873L22 10.4022L12.5915 2.74023V7.48677ZM13.7639 8.64409V5.20715L20.1449 10.4035L13.7639 15.6157V12.1616H13.1777C10.9547 12.1616 8.77181 12.7866 6.86496 13.9691C5.42092 14.8646 4.1906 16.0452 3.24616 17.432C3.84835 12.4869 8.07262 8.64409 13.1777 8.64409H13.7639Z"
        fill="black"
      />
    </svg>
  );
};

export const CustomerItinerarySave = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7087 21.2962H3.65735C3.33548 21.2959 3.02691 21.1678 2.79931 20.9403C2.57172 20.7127 2.44371 20.4041 2.44336 20.0822V7.36959C2.44371 7.04773 2.57173 6.73915 2.79932 6.51156C3.02691 6.28397 3.33549 6.15596 3.65735 6.15561H7.76512C8.08703 6.15628 8.3957 6.28378 8.62425 6.51046L10.8728 8.75918C11.2423 9.12599 11.7415 9.33242 12.2622 9.33377H19.548C19.8699 9.3341 20.1785 9.4621 20.4062 9.68969C20.6338 9.91727 20.7619 10.2259 20.7623 10.5478V11.9899C20.7623 12.0893 20.8018 12.1847 20.8722 12.2551C20.9425 12.3254 21.0379 12.3649 21.1373 12.3649C21.2368 12.3649 21.3322 12.3254 21.4025 12.2551C21.4728 12.1847 21.5123 12.0893 21.5123 11.9899V10.5478C21.5117 10.027 21.3045 9.52777 20.9363 9.15957C20.568 8.79138 20.0687 8.5843 19.548 8.58377H18.9119V3.64779C18.893 3.25988 18.7215 2.89517 18.4347 2.63328C18.1479 2.3714 17.7691 2.2336 17.3811 2.24997H5.82422C5.43619 2.2336 5.05744 2.3714 4.77065 2.63329C4.48386 2.89517 4.31232 3.25988 4.29347 3.64779V5.40559H3.65735C3.13665 5.4062 2.63746 5.61331 2.26927 5.9815C1.90108 6.34969 1.69397 6.84888 1.69336 7.36958V20.0822C1.69397 20.6029 1.90109 21.1021 2.26928 21.4703C2.63746 21.8385 3.13665 22.0456 3.65735 22.0462H10.7087C10.8082 22.0462 10.9036 22.0067 10.9739 21.9364C11.0442 21.8661 11.0837 21.7707 11.0837 21.6712C11.0837 21.5718 11.0442 21.4764 10.9739 21.406C10.9036 21.3357 10.8082 21.2962 10.7087 21.2962ZM5.04346 3.64779C5.06285 3.45908 5.1555 3.28551 5.30149 3.16438C5.44748 3.04324 5.63517 2.98421 5.82422 2.99997H17.3811C17.5701 2.98421 17.7578 3.04325 17.9038 3.16438C18.0498 3.28552 18.1425 3.45909 18.1619 3.64779V8.58377H12.2622C11.9403 8.58297 11.6316 8.45542 11.4031 8.22872L9.15455 5.98001C8.78497 5.61326 8.28579 5.40688 7.76512 5.40559H5.04346V3.64779Z"
        fill="black"
        stroke="black"
        strokeWidth="0.6"
      />
      <path
        d="M16.3704 11.3765C15.1962 11.3764 14.0484 11.7246 13.0721 12.3769C12.0958 13.0293 11.3349 13.9564 10.8855 15.0412C10.4362 16.126 10.3186 17.3197 10.5476 18.4713C10.7767 19.6229 11.3421 20.6808 12.1724 21.511C13.0026 22.3413 14.0604 22.9068 15.212 23.1358C16.3636 23.3649 17.5573 23.2474 18.6421 22.7981C19.7269 22.3488 20.6541 21.5878 21.3065 20.6116C21.9588 19.6353 22.307 18.4875 22.3071 17.3133C22.3053 15.7393 21.6793 14.2303 20.5664 13.1173C19.4534 12.0043 17.9444 11.3783 16.3704 11.3765ZM16.3704 22.4999C15.3446 22.5 14.3418 22.1958 13.4888 21.6259C12.6358 21.0559 11.971 20.2459 11.5784 19.2981C11.1858 18.3504 11.0831 17.3075 11.2833 16.3013C11.4834 15.2952 11.9774 14.371 12.7028 13.6456C13.4281 12.9202 14.3523 12.4262 15.3585 12.2261C16.3646 12.026 17.4075 12.1287 18.3553 12.5213C19.303 12.9139 20.1131 13.5787 20.683 14.4317C21.2529 15.2846 21.5571 16.2875 21.5571 17.3133C21.5556 18.6884 21.0086 20.0068 20.0363 20.9792C19.0639 21.9515 17.7455 22.4984 16.3704 22.4999Z"
        fill="black"
        stroke="black"
        strokeWidth="0.3"
      />
      <path
        d="M17.9534 18.2417L16.7453 19.7408V13.8226C16.7433 13.7245 16.703 13.631 16.6328 13.5623C16.5627 13.4936 16.4685 13.4551 16.3703 13.4551C16.2721 13.4551 16.1778 13.4936 16.1077 13.5623C16.0376 13.631 15.9973 13.7245 15.9953 13.8227V19.7408L14.7872 18.2417C14.7241 18.1665 14.6341 18.1189 14.5364 18.1094C14.4387 18.0998 14.3411 18.1289 14.2647 18.1905C14.1883 18.2521 14.139 18.3412 14.1276 18.4387C14.1161 18.5362 14.1434 18.6343 14.2034 18.7119L16.0784 21.0388C16.1138 21.0821 16.1583 21.117 16.2088 21.1409C16.2593 21.1649 16.3144 21.1773 16.3703 21.1773C16.4262 21.1773 16.4814 21.1649 16.5318 21.1409C16.5823 21.117 16.6268 21.0821 16.6622 21.0388L18.5372 18.7119C18.568 18.6736 18.5911 18.6296 18.6049 18.5823C18.6188 18.5351 18.6232 18.4856 18.6179 18.4367C18.6127 18.3877 18.5978 18.3403 18.5742 18.2971C18.5506 18.2539 18.5187 18.2158 18.4804 18.1849C18.4421 18.1541 18.398 18.1311 18.3508 18.1172C18.3036 18.1033 18.2541 18.0989 18.2052 18.1042C18.1562 18.1095 18.1088 18.1243 18.0656 18.1479C18.0224 18.1715 17.9843 18.2034 17.9534 18.2417Z"
        fill="black"
        stroke="black"
        strokeWidth="0.4"
      />
    </svg>
  );
};

export const CustomerItineraryDownload = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7087 21.2962H3.65735C3.33548 21.2959 3.02691 21.1678 2.79931 20.9403C2.57172 20.7127 2.44371 20.4041 2.44336 20.0822V7.36959C2.44371 7.04773 2.57173 6.73915 2.79932 6.51156C3.02691 6.28397 3.33549 6.15596 3.65735 6.15561H7.76512C8.08703 6.15628 8.3957 6.28378 8.62425 6.51046L10.8728 8.75918C11.2423 9.12599 11.7415 9.33242 12.2622 9.33377H19.548C19.8699 9.3341 20.1785 9.4621 20.4062 9.68969C20.6338 9.91727 20.7619 10.2259 20.7623 10.5478V11.9899C20.7623 12.0893 20.8018 12.1847 20.8722 12.2551C20.9425 12.3254 21.0379 12.3649 21.1373 12.3649C21.2368 12.3649 21.3322 12.3254 21.4025 12.2551C21.4728 12.1847 21.5123 12.0893 21.5123 11.9899V10.5478C21.5117 10.027 21.3045 9.52777 20.9363 9.15957C20.568 8.79138 20.0687 8.5843 19.548 8.58377H18.9119V3.64779C18.893 3.25988 18.7215 2.89517 18.4347 2.63328C18.1479 2.3714 17.7691 2.2336 17.3811 2.24997H5.82422C5.43619 2.2336 5.05744 2.3714 4.77065 2.63329C4.48386 2.89517 4.31232 3.25988 4.29347 3.64779V5.40559H3.65735C3.13665 5.4062 2.63746 5.61331 2.26927 5.9815C1.90108 6.34969 1.69397 6.84888 1.69336 7.36958V20.0822C1.69397 20.6029 1.90109 21.1021 2.26928 21.4703C2.63746 21.8385 3.13665 22.0456 3.65735 22.0462H10.7087C10.8082 22.0462 10.9036 22.0067 10.9739 21.9364C11.0442 21.8661 11.0837 21.7707 11.0837 21.6712C11.0837 21.5718 11.0442 21.4764 10.9739 21.406C10.9036 21.3357 10.8082 21.2962 10.7087 21.2962ZM5.04346 3.64779C5.06285 3.45908 5.1555 3.28551 5.30149 3.16438C5.44748 3.04324 5.63517 2.98421 5.82422 2.99997H17.3811C17.5701 2.98421 17.7578 3.04325 17.9038 3.16438C18.0498 3.28552 18.1425 3.45909 18.1619 3.64779V8.58377H12.2622C11.9403 8.58297 11.6316 8.45542 11.4031 8.22872L9.15455 5.98001C8.78497 5.61326 8.28579 5.40688 7.76512 5.40559H5.04346V3.64779Z"
        fill="black"
        stroke="black"
        strokeWidth="0.6"
      />
      <path
        d="M16.3704 11.3765C15.1962 11.3764 14.0484 11.7246 13.0721 12.3769C12.0958 13.0293 11.3349 13.9564 10.8855 15.0412C10.4362 16.126 10.3186 17.3197 10.5476 18.4713C10.7767 19.6229 11.3421 20.6808 12.1724 21.511C13.0026 22.3413 14.0604 22.9068 15.212 23.1358C16.3636 23.3649 17.5573 23.2474 18.6421 22.7981C19.7269 22.3488 20.6541 21.5878 21.3065 20.6116C21.9588 19.6353 22.307 18.4875 22.3071 17.3133C22.3053 15.7393 21.6793 14.2303 20.5664 13.1173C19.4534 12.0043 17.9444 11.3783 16.3704 11.3765ZM16.3704 22.4999C15.3446 22.5 14.3418 22.1958 13.4888 21.6259C12.6358 21.0559 11.971 20.2459 11.5784 19.2981C11.1858 18.3504 11.0831 17.3075 11.2833 16.3013C11.4834 15.2952 11.9774 14.371 12.7028 13.6456C13.4281 12.9202 14.3523 12.4262 15.3585 12.2261C16.3646 12.026 17.4075 12.1287 18.3553 12.5213C19.303 12.9139 20.1131 13.5787 20.683 14.4317C21.2529 15.2846 21.5571 16.2875 21.5571 17.3133C21.5556 18.6884 21.0086 20.0068 20.0363 20.9792C19.0639 21.9515 17.7455 22.4984 16.3704 22.4999Z"
        fill="black"
        stroke="black"
        strokeWidth="0.3"
      />
      <path
        d="M17.9534 18.2417L16.7453 19.7408V13.8226C16.7433 13.7245 16.703 13.631 16.6328 13.5623C16.5627 13.4936 16.4685 13.4551 16.3703 13.4551C16.2721 13.4551 16.1778 13.4936 16.1077 13.5623C16.0376 13.631 15.9973 13.7245 15.9953 13.8227V19.7408L14.7872 18.2417C14.7241 18.1665 14.6341 18.1189 14.5364 18.1094C14.4387 18.0998 14.3411 18.1289 14.2647 18.1905C14.1883 18.2521 14.139 18.3412 14.1276 18.4387C14.1161 18.5362 14.1434 18.6343 14.2034 18.7119L16.0784 21.0388C16.1138 21.0821 16.1583 21.117 16.2088 21.1409C16.2593 21.1649 16.3144 21.1773 16.3703 21.1773C16.4262 21.1773 16.4814 21.1649 16.5318 21.1409C16.5823 21.117 16.6268 21.0821 16.6622 21.0388L18.5372 18.7119C18.568 18.6736 18.5911 18.6296 18.6049 18.5823C18.6188 18.5351 18.6232 18.4856 18.6179 18.4367C18.6127 18.3877 18.5978 18.3403 18.5742 18.2971C18.5506 18.2539 18.5187 18.2158 18.4804 18.1849C18.4421 18.1541 18.398 18.1311 18.3508 18.1172C18.3036 18.1033 18.2541 18.0989 18.2052 18.1042C18.1562 18.1095 18.1088 18.1243 18.0656 18.1479C18.0224 18.1715 17.9843 18.2034 17.9534 18.2417Z"
        fill="black"
        stroke="black"
        strokeWidth="0.4"
      />
    </svg>
  );
};

export const OptimizeStars = () => {
  return (
    <svg
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.625 1.12512L15.5225 3.53137L13.125 4.62512L15.5225 5.72762L16.625 8.12512L17.7188 5.72762L20.125 4.62512L17.7188 3.53137M7.875 3.75012L5.6875 8.56262L0.875 10.7501L5.6875 12.9376L7.875 17.7501L10.0625 12.9376L14.875 10.7501L10.0625 8.56262M16.625 13.3751L15.5225 15.7726L13.125 16.8751L15.5225 17.9689L16.625 20.3751L17.7188 17.9689L20.125 16.8751L17.7188 15.7726"
        fill="white"
      />
    </svg>
  );
};

export const AddDestinationPlus = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1825 11.3136H6.75391V10.1708H10.1825V6.74219H11.3253V10.1708H14.7539V11.3136H11.3253V14.7422H10.1825V11.3136Z"
        fill="#1AB2D2"
      />
    </svg>
  );
};

export const RemoveDestinationMinus = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.75" width="24" height="24" rx="12" fill="#FFDCD0" />
      <g clipPath="url(#clip0_36_802)">
        <path
          d="M17.8346 13.7799H7.16797V12.1799H17.8346V13.7799Z"
          fill="#D15024"
        />
      </g>
      <defs>
        <clipPath id="clip0_36_802">
          <rect
            width="10.6667"
            height="10.6667"
            fill="white"
            transform="translate(7.16797 7.91406)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const StepperIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 45 45" fill="none">
      <rect x="12" y="11" rx="50" width="20" height="20" fill="orange"></rect>
      <rect x="17" y="16" rx="50" width="10" height="10" fill="white"></rect>
      <line
        x1="21.5"
        x2="21.5"
        y1="14"
        y2="-30"
        stroke="orange"
        strokeWidth="3"
      />
      <line
        x1="21.5"
        x2="21.5"
        y1="75"
        y2="30"
        stroke="orange"
        strokeWidth="3"
      />
    </svg>
  );
};

export const ConfirmationCheck = () => {
  return (
    <svg
      width="70"
      height="71"
      viewBox="0 0 70 71"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.9987 67.1043C26.6831 67.1043 18.708 63.8009 12.828 57.9208C6.94791 52.0408 3.64453 44.0657 3.64453 35.7501C3.64453 27.4344 6.94791 19.4594 12.828 13.5793C18.708 7.6993 26.6831 4.39592 34.9987 4.39592C39.8849 4.37975 44.7045 5.52964 49.057 7.75009C49.3137 7.88031 49.5421 8.05981 49.7294 8.27833C49.9166 8.49685 50.059 8.75012 50.1484 9.02366C50.2378 9.29721 50.2724 9.58568 50.2503 9.87261C50.2282 10.1595 50.1498 10.4393 50.0195 10.6959C49.8893 10.9525 49.7098 11.181 49.4913 11.3683C49.2728 11.5555 49.0195 11.6979 48.746 11.7873C48.4724 11.8766 48.1839 11.9113 47.897 11.8892C47.6101 11.8671 47.3303 11.7886 47.0737 11.6584C43.34 9.73411 39.1991 8.73388 34.9987 8.74175C29.664 8.74176 24.449 10.3233 20.0129 13.2865C15.5768 16.2498 12.1188 20.4616 10.076 25.3897C8.03316 30.3178 7.49718 35.7409 8.53581 40.9736C9.57445 46.2062 12.1411 51.0135 15.9113 54.7878C19.6814 58.562 24.4859 61.1338 29.7175 62.1781C34.949 63.2224 40.3727 62.6923 45.303 60.6548C50.2333 58.6173 54.4489 55.1638 57.4169 50.731C60.3849 46.2981 61.9721 41.0848 61.9779 35.7501C61.9931 35.5073 61.9931 35.2637 61.9779 35.0209C61.9237 34.4408 62.1023 33.8629 62.4742 33.4143C62.8461 32.9658 63.381 32.6834 63.9612 32.6293C64.5414 32.5751 65.1193 32.7536 65.5678 33.1256C66.0163 33.4975 66.2987 34.0324 66.3529 34.6126V35.7501C66.3451 44.0634 63.0393 52.0339 57.1609 57.9123C51.2825 63.7907 43.312 67.0965 34.9987 67.1043Z"
        fill="#00A84D"
      />
      <path
        d="M34.4744 45.6959C34.1909 45.7001 33.9096 45.6452 33.6485 45.5347C33.3874 45.4242 33.1521 45.2606 32.9577 45.0542L18.8702 31.2292C18.4606 30.819 18.2305 30.263 18.2305 29.6834C18.2305 29.1037 18.4606 28.5477 18.8702 28.1375C19.2889 27.733 19.8484 27.5069 20.4306 27.5069C21.0128 27.5069 21.5723 27.733 21.991 28.1375L34.5035 40.475L62.6494 12.7959C63.0681 12.3913 63.6276 12.1652 64.2098 12.1652C64.792 12.1652 65.3515 12.3913 65.7702 12.7959C66.1799 13.206 66.41 13.762 66.41 14.3417C66.41 14.9214 66.1799 15.4774 65.7702 15.8875L36.0785 45.0542C35.8698 45.2659 35.6195 45.4321 35.3435 45.5425C35.0674 45.653 34.7715 45.7052 34.4744 45.6959Z"
        fill="#00A84D"
      />
    </svg>
  );
};

export const DollarSymbol = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="icon icon-tabler icons-tabler-outline icon-tabler-currency-dollar"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M16.7 8a3 3 0 0 0 -2.7 -2h-4a3 3 0 0 0 0 6h4a3 3 0 0 1 0 6h-4a3 3 0 0 1 -2.7 -2" />
      <path d="M12 3v3m0 12v3" />
    </svg>
  );
};
