import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import whatsappConfig from "../../configs/whatsapp";
import regexData from "../../constants/regexData";
import { strings } from "../../constants/strings";
import {
  getContactDetails,
  clearContactDetails,
} from "../../store/contact/actions";
import ReactGA from "react-ga4";
import "./Footer.scss";
import awsConfig from "../../configs/aws";
import { getAnalyticsEnabled } from "../../helpers/backend_helper";
import { useLocation } from "react-router-dom";

function Footer() {
  const screenWidth = window.innerWidth;
  const dispatch = useDispatch();
  const location = useLocation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [contactUsRequest, setContactUsRequest] = useState(false);
  const [whatsappNumber] = useState(whatsappConfig.number);
  const [whatsappMessage, setWhatsappMessage] = useState("");

  const { response, loader } = useSelector(
    (state: any) => state.ContactDetailsReducer
  );

  const nameContainerRef = useRef<HTMLDivElement>(null);
  const nameInputRef = useRef<HTMLInputElement>(null);
  const nameErrorRef = useRef<HTMLParagraphElement>(null);
  const emailContainerRef = useRef<HTMLDivElement>(null);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const emailErrorRef = useRef<HTMLParagraphElement>(null);
  const phoneNumberContainerRef = useRef<HTMLDivElement>(null);
  const phoneNumberInputRef = useRef<HTMLInputElement>(null);
  const phoneNumberErrorRef = useRef<HTMLParagraphElement>(null);
  const messageContainerRef = useRef<HTMLDivElement>(null);
  const messageInputRef = useRef<HTMLTextAreaElement>(null);
  const messageErrorRef = useRef<HTMLParagraphElement>(null);
  const contactUsRef = useRef<HTMLDivElement>(null);

  const createRequest = () => {
    sessionStorage.setItem("scrollPosition", `${window.pageYOffset}`);
    sessionStorage.setItem("homePageScrollPosition", `${window.pageYOffset}`);
    const data = {
      name: name,
      email: email,
      phoneNumber: phoneNumber,
      message: message,
      userNotifyType: "support.contactUs",
    };
    dispatch(getContactDetails(data));
    setContactUsRequest(true);
    if (getAnalyticsEnabled()) {
      ReactGA.event({
        action: `Filled Contact detail`,
        category: `footer`,
      });
    }
  };

  useEffect(() => {
    if (response && response.message) {
      setShowSuccessMessage(true);
      setName("");
      setEmail("");
      setPhoneNumber("");
      setMessage("");
      dispatch(clearContactDetails());
    }
  }, [response]);

  useEffect(() => {
    if (location.pathname.includes("packages")) {
      const packageName = location.pathname
        .split("/packages/")[1]
        .split("-")
        .slice(0, -1)
        .join(" ");

      const packageNameWords = packageName.split(" ");
      const formattedWords = packageNameWords.map((word) => {
        const lower = word.toLowerCase();
        return lower.charAt(0).toUpperCase() + lower.slice(1);
      });

      const resultString = `I have a travel query regarding ${formattedWords.join(
        " "
      )}.`;

      setWhatsappMessage(resultString);
    } else {
      setWhatsappMessage(whatsappConfig.message);
    }
  }, [location]);

  const clearError = () => {
    nameContainerRef.current?.classList.remove("error");
    nameErrorRef.current?.classList.remove("show");
    emailContainerRef.current?.classList.remove("error");
    emailErrorRef.current?.classList.remove("show");
    phoneNumberContainerRef.current?.classList.remove("error");
    phoneNumberErrorRef.current?.classList.remove("show");
    messageContainerRef.current?.classList.remove("error");
    messageErrorRef.current?.classList.remove("show");
  };

  const validate = () => {
    const nameRegex = new RegExp(regexData.name);
    const emailRegex = new RegExp(regexData.email);
    const numberRegex = new RegExp(regexData.mobileNumber);

    clearError();

    switch (true) {
      case !name:
        nameContainerRef.current?.classList.add("error");
        nameErrorRef.current?.classList.add("show");
        nameErrorRef.current!.innerHTML = "Enter name";
        nameInputRef.current?.focus();
        break;

      case !nameRegex.test(name):
        nameContainerRef.current?.classList.add("error");
        nameErrorRef.current?.classList.add("show");
        nameErrorRef.current!.innerHTML = "Enter valid name";
        nameInputRef.current?.focus();
        break;

      case !email:
        emailContainerRef.current?.classList.add("error");
        emailErrorRef.current?.classList.add("show");
        emailErrorRef.current!.innerHTML = "Enter email";
        emailInputRef.current?.focus();
        break;

      case !emailRegex.test(email):
        emailContainerRef.current?.classList.add("error");
        emailErrorRef.current?.classList.add("show");
        emailErrorRef.current!.innerHTML = "Enter valid email";
        emailInputRef.current?.focus();
        break;

      case !phoneNumber:
        phoneNumberContainerRef.current?.classList.add("error");
        phoneNumberErrorRef.current?.classList.add("show");
        phoneNumberErrorRef.current!.innerHTML = "Enter mobile";
        phoneNumberInputRef.current?.focus();
        break;

      case !numberRegex.test(phoneNumber):
        phoneNumberContainerRef.current?.classList.add("error");
        phoneNumberErrorRef.current?.classList.add("show");
        phoneNumberErrorRef.current!.innerHTML = "Enter valid mobile";
        phoneNumberInputRef.current?.focus();
        break;

      case !message:
        messageContainerRef.current?.classList.add("error");
        messageErrorRef.current?.classList.add("show");
        messageErrorRef.current!.innerHTML = "Enter message";
        messageInputRef.current?.focus();
        break;

      default:
        createRequest();
        break;
    }
  };

  const setSessionStorage = () => {
    sessionStorage.setItem("footerScrollPostion", `${window.pageYOffset}`);
  };

  const removeSessionStorage = () => {
    sessionStorage.removeItem("footerScrollPostion");
    sessionStorage.removeItem("homePageScrollPosition");
    sessionStorage.removeItem("aboutUsScrollPosition");
  };
  return (
    <>
      <div className="footer-section">
        <div className="section">
          <div className="left">
            <div className="footer-logo">
              <ul>
                <li className="logo-text">
                  <img
                    src={awsConfig.s3Path + "logo_transparent.png"}
                    alt=""
                    className="img-fluid"
                  />
                </li>
                <li className="w24">
                  <a
                    href="https://www.facebook.com/voyaahholidays/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <img
                      src={require("../../assets/images/social1.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </a>
                </li>
                <li className="w24">
                  <a
                    href="https://twitter.com/Voyaah_holidays"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <img
                      src={require("../../assets/images/social2.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </a>
                </li>
                <li className="w24">
                  <a
                    href="https://instagram.com/voyaah_holidays"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <img
                      src={require("../../assets/images/social3.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </a>
                </li>
                <li className="w24">
                  <a
                    href="https://www.linkedin.com/company/voyaah-holidays/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <img
                      src={require("../../assets/images/social4.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </a>
                </li>
                <li className="w24">
                  <a
                    href="https://www.youtube.com/@Voyaah_holidays"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <img
                      src={require("../../assets/images/social5.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer-menu">
              <ul>
                <li>
                  <Link
                    onClick={() => {
                      removeSessionStorage();
                    }}
                    to="/about-us"
                  >
                    About Us
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      setSessionStorage();
                    }}
                    to="/news"
                  >
                    News
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      setSessionStorage();
                    }}
                    to="/partners"
                  >
                    Partners
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      setSessionStorage();
                    }}
                    to="/media"
                  >
                    Media
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      setSessionStorage();
                    }}
                    to="/blogs"
                  >
                    Blogs & Stories
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      setSessionStorage();
                    }}
                    to="/terms-and-conditions"
                  >
                    Terms and Conditions
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      setSessionStorage();
                    }}
                    to="/site-overview"
                  >
                    SiteMap
                  </Link>
                </li>
              </ul>
            </div>
            {/* <div className="footer-newsletter">
              <div className="heading">Subscribe to our newsletter</div>
              <div className="input">
                <input type="text" placeholder="Enter Email" name="" />
                <input type="button" value="Subscribe" className="green-btn" />
              </div>
            </div> */}
          </div>
          <div className="right">
            <div className="heading" ref={contactUsRef}>
              Write to us
            </div>
            <div className="form">
              <div className="form-fields" ref={nameContainerRef}>
                <input
                  ref={nameInputRef}
                  type="text"
                  placeholder="Enter Your Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  maxLength={50}
                  required
                />
                <p className="error-msg" ref={nameErrorRef}>
                  Error Text
                </p>
              </div>
              <div className="form-fields" ref={emailContainerRef}>
                <input
                  ref={emailInputRef}
                  type="text"
                  placeholder="Enter Your Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  maxLength={50}
                  required
                />
                <p className="error-msg" ref={emailErrorRef}>
                  Error Text
                </p>
              </div>
              <div className="form-fields" ref={phoneNumberContainerRef}>
                <input
                  ref={phoneNumberInputRef}
                  type="text"
                  placeholder="Enter Your Mobile Number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  maxLength={10}
                  required
                />
                <p className="error-msg" ref={phoneNumberErrorRef}>
                  Error Text
                </p>
              </div>
              <div className="form-fields" ref={messageContainerRef}>
                <textarea
                  ref={messageInputRef}
                  placeholder="Enter Your Message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <p className="error-msg" ref={messageErrorRef}>
                  Error Text
                </p>
              </div>
              <div className="form-fields">
                <input
                  type="button"
                  value="Submit"
                  className="green-btn"
                  onClick={validate}
                  maxLength={250}
                />
              </div>
              {showSuccessMessage && contactUsRequest && (
                <div className="form-fields">
                  {strings.contactUs.successMessage}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="section">
          <div className="footer-copyright">
            © 2023 Voyaah&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;
            {/* <Link to="/terms-of-use">Terms of Use</Link> */}
            <Link to="/privacy-policy">Privacy Policy</Link>
            &nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;
            <Link to="/cookie-policy">
              {" "}
              Voyaah delivers a personalised experience through cookies. Manage
              your cookie preferences in the <u>Cookie Policy</u>
            </Link>
            &nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;
            {/* <Link to="/faq">FAQs</Link> */}
          </div>
        </div>
      </div>
      <div className="floating-icons">
        <ul>
          <li>
            {screenWidth > 767 && <span>022 - 48930225</span>}
            <a href="tel:02248930225">
              <img
                src={require("../../assets/images/call.png")}
                alt=""
                className="img-fluid"
              />
            </a>
          </li>
          <li>
            {/* href="https://wa.me/${phoneNumber}?text=${encodeURI(message)}" */}
            <a
              href={`https://wa.me/${whatsappNumber}?text=${encodeURI(
                whatsappMessage
              )}`}
              rel="noreferrer"
              target="_blank"
            >
              <img
                src={require("../../assets/images/whatsapp.png")}
                alt=""
                className="img-fluid"
              />
            </a>
          </li>
        </ul>
      </div>

      {loader && (
        <div className="loader-container">
          <div className="loader">
            <Bars color="#00BFFF" height={50} width={100} />
          </div>
        </div>
      )}
    </>
  );
}

export default Footer;
