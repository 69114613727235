import React from "react";
import { formatMoney } from "../../helpers/utils";

import "./PendingPayments.scss";

function PendingPaymentsCard() {
  return (
    <div className="my-booking-section">
      <div className="my-booking">
        <div className="image">
          <img
            src={require("../../assets/images/my-booking.png")}
            alt=""
            className="img-fluid"
          />
        </div>
        <div className="content">
          <div className="subhead">Service Description</div>
          <div className="head">{"Spa Services"}</div>
          &nbsp;
          <div className="amount lato-font">{`Amount paid: ₹${formatMoney(
            "7999"
          )}`}</div>
          &nbsp;
          <div className="btns">
            <div className="modify">Continue to pay</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PendingPaymentsCard;
