import React, { useState, useEffect } from "react";

import "./Details.scss";

function PackageDescription(props: any) {
  const { description } = props;
  const screenWidth = window.innerWidth;

  const [descriptionCharLimit, setDescCharLimit] = useState(200);
  const [descriptionWithoutTags, setDescriptionWithoutTags] = useState("");

  useEffect(() => {
    if (description) {
      const stringWithoutText = description?.replaceAll(/<[^>]+>/g, "");
      setDescriptionWithoutTags(stringWithoutText);
      //If mobile view, we change the char limit
      if (screenWidth < 767) {
        setDescCharLimit(80);
      } else {
      }
    }
  }, [description]);

  const [showFullDescription, setShowFullDescription] = useState(false);
  return (
    <>
      {descriptionWithoutTags?.length <= descriptionCharLimit && (
        <span
          className={
            showFullDescription ? "description-show-full-text" : "description"
          }
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      )}
      {descriptionWithoutTags?.length > descriptionCharLimit && (
        <>
          {!showFullDescription && (
            <>
              <span
                className={
                  showFullDescription
                    ? "description-show-full-text"
                    : "description"
                }
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
              <span>...</span>
            </>
          )}
          {showFullDescription && (
            <span
              className={
                showFullDescription
                  ? "description-show-full-text"
                  : "description"
              }
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
          )}
          <span
            className="inclusion-view-more"
            onClick={() => setShowFullDescription(!showFullDescription)}
          >{`${showFullDescription ? "Show Less" : "Show More"} `}</span>
        </>
      )}
    </>
  );
}

export default PackageDescription;
