import React, { useEffect, useState, useRef } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useLocation, useNavigate } from "react-router-dom";
import ReactPixel from 'react-facebook-pixel';

import { auth, resetPassword } from "../../helpers/firebase";
import "./PasswordReset.scss";

function useQuery() {
  const location = useLocation();
  return new URLSearchParams(location.search);
}

function PasswordReset() {
  const [newPassword, setNewPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [user, loading] = useAuthState(auth);
  const [showPasswordText, setShowPasswordText] = useState(false);
  const query = useQuery();
  const errorRef = useRef<HTMLParagraphElement>(null);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Forgot Password | Voyaah";
    ReactPixel.fbq('init', process.env.REACT_APP_FBPIXEL_ID);
    ReactPixel.fbq('track', 'PageView');
  }, []);

  useEffect(() => {
    if (loading) return;
    if (user) navigate("/");
  }, [user, loading, navigate]);

  const submitResetPassword = () => {
    if (newPassword === confPassword) {
      resetPassword(query.get("oobCode")!, confPassword).catch((err: Error) => {
        let errorText = "";
        switch (err.message) {
          case "Firebase: Error (auth/invalid-action-code).":
            errorText = "Reset-Link has already been used.";
            break;
          case "Firebase: Error (auth/expired-action-code).":
            errorText = "Reset-Link has expired.";
            break;
          case "Firebase: Error (auth/user-disabled).":
            errorText = "Account Disabled.";
            break;
          case "Firebase: Error (auth/user-not-found).":
            errorText = "You are not registered.";
            break;
          default:
            console.log("Unknown Error.");
        }

        if (err) {
          errorRef?.current?.classList.add("show");
          if (errorRef?.current?.innerHTML) {
            errorRef.current.innerHTML = errorText;
          }
        }
      });
    } else {
      errorRef?.current?.classList.add("show");
      if (errorRef?.current?.innerHTML) {
        errorRef.current.innerHTML = "Passwords dont match";
      }
    }
  };

  return (
    <div className="login-section">
      <div className="section">
        <div className="heading">
          Discover the world in a<br /> new way
        </div>
        <div className="login-container">
          <div className="head">Reset Password</div>
          <div className="form">
            <div className="form-fields">
              <label>New Password</label>
              <input
                type={showPasswordText ? "text" : "password"}
                placeholder="Enter New Pasword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <span className="icon">
                <img
                  src={require("../../assets/images/eye-icon.png")}
                  alt=""
                  className="img-fluid"
                  onClick={() => setShowPasswordText(!showPasswordText)}
                />
              </span>
            </div>
            <div className="form-fields">
              <label>Confirm Password</label>
              <input
                type={showPasswordText ? "text" : "password"}
                placeholder="Confirm Passsword"
                value={confPassword}
                onChange={(e) => setConfPassword(e.target.value)}
              />
              <span className="icon">
                <img
                  src={require("../../assets/images/eye-icon.png")}
                  alt=""
                  className="img-fluid"
                  onClick={() => setShowPasswordText(!showPasswordText)}
                />
              </span>
            </div>
            <div className="form-fields no-bg">
              <input
                type="button"
                value="Reset"
                className="green-btn"
                onClick={() => submitResetPassword()}
              />
            </div>
          </div>
          <p className="error-msg" ref={errorRef}>
            Error Text
          </p>
        </div>
      </div>
    </div>
  );
}

export default PasswordReset;
