import {
  GET_PACKAGE_DETAILS,
  GET_PACKAGE_DETAILS_SUCCESS,
  GET_PACKAGE_DETAILS_FAIL,
  CLEAR_PACKAGE_DETAILS,
  GET_PACKAGE_PRICING,
  GET_PACKAGE_PRICING_SUCCESS,
  GET_PACKAGE_PRICING_FAIL,
  CLEAR_PACKAGE_PRICING,
} from "./actionTypes";

export const getPackageDetails = (
  id: any,
  customerProfileId: string,
  accountId: string
) => {
  return {
    type: GET_PACKAGE_DETAILS,
    payload: id,
    customerProfileId: customerProfileId,
    accountId: accountId,
  };
};

export const getPackageDetailsSuccess = (data: any) => {
  return {
    type: GET_PACKAGE_DETAILS_SUCCESS,
    payload: data,
  };
};

export const getPackageDetailsFail = (error: any) => {
  return {
    type: GET_PACKAGE_DETAILS_FAIL,
    payload: error,
  };
};

export const clearPackageDetails = () => {
  return {
    type: CLEAR_PACKAGE_DETAILS,
  };
};

export const getPackagePricing = (
  data: any[],
  customerProfileId: string,
  accountId: string,
  packageId: string
) => {
  return {
    type: GET_PACKAGE_PRICING,
    payload: data,
    customerProfileId: customerProfileId,
    accountId: accountId,
    packageId: packageId
  };
};

export const getPackagePricingSuccess = (data: any) => {
  return {
    type: GET_PACKAGE_PRICING_SUCCESS,
    payload: data,
  };
};

export const getPackagePricingFail = (error: any) => {
  return {
    type: GET_PACKAGE_PRICING_FAIL,
    payload: error,
  };
};

export const clearPackagePricing = () => {
  return {
    type: CLEAR_PACKAGE_PRICING,
  };
};
