import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const usePrompt = (message: string, when: boolean) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!when) {
      return;
    }

    const handleBeforeUnload = (event: any) => {
      event.preventDefault();
      event.returnValue = message;
      return message;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [navigate, location, when]);
};

export default usePrompt;
