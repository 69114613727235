import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

const AccessConfirmationComponent = observer(
  ({ closeModal, openDialog }: { closeModal: any; openDialog: boolean }) => {
    const navigate = useNavigate();
    // old jsx
    // <div className="confirmation-overlay">
    //   <div className="confirmation-popup">
    //     <div className="confirmation-cancel"></div>
    //     <div className="confirmation-content">
    //       <div className="confirmation-content-text">
    //         <p className="content-text-first">
    //           You do not have access to edit this itinerary since you are not
    //           the owner
    //         </p>
    //       </div>
    //     </div>
    //     <div className="validation-btn-div">
    //       <button
    //         className="confirmation-okay-btn"
    //         style={{ color: "white" }}
    //         onClick={() => {
    //           navigate("/");
    //         }}
    //       >
    //         Proceed to home page
    //       </button>
    //     </div>
    //   </div>
    // </div>
    return (
      <Dialog
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
      >
        <DialogContent sx={{ backgroundColor: "#222222" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <DialogContentText
                id="alert-dialog-description"
                variant="h5"
                sx={{ color: "#fff", textAlign: "center", padding: "50px 0px" }}
              >
                You do not have access to edit this itinerary since you are not
                          the owner
              </DialogContentText>
              <button
                className="confirmation-okay-btn"
                style={{ color: "white" }}
                onClick={() => {
                  navigate("/");
                }}
              >
                Proceed to home page
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
);

export default AccessConfirmationComponent;
