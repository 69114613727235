import {
    GET_GROUP_BOOKING_DATA,
    GET_GROUP_BOOKING_DATA_SUCCESS,
    GET_GROUP_BOOKING_DATA_FAIL,
} from "./actionTypes";

const initialState = {
    groupBookingResponse: {},
    loader: false,
    error: {
        message: "",
    },
};

const GroupBookingDataReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_GROUP_BOOKING_DATA:
            state = { ...state, loader: true };
            break;
        case GET_GROUP_BOOKING_DATA_SUCCESS:
            state = { ...state, groupBookingResponse: action.payload, loader: false };
            break;
        case GET_GROUP_BOOKING_DATA_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loader: false,
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default GroupBookingDataReducer;