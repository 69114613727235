import { useEffect, useState } from "react";
import "./VoyaahPromises.scss";
function VoyaahPromises(props: any) {
  const { data } = props;
  const [dataArr, setDataArr] = useState<any>([]);

  useEffect(() => {
    if(data?.folder.files !== 0) {
    data.folder.files.sort((a: any, b: any) => {
      if(a.displayIndex !== null) {
        var value;
        value = (a.displayIndex > b.displayIndex) ? 1 : (a.displayIndex < b.displayIndex) ? -1 : 0;
        return value;
    }
    });
    setDataArr(data);
  }
}, [data]);
  return (
    <div className="about-section">
      {dataArr.length !== 0 && dataArr.folder?.files.length !== 0 &&
      <div className="section">
        <ul>
          {dataArr.folder?.files.map((item: any, index: number) => {
            return (
              <li key={index}>
                <span className="icon">
                  <img
                    src={`${dataArr.folder?.url + '/' + item.fileName}`}
                    alt=""
                    className="img-fluid"
                  />
                </span>
                <span className="text">
                  {item.cardAttributes.title}
                  <br />
                  {item.cardAttributes.subTitle}
                </span>
              </li>
            );
          })}
        </ul>
      </div>
      }
    </div>
  );
}

export default VoyaahPromises;
