import {
  GET_ITINERARY_LIST,
  GET_ITINERARY_LIST_FAIL,
  GET_ITINERARY_LIST_SUCCESS,
} from "./actionTypes";

const initialState = {
  response: {},
  loader: false,
  error: {
    message: "",
  },
};

const ItineraryListingReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_ITINERARY_LIST:
      state = { ...state, loader: true };
      break;
    case GET_ITINERARY_LIST_SUCCESS:
      state = { ...state, response: action.payload, loader: false };
      break;
    case GET_ITINERARY_LIST_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loader: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default ItineraryListingReducer;
