import { Link } from "react-router-dom";
import "./Pagination.scss";
import { useMemo } from "react";

export const Pagination = (props: any) => {
  const {
    onPageChange,
    currentPage,
    totalRecords,
    listCount,
    pathName,
    urlQueryParams,
    isPathsEnabled,
  } = props;

  const handleClick = (action: any) => {
    onPageChange(action);
  };

  const range = (start: number, end: number) => {
    let length = end - start + 1;
    return Array.from({ length }, (_, idx) => idx + start);
  };

  const DOTS = "...";

  const paginationRange = useMemo(() => {
    const totalPageCount = Math.ceil(totalRecords / listCount);

    const totalPageNumbers = 1 + 5;

    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    const leftSiblingIndex = Math.max(currentPage - 1, 1);
    const rightSiblingIndex = Math.min(currentPage + 1, totalPageCount);

    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2;
      let leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 3 + 2;
      let rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount
      );
      return [firstPageIndex, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
  }, [currentPage, totalRecords, pathName]);

  const calculateResultRange = () => {
    let upperLimit = 0;
    let lowerLimit = 1;
    if (totalRecords < listCount) {
      upperLimit = totalRecords;
    } else {
      if (currentPage * listCount > totalRecords) {
        upperLimit = totalRecords;
        lowerLimit = currentPage * listCount - listCount + 1;
      } else {
        upperLimit = currentPage * listCount;
        lowerLimit = upperLimit - listCount + 1;
      }
    }
    return [lowerLimit, upperLimit];
  };

  const renderPathButtonsJsx = () => {
    return (
      <div className="pagination-left-container">
        {/* Previous Button */}
        <Link
          to={
            urlQueryParams.size > 0
              ? `${pathName}/page${currentPage - 1}?${urlQueryParams}`
              : `${pathName}/page${currentPage - 1}`
          }
        >
          <button
            disabled={currentPage === 1}
            className={
              currentPage === 1 ? "pagination-prev-disabled" : "pagination-prev"
            }
            onClick={() => handleClick("prev")}
          />
        </Link>
        {paginationRange &&
          paginationRange.map((pageNumber: any, index: number) => {
            return (
              // Page Number Tabs
              <Link
                to={
                  urlQueryParams.size > 0
                    ? `${pathName}/page${pageNumber}?${urlQueryParams}`
                    : `${pathName}/page${pageNumber}`
                }
              >
                <span
                  key={`list_${index}`}
                  className={currentPage === pageNumber ? "active" : "inactive"}
                  onClick={() => handleClick(pageNumber)}
                >
                  {pageNumber}
                </span>
              </Link>
            );
          })}
        {/* Next Button */}
        <Link
          to={
            urlQueryParams.size > 0
              ? `${pathName}/page${currentPage + 1}?${urlQueryParams}`
              : `${pathName}/page${currentPage + 1}`
          }
        >
          <button
            disabled={currentPage === Math.ceil(totalRecords / listCount)}
            className={
              currentPage === Math.ceil(totalRecords / listCount)
                ? "pagination-next-disabled"
                : "pagination-next"
            }
            onClick={() => handleClick("next")}
          />
        </Link>
      </div>
    );
  };

  const renderNonPathButtonJsx = () => {
    return (
      <div className="pagination-left-container-non">
        {/* Previous Button */}

        <button
          disabled={currentPage === 1}
          className={
            currentPage === 1 ? "pagination-prev-disabled" : "pagination-prev"
          }
          onClick={() => handleClick("prev")}
        />
        {paginationRange &&
          paginationRange.map((pageNumber: any, index: number) => (
            <span
              className={currentPage === pageNumber ? "active" : "non-inactive"}
              onClick={() => handleClick(pageNumber)}
              key={index}
            >
              {pageNumber}
            </span>
          ))}

        {/* Next Button */}
        <button
          disabled={currentPage === Math.ceil(totalRecords / listCount)}
          className={
            currentPage === Math.ceil(totalRecords / listCount)
              ? "pagination-next-disabled"
              : "pagination-next"
          }
          onClick={() => handleClick("next")}
        />
      </div>
    );
  };

  return (
    <div
      className={`pagination-holder ${isPathsEnabled ? "path" : "non-path"}`}
    >
      {totalRecords > listCount && (
        <>
          {isPathsEnabled ? renderPathButtonsJsx() : renderNonPathButtonJsx()}
        </>
      )}

      <div
        className="pagination-right-container"
      >
        <span className={isPathsEnabled ? "path-text" : "non-path-text"}>
          Showing {calculateResultRange()[0]} - {calculateResultRange()[1]} of{" "}
          {totalRecords} result{totalRecords > 1 ? "s" : ""}.
        </span>
      </div>
    </div>
  );
};
