import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { Bars } from "react-loader-spinner";
import ReactGA from "react-ga4";
import { getAnalyticsEnabled } from "../../helpers/backend_helper";
import { getPaymentDetails } from "../../store/booking/actions";

import "./PaymentCapture.scss";
import _ from "underscore";
import ReactPixel from "react-facebook-pixel";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { getDateStrInYYYYMMDD } from "src/helpers/utils";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#292929",
    borderBottom: "0px",
    color: "#FFF",
    fontSize: 18,
    fontWeight: 600,
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    backgroundColor: "#292929",
    borderBottom: "0px",
    padding: "13px",
    color: "#FFF",
  },
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  overflowY: "auto",
  scrollbarColor: "#c1c4c9 #fff",
  scrollbarWidth: "thin",
}));

type Column = {
  label: string;
  align: "inherit" | "center" | "left" | "right" | "justify";
};

// Define columns with their labels and alignment
const columns: Column[] = [
  { label: "S.No", align: "center" },
  { label: "Property Details", align: "left" },
  { label: "Check In", align: "center" },
  { label: "Check Out", align: "center" },
  { label: "Room Details", align: "center" },
  { label: "Count", align: "center" },
  { label: "Cancellation", align: "center" },
];

function Row({ row, sn }: any) {
  const [open, setOpen] = useState(false);

  const isRateCommentsAvailable =
    row?.extractedJson?.roomDistribution[0]?.rateComments !== undefined ||
    row?.extractedJson?.roomDistribution[0].rateComments !== null;

  const compareDates = (policyDate: any) => {
    let result = "";
    if (policyDate) {
      const planPolicyDate = policyDate?.split("T")[0];
      const date = new Date();
      const todaydate: any = getDateStrInYYYYMMDD(date);

      if (todaydate > planPolicyDate) {
        result = "Booking is non-refundable";
      } else {
        result =
          "Free Cancellation Upto " +
          planPolicyDate?.split("-")[2] +
          "-" +
          planPolicyDate?.split("-")[1] +
          "-" +
          planPolicyDate?.split("-")[0];
      }
    } else {
      result = "";
    }
    return result;
  };

  return (
    <>
      <TableRow>
        <StyledTableCell align="center">
          <Box sx={{ minWidth: "60px" }}>{sn}</Box>
        </StyledTableCell>
        <StyledTableCell>
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <b style={{ fontSize: "18px" }}>
              {`${row?.propertyName}, ${row?.property?.location ?? row?.city?.name}`}
            </b>
            {!open && (
              <div>
                {isRateCommentsAvailable &&
                row?.extractedJson?.roomDistribution[0]?.rateComments?.length >
                  0 ? (
                  <div
                    title={
                      row?.extractedJson?.roomDistribution[0]?.rateComments
                    }
                    style={{ textAlign: "justify" }}
                  >
                    <p style={{ textAlign: "justify", marginTop: "3px" }}>
                      {row?.extractedJson?.roomDistribution[0]?.rateComments
                        ?.length > 150
                        ? row?.extractedJson?.roomDistribution[0]?.rateComments?.substring(
                            0,
                            150
                          )
                        : row?.extractedJson?.roomDistribution[0]?.rateComments}
                    </p>
                  </div>
                ) : null}
                {row?.extractedJson?.roomDistribution[0]?.rateComments?.length > 150 && (
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    disableTouchRipple
                    disableRipple
                    sx={{ color: "#4D8C98", padding: "2px 0px" }}
                    onClick={() => setOpen(!open)}
                  >
                    {open ? "View less" : "View more"}
                  </IconButton>
                )}
              </div>
            )}
          </Box>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <p style={{ textAlign: "justify", marginTop: "3px" }}>
                {row?.extractedJson?.roomDistribution[0]?.rateComments}
              </p>
              <IconButton
                aria-label="expand row"
                size="small"
                disableTouchRipple
                disableRipple
                sx={{ color: "#4D8C98", padding: "2px 0px" }}
                onClick={() => setOpen(!open)}
              >
                {open ? "View less" : "View more"}
              </IconButton>
            </Box>
          </Collapse>
        </StyledTableCell>
        <StyledTableCell align="center">
          <Box sx={{ minWidth: "100px" }}>
            {moment(row?.fromDate).format("MMM DD, YYYY") ?? ""}
          </Box>
        </StyledTableCell>
        <StyledTableCell align="center">
          <Box sx={{ minWidth: "100px" }}>
            {moment(row?.toDate).format("MMM DD, YYYY") ?? ""}
          </Box>
        </StyledTableCell>
        <StyledTableCell align="center">
          <Box sx={{ minWidth: "130px" }}>{row?.name}</Box>
        </StyledTableCell>
        <StyledTableCell align="center">
          <Box sx={{ minWidth: "80px" }}>{row?.numRooms}</Box>
        </StyledTableCell>
        <StyledTableCell align="center">
          <Box>
            {/* {row?.bookedRooms[0]?.cancellationPolicies.length > 0
              ? `Free cancellation upto ${moment
                  .utc(
                    row?.bookedRooms[0]?.cancellationPolicies[0]?.ifCancelledBy
                  )
                  .format("DD-MM-YYYY")}`
              : "Booking is non-refundable"} */}
            {compareDates(
              row?.extractedJson?.roomDistribution[0]?.cancellationPolicies[0]
                ?.ifCancelledBy
            )}
          </Box>
        </StyledTableCell>
      </TableRow>
    </>
  );
}

function PaymentCapture() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [bookingStatus, setBookingStatus] = useState(false);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [channelOfSale, setChannelOfSale] = useState("");
  const [orderIdOfflineBooking, setOrderIdOfflineBooking] = useState("");

  const { paymentDetails, bookingLoader } = useSelector(
    (state: any) => state.BookingDetailsReducer
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    document.title = "Payment Capture | Voyaah";
    let params = new URLSearchParams(location.search);
    const orderAmount = parseFloat(params.get("amount")!);
    const orderCurrency = params.get("currency");

    ReactPixel.fbq("init", process.env.REACT_APP_FBPIXEL_ID);
    ReactPixel.fbq("track", "PageView");
    ReactPixel.fbq("track", "Purchase", {
      value: orderAmount,
      currency: orderCurrency,
    });
    if (getAnalyticsEnabled()) {
      ReactGA.event({
        action: `{send_to: ${process.env.REACT_APP_GA_MEASUREMENT_ID}/${process.env.REACT_APP_GTAG_CONVERSION_ID}, transaction_id: '' }`,
        category: `conversion`,
      });
    }
  }, []);

  useEffect(() => {
    let params = new URLSearchParams(location.search);
    let orderId;
    const channelOfSale = params.get("merchant_param1");
    setChannelOfSale(channelOfSale!);
    if (channelOfSale === "channelOfSale.Offline") {
      orderId = params.get("merchant_param3");
      setOrderIdOfflineBooking(params.get("order_id")!);
    } else {
      orderId = params.get("order_id");
    }

    if (orderId) {
      const localStorageData = localStorage.getItem("voyaahCustomerTracker");
      let customerProfileId = "";
      if (localStorageData) {
        customerProfileId = JSON.parse(localStorageData!).id;
      }
      dispatch(getPaymentDetails(orderId, channelOfSale, customerProfileId));
    }
  }, [location, dispatch]);

  useEffect(() => {
    if (!_.isEmpty(paymentDetails)) {
      let bookingStatus: any;
      if (channelOfSale === "channelOfSale.Offline") {
        paymentDetails.paymentTransaction.forEach((item: any) => {
          if (item?.payments[0]?.id === orderIdOfflineBooking) {
            bookingStatus =
              item?.payments[0]?.paymentStatus === "paymentStatus.Completed";
          }
        });
      } else {
        bookingStatus =
          paymentDetails.bookingStatus === "bookingStatus.paymentComplete" ||
          paymentDetails.bookingStatus === "bookingStatus.confirmed";
      }

      setBookingStatus(bookingStatus!);

      if (bookingStatus) {
        if (getAnalyticsEnabled()) {
          ReactGA.event({
            action: "booking_success",
            category: "payment_capture_page",
          });
        }
      } else {
        if (getAnalyticsEnabled()) {
          ReactGA.event({
            action: "booking_failed",
            category: "payment_capture_page",
          });
        }
      }

      const travelStartDate = new Date(paymentDetails.fromDate);
      const formattedTravelStartDate = moment(travelStartDate).format("MMM DD");
      setStartDate(formattedTravelStartDate);

      let travelEndDate = new Date(paymentDetails.toDate);
      const formattedTravelEndDate =
        moment(travelEndDate).format("MMM DD, YYYY");
      setEndDate(formattedTravelEndDate);
    }
  }, [paymentDetails]);

  const navigateToHome = () => {
    navigate(`/`);
  };

  return (
    <>
      <div className="inner-page details-page">
        <div className="section">
          <div className="breadcrum">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>›</li>
              <li>Payment Capture</li>
            </ul>
          </div>

          {!_.isEmpty(paymentDetails) && (
            <div className="content-wrapper">
              <div className="payment-section-blk">
                <div className="img">
                  <img
                    src={require(`../../assets/images/${
                      bookingStatus ? "success" : "error"
                    }.png`)}
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <div className="head">{`Payment ${
                  bookingStatus ? "Successful" : "Failed"
                }`}</div>
                <div className="content">
                  <p>
                    {`${
                      bookingStatus
                        ? "All done! We will send you the booking confirmation in next 48 hours"
                        : "We are sorry, your payment isn't getting processed for the below selected package."
                    }`}
                  </p>
                  <div className="date-location">
                    <div className="image">
                      <img
                        src={
                          paymentDetails?.mainImage ||
                          require("../../assets/images/image24.png")
                        }
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="detail">
                      <p className="head">{`${paymentDetails?.packageName}`}</p>
                      <p className="des">
                        <span>Date</span> {`${startDate} to ${endDate}`}
                      </p>
                      {/* <p className="des">
                  <span>Location</span> {`Destination`}
                </p> */}
                      <p className="des">
                        <span>Order Id</span> {`${paymentDetails?.bookingId}`}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rate-comment-section">
                {bookingStatus && (
                  <StyledTableContainer>
                    <TableContainer
                      sx={{ backgroundColor: "#292929", borderRadius: "8px" }}
                    >
                      <Table aria-label="collapsible table">
                        <TableHead>
                          <TableRow>
                            {columns.map(({ label, align }, i) => (
                              <StyledTableCell key={i} align={align}>
                                {label}
                              </StyledTableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {paymentDetails?.roomDetails
                            ?.sort((a: any, b: any) => {
                              return a.fromDate.localeCompare(b.fromDate);
                            })
                            .map((row: any, index: number) => (
                              <Row key={index} row={row} sn={index + 1} />
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </StyledTableContainer>
                )}
                <p>
                  {`${
                    bookingStatus
                      ? "Your booking is under process. Please wait for 48 hours for the final confirmation"
                      : "For processing request offline you can reach our travel expert on 022-48930225."
                  }`}
                </p>
                <div
                  className="green-btn"
                  style={{ padding: "8px 75px" }}
                  onClick={navigateToHome}
                >
                  Explore More
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {bookingLoader && (
        <div className="loader-container">
          <div className="loader">
            <Bars color="#00BFFF" height={50} width={100} />
          </div>
        </div>
      )}
    </>
  );
}

export default PaymentCapture;