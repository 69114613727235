import { takeLatest, put, call } from "redux-saga/effects";

import { GET_HOME_DATA } from "./actionTypes";

import { getHomeDataSuccess, getHomeDataFail } from "./actions";

import { getHomeData } from "../../helpers/backend_helper";

function* onGetHomeData({ payload: customerProfileId, accountId }) {
  try {
    const response = yield call(getHomeData, customerProfileId, accountId);
    yield put(getHomeDataSuccess(response));
  } catch (error) {
    yield put(getHomeDataFail(error.response));
  }
}

function* HomeDataSaga() {
  yield takeLatest(GET_HOME_DATA, onGetHomeData);
}

export default HomeDataSaga;
