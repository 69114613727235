import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  EmailAuthProvider,
  sendPasswordResetEmail,
  signOut,
  signInWithPhoneNumber,
  linkWithCredential,
  updateProfile,
  confirmPasswordReset,
  verifyPasswordResetCode,
  signInAnonymously,
} from "firebase/auth";
import {
  getFirestore,
  collection,
  addDoc,
  query,
  where,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import firebaseConfig from "../configs/firebase";
import regexData from "../constants/regexData";

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage();

const logInWithEmailAndPassword = async (cred: any, password: any) => {
  try {
    /* Email Test: When the User Enters Email*/
    const emailRegex = new RegExp(regexData.email);
    if (emailRegex.test(cred)) {
      let userDetails = await signInWithEmailAndPassword(auth, cred, password);
      return userDetails;
    }

    /* Phone Test: When the User Enters Phone Number*/
    const numberRegex = new RegExp(regexData.mobileNumber);
    if (numberRegex.test(cred)) {
      const userEmail = await getEmailFromNumber(cred, password);
      if (userEmail === "") {
        throw new Error("You Aren't registered yet.");
      }
      let userDetails = await signInWithEmailAndPassword(
        auth,
        userEmail,
        password
      );
      return userDetails;
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
};

//shall be called on generate OTP button
const registerWithPhoneAndOTP = async (
  name: any,
  email: any,
  password: any,
  phoneNumber: any,
  appVerifier: any
) => {
  let userExist = false;
  const docRef = collection(db, "users");
  const docQuery = query(docRef, where("email", "==", email));
  const docQueryPhone = query(docRef, where("phoneNumber", "==", phoneNumber));

  //Check docs for existing user credentials
  await getDocs(docQuery).then((res) => {
    res.forEach((doc) => {
      if (doc.exists()) {
        userExist = doc.exists();
      }
    });
  });

  await getDocs(docQueryPhone).then((res) => {
    res.forEach((doc) => {
      if (doc.exists()) {
        userExist = doc.exists();
      }
    });
  });

  if (!userExist) {
    //otp send
    signInWithPhoneNumber(auth, `+91${phoneNumber}`, appVerifier).then(
      (confirmationResult) => {
        window.confirmationResult = confirmationResult;
      }
    );
  }
  return userExist;
};

const registerUsingPhoneNumber = async (cred: any, appVerifier: any) => {
  const phoneNumber = `+91${cred}`;
  const auth = getAuth();
  signInWithPhoneNumber(auth, phoneNumber, appVerifier)
    .then((confirmationResult) => {
      window.confirmationResult = confirmationResult;
    })
    .catch((error) => {
      console.log("error", error);
      // Error; SMS not sent
      // ...
    });
};

const signInUsingOtp = async (cred: any, appVerifier: any) => {
  let userExist = false;
  const docRef = collection(db, "users");
  const userEmail = await getEmailFromNumber(cred, "password");
  const docQueryForNumberAsCred = query(
    docRef,
    where("email", "==", userEmail)
  );
  const docQueryForEmailAsCred = query(docRef, where("email", "==", cred));

  const numberRegex = new RegExp(regexData.mobileNumber);
  const emailRegex = new RegExp(regexData.email);
  // Check if number is registered
  await getDocs(docQueryForNumberAsCred).then((res) => {
    res.forEach(async (doc) => {
      if (doc.exists()) {
        userExist = doc.exists();
      }
    });
  });
  // Check If email is registered
  await getDocs(docQueryForEmailAsCred).then((res) => {
    res.forEach((doc) => {
      if (doc.exists()) {
        userExist = doc.exists();
      }
    });
  });
  if (userExist) {
    if (emailRegex.test(cred)) {
      const userNumber = await getNumberFromEmail(cred);

      signInWithPhoneNumber(auth, `+91${userNumber}`, appVerifier).then(
        (confirmationResult) => {
          window.confirmationResult = confirmationResult;
        }
      );
    }

    if (numberRegex.test(cred)) {
      signInWithPhoneNumber(auth, `+91${cred}`, appVerifier).then(
        (confirmationResult) => {
          window.confirmationResult = confirmationResult;
        }
      );
    }
  }
  return userExist;
};

const verifyOtpAfterSignIn = async (otp: any) => {
  try {
    const userCred = await window.confirmationResult.confirm(otp);
    if (userCred) {
      return userCred;
    }
  } catch (error: any) {
    throw new Error(error.message);
  }
};

const verifyOtpAfterLogin = async (
  otp: any,
  credentials: any,
  partnerID: any
) => {
  try {
    const userCred = await window.confirmationResult.confirm(otp);
    if (userCred) {
      const usersRef = collection(db, "users");
      const userQueryEmail = query(usersRef, where("email", "==", credentials));
      const userQueryPhoneNumber = query(
        usersRef,
        where("phoneNumber", "==", credentials)
      );
      const [userQueryEmailSnapshot, userQueryPhoneNumberSnapshot] =
        await Promise.all([
          getDocs(userQueryEmail),
          getDocs(userQueryPhoneNumber),
        ]);
      const emailArray = userQueryEmailSnapshot.docs;
      const phoneNumberArray = userQueryPhoneNumberSnapshot.docs;
      const newArray = emailArray.concat(phoneNumberArray);
      newArray.forEach((doc) => {
        var obj = {
          authProvider: "local",
          name: doc.data().name,
          email: doc.data().email,
          phoneNumber: doc.data().phoneNumber,
          uid: userCred.user.uid,
        };
        var sessionStorageObj = {
          authProvider: "local",
          name: doc.data().name,
          email: doc.data().email,
          phoneNumber: doc.data().phoneNumber,
          uid: userCred.user.uid,
          channelOfSale: {
            "channelOfSale.ClubVistara": partnerID,
          },
        };
        updateDoc(doc.ref, obj);
        sessionStorage.setItem("email", doc.data()?.email);
        sessionStorage.setItem(
          "partnerUser",
          JSON.stringify(sessionStorageObj)
        );
      });
      return;
    }
  } catch (error: any) {
    throw new Error(error.message);
  }
};

const verifyOtpRegistration = async (otp: any, registrationData: any) => {
  try {
    const data = registrationData;
    window.confirmationResult
      .confirm(otp)
      .then((result: any) => {
        const user = result.user;
        const partnerUser = {
          authProvider: "local",
          name: data.name,
          email: data.email,
          phoneNumber: data.phoneNumber,
          channelOfSale: {
            "channelOfSale.ClubVistara": data.partnerID,
          },
          uid: user.uid,
        };
        sessionStorage.setItem("partnerUser", JSON.stringify(partnerUser));
        sessionStorage.setItem("email", partnerUser.email);
        getUserDocsFromEmail(data.email).then((res) => {
          if (res.docs.length === 0) {
            addDoc(collection(db, "users"), partnerUser);
          } else {
            res.forEach(async (doc) => {
              if (doc.exists()) {
                updateDoc(doc.ref, partnerUser);
              } else {
                addDoc(collection(db, "users"), partnerUser);
              }
            });
          }
        });
      })
      .catch((err: any) => console.log("err", err));
  } catch (error: any) {
    throw new Error(error.message);
  }
};

//Only for Testing
const getUserData = async () => {
  let userExist = false;
  const docRef = collection(db, "users");
  const docQuery = query(
    docRef,
    where("phoneNumber", "==", "9407518225"),
    where("email", "==", "amet1197@gmail.com")
  );

  await getDocs(docQuery).then((res) => {
    res.forEach((doc) => {
      if (doc.exists()) {
        userExist = doc.exists();
        console.log("User Found");
      }
    });
  });
  if (!userExist) {
    console.log("User Not Found");
  }
  return userExist;
};

const getEmailFromNumber = async (cred: string, password: any) => {
  let userEmail = "";
  await getUserDocsFromPhoneNumber(cred).then((res) => {
    res.forEach(async (doc) => {
      const userData = doc.data();
      userEmail = await userData.email;
    });
  });
  return userEmail;
};

const getNumberFromEmail = async (cred: string) => {
  let userNumber = "";
  await getUserDocsFromEmail(cred).then((res) => {
    res.forEach(async (doc) => {
      const userData = doc.data();
      userNumber = await userData.phoneNumber;
    });
  });
  return userNumber;
};

async function getEmailFromNumberAndSendVerificationLink(cred: string) {
  let response = false;

  await getUserDocsFromPhoneNumber(cred).then((res) => {
    res.forEach(async (doc) => {
      const userData = doc.data();
      if (doc.exists()) {
        response = true;
        await sendPasswordReset(userData.email).then((res) => {
          if (res) {
            response = doc.exists();
          }
        });
      }
    });
  });
  return response;
}

// function validateAndSendVerificationLink(cred: string) {
//   /* Phone Test: When the User Enters Phone Number*/
//   const numberRegex = new RegExp(regexData.mobileNumber);
//   if (numberRegex.test(cred)) {
//     getEmailFromNumberAndSendVerificationLink(cred);
//     return 0;
//   }

//   /* Email Test: When the User Enters Email*/
//   const emailRegex = new RegExp(regexData.email);
//   if (emailRegex.test(cred)) {
//     sendPasswordReset(cred);
//     return 1;
//   }
//   return 2;
// }

//shall be called on verify number button
const verifyPhoneNumber = async (
  otp: any,
  name: any,
  email: any,
  password: any,
  phoneNumber: any
) => {
  try {
    //credential create
    const userCred = await window.confirmationResult.confirm(otp);
    if (userCred) {
      const credential = EmailAuthProvider.credential(email, password);

      updateProfile(userCred.user, { displayName: name });

      linkWithCredential(userCred.user, credential);

      addDoc(collection(db, "users"), {
        uid: auth.currentUser?.uid,
        name,
        authProvider: "local",
        email,
        phoneNumber,
      });
      return;
    }
  } catch (error: any) {
    throw new Error(error.message);
  }
};

const regsterUserFirebase = async (data: any) => {
  const usersRef = collection(db, "users");
  const userQueryEmail = query(usersRef, where("email", "==", data.email));
  const userQueryPhoneNumber = query(
    usersRef,
    where("phoneNumber", "==", data.phoneNumber)
  );
  const [userQueryEmailSnapshot, userQueryPhoneNumberSnapshot] =
    await Promise.all([getDocs(userQueryEmail), getDocs(userQueryPhoneNumber)]);
  const emailArray = userQueryEmailSnapshot.docs;
  const phoneNumberArray = userQueryPhoneNumberSnapshot.docs;
  const newArray = emailArray.concat(phoneNumberArray);
  return newArray;
};

const checkLoginCredential = async (email: any) => {
  const usersRef = collection(db, "users");
  const userQueryEmail = query(usersRef, where("email", "==", email));
  const [userQueryEmailSnapshot] = await Promise.all([getDocs(userQueryEmail)]);
  const emailArray = userQueryEmailSnapshot.docs;
  return emailArray;
};

const addDocFirestore = (cred: any) => {
  addDoc(collection(db, "users"), cred);
};

const sendPasswordReset = async (email: any) => {
  try {
    await sendPasswordResetEmail(auth, email, {
      url: window.location.origin + "/login",
    }).catch((err) => {
      throw new Error(err.message);
    });
    return true;
  } catch (err: any) {
    throw new Error(err.message);
  }
};

const resetPassword = async (oobCode: string, newPassword: string) => {
  try {
    await verifyPasswordResetCode(auth, oobCode).then((email) => {
      confirmPasswordReset(auth, oobCode, newPassword).then((response) => {
        logInWithEmailAndPassword(email, newPassword);
      });
    });
    return true;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

const logout = () => {
  signOut(auth);
};

const guestSignIn = async () => {
  await signInAnonymously(auth);
};

//FIREBASE-STORAGE
const uploadImageToStorage = async (file: any, user: any) => {
  let downloadUrl = "";
  const userProfileRef = ref(storage, "userPhotoUrl/" + user.uid + ".png");
  try {
    const snapshot = await uploadBytes(userProfileRef, file).then(
      async (uploadRes) => {
        const photoURL = await getDownloadURL(userProfileRef).then((url) => {
          updateProfile(user, { photoURL: url });
          downloadUrl = url;
        });
      }
    );
  } catch (error) {
    alert(error);
  }
  return downloadUrl;
};

//FIREBASE FIRESTORE
const getUserDetailsFromFirestore = () => {
  const usersRef = collection(db, "users");
  const userQuery = query(usersRef, where("uid", "==", auth.currentUser?.uid));
  return getDocs(userQuery);
};

const getUserDocsFromPhoneNumber = (cred: string) => {
  const usersRef = collection(db, "users");
  const userQuery = query(usersRef, where("phoneNumber", "==", cred));
  return getDocs(userQuery);
};

const getUserDocsFromEmail = (cred: string) => {
  const usersRef = collection(db, "users");
  const userQuery = query(usersRef, where("email", "==", cred));
  return getDocs(userQuery);
};

const saveUserDetailsToFirestore = (cred: any) => {
  getUserDocsFromEmail(cred.email).then((res) => {
    res.forEach((doc) => {
      if (doc.exists()) {
        updateDoc(doc.ref, cred);
      }
    });
  });
};

export {
  auth,
  db,
  logInWithEmailAndPassword,
  registerWithPhoneAndOTP,
  verifyPhoneNumber,
  sendPasswordReset,
  logout,
  resetPassword,
  getUserDetailsFromFirestore,
  saveUserDetailsToFirestore,
  uploadImageToStorage,
  getEmailFromNumber,
  getUserData,
  getEmailFromNumberAndSendVerificationLink,
  guestSignIn,
  signInUsingOtp,
  verifyOtpAfterSignIn,
  getNumberFromEmail,
  regsterUserFirebase,
  registerUsingPhoneNumber,
  verifyOtpRegistration,
  verifyOtpAfterLogin,
  addDocFirestore,
  checkLoginCredential,
};
