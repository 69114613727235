import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { Bars } from "react-loader-spinner";
import {
  auth,
  logInWithEmailAndPassword,
  signInUsingOtp,
  verifyOtpAfterSignIn,
  getNumberFromEmail,
} from "../../helpers/firebase";
import regexData from "../../constants/regexData";
import { RecaptchaVerifier } from "firebase/auth";
import "../login/Login.scss";
import ReactGA from "react-ga4";
import { getAnalyticsEnabled } from "../../helpers/backend_helper";
import ReactPixel from "react-facebook-pixel";
import {
  createCustomerProfile,
  updateCustomerProfile,
} from "../../store/customer/actions";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { guestSignIn } from "../../helpers/firebase";

type Stored = {
  title: string;
  path: string;
};

function CustomItenaryLogin(props: any) {
  const { handler, email, isAuth } = props;
  const dispatch = useDispatch();
  const [credentials, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPasswordText, setShowPasswordText] = useState(false);
  const [expandForm, setExpandForm] = useState(false);
  const [showPasswordField, setShowPasswordField] = useState(false);
  const [counter, setCounter] = useState(0);
  const [otp, setOtp] = useState("");
  const [appVerified, setAppVerified] = useState(false);
  const [otpTry, setOtpTry] = useState(0);
  const [numberSentTo, setNumberSentTo] = useState("");
  const [showLoadingAnim, setShowLoadingAnim] = useState(false);

  const emailRef = useRef<HTMLInputElement>(null);
  const emailContainerRef = useRef<HTMLDivElement>(null);
  const emailErrorRef = useRef<HTMLParagraphElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const passwordContainerRef = useRef<HTMLDivElement>(null);
  const passwordErrorRef = useRef<HTMLParagraphElement>(null);
  const loginErrorRef = useRef<HTMLParagraphElement>(null);
  const otpErrorRef = useRef<HTMLParagraphElement>(null);

  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  const { customerProfileResponse, updateCustomerProfileResponse } =
    useSelector((state: any) => state.CustomerProfileReducer);

  // no need to set title bcz this is dialog box login flow
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   document.title = "Login | Voyaah";
  //   ReactPixel.fbq("init", process.env.REACT_APP_FBPIXEL_ID);
  //   ReactPixel.fbq("track", "PageView");
  // }, []);

  useEffect(() => {
    let timer = setInterval(() => {}, 1000);
    if (counter > 0) {
      timer = setInterval(() => setCounter(counter - 1), 1000);
    }
    // counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  useEffect(() => {
    if (customerProfileResponse !== undefined) {
      const customerTracker = localStorage.getItem("voyaahCustomerTracker");
      const customerConsentStore = localStorage.getItem(
        "voyaahCustomerConsent"
      );

      if (!customerTracker) {
        const customerProfile = {
          id: customerProfileResponse,
        };

        localStorage.setItem(
          "voyaahCustomerTracker",
          JSON.stringify(customerProfile)
        );
      }

      if (!customerConsentStore) {
        const customerConsent = {
          essentialCookieEnabled: true,
          analyticsCookieEnabled: true,
          personalizationCookieEnabled: true,
        };

        localStorage.setItem(
          "voyaahCustomerConsent",
          JSON.stringify(customerConsent)
        );
      }
    }

    if (updateCustomerProfileResponse !== undefined) {
      localStorage.removeItem("voyaahCustomerTracker");

      const customerProfile = {
        id: updateCustomerProfileResponse,
      };

      localStorage.setItem(
        "voyaahCustomerTracker",
        JSON.stringify(customerProfile)
      );

      // if (user) {
      //   const storedValue: any = localStorage.getItem(
      //     "voyaahCustomerLoggedInFromDifferentPages"
      //   );
      //   if (storedValue !== null) {
      //     const parsedValue = JSON.parse(storedValue);
      //     // Check if parsedValue has the path property
      //     if (parsedValue && typeof parsedValue.path === "string") {
      //       navigate(`/${parsedValue.path}`);
      //       localStorage.removeItem("voyaahCustomerLoggedInFromDifferentPages");
      //     }
      //   } else {
      //     navigate("/");
      //   }
      // }
    }
    if (loading) return;
  }, [
    customerProfileResponse,
    updateCustomerProfileResponse,
    loading,
    user,
    navigate,
  ]);

  useEffect(() => {
    let customerTracker = localStorage.getItem("voyaahCustomerTracker");
    if (customerTracker == null && !loading) {
      const consentTracker = {
        customerProfileId: null,
        accountId: user?.uid ?? null,
        essentialConsent: true,
        analyticsConsent: true,
        personalizationConsent: true,
      };

      dispatch(createCustomerProfile(consentTracker));
    }
  }, [dispatch, loading]);

  useEffect(() => {
    window.appVerifier = new RecaptchaVerifier(auth, "recaptcha-container", {
      size: "invisible",
      callback: (response: any) => {},
      "expired-callback": () => {
        setAppVerified(false);
      },
    });
    if (!appVerified) {
      window.appVerifier.verify();
    }
    return () => {
      window.appVerifier.clear();
    };
  }, [appVerified]);

  const clearError = () => {
    emailContainerRef?.current?.classList.remove("error");
    emailErrorRef?.current?.classList.remove("show");
    passwordContainerRef?.current?.classList.remove("error");
    passwordErrorRef?.current?.classList.remove("show");
    loginErrorRef?.current?.classList.remove("show");
  };

  const validateDetailsAndLoginWithPassword = () => {
    clearError();

    switch (true) {
      case !password:
        passwordContainerRef?.current?.classList.add("error");
        passwordErrorRef?.current?.classList.add("show");
        if (passwordErrorRef?.current?.innerHTML) {
          passwordErrorRef.current.innerHTML = "Enter password";
        }
        passwordRef?.current?.focus();
        break;

      default:
        logInWithEmailAndPassword(credentials, password)
          .then((data: any) => {
            const localStorageData = localStorage.getItem(
              "voyaahCustomerTracker"
            );
            let customerProfileId = "";
            if (localStorageData) {
              customerProfileId = JSON.parse(localStorageData!).id;
            }

            const updateLoginObj = {
              accountId: data.user.uid,
              customerProfileId: customerProfileId,
            };
            handler({ closeDialog: false, userInfo: data.user });
            localStorage.setItem("voyaahCustomerLoggedIn", "true");
            dispatch(updateCustomerProfile(updateLoginObj));
          })
          .catch((err) => {
            if (err.message === "Firebase: Error (auth/user-not-found).") {
              loginErrorRef?.current?.classList.add("show");
              if (loginErrorRef?.current?.innerHTML) {
                loginErrorRef.current.innerHTML = "You aren't registered yet.";
              }
            } else if (
              err.message === "Firebase: Error (auth/wrong-password)."
            ) {
              loginErrorRef?.current?.classList.add("show");
              if (loginErrorRef?.current?.innerHTML) {
                loginErrorRef.current.innerHTML =
                  "You entered Wrong Credentials";
              }
            } else if (
              err.message ===
              "Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests)."
            ) {
              loginErrorRef?.current?.classList.add("show");
              if (loginErrorRef?.current?.innerHTML) {
                loginErrorRef.current.innerHTML =
                  "We are unable to authenticate. Please try after sometime.";
              }
            } else {
              loginErrorRef?.current?.classList.add("show");
              if (loginErrorRef?.current?.innerHTML) {
                loginErrorRef.current.innerHTML = err.message;
              }
            }
          });
        break;
    }
  };

  const handleOtpRequest = () => {
    const numberRegex = new RegExp(regexData.mobileNumberCompact);
    const emailRegex = new RegExp(regexData.email);

    clearError();
    switch (true) {
      case !credentials:
        emailContainerRef?.current?.classList.add("error");
        emailErrorRef?.current?.classList.add("show");
        if (emailErrorRef?.current?.innerHTML) {
          emailErrorRef.current.innerHTML = "Enter your credentials";
        }
        emailRef?.current?.focus();
        break;

      case !numberRegex.test(credentials) && !emailRegex.test(credentials):
        emailContainerRef?.current?.classList.add("error");
        emailErrorRef?.current?.classList.add("show");
        if (emailErrorRef?.current?.innerHTML) {
          emailErrorRef.current.innerHTML = "Enter a valid credentials.";
        }
        emailRef?.current?.focus();
        break;

      default:
        //Sign in using otp
        getUserPhoneNumber();
        setShowLoadingAnim(true);
        signInUsingOtp(credentials, window.appVerifier)
          .then((userExist) => {
            setShowLoadingAnim(false);
            if (!userExist) {
              loginErrorRef?.current?.classList.add("error");
              loginErrorRef?.current?.classList.add("show");
              if (loginErrorRef?.current?.innerHTML) {
                loginErrorRef.current.innerHTML =
                  "You aren't registered yet...";
              }
            } else if (userExist) {
              setExpandForm(true);
              setCounter(60);
            }
          })
          .catch((error) => {
            setShowLoadingAnim(false);
            alert(error.message);
          });
        break;
    }
  };

  const goBack = () => {
    setCounter(0);
    setExpandForm(false);
  };

  function onVerifyOtpSubmit() {
    clearError();
    if (otp === "") {
      otpErrorRef?.current?.classList.add("error");
      otpErrorRef?.current?.classList.add("show");
      if (otpErrorRef?.current?.innerHTML) {
        otpErrorRef.current.innerHTML =
          "Please enter OTP you received on provided number.";
      }
    } else {
      verifyOtpAfterSignIn(otp)
        .then((userCred) => {
          const localStorageData = localStorage.getItem(
            "voyaahCustomerTracker"
          );
          let customerProfileId = "";
          if (localStorageData) {
            customerProfileId = JSON.parse(localStorageData!).id;
          }

          const updateLoginObj = {
            accountId: userCred.user.uid,
            customerProfileId: customerProfileId,
            email: userCred?.user?.email,
          };
          handler({ closeDialog: false, userInfo: userCred.user });
          localStorage.setItem("voyaahCustomerLoggedIn", "true");
          dispatch(updateCustomerProfile(updateLoginObj));
        })
        .catch(function (error) {
          if (
            error.message ===
            "Firebase: Error (auth/invalid-verification-code)."
          ) {
            otpErrorRef?.current?.classList.add("error");
            otpErrorRef?.current?.classList.add("show");
            if (otpErrorRef?.current?.innerHTML) {
              otpErrorRef.current.innerHTML = "Invalid Code";
            }
          }
        });
    }
  }

  const resendOTP = () => {
    setAppVerified(false);
    if (window.appVerifier && otpTry < 3) {
      setCounter(30);
      signInUsingOtp(credentials, window.appVerifier);
    } else {
      otpErrorRef?.current?.classList.add("error");
      otpErrorRef?.current?.classList.add("show");
      if (otpErrorRef?.current?.innerHTML) {
        otpErrorRef.current.innerHTML =
          "Please try signing up with different phone number or email ID.";
        setExpandForm(false);
        setOtpTry(0);
      }
    }
  };

  const handleSignInWithPassword = () => {
    const emailRegex = new RegExp(regexData.email);
    const numberRegex = new RegExp(regexData.mobileNumberCompact);

    clearError();

    switch (true) {
      case !credentials:
        emailContainerRef?.current?.classList.add("error");
        emailErrorRef?.current?.classList.add("show");
        if (emailErrorRef?.current?.innerHTML) {
          emailErrorRef.current.innerHTML = "Enter your credentials";
        }
        emailRef?.current?.focus();
        break;

      case !emailRegex.test(credentials) && !numberRegex.test(credentials):
        emailContainerRef?.current?.classList.add("error");
        emailErrorRef?.current?.classList.add("show");
        if (emailErrorRef?.current?.innerHTML) {
          emailErrorRef.current.innerHTML = "Enter valid credentials";
        }
        emailRef?.current?.focus();
        break;

      default:
        setShowPasswordField(!showPasswordField);
        break;
    }
  };

  const getUserPhoneNumber = async () => {
    const emailRegex = new RegExp(regexData.email);
    const numberRegex = new RegExp(regexData.mobileNumberCompact);
    let phoneNumber = "";
    if (emailRegex.test(credentials)) {
      phoneNumber = await getNumberFromEmail(credentials);
      // phoneNumber = await getEmailFromNumber(credentials, "")
    }

    if (numberRegex.test(credentials)) {
      phoneNumber = credentials;
    }

    setNumberSentTo(phoneNumber.substring(6, 10));
    return phoneNumber;
  };

  const gotoRegistrationPage = () => {
    if (getAnalyticsEnabled()) {
      ReactGA.event({
        action: `sign_up`,
        category: `login_page`,
      });
    }
  };

  const signInasGuestEmail = () => {
    sessionStorage.setItem("email", email);
  };

  const renderCheckoutGuestText = () => {
    return (
      <div style={{ textAlign: "center" }}>
        <span
          style={{
            cursor: "pointer",
            color: "#67E1F2",
            fontWeight: "500",
            fontSize: "13px",
            letterSpacing: "1px",
          }}
          onClick={() => {
            guestSignIn();
            signInasGuestEmail();
          }}
        >
          Checkout as Guest
        </span>
      </div>
    );
  };

  return (
    <>
      <div id="recaptcha-container" />
      <div
        className="login-container"
        style={{ padding: "0px", width: "100%", margin: 0, maxWidth: "100%" }}
      >
        <div className="login-text new-txt" style={{ fontSize: "22px" }}>
          Don’t have an account?{" "}
          <a
            onClick={() => gotoRegistrationPage()}
            href="/register"
            target="_blank"
            rel="noopener noreferrer"
          >
            Sign up now
          </a>
        </div>
        <div className="head" style={{ color: "#fff" }}>
          Login
        </div>
        {!expandForm && (
          <div>
            {!showPasswordField && (
              <div className="form">
                <div className="form-fields" ref={emailContainerRef}>
                  <label style={{ color: "#fff" }}>Email or Phone Number</label>
                  <input
                    ref={emailRef}
                    type="text"
                    placeholder="Enter Email or Phone Number"
                    value={credentials}
                    maxLength={50}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    id="customItinaryLogin_txtEmailOrPhone"
                  />
                  <p className="error-msg" ref={emailErrorRef}>
                    Error Text
                  </p>
                </div>
                <div className="form-fields no-bg">
                  <input
                    type="button"
                    value="Sign In with Password"
                    className="green-btn-input login"
                    style={{ marginRight: "20px" }}
                    onClick={() => handleSignInWithPassword()}
                    id="customItinaryLogin_txtSignInPasswordBtn"
                  />
                  <input
                    type="button"
                    value="Sign In with OTP"
                    className="green-btn-input login"
                    onClick={() => handleOtpRequest()}
                    id="customItinaryLogin_txtSignInWithOtpBtn"
                  />
                </div>
                <div className="form-fields no-bg">
                  <p className="error-msg" ref={loginErrorRef}>
                    Error Text
                  </p>
                </div>
                <div className="form-fields no-bg">
                  <div className="left">
                    <input
                      type="checkbox"
                      name="remember"
                      className="checkbox"
                      id="customItinaryLogin_txtRememberMeCheckBox"
                    />{" "}
                    Remember me
                  </div>
                  <div className="right">
                    <a
                      href="/forgot-password"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Forgot Password
                    </a>
                  </div>
                </div>
                {/* <div className="form-fields no-bg">
                  <div className="right-align">
                    <p onClick={handleOtpRequest}>Sign-In using OTP</p>
                  </div>
                </div> */}
                {isAuth && renderCheckoutGuestText()}
              </div>
            )}
          </div>
        )}
        {!expandForm && (
          <div>
            {showPasswordField && (
              <div className="form">
                <div className="form-fields" ref={passwordContainerRef}>
                  <label style={{ color: "#fff" }}>Password</label>
                  <input
                    type={showPasswordText ? "text" : "password"}
                    placeholder="Enter Password"
                    value={password}
                    ref={passwordRef}
                    onChange={(e) => setPassword(e.target.value)}
                    id="customItinaryLogin_txtPassword"
                  />
                  <span className="icon">
                    <img
                      src={require("../../assets/images/eye-icon.png")}
                      alt=""
                      className="img-fluid"
                      onClick={() => setShowPasswordText(!showPasswordText)}
                    />
                  </span>
                  <p className="error-msg" ref={passwordErrorRef}>
                    Error Text
                  </p>
                </div>
                <div className="form-fields no-bg">
                  <p className="error-msg" ref={loginErrorRef}>
                    Error Text
                  </p>
                  <input
                    type="button"
                    value="Sign In"
                    className="green-btn"
                    onClick={() => validateDetailsAndLoginWithPassword()}
                    id="customItinaryLogin_txtSignInBtn"
                  />
                </div>
                <div className={isAuth ? "wrapper" : "wrapper-none"}>
                  {isAuth && renderCheckoutGuestText()}
                  <div className="form-fields no-bg">
                    <div className="right-align">
                      <p
                        onClick={() => setShowPasswordField(!showPasswordField)}
                      >
                        Change E-mail/Phone Number
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {expandForm && (
          <div>
            <div className="form-fields" ref={emailContainerRef}>
              <label style={{ color: "#fff" }}>
                OTP sent to number ending with {numberSentTo}.
              </label>
              <input
                ref={emailRef}
                type="text"
                placeholder="Enter OTP sent to your phone"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                required
                maxLength={6}
                id="customItinaryLogin_txtOTP"
              />
              <p className="error-msg" ref={emailErrorRef}>
                Error Text
              </p>
            </div>
            {/* <div className="form-fields no-bg">
                  <input
                    type="button"
                    value="Change Phone Number"
                    className="gray-btn mr-5"
                    onClick={goBack}
                  />
                </div> */}
            <div className="form-fields no-bg">
              <button
                className="resend-btn"
                onClick={resendOTP}
                disabled={counter > 0}
                color="green"
                id="customItinaryLogin_btnResendOtp"
                style={{ width: "100%", marginRight: "0px" }}
              >
                Resend OTP{" "}
                {counter > 0 && (
                  <span style={{ color: "green", fontWeight: "bold" }}>
                    in {counter} sec
                  </span>
                )}
              </button>
            </div>
            <div className="form-fields no-bg">
              <p className="error-msg" ref={otpErrorRef}>
                Error Text
              </p>
              <input
                type="button"
                value="Submit"
                className="green-btn-input"
                onClick={onVerifyOtpSubmit}
                id="customItinaryLogin_txtSubmitBtn"
              />
            </div>
            <div className={isAuth ? "wrapper" : "wrapper-none"}>
              {isAuth && renderCheckoutGuestText()}
              <div className="form-fields no-bg">
                <div className="right-align">
                  <p onClick={goBack}>Change E-mail/Phone Number</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {showLoadingAnim && (
        <div className="loader-container">
          <div className="loader">
            <Bars color="#00BFFF" height={50} width={100} />
          </div>
        </div>
      )}
      <ToastContainer />
    </>
  );
}

export default CustomItenaryLogin;
