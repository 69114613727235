import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "underscore";
import { Bars } from "react-loader-spinner";
import ReactPixel from "react-facebook-pixel";

import {
  getBookingList,
  updateBookingStatus,
  clearBookingStatus,
} from "../../store/booking/actions";
import { Pagination } from "../../components/pagination-tray/Pagination";
import MyBookingCard from "./MyBookingCard";
import "./MyBooking.scss";

function MyBooking() {
  let dispatch = useDispatch();
  const [bookingListData, setBookingListData] = useState<any[]>([]);
  const [showPopup, setShowPopup] = useState(false);
  const [popupTitle, setPopupTitle] = useState("");
  const [popupMessage, setPopupMessage] = useState("");
  const [type, setType] = useState("");
  const [orderId, setOrderId] = useState("");
  const [showConformation, setShowConformation] = useState(false);
  const [showOk, setShowOk] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const { bookingList, bookingLoader, bookingStatusResponse } = useSelector(
    (state: any) => state.BookingDetailsReducer
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactPixel.fbq("init", process.env.REACT_APP_FBPIXEL_ID);
    ReactPixel.fbq("track", "PageView");
  }, []);

  useEffect(() => {
    dispatch(getBookingList(currentPageNumber));
  }, [dispatch, currentPageNumber]);

  useEffect(() => {
    let newBookingListData: any[] = [];
    _.each(bookingList?.bookingList, function (a) {
      if (new Date(a.travelStartDate) > new Date("2022-10-23")) {
        newBookingListData.push(a);
      }
    });
    setBookingListData(bookingList?.bookingList);
  }, [bookingList, currentPageNumber]);

  const showCancelBookingPopup = (type: any, orderId: any) => {
    setType(type);
    setOrderId(orderId);
    setShowConformation(true);
    setShowOk(false);
    setPopupTitle("Cancel Booking");
    setPopupMessage("Are you sure you wish to cancel your booking?");
    setShowPopup(true);
  };

  const showModifyBookingPopup = (type: any, orderId: any) => {
    setType(type);
    setOrderId(orderId);
    setShowConformation(true);
    setShowOk(false);
    setPopupTitle("Modify Booking");
    setPopupMessage("Are you sure you wish to modify your booking?");
    setShowPopup(true);
  };

  const showContactUsPopup = (type: any, orderId: any) => {
    setType(type);
    setOrderId(orderId);
    setShowConformation(true);
    setShowOk(false);
    setPopupTitle("Contact Us");
    setPopupMessage("Do you wish to contact us regarding this booking?");
    setShowPopup(true);
  };

  const popupConfirmation = () => {
    setShowPopup(false);
    var data: any = {
      bookingId: orderId,
    };
    if (type === "cancel") {
      data["changedStatus"] = "bookingStatus.cancelled";
    } else if (type === "modify") {
      data["changedStatus"] = "bookingStatus.modified";
    }
    dispatch(updateBookingStatus(data));
  };

  const popupCancellation = () => {
    dispatch(clearBookingStatus());
    setShowPopup(false);
  };

  useEffect(() => {
    setShowConformation(false);
    setShowOk(true);
    setPopupTitle(`${type} Booking`);
    if (
      !_.isEmpty(bookingStatusResponse) &&
      bookingStatusResponse?.message === "message sent successfully"
    ) {
      setPopupMessage("A Travel SPOC will reach you within 24 hours.");
      setShowPopup(true);
      // dispatch(clearBookingStatus());
    } else if (
      !_.isEmpty(bookingStatusResponse) &&
      bookingStatusResponse?.message !== "message sent successfully"
    ) {
      setPopupMessage(
        "Sorry, we couldn't process your request for now. Please try after sometime."
      );
      setShowPopup(true);
      // dispatch(clearBookingStatus());
    }
  }, [bookingStatusResponse]);

  const handlePageChange = (action: any) => {
    window.scrollTo({
      top: 0,
    });
    sessionStorage.removeItem("scrollPosition");
    let _pNumb = currentPageNumber;
    switch (true) {
      case action === "prev":
        setCurrentPageNumber(currentPageNumber - 1);
        _pNumb--;
        break;
      case action === "next":
        setCurrentPageNumber(currentPageNumber + 1);
        _pNumb++;
        break;
      case !isNaN(action):
        setCurrentPageNumber(action);
        _pNumb = action;
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="my-account-content">
        <div className="my-booking-blk">
          {bookingListData !== undefined &&
            bookingListData.length > 0 &&
            bookingListData?.map((item: any, index: number) => {
              return (
                <MyBookingCard
                  key={`booking-card-${index}`}
                  item={item}
                  showCancelBookingPopup={showCancelBookingPopup}
                  showModifyBookingPopup={showModifyBookingPopup}
                  showContactUsPopup={showContactUsPopup}
                />
              );
            })}
          {bookingListData !== undefined && bookingListData.length === 0 && (
            <div className="info-txt">No record found</div>
          )}
        </div>
        {/* {bookingListData.length > 0 && (
          <div className="info-txt booking">That's all for now</div>
        )} */}

        {bookingListData !== undefined && bookingListData.length > 0 && (
          <div>
            <Pagination
              urlQueryParams={""}
              pathName={""}
              onPageChange={handlePageChange}
              currentPage={currentPageNumber}
              totalRecords={bookingList.recordCount}
              listCount={bookingList.pageSize}
              isPathsEnabled={false}
            />
          </div>
        )}

        {showPopup && (
          <div className="pop-overlay">
            <div className="popup">
              <div className="heading">
                {popupTitle}
                <span>
                  <img
                    src={require("../../assets/images/cancel.png")}
                    alt=""
                    className="img-fluid"
                    onClick={() => setShowPopup(false)}
                  />
                </span>
              </div>
              <div className="description">
                <p>{popupMessage}</p>
                {showConformation && (
                  <>
                    <div
                      className="button mr10"
                      onClick={() => popupConfirmation()}
                    >
                      Yes
                    </div>
                    <div className="button" onClick={() => popupCancellation()}>
                      No
                    </div>
                  </>
                )}
                {showOk && (
                  <>
                    <div className="button" onClick={() => popupCancellation()}>
                      Ok
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      {bookingLoader && (
        <div className="loader-container">
          <div className="loader">
            <Bars color="#00BFFF" height={50} width={100} />
          </div>
        </div>
      )}
    </>
  );
}

export default MyBooking;
