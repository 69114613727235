import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import TestimonialCard from "./testimonial-card/TestimonialCard";

import "./TestimonialLayout.scss";

function TestimonialLayout(props: any) {
  const { data } = props;
  const [stagePadding, setStagePadding] = useState(0);
  const [dataArr, setDataArr] = useState<any>([]);

  useEffect(() => {
    if(data?.folder.files !== 0) {
    data.folder.files.sort((a: any, b: any) => {
      if(a.displayIndex !== null) {
        var value;
        value = (a.displayIndex > b.displayIndex) ? 1 : (a.displayIndex < b.displayIndex) ? -1 : 0;
        return value;
    }
    });
    setDataArr(data);
  }
  }, [data]);
  useEffect(() => {
    const screenWidth = window.innerWidth;
    if (screenWidth > 320 && screenWidth < 767) {
      setStagePadding(70);
    } else if (screenWidth > 767) {
      setStagePadding(300);
    }
  }, []);
  return (
    <div className="testimonial-section">
      {dataArr.length !== 0 && dataArr?.folder?.files?.length !== 0 &&
      <>
        <h2 className="heading">{dataArr.collectionType}</h2>
        <OwlCarousel
          className="owl-theme"
          items={1}
          margin={20}
          loop
          stagePadding={stagePadding}
        >
          {dataArr.folder.files.map((item: any, index: number) => {
            return <TestimonialCard key={index} data={item} folderUrl={dataArr.folder.url} />;
          })}
        </OwlCarousel>
      </>
      }
    </div>
  );
}

export default TestimonialLayout;
