import React, { useEffect, useState } from "react";
import _ from "underscore";

import "./Details.scss";

function Inclusion(props: any) {
  const { inclusions } = props;

  const [showListCount, setShowListCount] = useState(5);
  const [showViewMore, setShowViewMore] = useState(false);
  const [showMoreText, setShowMoreText] = useState("");

  useEffect(() => {
    if (_.size(inclusions) > 5) {
      setShowViewMore(true);
      const text = `+ ${_.size(inclusions) - showListCount} More`;
      setShowMoreText(text);
    }
  }, [inclusions]);

  const updateListCount = () => {
    const listSize = _.size(inclusions);
    setShowListCount(listSize);
    setShowViewMore(false);
  };

  return (
    <>
      {inclusions && (
        <>
          <h2 className="head">Inclusion</h2>
          <ul>
            {inclusions
              ?.slice(0, showListCount)
              ?.map((item: any, index: number) => {
                return <li key={`package_inclusion_${index}`}>{item.name}</li>;
              })}
          </ul>
          {showViewMore && (
            <div
              className="inclusion-view-more"
              onClick={() => updateListCount()}
            >
              {showMoreText}
            </div>
          )}
        </>
      )}
    </>
  );
}

export default Inclusion;
