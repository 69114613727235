export const GET_BOOKING_DETAILS = "GET_BOOKING_DETAILS";
export const GET_BOOKING_DETAILS_SUCCESS = "GET_BOOKING_DETAILS_SUCCESS";
export const GET_BOOKING_DETAILS_FAIL = "GET_BOOKING_DETAILS_FAIL";
export const CLEAR_BOOKING_DETAILS = "CLEAR_BOOKING_DETAILS";
export const GET_BOOKING_LIST = "GET_BOOKING_LIST";
export const GET_BOOKING_LIST_SUCCESS = "GET_BOOKING_LIST_SUCCESS";
export const GET_BOOKING_LIST_FAIL = "GET_BOOKING_LIST_FAIL";
export const UPLOAD_BOOKING_DOCUMENT = "UPLOAD_BOOKING_DOCUMENT";
export const UPLOAD_BOOKING_DOCUMENT_SUCCESS = "UPLOAD_BOOKING_DOCUMENT_SUCCESS";
export const UPLOAD_BOOKING_DOCUMENT_FAIL = "UPLOAD_BOOKING_DOCUMENT_FAIL";
export const GET_CLIENT_IP = "GET_CLIENT_IP";
export const GET_CLIENT_IP_SUCCESS = "GET_CLIENT_IP_SUCCESS";
export const GET_CLIENT_IP_FAIL = "GET_CLIENT_IP_FAIL";
export const UPDATE_BOOKING_STATUS = "UPDATE_BOOKING_STATUS";
export const UPDATE_BOOKING_STATUS_SUCCESS = "UPDATE_BOOKING_STATUS_SUCCESS";
export const UPDATE_BOOKING_STATUS_FAIL = "UPDATE_BOOKING_STATUS_FAIL";
export const CLEAR_BOOKING_STATUS = "CLEAR_BOOKING_STATUS";
export const GET_PAYMENT_DETAILS = "GET_PAYMENT_DETAILS";
export const GET_PAYMENT_DETAILS_SUCCESS = "GET_PAYMENT_DETAILS_SUCCESS";
export const GET_PAYMENT_DETAILS_FAIL = "GET_PAYMENT_DETAILS_FAIL";