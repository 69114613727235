import React, { useEffect, useState } from "react";
import _ from "underscore";

import "./Details.scss";

function ItineraryCard(props: any) {
  const { item, index } = props;
  const [titleCharLimit] = useState(50);
  const [descriptionCharLimit] = useState(900);
  const [showViewMore, setShowViewMore] = useState(false);
  const [offsetHeight, setOffsetHeight] = useState<any>(0);

  useEffect(() => {
    const screenWidth = window.innerWidth;
    if (screenWidth > 767) {
      const contentHeight = document?.getElementById(
        `package_day_wise_itinerary_${index}`
      )?.offsetHeight;
      setOffsetHeight(contentHeight);
    }
  }, [showViewMore]);

  return (
    <div
      style={window.innerWidth > 767 ? styles.itineraryCard : {}}
      className="itinerary"
      key={`package_day_wise_itinerary_${index}`}
    >
      <div className="itinerary-image" style={{ height: offsetHeight + "px" }}>
        <div className="image-container">
          <img
            // src={require("../../assets/images/image15.png")}
            src={item?.imagePath || require("../../assets/images/image15.png")}
            alt=""
            className="img-fluid"
          />
        </div>
      </div>
      <div
        className="itinerary-content"
        id={`package_day_wise_itinerary_${index}`}
      >
        <h1 className="sub-head" style={{marginBottom:'8px'}}>{`Day ${index + 1}: ${item?.title}`}</h1>
        {/* {item?.title && (
          <div className="head">
            {item?.dayTitle?.length > titleCharLimit && (
              <>{item?.dayTitle?.substring(0, titleCharLimit) + "..."}</>
            )}
            {item?.dayTitle?.length < titleCharLimit && <>{item?.dayTitle}</>}
            {item?.title}
          </div>
        )} */}
        {item?.description?.length > descriptionCharLimit && (
          <>
            {!showViewMore && (
              <p
                className="text-ellipsis day-itinerary"
                dangerouslySetInnerHTML={{
                  __html:
                    item?.description?.substring(0, descriptionCharLimit) +
                    "...",
                }}
              />
            )}
            {showViewMore && (
              <p
                className="day-itinerary"
                dangerouslySetInnerHTML={{
                  __html: item?.description,
                }}
              />
            )}
            <p
              className={`note btn ${showViewMore ? "show-less" : ""}`}
              onClick={() => setShowViewMore(!showViewMore)}
            >{`${showViewMore ? "Show Less" : "Show More"} `}</p>
          </>
        )}
        {item?.description?.length < descriptionCharLimit && (
          <p
            className="day-itinerary"
            dangerouslySetInnerHTML={{
              __html: item?.description,
            }}
          />
        )}
        {item?.dayNote && <p className="note">{item?.dayNote}</p>}
      </div>
    </div>
  );
}

const styles = {
  itineraryCard: {
    display: "flex",
    alignItems: "center",
  },
};
export default ItineraryCard;
