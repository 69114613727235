import React, { useEffect, useState, useRef } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import ReactPixel from 'react-facebook-pixel';

import {
  auth,
  getEmailFromNumberAndSendVerificationLink,
  sendPasswordReset,
} from "../../helpers/firebase";
import "./ForgotPassword.scss";
import regexData from "../../constants/regexData";

function ForgotPassword() {
  const [cred, setCred] = useState("");
  const [user, loading] = useAuthState(auth);

  const credRef = useRef<HTMLInputElement>(null);
  const credContainerRef = useRef<HTMLDivElement>(null);
  const credErrorRef = useRef<HTMLParagraphElement>(null);
  const successRef = useRef<HTMLParagraphElement>(null);
  const errorRef = useRef<HTMLParagraphElement>(null);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Forgot Password | Voyaah";
    ReactPixel.fbq('init', process.env.REACT_APP_FBPIXEL_ID);
    ReactPixel.fbq('track', 'PageView');
  }, []);

  useEffect(() => {
    if (loading) return;
    if (user) navigate("/");
  }, [user, loading, navigate]);

  const clearError = () => {
    credContainerRef?.current?.classList.remove("error");
    credErrorRef?.current?.classList.remove("show");
    errorRef?.current?.classList.remove("show");
  };

  const validateCredentialsAndSendLink = () => {
    const numberRegex = new RegExp(regexData.mobileNumber);
    const emailRegex = new RegExp(regexData.email);

    clearError();

    switch (true) {
      case !cred || cred === "":
        credContainerRef?.current?.classList.add("error");
        credErrorRef?.current?.classList.add("show");
        if (credErrorRef?.current?.innerHTML) {
          credErrorRef.current.innerHTML = "Enter Credentials";
        }
        credRef?.current?.focus();
        break;

      case !emailRegex.test(cred) && !numberRegex.test(cred):
        credContainerRef?.current?.classList.add("error");
        credErrorRef?.current?.classList.add("show");
        if (credErrorRef?.current?.innerHTML) {
          credErrorRef.current.innerHTML = "Enter Valid Email/Phone Number";
        }
        credRef?.current?.focus();
        break;

      case emailRegex.test(cred):
        sendPasswordReset(cred)
          .then((res) => {
            if (res) {
              successRef?.current?.classList.add("show");
              if (successRef?.current?.innerHTML) {
                successRef.current.innerHTML =
                  "Link Sent to your registered email.";
              }
            }
          })
          .catch((err) => {
            if (err.message === "Firebase: Error (auth/user-not-found).") {
              errorRef?.current?.classList.add("show");
              if (errorRef?.current?.innerHTML) {
                errorRef.current.innerHTML = "No registered user found.";
              }
            }
          });
        break;

      case numberRegex.test(cred):
        getEmailFromNumberAndSendVerificationLink(cred).then((res) => {
          if (res) {
            successRef?.current?.classList.add("show");
            if (successRef?.current?.innerHTML) {
              successRef.current.innerHTML =
                "Link Sent to your registered email.";
            }
          } else {
            //User Not Found
            errorRef?.current?.classList.add("show");
            if (errorRef?.current?.innerHTML) {
              errorRef.current.innerHTML = "No registered user found.";
            }
          }
        });
        break;

      default:
        console.log("Unknown Error");
        break;
    }
  };

  return (
    <div className="login-section">
      <div className="section">
        <div className="heading">
          Discover the world in a<br /> new way
        </div>
        <div className="login-container">
          <div className="login-text">
            Don’t have an account? <Link to="/register">Sign up now</Link>
          </div>
          <div className="head">Forgot Password</div>
          <div className="form">
            <div className="form-fields" ref={credContainerRef}>
              <label>Email/ Phone Number</label>
              <input
                type="text"
                placeholder="Enter Registered Email/ Phone Number"
                value={cred}
                onChange={(e) => setCred(e.target.value)}
                ref={credRef}
              />
              <p className="error-msg" ref={credErrorRef}>
                Error Text
              </p>
            </div>
            <div className="form-fields no-bg">
              <input
                type="button"
                value="Reset"
                className="green-btn"
                onClick={() => validateCredentialsAndSendLink()}
              />
            </div>
          </div>
          <p className="success-msg" ref={successRef}>
            Success Text
          </p>
          <p className="error-msg" ref={errorRef}>
            Error Text
          </p>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
