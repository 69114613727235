import axios from "axios";

//apply base url for axios
const API_SERVER_URL = process.env.REACT_APP_BACKEND_URL;
const EXPERIENCE_API_URL = process.env.REACT_APP_NEW_BACKEND_URL;

const apiServerAxios = axios.create({
  baseURL: API_SERVER_URL,
});

axios.interceptors.request.use(function (config) {
  return config;
});

apiServerAxios.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export const experienceApiAxios = axios.create({
  baseURL: EXPERIENCE_API_URL,
});

experienceApiAxios.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export async function apiServerGet(url: any, config?: any) {
  return await apiServerAxios
    .get(url, {
      ...config,
    })
    .then((response) => response.data);
}

export async function apiServerPost(url: any, data: any, config?: any) {
  return await apiServerAxios
    .post(url, data, { ...config })
    .then((response) => response.data);
}

export async function experienceApiGet(url: any, config?: any) {
  return await experienceApiAxios
    .get(url, {
      ...config,
    })
    .then((response) => response.data);
}

export async function experienceApiPost(url: any, data: any, config?: any) {
  return await experienceApiAxios
    .post(url, data, { ...config })
    .then((response) => response.data);
}

export async function experienceApiPut(url: any, data?: any, config?: any) {
  return await experienceApiAxios
    .put(url, data, { ...config })
    .then((response) => response.data);
}
