import { useEffect, useState } from "react";
import makeAnimated from "react-select/animated";
import CreatableSelect from "react-select/creatable";
import {
  generateTimeSlots,
  haversineDistanceForCoOrds,
} from "src/helpers/utils";
import { OptionsOrGroups } from "react-select";
import itineraryPlanStore from "src/store/itineraryPlanStore";
import {
  Interests,
  PlaceToVisit,
  PlaceToVisitActivity,
} from "src/models/models";
import { LocationIcon } from "src/icons/coreIcons";

import GoogleMapComponent from "./GoogleMapComponent";
import { experienceApiAxios } from "src/helpers/api_helper";
import { DESTINATIONS } from "src/helpers/url_helper";
import { getHeader } from "src/helpers/backend_helper";

type waypoints = {
  lat: number;
  lng: number;
};

const ItineraryPlacesToVisitView = ({
  data,
  index,
  screenWidth,
}: {
  data: PlaceToVisit;
  index: number;
  screenWidth: number;
}) => {
  const timeSlots = generateTimeSlots();
  const [places, setPlaces] = useState<PlaceToVisit>(data);
  const [sectionOpen, setSectionOpen] = useState(true);
  const dropDownOptions: OptionsOrGroups<Interests, any> | undefined = [];

  const [errors, setErrors] = useState<any>({});
  const [mapCoords, setMapCoords] = useState<waypoints[]>([]);
  const [placesOfInterestData, setPlacesOfInterestData] = useState<any>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const headerResponse = await getHeader();
        const destinationPromises = places.cityList.map(async (city) => {
          const result = await experienceApiAxios.get(
            `${DESTINATIONS}/${city.cityId}`,
            {
              headers: headerResponse,
            }
          );

          if (result.status === 200) {
            return {
              ...city,
              placesToVisit: result.data.destinationData.placeOfInterest,
            };
          }
        });

        const fetchedDestinationPromises = await Promise.all(
          destinationPromises
        );

        setPlacesOfInterestData(fetchedDestinationPromises);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
    generateMap();
  }, [places]);

  const generateMap = () => {
    const externalCities: { lat: number; lng: number }[] = [];
    itineraryPlanStore.placesToVisit[index - 1].activities.forEach(
      (activity) => {
        activity.interests?.forEach((interest) => {
          if (interest.external) {
            const externalCity = {
              lat: parseFloat(interest.latitude!),
              lng: parseFloat(interest.longitude!),
            };

            externalCities.push(externalCity);
          }
        });
      }
    );

    setMapCoords(externalCities);
  };

  const customStyles = {
    multiValue: (styles: any, { data }: any) => {
      return {
        ...styles,
        backgroundColor: "#badade",
        borderRadius: "15px",
        paddingRight: "7px",
        paddingLeft: "7px",
      };
    },
    multiValueLabel: (styles: any, { data }: any) => {
      return {
        ...styles,
        color: "#000",
      };
    },
  };

  if (screenWidth < 700) {
    return (
      <div className="places-to-visit-section-resp">
        <div className="accordian-header-section">
          <div className="accordian-header">
            <img
              src={require("../../assets/images/disable-right.png")}
              className={`accordian-image ${sectionOpen ? "rotate" : ""}`}
              onClick={() => setSectionOpen(!sectionOpen)}
              alt="accordian-toggle"
            />
            {!itineraryPlanStore.placesToVisitEdit && (
              <h3 className="accordian-header-text">{places.name}</h3>
            )}
          </div>
        </div>
        {sectionOpen && places.activities.length > 0 && (
          <div className="places-to-visit-content-section">
            {places.activities.map(
              (place: PlaceToVisitActivity, activityIndex: number) => {
                return (
                  <div className="places-to-visit-div">
                    <div className="place-to-visit-item">
                      <div className="from-to-city-div-resp">
                        <div className="from-to-div">
                          <label className="timing-label">From/To: </label>
                          <div className="from-to-input-div">
                            <select
                              className="ptv-time-from"
                              value={place.from || ""}
                              disabled={true}
                            >
                              {timeSlots.map((time, index) => (
                                <option key={index} value={time}>
                                  {time}
                                </option>
                              ))}
                            </select>
                            <select
                              className="ptv-time-to"
                              value={place.to || ""}
                              disabled={true}
                            >
                              {timeSlots.map((time, index) => (
                                <option key={index} value={time}>
                                  {time}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="cities-div">
                          <label className="cities-label">
                            Places to visit:
                          </label>
                          <div className="city-input-div">
                            <select
                              className="ptv-city-list"
                              value={place.placeName || ""}
                              disabled={true}
                            >
                              <option key={null} value={""}>
                                Select a City
                              </option>
                              {itineraryPlanStore.visitDestinationList.map(
                                (city: any) => (
                                  <option
                                    value={city.cityName}
                                    key={city.cityId}
                                  >
                                    {city.cityName}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        </div>
                        <CreatableSelect
                          isMulti
                          className="places-of-interest-resp"
                          components={makeAnimated()}
                          value={place.interests}
                          styles={customStyles}
                          options={dropDownOptions}
                          isDisabled={!itineraryPlanStore.placesToVisitEdit}
                        />
                      </div>
                    </div>
                  </div>
                );
              }
            )}
            {mapCoords && mapCoords.length >= 2 && (
              <GoogleMapComponent waypoints={mapCoords} />
            )}
          </div>
        )}
        {}
        {sectionOpen && places.activities.length == 0 && <></>}
      </div>
    );
  } else {
    return (
      <div className="places-to-visit-section">
        <div className="accordian-header-section">
          <div className="accordian-header">
            <img
              src={require("../../assets/images/disable-right.png")}
              className={`accordian-image ${sectionOpen ? "rotate" : ""}`}
              onClick={() => setSectionOpen(!sectionOpen)}
              alt="accordian-toggle"
            />
            {!itineraryPlanStore.placesToVisitEdit && (
              <h3 className="accordian-header-text">{places.name}</h3>
            )}
          </div>
        </div>
        {sectionOpen && places.activities.length > 0 && (
          <div className="places-to-visit-content-section">
            {places.activities.map(
              (place: PlaceToVisitActivity, activityIndex: number) => {
                return (
                  <div className="places-to-visit-div">
                    <div className="place-to-visit-item">
                      <div className="from-to-city-div">
                        <div className="from-to-div">
                          <label className="timing-label">From/To: </label>
                          <div className="from-to-input-div">
                            <select
                              className="ptv-time-from"
                              value={place.from || ""}
                              disabled={true}
                            >
                              {timeSlots.map((time, index) => (
                                <option key={index} value={time}>
                                  {time}
                                </option>
                              ))}
                            </select>
                            <select
                              className="ptv-time-to"
                              value={place.to || ""}
                              disabled={true}
                            >
                              {timeSlots.map((time, index) => (
                                <option key={index} value={time}>
                                  {time}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="cities-div">
                          <label className="cities-label">
                            Places to visit:
                          </label>
                          <div className="city-input-div">
                            <select
                              className="ptv-city-list"
                              value={place.placeName || ""}
                              disabled={true}
                            >
                              <option key={null} value={""}>
                                Select a City
                              </option>
                              {itineraryPlanStore.visitDestinationList.map(
                                (city: any) => (
                                  <option
                                    value={city.cityName}
                                    key={city.cityId}
                                  >
                                    {city.cityName}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="places-of-interest-div-view">
                        <CreatableSelect
                          isMulti
                          className="places-of-interest"
                          components={makeAnimated()}
                          value={place.interests}
                          styles={customStyles}
                          options={dropDownOptions}
                          isDisabled={!itineraryPlanStore.placesToVisitEdit}
                        />
                      </div>
                    </div>
                  </div>
                );
              }
            )}
            {placesOfInterestData && placesOfInterestData.length >= 1 && (
              <div>
                {placesOfInterestData.map((placeOfInterestObj: any) => {
                  if (
                    placeOfInterestObj.placesToVisit &&
                    itineraryPlanStore.placesToVisitEdit &&
                    placeOfInterestObj.placesToVisit.length > 0
                  ) {
                    return (
                      <div className="popular-destinations-div">
                        <h3>
                          Popular destinations in {placeOfInterestObj.cityName}
                        </h3>
                        <div className="places-options">
                          {placeOfInterestObj.placesToVisit.map(
                            (placeObj: any, index: number) => {
                              if (index < 5) {
                                return (
                                  <div className="place-option">
                                    <LocationIcon />
                                    <div className="place-name-option">
                                      <p className="place-name-text">
                                        {placeObj.name}
                                      </p>
                                      <p className="place-distance-text">
                                        {Math.ceil(
                                          haversineDistanceForCoOrds(
                                            placeOfInterestObj.city.latitude,
                                            placeOfInterestObj.city.longitude,
                                            placeObj.latitude,
                                            placeObj.longitude
                                          )
                                        )}{" "}
                                        kms from {placeOfInterestObj.cityName}
                                      </p>
                                    </div>
                                  </div>
                                );
                              }
                            }
                          )}
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            )}
            {mapCoords && mapCoords.length >= 2 && (
              <GoogleMapComponent waypoints={mapCoords} />
            )}
          </div>
        )}
        {}
        {sectionOpen && places.activities.length == 0 && <></>}
      </div>
    );
  }
};

export default ItineraryPlacesToVisitView;
