import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import ReactGA from "react-ga4";
import { getAnalyticsEnabled } from "../helpers/backend_helper";
import HeroSection from "../components/hero-section/HeroSection";
import VoyaahPromises from "../components/voyaah-promises/VoyaahPromises";
import CardTray from "../components/card-tray/CardTray";
import DiscoverTray from "../components/discover-tray/DiscoverTray";
import VideoTray from "../components/video-tray/VideoTray";
import PackageTray from "../components/package-tray/PackageTray";
import DiscoverInternationalTray from "../components/discover-international-tray/DiscoverInternationalTray";
import TestimonialLayout from "../components/testimonial-layout/TestimonialLayout";
import { getHomeData } from "../store/home/actions";
import { Helmet } from "react-helmet";
import ReactPixel from "react-facebook-pixel";
import { createCustomerProfile } from "../store/customer/actions";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../helpers/firebase";

export default function Home() {
  let dispatch = useDispatch();
  const location = useLocation();
  const screenWidth = window.innerWidth;
  const [user, loading] = useAuthState(auth);

  const [metaTags, setMetaTags] = useState<any[]>([]);
  const [breadcrumbs, setBreadcrumbs] = useState<any[]>([]);

  const { customerProfileResponse } = useSelector(
    (state: any) => state.CustomerProfileReducer
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactPixel.fbq("init", process.env.REACT_APP_FBPIXEL_ID);
    ReactPixel.fbq("track", "PageView");
  }, []);

  useEffect(() => {
    if (customerProfileResponse != undefined) {
      const customerTracker = localStorage.getItem("voyaahCustomerTracker");
      const customerConsentStore = localStorage.getItem(
        "voyaahCustomerConsent"
      );

      if (!customerTracker) {
        const customerProfile = {
          id: customerProfileResponse,
        };

        localStorage.setItem(
          "voyaahCustomerTracker",
          JSON.stringify(customerProfile)
        );
      }

      if (!customerConsentStore) {
        const customerConsent = {
          essentialCookieEnabled: true,
          analyticsCookieEnabled: true,
          personalizationCookieEnabled: true,
        };

        localStorage.setItem(
          "voyaahCustomerConsent",
          JSON.stringify(customerConsent)
        );
      }
    }
  }, [customerProfileResponse]);

  useEffect(() => {
    let params = new URLSearchParams(location.search);
    let customerTracker = localStorage.getItem("voyaahCustomerTracker");
    if (customerTracker == null && !loading) {
      const consentTracker = {
        customerProfileId: null,
        accountId: user?.uid ?? null,
        essentialConsent: true,
        analyticsConsent: true,
        personalizationConsent: true,
      };

      dispatch(createCustomerProfile(consentTracker));
    }
    const trackingId = params.get("tracking_id");
    if (trackingId && getAnalyticsEnabled()) {
      ReactGA.event({
        action: "tracking_" + trackingId,
        category: "home_page",
      });
    }
  }, [location, dispatch, loading]);

  useEffect(() => {
    const localStorageData = localStorage.getItem("voyaahCustomerTracker");
    let customerProfileId = "";
    if (localStorageData) {
      customerProfileId = JSON.parse(localStorageData!).id;
    }
    if (!loading) {
      dispatch(getHomeData(customerProfileId, user?.uid ?? ""));
    }
  }, [dispatch, loading]);

  const { homeData, loader } = useSelector(
    (state: any) => state.HomeDataReducer
  );

  function formatMetatagsForPackage(metaTags: any) {
    const metaTagArray: any[] = [];
    metaTags.forEach((metaTag: { tag: any; value: any }) => {
      const metaTagObject = { key: metaTag.tag, value: metaTag.value };
      metaTagArray.push(metaTagObject);
    });

    setMetaTags(metaTagArray);
  }

  function formatBreadcrumbsForPackage(breadcrumbs: any) {
    const breadcrumbsArray: any[] = [];
    breadcrumbs.forEach((breadcrumb: { name: any; url: any }) => {
      const breadcrumbObject = { name: breadcrumb.name, url: breadcrumb.url };
      breadcrumbsArray.push(breadcrumbObject);
    });

    setBreadcrumbs(breadcrumbsArray);
  }

  useEffect(() => {
    if (homeData && homeData.clientIP) {
      sessionStorage.setItem("clientIp", homeData.clientIP);
      document.title = `${homeData?.content?.titleForPageTab}`;
    }

    if (homeData.content?.metaTags) {
      formatMetatagsForPackage(homeData.content.metaTags);
    }

    if (homeData.content?.breadcrumbs) {
      formatBreadcrumbsForPackage(homeData.content.breadcrumbs);
    }
  }, [homeData]);

  useEffect(() => {
    sessionStorage.removeItem("selectedSortItem");
    sessionStorage.removeItem("showListCount");
    sessionStorage.removeItem("currentPage");
    sessionStorage.removeItem("aboutUsScrollPosition");
    const positionData = sessionStorage.getItem("homePageScrollPosition");
    const footerPositionData = sessionStorage.getItem("footerScrollPostion");
    if (footerPositionData) {
      window.scrollTo(0, +`${footerPositionData}`);
    } else if (positionData) {
      window.scrollTo(0, +`${positionData}`);
    } else {
      window.scrollTo(0, 0);
    }
  });

  useEffect(() => {
    function onrefresh() {
      sessionStorage.removeItem("homePageScrollPosition");
      sessionStorage.removeItem("footerScrollPostion");
    }
    window.addEventListener("beforeunload", onrefresh);
    sessionStorage.removeItem("scrollPosition");
    sessionStorage.removeItem("filterListData");
    sessionStorage.removeItem("detailPropertyData");
    return () => {
      sessionStorage.removeItem("scrollPosition");
      sessionStorage.removeItem("filterListData");
      window.removeEventListener("beforeunload", onrefresh);
    };
  }, []);

  return (
    <>
      {homeData && homeData.content && (
        <Helmet>
          {homeData.content.keywords !== "" && (
            <meta name="keywords" content={homeData.content.keywords} />
          )}
          {metaTags.length > 0 &&
            metaTags.map((metaTag, index) => {
              return <meta property={metaTag.key} content={metaTag.value} />;
            })}
          {breadcrumbs.length > 0 && (
            <script type="application/ld+json">
              {`{
                  "@context": "https://schema.org",
                  "@type": "BreadcrumbList",
                  "itemListElement": ${JSON.stringify(
                    breadcrumbs.map((item, index) => ({
                      "@type": "ListItem",
                      position: index + 1,
                      name: item.name,
                      item: item.url,
                    }))
                  )}}`}
            </script>
          )}
        </Helmet>
      )}

      {homeData &&
        homeData.content &&
        homeData.content.mediaCollection &&
        homeData.content.mediaCollection.map((item: any, index: number) => {
          if (item) {
            if (item.layout === "cardType.carouselDesktop" && screenWidth > 767)
              return (
                <HeroSection
                  id={index}
                  key={`carousel-deskop_${index}`}
                  data={item}
                  campaigns={homeData.content?.activeCampaigns}
                />
              );
            if (
              item.layout === "cardType.carouselMobile" &&
              screenWidth > 320 &&
              screenWidth < 767
            )
              return (
                <HeroSection
                  id={index}
                  key={`carousel-deskop_${index}`}
                  data={item}
                  campaigns={homeData.content?.activeCampaigns}
                />
              );
            if (item.layout === "cardType.4cardTray")
              return (
                <CardTray
                  id={index}
                  key={`card_tray_${index}`}
                  data={item}
                  campaign={homeData.content?.activeCampaigns}
                />
              );
            if (item.layout === "cardType.discoverTray")
              return (
                <DiscoverTray
                  id={index}
                  key={`discovery_tray_${index}`}
                  data={item}
                  campaign={homeData.content?.activeCampaigns}
                />
              );
            if (item.layout === "cardType.voyaahPromises")
              return (
                <VoyaahPromises key={`voyaah_promises_${index}`} data={item} />
              );
            if (item.layout === "cardType.discoverVideo")
              return <VideoTray id={index} key={index} data={item} />;
            if (item.layout === "cardType.2row4CardTray")
              return (
                <PackageTray
                  id={index}
                  key={`package_tray_${index}`}
                  data={item}
                />
              );
            if (item.layout === "cardType.discoverInternationalTray")
              return (
                <DiscoverInternationalTray
                  id={index}
                  key={`dis_int_tray_${index}`}
                  data={item}
                />
              );
            if (item.layout === "cardType.TestimonialLayout")
              return (
                <TestimonialLayout
                  id={index}
                  key={`testimonial_layout`}
                  data={item}
                />
              );
            return <></>;
          }
        })}

      {loader && (
        <div className="loader-container">
          <div className="loader">
            <Bars color="#00BFFF" height={50} width={100} />
          </div>
        </div>
      )}
    </>
  );
}
