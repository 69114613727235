import moment from "moment";
import React, { useEffect, useState } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { createImageAltText, formatMoney } from "../../helpers/utils";
import _ from "underscore";
import awsConfig from "../../configs/aws";

import "./ListingCardLayout.scss";

function ListingCardLayout(props: any) {
  const { data } = props;
  const navigate = useNavigate();
  const [expireIn, setExpireIn] = useState("");
  const [isPartnerSite, setIsPartnerSite] = useState<boolean>(false);
  const [pointsEarnedFormat, setPointsEarnedFormat] = useState<string>("");

  useEffect(() => {
    if (
      process.env.REACT_APP_PARTNER_SITE &&
      process.env.REACT_APP_PARTNER_SITE === "true"
    ) {
      setIsPartnerSite(true);
    }
    if (process.env.REACT_APP_POINT_EARNED_FORMAT) {
      setPointsEarnedFormat(process.env.REACT_APP_POINT_EARNED_FORMAT);
    }
  }, []);

  useEffect(() => {
    if (data.availableTo) {
      var now = moment(new Date());
      var endDate = new Date(data.availableTo?.split("T")?.[0]);
      endDate.setHours(23, 59, 59);
      var end = moment(endDate);
      var duration = moment.duration(end.diff(now));
      var hours = duration.asHours();
      if (hours < 72 && hours > 0) {
        setExpireIn(`Offer ends in ${hours.toFixed(0)} hours`);
      } else {
        setExpireIn("");
      }
    }
  }, [data]);

  const navigateToDetails = (routeLink: string) => {
    sessionStorage.setItem("scrollPosition", `${window.pageYOffset}`);
    sessionStorage.setItem("showListCount", `${props.showListCount}`);
    sessionStorage.removeItem("footerScrollPostion");
    sessionStorage.removeItem("detailPropertyData");
    navigate(routeLink);
  };

  function trimInclusion(inclusions: any) {
    return data?.inclusions?.slice(0, 2).join(" <span>&#9679;</span> ").length >
      150
      ? `${data?.inclusions
          ?.slice(0, 2)
          .join(" <span>&#9679;</span> ")
          .substring(0, 150)}...`
      : `${data?.inclusions?.slice(0, 2).join(" <span>&#9679;</span> ")}`;
  }

  return (
    <div className="filter-result-blk">
      <div className="image link" onClick={()=>navigateToDetails(`/packages/${data.reference}`)}>
        {/* src={require("../../assets/images/image8.png")} */}
        <NavLink to={`/packages/${data.reference}`}>
          <img
            src={data.mainImage}
            alt={createImageAltText(`${data.name} Main Image`)}
            className="img-fluid"
          />
        </NavLink>
        {data.persuasionText && (
          <span>{data.persuasionText} &nbsp; &nbsp;</span>
        )}
      </div>
      <div className="content">
        {data.type === "packageType.Staycation" && (
          <div className="sub-title">{`${data.destination}`}</div>
        )}
        {data.type === "packageType.Holiday" && (
          <div className="sub-title">{`${data.destination}`}</div>
        )}

        <NavLink to={`/packages/${data.reference}`}>
          <span
            style={{ color: "white" }}
            className="title link"
            onClick={()=>navigateToDetails(`/packages/${data.reference}`)}
          >
            {data.name}
          </span>
        </NavLink>
        <div className="days">
          {`${data.numNights}N & ${data.numDays}D`}&nbsp; &nbsp;
          {/* {`|`}&nbsp; &nbsp; */}
          {/* {`${data.numAdults} Adults`} */}
        </div>
        {data?.inclusions && (
          <div className="description">
            {/* <span>Inclusions -</span> */}
            <span
              className="normal"
              dangerouslySetInnerHTML={{
                __html: `${trimInclusion(data?.inclusions)}`,
              }}
            ></span>
            {/* {`${data?.inclusions?.slice(0, 2).join("  ✳  ")}`} */}
            <Link to={`/packages/${data.reference}`}>
              <p
                className="link-btn"
                style={{ marginTop: "0" }}
              >
                {`${data?.inclusions?.length > 3 ? " View All" : ""}`}
              </p>
          </Link>
          </div>
        )}

        <div className="offer-blk">
          <div className="left">
            <div className="offer-ends">{expireIn}</div>
            <div className="price lato-font">
              <span>{`₹ ${formatMoney(data?.mrp)}`}</span>{" "}
              {`₹ ${formatMoney(data?.packagePrice)} `}
              <span style={{fontSize: '15px', textDecoration: 'none', color: '#67E1F2'}}>{`/ ${data.numAdults} Adults`}</span>
            </div>
            {isPartnerSite && (
              <div
                className="points-earned"
                dangerouslySetInnerHTML={{
                  __html: pointsEarnedFormat.replace(
                    "{points}",
                    data?.pointsEarned
                  ),
                }}
              />
            )}
          </div>
          <Link
            className="booknow-btn"
            to={`/packages/${data.reference}`}
            onClick={(e) => {
              navigateToDetails(`/packages/${data.reference}`);
              e.preventDefault();
            }}
          >
            Book Now
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ListingCardLayout;
