import { useEffect, useState } from "react";
import _ from "underscore";
import { themeFilterData as fd } from "../../constants/listingData";
import { experienceApiAxios } from "src/helpers/api_helper";
import { DESTINATIONS } from "src/helpers/url_helper";
import Rating from "@mui/material/Rating";
import "./PlacesOfInterestOverlay.scss";
import { returnPlaceOfInterestUrl } from "src/helpers/utils";
import { getHeader } from "src/helpers/backend_helper";
import itineraryPlanStore from "src/store/itineraryPlanStore";

const PlacesOfInterestOverlay = ({
  cityName,
  cityId,
  activityIndex,
  onClosePlacesOfInterestOverlay,
  removePlaceOfInterest,
  addPlaceOfInterest,
  interests,
  overlayText,
  placeIndex,
}: {
  onClosePlacesOfInterestOverlay: any;
  addPlaceOfInterest: any;
  removePlaceOfInterest: any;
  activityIndex: number;
  cityName: string;
  cityId: number;
  interests: { label: string; value: string }[];
  overlayText: string;
  placeIndex: number;
}) => {
  const [filterData, setFilterData] = useState<any[]>([]);
  const [placesToVisit, setPlacesToVisit] = useState<any[]>([]);
  const [filterText, setFilterText] = useState<string>("");
  const [isFilterCollapse, setIsFilterCollapse] = useState(false);

  useEffect(() => {
    let filterDataObj: any[] = JSON.parse(JSON.stringify(fd));
    setFilterData(filterDataObj);

    const fetchPlacesOfInterest = async () => {
      const headerResponse = await getHeader();
      experienceApiAxios
        .get(`${DESTINATIONS}/${cityId}`, { headers: headerResponse })
        .then((response) => {
          const placeOfInterestArr =
            response.data?.destinationData?.placeOfInterest?.map(
              (place: any) => {
                return { ...place, filtered: false };
              }
            );
          setPlacesToVisit(placeOfInterestArr);
        });
    };

    fetchPlacesOfInterest();
  }, [cityId]);

  const checkInterestAdded = (placeName: string) => {
    const isAdded = interests.find((interest) => interest.label === placeName);
    return isAdded ? true : false;
  };

  const checkActivityAddedPreviously = (placeName: string) => {
    let returnString = "";

    itineraryPlanStore.placesToVisit.forEach((place, placeToVisitIndex) => {
      if (placeToVisitIndex === placeIndex) {
        place.activities?.forEach((activity, index) => {
          if (index !== activityIndex) {
            if (
              activity.interests?.find(
                (interest) => interest.label === placeName
              )
            ) {
              returnString = `Part of itinerary on Day ${
                itineraryPlanStore.placesToVisit[placeToVisitIndex].dayIndex
              } activity slot ${index + 1}`;
            }
          }
        });
      } else {
        place.activities.forEach((activity, index) => {
          if (
            activity.interests?.find((interest) => interest.value === placeName)
          ) {
            returnString = `Part of itinerary on Day ${
              itineraryPlanStore.placesToVisit[placeToVisitIndex].dayIndex
            } activity slot ${index + 1}`;
          }
        });
      }
    });

    return returnString;
  };

  const handleItemClick = (item: any, data: any) => {
    let itemIndex = filterData.findIndex(
      (obj: any) => obj.title === item.title
    );
    let dataIndex = filterData[itemIndex].data.findIndex(
      (obj: any) => obj.title === data.title
    );
    let filterDataObj = [...filterData];
    filterDataObj[itemIndex].data[dataIndex].isSelected = !data.isSelected;
    if (data.data) {
      filterDataObj[itemIndex].data[dataIndex].data = _.map(
        filterDataObj[itemIndex].data[dataIndex].data,
        function (a) {
          a.isSelected = filterDataObj[itemIndex].data[dataIndex].isSelected;
          return a;
        }
      );
    }
    setFilterData(filterDataObj);
    checkIfPlaceIncludesTheme();
  };

  const checkIfPlaceIncludesTheme = () => {
    const themeFilterArr: string[] = [];
    filterData[0].data.forEach(
      (filterItem: { isSelected: any; value: string }) => {
        if (filterItem.isSelected) {
          themeFilterArr.push(filterItem.value);
        }
      }
    );

    const tempPlacesToVisit = placesToVisit;

    const newArray = tempPlacesToVisit.map(
      (tempPlace: { name: string; tags: string[] }) => {
        if (
          tempPlace.name.toLowerCase().includes(filterText.toLowerCase()) &&
          (themeFilterArr.length > 0
            ? tempPlace.tags?.some((tag) => themeFilterArr.includes(tag))
            : true)
        ) {
          return { ...tempPlace, filtered: false };
        } else {
          return { ...tempPlace, filtered: true };
        }
      }
    );

    setPlacesToVisit(newArray);
  };

  const handleSubItemClick = (item: any, data: any, subData: any) => {
    let itemIndex = filterData.findIndex(
      (obj: any) => obj.title === item.title
    );
    let dataIndex = filterData[itemIndex].data.findIndex(
      (obj: any) => obj.title === data.title
    );
    let subDataIndex = filterData[itemIndex].data[dataIndex].data.findIndex(
      (obj: any) => obj.title === subData.title
    );
    let filterDataObj = [...filterData];
    filterDataObj[itemIndex].data[dataIndex].data[subDataIndex].isSelected =
      !subData.isSelected;

    setFilterData(filterDataObj);
  };

  return (
    <div className="places-overlay">
      <div className="places-listing-popup">
        <div className="places-overlay-header">
          <div className="places-overlay-header-div">
            <div className="places-overlay-header-txt">
              Select Places to Visit - {cityName}&nbsp;
              <span className="overlay-text">({overlayText})</span>
            </div>
          </div>
          <div className="places-overlay-header-search-close">
            <input
              className="places-hotel-search-txt"
              id={`poiOverlay_inpPlaceName_${activityIndex}`}
              type="text"
              placeholder="Search for places to visit"
              value={filterText}
              onChange={(e) => {
                setFilterText(e.target.value);
                const themeFilterArr: string[] = [];
                filterData[0].data.forEach(
                  (filterItem: { isSelected: any; value: string }) => {
                    if (filterItem.isSelected) {
                      themeFilterArr.push(filterItem.value);
                    }
                  }
                );

                const tempPlacesToVisit = placesToVisit;

                const newArray = tempPlacesToVisit.map(
                  (tempPlace: { name: string; tags: string[] }) => {
                    if (
                      tempPlace.name
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase()) &&
                      (themeFilterArr.length > 0
                        ? tempPlace.tags?.some((tag) =>
                            themeFilterArr.includes(tag)
                          )
                        : true)
                    ) {
                      return { ...tempPlace, filtered: false };
                    } else {
                      return { ...tempPlace, filtered: true };
                    }
                  }
                );

                setPlacesToVisit(newArray);
              }}
            />
            <button
              className="places-close-btn"
              id={`poiOverlay_btnClose_${activityIndex}`}
              style={{ fontWeight: 600 }}
              onClick={() => onClosePlacesOfInterestOverlay()}
            >
              &#x2715;
            </button>
          </div>
        </div>
        <div className="poi-overlay-content">
          <div className="places-filter-div">
            <div className="property-filter-by">
              <div className="head-container">
                <div className="head">
                  Filter By
                  <span
                    className={`arrow ${!isFilterCollapse ? "active" : ""}`}
                  >
                    <img
                      src={require("../../assets/images/filter-arrow.png")}
                      alt=""
                      className="img-fluid"
                      onClick={() => {
                        setIsFilterCollapse(!isFilterCollapse);
                      }}
                    />
                  </span>
                </div>
              </div>
              {!isFilterCollapse && (
                <div>
                  {filterData.map((item: any, index: number) => {
                    if (item.title !== "Themes") {
                      return <></>;
                    } else {
                      return (
                        <div className="acc-blk" key={`filter_${index}`}>
                          <div className="acc-head">{item.title}</div>
                          {item.isExpand && (
                            <div className="acc-content">
                              <ul>
                                {item.data.map((data: any, index: number) => {
                                  return (
                                    <li key={`filter_item_${index}`}>
                                      <span
                                        className={`checkbox ${
                                          data.isSelected ? "active" : ""
                                        }`}
                                        onClick={() =>
                                          handleItemClick(item, data)
                                        }
                                      ></span>
                                      <span
                                        onClick={() =>
                                          handleItemClick(item, data)
                                        }
                                      >
                                        {data.title}
                                      </span>

                                      {data.data && (
                                        <ul>
                                          {data.data.map(
                                            (subData: any, index: number) => {
                                              return (
                                                <li
                                                  key={`filter_sub_item_${index}`}
                                                >
                                                  <span
                                                    className={`checkbox ${
                                                      subData.isSelected
                                                        ? "active"
                                                        : ""
                                                    }`}
                                                    id={`poiOverlay_chkFilterItem_${subData.title}_${index}`}
                                                    onClick={() =>
                                                      handleSubItemClick(
                                                        item,
                                                        data,
                                                        subData
                                                      )
                                                    }
                                                  ></span>
                                                  <span
                                                    onClick={() =>
                                                      handleSubItemClick(
                                                        item,
                                                        data,
                                                        subData
                                                      )
                                                    }
                                                  >
                                                    {subData.title}
                                                  </span>
                                                </li>
                                              );
                                            }
                                          )}
                                        </ul>
                                      )}
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          )}
                        </div>
                      );
                    }
                  })}
                </div>
              )}
            </div>
          </div>
          <div className="places-results-div">
            <div className="places-results-div-content">
              {placesToVisit.length > 0 &&
                placesToVisit.map((placeToVisit: any) => {
                  if (!placeToVisit.filtered) {
                    return (
                      <div
                        className={
                          checkInterestAdded(placeToVisit.name)
                            ? "places-listing-section places-selected"
                            : "places-listing-section places-not-added"
                        }
                      >
                        <div className="places-listing-img-div">
                          {placeToVisit.imageUrl && (
                            <img
                              src={returnPlaceOfInterestUrl(
                                placeToVisit.imageUrl
                              )}
                              alt=""
                              className="place-of-interest-img"
                            />
                          )}
                          {!placeToVisit.imageUrl && (
                            <img
                              src={require("../../assets/images/discover-bg.png")}
                              alt=""
                              className="place-of-interest-img"
                            />
                          )}
                        </div>
                        <div className="property-listing-div">
                          <div className="places-listing-header-div">
                            <p className="places-listing-header-txt">
                              {placeToVisit.name}
                            </p>
                            {checkActivityAddedPreviously(
                              placeToVisit.name
                            ) && (
                              <button className="places-listing-header-btn-added">
                                {checkActivityAddedPreviously(
                                  placeToVisit.name
                                )}
                              </button>
                            )}
                            {!checkActivityAddedPreviously(placeToVisit.name) &&
                              !checkInterestAdded(placeToVisit.name) && (
                                <button
                                  className="places-listing-header-btn"
                                  id={`poiOverlay_btnAddPlace_${activityIndex}`}
                                  onClick={() => {
                                    addPlaceOfInterest(
                                      placeToVisit.imageUrl ?? "",
                                      placeToVisit.id,
                                      placeToVisit.name,
                                      placeToVisit.latitude,
                                      placeToVisit.longitude,
                                      activityIndex,
                                      placeToVisit.description
                                    );
                                  }}
                                >
                                  Add
                                </button>
                              )}
                            {!checkActivityAddedPreviously(placeToVisit.name) &&
                              checkInterestAdded(placeToVisit.name) && (
                                <button
                                  className="places-listing-header-remove-btn"
                                  id={`poiOverlay_btnRemovePlace_${activityIndex}`}
                                  onClick={() => {
                                    removePlaceOfInterest(
                                      placeToVisit.name,
                                      activityIndex
                                    );
                                  }}
                                >
                                  Added
                                </button>
                              )}
                          </div>
                          <div className="places-listing-details-div">
                            <div className="property-listing-details-location">
                              Needed time: Typically {placeToVisit.timeNeeded}{" "}
                              hours
                            </div>
                            <div className="place-of-interest-listing-details-text">
                              {placeToVisit.description}
                            </div>
                            <div className="place-of-interest-time-rating">
                              <div className="place-of-interest-time">
                                Opening hours: {placeToVisit.openFrom} to{" "}
                                {placeToVisit.openTo}
                              </div>
                              <div className="place-of-interest-time">
                                {placeToVisit.indicativePricing
                                  ? `Pricing: ${placeToVisit.indicativePricing}`
                                  : ``}
                              </div>
                              <div className="place-of-interest-rating">
                                <Rating
                                  name="simple-controlled"
                                  value={placeToVisit.rating}
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  } else {
                    return <></>;
                  }
                })}
              {placesToVisit.length === 0 && (
                <p className="no-places-msg" style={{ color: "#fff" }}>
                  There are no places of interest for the city {cityName}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="overlay-close-btn">
          <button
            className="places-listing-header-btn"
            style={{ background: "#2e8b98" }}
            onClick={() => onClosePlacesOfInterestOverlay()}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default PlacesOfInterestOverlay;
