export const strings = {
    "contactUs": {
        "successMessage": "Thank you for contacting us. We will reach you within next 24 hours.",
    },
    "groupBooking": {
        "servicesOffered": "Services Offered",
        "contactUs": "Contact us",
        "ourClientsLoveUs": "Our clients love us",
        "glimpses": "Glimpses..."
    }
};