import {
    GET_PACKAGE_SUMMARY,
    GET_PACKAGE_SUMMARY_SUCCESS,
    GET_PACKAGE_SUMMARY_FAIL,
    GET_LISTING_PAGE_DATA,
    GET_LISTING_PAGE_DATA_SUCCESS,
    GET_LISTING_PAGE_DATA_FAIL
} from "./actionTypes";

const initialState = {
    packages: [],
    listingPageData: {},
    loader: false,
    error: {
        message: "",
    },
};

export const PackageSummaryReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_PACKAGE_SUMMARY:
            state = { ...state, loader: true };
            break;
        case GET_PACKAGE_SUMMARY_SUCCESS:
            state = { ...state, packages: action.payload, loader: false };
            break;
        case GET_PACKAGE_SUMMARY_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loader: false,
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export const ListingPageDataReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_LISTING_PAGE_DATA:
            state = { ...state, loader: true };
            break;
        case GET_LISTING_PAGE_DATA_SUCCESS:
            state = { ...state, listingPageData: action.payload, loader: false };
            break;
        case GET_LISTING_PAGE_DATA_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loader: false,
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}