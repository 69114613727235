import {
    POST_CONTACT_DETAILS,
    POST_CONTACT_DETAILS_SUCCESS,
    POST_CONTACT_DETAILS_FAIL,
    CLEAR_CONTACT_DETAILS,
} from "./actionTypes";

export const getContactDetails = (data: any) => {
    return {
        type: POST_CONTACT_DETAILS,
        payload: data,
    };
};

export const getContactDetailsSuccess = (data: any) => {
    return {
        type: POST_CONTACT_DETAILS_SUCCESS,
        payload: data,
    };
};

export const getContactDetailsFail = (error: any) => {
    return {
        type: POST_CONTACT_DETAILS_FAIL,
        payload: error,
    };
};

export const clearContactDetails = () => {
    return {
        type: CLEAR_CONTACT_DETAILS,
    };
};