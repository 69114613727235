import React, {useEffect, useState} from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import HeroCard from "./HeroCard";

function HeroSection(props: any) {
  const { data, campaigns } = props;
  const [dataArr, setDataArr] = useState<any>([]);

  useEffect(() => {
    if(data?.folder.files !== 0) {
    data.folder.files.sort((a: any, b: any) => {
      if(a.displayIndex !== null) {
        var value;
        value = (a.displayIndex > b.displayIndex) ? 1 : (a.displayIndex < b.displayIndex) ? -1 : 0;
        return value;
    }
    });
    setDataArr(data);
  }
}, [data]);
  return (
    <>
    {dataArr.length !== 0 && dataArr?.folder?.files?.length !== 0 &&
      <OwlCarousel
        className="owl-theme"
        items={1}
        loop={dataArr?.folder?.files?.length > 1}
        autoplay={dataArr?.folder?.files?.length > 1}
        autoplayTimeout={10000}
      >
        {dataArr?.folder?.files.map((item: any, index: number) => {
          return (
            <div key={`owl_carousel_${index}`}>
              <HeroCard data={item} folderUrl={dataArr?.folder.url} campaigns={campaigns} />
            </div>
          );
        })}
      </OwlCarousel>
    }
    </>
  );
}

export default HeroSection;
