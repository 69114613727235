import React, { useState } from "react";

import "./TestimonialCard.scss";

function TestimonialCard(props: any) {
  const { data } = props;

  const [charLimit] = useState(180);

  return (
    <div className="testimonial-blk">
      <div className="testimonial-blk-left">
        <div className="vcenter">
          <div className="user-pic">
            <img src={`${props.folderUrl}/${data.fileName}`} alt="" className="user-img" />
          </div>
          <div className="user-name">{data.cardAttributes.title}</div>
          <div className="user-designation">{data.cardAttributes.subTitle}</div>
        </div>
      </div>
      <div className="testimonial-blk-right">
        <div className="user-testimonial">
          <span className="top-quotes"></span>
          <span className="testimonial-message">{data?.cardAttributes.highlightText}</span>
          <span className="bottom-quotes"></span>
        </div>
      </div>
    </div>
  );
}

export default TestimonialCard;
