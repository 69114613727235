import {
  GET_TERMSANDCONDITION_DATA,
  GET_TERMSANDCONDITION_DATA_SUCCESS,
  GET_TERMSANDCONDITION_DATA_FAIL,
} from "./actionTypes";

export const getTermsAndConditionData = (
  pageUrl: any,
  customerProfileId: string,
  accountId: string
) => {
  return {
    type: GET_TERMSANDCONDITION_DATA,
    payload: pageUrl,
    customerProfileId: customerProfileId,
    accountId: accountId,
  };
};

export const getTermsAndConditionDataSuccess = (data: any) => {
  return {
    type: GET_TERMSANDCONDITION_DATA_SUCCESS,
    payload: data,
  };
};

export const getTermsAndConditionDataFail = (error: any) => {
  return {
    type: GET_TERMSANDCONDITION_DATA_FAIL,
    payload: error,
  };
};
