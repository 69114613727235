export const UPLOAD_BOOKING_DOCUMENT = "/transImegeUpload";
//Experience API Urls
export const GET_PACKAGE_LISTING = "/packages";
export const GET_PROPERTY_LISTING = "/properties";
export const GET_HOME_CONTENT = "/content/home";
export const GET_PACKAGE_AVAILABILITY = "/availability";
export const GET_BOOKING_DETAILS = "/bookings";
export const GET_BOOKING_CONFIRMATION = "/bookings/confirmation";
export const GET_BOOKING_LIST = "/customer/bookings";
export const GET_ITINERARY_LIST = "/customer/itineraries";
export const SET_CUSTOMER_TRACKING = "/customer/tracking";
export const CONTACT_SUPPORT = "/support/contact";
export const UPDATE_BOOKING_STATUS = "/bookings/";
export const GET_CMS_DATA = "/content/page";
export const CUSTOMER_INTERESTS = "/customer/interests";
export const CUSTOMER_PROFILE = "/customer/profile";
export const DESTINATIONS = "/destinations";
export const OPPORTUNITIES = "/opportunities";
export const CUSTOMER = "/customer";
export const ITINERARY = "/itinerary";
export const MEALLIST = "/mockdata";
export const RATELIST = "/mockdata";
