import React, { useEffect, useState } from "react";
import _ from "underscore";
import { createImageAltText } from "../../helpers/utils";
import "./Details.scss";

function ImageGalleryPopup(props: any) {
  const {
    packageData,
    showImageGalleryPopup,
    updateShowImageGalleryPopup,
    imageIndex,
  } = props;

  const [imageArr, setImageArr] = useState<any>([]);
  const [shownImageIndex, setShownImageIndex] = useState(imageIndex ?? 0);

  useEffect(() => {
    var additionalImageArr = _.findWhere(packageData, {
      collectionType: "Additional",
    });
    setImageArr(additionalImageArr?.images);
  }, [packageData]);

  const handleImageClick = (item: any) => {
    setShownImageIndex(item);
  };

  const updateImage = (data: string) => {
    if (data === "left" && shownImageIndex > 0) {
      setShownImageIndex(shownImageIndex - 1);
      document
        ?.getElementById(`details_image_${shownImageIndex - 1}`)
        ?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
    } else if (data === "right" && shownImageIndex < _.size(imageArr) - 1) {
      setShownImageIndex(shownImageIndex + 1);
      document
        ?.getElementById(`details_image_${shownImageIndex + 1}`)
        ?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
    }
  };

  return (
    <div className="popup-overlay">
      <div className="gallery-popup">
        <div className="sub-head">
          <span className="head">{packageData?.packagename}</span>
          <span
            className="close"
            onClick={() => {
              updateShowImageGalleryPopup(!showImageGalleryPopup);
            }}
          >
            <a>Close</a>
          </span>
        </div>
        {/* <div className="head">{packageData?.packagename}</div> */}
        <div style={{ overflow: "hidden" }}>
          <div className="images-small-blk">
            {imageArr?.map((item: any, index: number) => {
              return (
                <div
                  id={`details_image_${index}`}
                  className={`image-gallery-popup ${
                    shownImageIndex === index ? "active" : ""
                  } `}
                  key={`details_image_owl_carousel_${index}`}
                  onClick={() => handleImageClick(index)}
                >
                  <img
                    src={item.imageUrl}
                    alt={createImageAltText(item?.imageCaption)}
                    className="img-fluid"
                  />
                  <span className="active-overlay"></span>
                </div>
              );
            })}
          </div>

          <div className="image-big">
            <div className="image-container">
              {shownImageIndex !== 0 && (
                <div
                  className="left-arrow"
                  onClick={() => updateImage("left")}
                ></div>
              )}
              {imageArr[shownImageIndex] && (
                <img
                  src={imageArr[shownImageIndex].imageUrl}
                  alt={createImageAltText(
                    imageArr[shownImageIndex]?.imageCaption
                  )}
                  className="img-fluid"
                  style={{
                    width: "100%",
                    height: "80vh",
                    objectFit: "contain",
                  }}
                />
              )}

              {shownImageIndex !== imageArr.length - 1 && (
                <div
                  className="right-arrow"
                  onClick={() => updateImage("right")}
                ></div>
              )}
            </div>
            <div className="image-counter">{`${shownImageIndex + 1} / ${_.size(
              imageArr
            )}`}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageGalleryPopup;
