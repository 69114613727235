import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./TermsAndConditions.scss";
import { useDispatch, useSelector } from "react-redux";
import { getTermsAndConditionData } from "../../store/terms-and-condition/actions";
import { Helmet } from "react-helmet";
import ReactPixel from "react-facebook-pixel";
import { createCustomerProfile } from "../../store/customer/actions";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../helpers/firebase";

function TermsAndConditions() {
  let dispatch = useDispatch();
  const [user, loading] = useAuthState(auth);
  const [termsAndConditionData, setTermsAndConditionData] = useState<any>();
  const [metaTags, setMetaTags] = useState<any[]>([]);
  const [breadcrumbs, setBreadcrumbs] = useState<any[]>([]);

  const { customerProfileResponse } = useSelector(
    (state: any) => state.CustomerProfileReducer
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactPixel.fbq("init", process.env.REACT_APP_FBPIXEL_ID);
    ReactPixel.fbq("track", "PageView");
  }, []);

  useEffect(() => {
    if (customerProfileResponse != undefined) {
      const customerTracker = localStorage.getItem("voyaahCustomerTracker");
      const customerConsentStore = localStorage.getItem(
        "voyaahCustomerConsent"
      );

      if (!customerTracker) {
        const customerProfile = {
          id: customerProfileResponse,
        };

        localStorage.setItem(
          "voyaahCustomerTracker",
          JSON.stringify(customerProfile)
        );
      }

      if (!customerConsentStore) {
        const customerConsent = {
          essentialCookieEnabled: true,
          analyticsCookieEnabled: true,
          personalizationCookieEnabled: true,
        };

        localStorage.setItem(
          "voyaahCustomerConsent",
          JSON.stringify(customerConsent)
        );
      }
    }
  }, [customerProfileResponse]);

  useEffect(() => {
    let customerTracker = localStorage.getItem("voyaahCustomerTracker");
    if (customerTracker == null && !loading) {
      const consentTracker = {
        customerProfileId: null,
        accountId: user?.uid ?? null,
        essentialConsent: true,
        analyticsConsent: true,
        personalizationConsent: true,
      };

      dispatch(createCustomerProfile(consentTracker));
    }
  }, [dispatch, loading]);

  useEffect(() => {
    const localStorageData = localStorage.getItem("voyaahCustomerTracker");
    let customerProfileId = "";
    if (localStorageData) {
      customerProfileId = JSON.parse(localStorageData!).id;
    }

    if (!loading) {
      dispatch(
        getTermsAndConditionData(
          "terms-and-conditions",
          customerProfileId,
          user?.uid ?? ""
        )
      );
    }
  }, [dispatch, loading]);

  const { response } = useSelector(
    (state: any) => state.TermsAndConditionDataReducer
  );

  useEffect(() => {
    if (response && response.contentSection) {
      response?.contentSection.map((item: any) => {
        if (item) {
          item.contentItem.sort((a: any, b: any) => {
            if (a.displayIndex !== null) {
              let value;
              value =
                a.displayIndex > b.displayIndex
                  ? 1
                  : a.displayIndex < b.displayIndex
                  ? -1
                  : 0;
              return value;
            }
          });
        }
      });
      setTermsAndConditionData(response);
    }
    document.title = `${response.titleForPageTab}`; //"Terms And Conditions | Voyaah";

    if (response && response.metaTags) {
      formatMetatagsForPackage(response.metaTags);
    }

    if (response && response.breadcrumbs) {
      formatBreadcrumbsForPackage(response.breadcrumbs);
    }
  }, [response]);

  function formatMetatagsForPackage(metaTags: any) {
    const metaTagArray: any[] = [];
    metaTags.forEach((metaTag: { tag: any; value: any }) => {
      const metaTagObject = { key: metaTag.tag, value: metaTag.value };
      metaTagArray.push(metaTagObject);
    });

    setMetaTags(metaTagArray);
  }

  function formatBreadcrumbsForPackage(breadcrumbs: any) {
    const breadcrumbsArray: any[] = [];
    breadcrumbs.forEach((breadcrumb: { name: any; url: any }) => {
      const breadcrumbObject = { name: breadcrumb.name, url: breadcrumb.url };
      breadcrumbsArray.push(breadcrumbObject);
    });

    setBreadcrumbs(breadcrumbsArray);
  }

  return (
    <div className="inner-page details-page">
      {termsAndConditionData && (
        <Helmet>
          {termsAndConditionData.keywords !== "" && (
            <meta name="keywords" content={termsAndConditionData.keywords} />
          )}
          {metaTags.length > 0 &&
            metaTags.map((metaTag, index) => {
              return <meta property={metaTag.key} content={metaTag.value} />;
            })}
          {breadcrumbs.length > 0 && (
            <script type="application/ld+json">
              {`{
                  "@context": "https://schema.org",
                  "@type": "BreadcrumbList",
                  "itemListElement": ${JSON.stringify(
                    breadcrumbs.map((item, index) => ({
                      "@type": "ListItem",
                      position: index + 1,
                      name: item.name,
                      item: item.url,
                    }))
                  )}}`}
            </script>
          )}
        </Helmet>
      )}
      <div className="section">
        <div className="breadcrum">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>›</li>
            <li>{termsAndConditionData?.name}</li>
          </ul>
        </div>

        <div className="inner-heading">
          <h1 className="head text-center">{termsAndConditionData?.title}</h1>
        </div>

        <div className="privacy-blk">
          {termsAndConditionData &&
            termsAndConditionData.contentSection &&
            termsAndConditionData.contentSection[0].contentItem.map(
              (contentData: any) => {
                return (
                  <div
                    style={{ textAlign: "justify" }}
                    dangerouslySetInnerHTML={{
                      __html: contentData?.content,
                    }}
                  />
                );
              }
            )}
          {/* <p
            dangerouslySetInnerHTML={{
              __html: termsAndConditionData?.description,
            }}
          /> */}
        </div>
      </div>
    </div>
  );
}

export default TermsAndConditions;
