import React from "react";
import { useNavigate } from "react-router-dom";
import "./PackageCardLayout.scss";
import ReactGA from "react-ga4";
import { getAnalyticsEnabled } from "../../../helpers/backend_helper";

function PackageCardLayout(props: any) {
  const screenWidth = window.innerWidth;
  const navigate = useNavigate();
  const { data } = props;
  const navigateToCTA = () => {
    sessionStorage.setItem("homePageScrollPosition", `${window.pageYOffset}`);
    navigate(`/search/${data.cardAttributes.ctaUrl}`);
    if (getAnalyticsEnabled()) {
      ReactGA.event({
        action: `${data?.type} package card`,
        category: `homepage`,
      });
    }
  };

  return (
    <>
      {data && (
        <div className="image link" onClick={navigateToCTA}>
          <span className="background-overlay"></span>
          <img
            src={`${props.folderUrl}/${data.fileName}`}
            alt=""
            className={screenWidth < 767 ? "mobileImagestyle" : "imageStyle"}
          />
          <div className="name">{data.cardAttributes.title}</div>
          {data.cardAttributes.Package &&
            data.cardAttributes.Package?.persuasionText && (
              <>
                <div className="tag">
                  {data.cardAttributes.Package?.persuasionText}
                </div>
                <div className="price">
                  <span>{data.cardAttributes.Package?.mrp}</span>
                  <span className="offer">
                    {data.cardAttributes.Package?.packagePrice}
                  </span>
                </div>
              </>
            )}
        </div>
      )}
      {/* {data && (
        <div className="image link" onClick={navigateToCTA}>
          <span className="background-overlay"></span>
          <img src={data.image} alt="" className={screenWidth < 767 ? "mobileImagestyle" : "imageStyle"} />
          <div className="name">{data.cardTitle}</div>
          {data.persuasionText && (
            <div className="tag">{data.persuasionText}</div>
          )}
          <div className="price">
            <span>{data.offerPrice}</span>
            <span className="offer">{data.price}</span>
          </div>
        </div>
      )} */}
    </>
  );
}

export default PackageCardLayout;
