import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";

import MyProfile from "../myProfile/MyProfile";
import MyBooking from "../myBooking/MyBooking";
import PendingPayments from "../pendingPayments/PendingPayments";

import "./Profile.scss";
import MyItineraries from "../myItineraries/MyItineraries";

function Profile() {
  const [profileMenu] = useState([
    {
      id: "profile",
      title: "Profile Details",
      icon: "account_circle.png",
      isActive: true,
    },
    {
      id: "booking",
      title: "My Booking",
      icon: "auto_stories.png",
      isActive: false,
    },
    {
      id: "itinerary",
      title: "My Itineraries",
      icon: "my-itinerary-icon.png",
      isActive: false,
    },
  ]);
  const [selectedTab, setSelectedTab] = useState<any>({});

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "My Profile | Voyaah";
    ReactPixel.fbq("init", process.env.REACT_APP_FBPIXEL_ID);
    ReactPixel.fbq("track", "PageView");
  }, []);

  useEffect(() => {
    setSelectedTab(profileMenu[0]);
  }, [profileMenu]);

  return (
    <div className="inner-page details-page">
      <div className="section">
        <div className="breadcrum">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>›</li>
            <li>My Profile</li>
          </ul>
        </div>

        <div className="inner-heading">
          <div className="head">My Account</div>
        </div>
      </div>

      <div className="white-bg">
        <div className="section">
          <div className="my-account-blk">
            <div className="my-account-menu">
              <ul>
                {profileMenu.map((item: any, index: number) => {
                  return (
                    <li
                      className={`common-class ${
                        selectedTab.id === item.id ? "active" : ""
                      } `}
                      onClick={() => setSelectedTab(item)}
                    >
                      <span>
                        <img
                          src={require("../../assets/images/" + item.icon)}
                          alt=""
                          className="img-fluid"
                        />
                      </span>
                      <span className="highlight">
                        <img
                          src={require("../../assets/images/" + item.icon)}
                          alt=""
                          className="img-fluid"
                        />
                      </span>
                      {item.title}
                    </li>
                  );
                })}
              </ul>
            </div>
            {selectedTab.id === "profile" && <MyProfile />}
            {selectedTab.id === "booking" && <MyBooking />}
            {selectedTab.id === "itinerary" && <MyItineraries />}
            {selectedTab.id === "payments" && <PendingPayments />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
