import React, { useEffect, useState } from "react";

type waypoints = {
  lat: number;
  lng: number;
};

const GoogleMapComponent = ({ waypoints }: { waypoints: waypoints[] }) => {
  const [url, setUrl] = useState("");

  useEffect(() => {
    let urlBuilder = `https://www.google.com/maps/embed/v1/directions?key=${
      process.env.REACT_APP_MAPS_KEY
    }&origin=${waypoints[0].lat},${waypoints[0].lng}&destination=${
      waypoints[waypoints.length - 1].lat
    },${waypoints[waypoints.length - 1].lng}`;
    if (waypoints.length > 2) {
      urlBuilder = urlBuilder + "&waypoints=";
      waypoints.forEach((point, index) => {
        if (
          index !== 0 &&
          index !== waypoints.length - 1 &&
          index !== waypoints.length - 2
        ) {
          urlBuilder =
            urlBuilder + `${waypoints[index].lat},${waypoints[index].lng}|`;
        }

        if (index === waypoints.length - 2) {
          urlBuilder =
            urlBuilder + `${waypoints[index].lat},${waypoints[index].lng}`;
        }
      });
    }
    urlBuilder += "&maptype=satellite";
    setUrl(urlBuilder);
  }, [waypoints]);

  return (
    <div>
      {url && (
        <iframe
          width="100%"
          height="250"
          style={{ border: "0", borderRadius: "10px" }}
          referrerPolicy="no-referrer-when-downgrade"
          src={url}
          allowFullScreen
        ></iframe>
      )}
    </div>
  );
};

export default GoogleMapComponent;
