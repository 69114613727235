import {
    GET_ABOUTUS_DATA,
    GET_ABOUTUS_DATA_SUCCESS,
    GET_ABOUTUS_DATA_FAIL,
} from "./actionTypes";

const initialState = {
    response: {},
    loader: false,
    error: {
        message: "",
    },
};

const AboutUsDataReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_ABOUTUS_DATA:
            state = { ...state, loader: true };
            break;
        case GET_ABOUTUS_DATA_SUCCESS:
            state = { ...state, response: action.payload, loader: false };
            break;
        case GET_ABOUTUS_DATA_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loader: false,
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default AboutUsDataReducer;