import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import _ from "underscore";

import CardLayout from "../card-layout/CardLayout";

import "./CardTray.scss";

function CardTray(props: any) {
  const screenWidth = window.innerWidth;
  const [dataArr, setDataArr] = useState<any>([]);
  let { data, campaign } = props;
  useEffect(() => {
    if(data?.folder.files.length !== 0) {
    const filteredStatus = data?.folder?.files?.filter((x: any) => {
      if(x?.cardAttributes?.Package?.packageStatus != 'packageStatus.Draft') {
        return x;
      }
    })
    data.folder.files = filteredStatus
    data.folder.files.sort((a: any, b: any) => {
      if(a.displayIndex !== null) {
        var value;
        value = (a.displayIndex > b.displayIndex) ? 1 : (a.displayIndex < b.displayIndex) ? -1 : 0;
        return value;
    }
    });
    setDataArr(data);
  }
  }, [data]);

  const responsive = {
    0: {
      items: 1,
    },
    480: {
      items: 2,
    },
    768: {
      items: 2,
    },
    1280: {
      items: 4,
    },
  }

    const resizedcard_responsive = {
      0: {
        items: 1,
      },
      480: {
        items: 2,
      },
      720: {
        items: 3,
      },
      1000: {
        items: 4,
      },
      1280: {
        items: 4,
      },
  };
  return (
    <>
      {dataArr.length !== 0 && dataArr.folder.files.length > 0 && (
        <div className="deals-section">
          <div className="section">
            <div className="heading">
              <h2 className="float-left card-tray-heading">{dataArr?.collectionType}</h2>
              <span
                className={dataArr.folder.files.length < 6 ? "view-all rt-0" : "view-all"}
              >
              </span>
            </div>
          </div>

          <div className="deals-gallery">
            {screenWidth < 767 ? (
              <>
                {dataArr.folder.files.length % 2 === 0 ? (
                  <div style={dataArr.folder.files.length === 1 ? styles.oneCardStyle :
                    styles.cardGridStyle}>
                    {dataArr.folder.files.map((item: any, index: number) => {
                      return (
                        (item.cardAttributes?.itemType === "mediaItem.cardtype-package" && item.cardAttributes.Package?.packageStatus === "packageStatus.Active") || (item.cardAttributes?.itemType === "mediaItem.cardtype-generic" || item.cardAttributes?.itemType === "mediaItem.cardtype-campaign" || item.cardAttributes?.itemType === "mediaItem.viewAll" || item.cardAttributes.itemType === null) ?
                        <div className="card-background" style={styles.cardLayoutDiv} key={`card_tray_owl_carousel_${index}`}>
                          <CardLayout cardType={"card"} data={item} campaign={campaign} height="66vw" index={index} length={dataArr.folder.files.length} imageSrc={item.cardAttributes?.itemType === "mediaItem.cardtype-package" ? item.cardAttributes?.Package?.images.portrait.url : `${dataArr.folder.url + '/' + item.fileName}`} />
                        </div> : ''
                      );
                    })}
                  </div>
                ) : (
                  <>
                    {dataArr.folder.files.length === 1 ? '' :
                      <div style={dataArr.folder.files.length === 1 ? styles.oneCardStyle :
                        styles.cardGridStyle}>
                        {dataArr.folder.files.slice(0, dataArr.folder.files.length % 2 === 0 ? dataArr.folder.files.length : dataArr.folder.files.length - 1).map((item: any, index: number) => {
                          return (
                            (item.cardAttributes?.itemType === "mediaItem.cardtype-package" && item.cardAttributes.Package?.packageStatus === "packageStatus.Active") || (item.cardAttributes?.itemType === "mediaItem.cardtype-generic" || item.cardAttributes?.itemType === "mediaItem.cardtype-campaign" || item.cardAttributes?.itemType === "mediaItem.viewAll" || item.cardAttributes.itemType === null) ?
                            <div className="card-background" style={styles.cardLayoutDiv} key={`card_tray_owl_carousel_${index}`}>
                              <CardLayout cardType={"card"} data={item} campaign={campaign} height="66vw" index={index} length={dataArr.folder.files.length} imageSrc={item.cardAttributes?.itemType === "mediaItem.cardtype-package" ? item.cardAttributes?.Package?.images.portrait.url : `${dataArr.folder.url + '/' + item.fileName}`} />
                            </div> : ''
                          );
                        })}
                      </div>
                    }
                    <div style={styles.oneCardStyle}>
                      {dataArr.folder.files.slice(dataArr.folder.files.length - 1, dataArr.folder.files.length).map((item: any, index: number) => {
                        return (
                          (item.cardAttributes?.itemType === "mediaItem.cardtype-package" && item.cardAttributes.Package?.packageStatus === "packageStatus.Active") || (item.cardAttributes?.itemType === "mediaItem.cardtype-generic" || item.cardAttributes?.itemType === "mediaItem.cardtype-campaign" || item.cardAttributes?.itemType === "mediaItem.viewAll" || item.cardAttributes.itemType === null) ?
                          <div className="card-background" style={styles.cardLayoutOnceCardDiv} key={`card_tray_owl_carousel_${index}`}>
                            <CardLayout cardType={"card"} data={item} campaign={campaign} height="66vw" index={index} length={dataArr.folder.files.length} imageSrc={item.cardAttributes?.itemType === "mediaItem.cardtype-package" ? item.cardAttributes?.Package?.images.portrait.url : `${dataArr.folder.url + '/' + item.fileName}`} />
                          </div> : ''
                        );
                      })}
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                {dataArr.folder.files[0].height && dataArr.folder.files[0].height ? 
                    <div className="section">
                               <OwlCarousel
                                 className="owl-theme"
                                 items={4}
                                 margin={20}
                                 autoWidth={false}
                                 stagePadding={50}
                                 dots={false}
                                responsive={resizedcard_responsive}
                                 nav
                               >
                                 {dataArr.folder.files.map((item: any, index: number) => {
                                   return (
                                     (item.cardAttributes?.itemType === "mediaItem.cardtype-package" && item.cardAttributes?.Package?.packageStatus === "packageStatus.Active") || (item.cardAttributes?.itemType === "mediaItem.cardtype-generic" || item.cardAttributes?.itemType === "mediaItem.cardtype-campaign" || item.cardAttributes?.itemType === "mediaItem.viewAll" || item.cardAttributes.itemType === null) ?
                                     <div className="card-background" key={`card_tray_owl_carousel_${index}`}>
                                       <CardLayout cardType={"card"} data={item} campaign={campaign} imageSrc={item.cardAttributes?.itemType === "mediaItem.cardtype-package" ? item.cardAttributes.Package?.images.portrait?.url : `${dataArr.folder.url + '/' + item.fileName}`} />
                                     </div> : ''
                                   );
                                 })}
                               </OwlCarousel>
                             </div>
                :
                <div className="section">
                  <OwlCarousel
                    className="owl-theme"
                    items={4}
                    margin={20}
                    stagePadding={50}
                    dots={false}
                    responsive={responsive}
                    nav
                  >
                    {dataArr.folder.files.map((item: any, index: number) => {
                      return (
                        (item.cardAttributes?.itemType === "mediaItem.cardtype-package" && item.cardAttributes?.Package?.packageStatus === "packageStatus.Active") || (item.cardAttributes?.itemType === "mediaItem.cardtype-generic" || item.cardAttributes?.itemType === "mediaItem.cardtype-campaign" || item.cardAttributes?.itemType === "mediaItem.viewAll" || item.cardAttributes.itemType === null) ?
                        <div className="card-background" key={`card_tray_owl_carousel_${index}`}>
                          <CardLayout cardType={"card"} data={item} campaign={campaign} imageSrc={item.cardAttributes?.itemType === "mediaItem.cardtype-package" ? item.cardAttributes.Package?.images.portrait?.url : `${dataArr.folder.url + '/' + item.fileName}`} />
                        </div> : ''
                      );
                    })}
                  </OwlCarousel>
                </div>
                }

              </>
             
            )}
          </div>
        </div>
      )}
    </>
  );
}

const styles = {
  oneCardStyle: {
    display: "flex",
    marginLeft: "15px",
  },
  cardGridStyle: {
    width: "100vw",
    display: "grid",
    gridTemplateColumns: "auto auto",
    marginLeft: "",
    marginRight: "8px",
    justifyContent: "space-evenly", //'space-around'
  },
  cardLayoutDiv: { marginRight: "", marginBottom: "25px", width: "44vw" }, //, width: '170px'
  cardLayoutOnceCardDiv: {
    marginRight: "15px",
    marginBottom: "10px",
    width: "100vw",
  },
  viewAllFontSize: { fontSize: "20px" },
};

export default CardTray;
