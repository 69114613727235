import { takeLatest, put, call } from "redux-saga/effects";

import { GET_PACKAGE_DETAILS, GET_PACKAGE_PRICING } from "./actionTypes";

import {
  getPackageDetailsSuccess,
  getPackageDetailsFail,
  getPackagePricingSuccess,
  getPackagePricingFail,
} from "./actions";

import {
  getPackageDetails,
  getPackagePricing,
} from "../../helpers/backend_helper";

function* onGetPackageDetails({ payload: id, customerProfileId, accountId }) {
  try {
    const response = yield call(
      getPackageDetails,
      id,
      customerProfileId,
      accountId
    );
    yield put(getPackageDetailsSuccess(response));
  } catch (error) {
    yield put(getPackageDetailsFail(error.response));
  }
}

function* onGetPackagePricing({ payload: data, customerProfileId, accountId, packageId }) {
  try {
    const response = yield call(
      getPackagePricing,
      data,
      customerProfileId,
      accountId,
      packageId
    );
    yield put(getPackagePricingSuccess(response));
  } catch (error) {
    yield put(getPackagePricingFail(error.response));
  }
}

function* PackageDetailsSaga() {
  yield takeLatest(GET_PACKAGE_DETAILS, onGetPackageDetails);
  yield takeLatest(GET_PACKAGE_PRICING, onGetPackagePricing);
}

export default PackageDetailsSaga;
