import {
  GET_PACKAGE_AVAILABILITY,
  GET_PACKAGE_AVAILABILITY_SUCCESS,
  GET_PACKAGE_AVAILABILITY_FAIL,
  CLEAR_PACKAGE_AVAILABILITY,
} from "./actionTypes";

export const getPackageAvailability = (
  data: any,
  customerProfileId: string
) => {
  return {
    type: GET_PACKAGE_AVAILABILITY,
    payload: data,
    customerProfileId: customerProfileId,
  };
};

export const getPackageAvailabilitySuccess = (data: any) => {
  return {
    type: GET_PACKAGE_AVAILABILITY_SUCCESS,
    payload: data,
  };
};

export const getPackageAvailabilityFail = (error: any) => {
  return {
    type: GET_PACKAGE_AVAILABILITY_FAIL,
    payload: error,
  };
};

export const clearPackageAvailability = () => {
  return {
    type: CLEAR_PACKAGE_AVAILABILITY,
  };
};
