export const amenitiesIconMapping = [
    {
        "title": "100% cotton robes and slippers",
        "keyword": "robe",
        "icon": "robe.png"
    },
    {
        "title": "24-hour Housekeeping",
        "keyword": "housekeeping",
        "icon": "room_service.png"
    },
    {
        "title": "24-hour In-room Dining",
        "keyword": "dining",
        "icon": "dining.png"
    },
    {
        "title": "24-hour in-villa dining",
        "keyword": "dining",
        "icon": "dining.png"
    },
    {
        "title": "24-hour Room Service",
        "keyword": "room service",
        "icon": "room_service.png"
    },
    {
        "title": "24HrsHotWater",
        "keyword": "hotwater",
        "icon": "generic_icon_for_amenities.png"
    },
    {
        "title": "29 m²",
        "keyword": "m²",
        "icon": "room_size.png"
    },
    {
        "title": "295 sq.ft ",
        "keyword": "sq.ft",
        "icon": "room_size.png"
    },
    {
        "title": "2nd Lock on Guest Doors",
        "keyword": "lock",
        "icon": "generic_icon_for_amenities.png"
    },
    {
        "title": "32 LCD TV with home theatre system",
        "keyword": "TV",
        "icon": "tv.png"
    },
    {
        "title": "Accessible baths",
        "keyword": "accessible",
        "icon": "accessible.png"
    },
    {
        "title": "Air Condition",
        "keyword": "Condition",
        "icon": "air_conditioner.png"
    },
    {
        "title": "Air Conditioning",
        "keyword": "Condition",
        "icon": "air_conditioner.png"
    },
    {
        "title": "Airconditioner",
        "keyword": "Condition",
        "icon": "air_conditioner.png"
    },
    {
        "title": "Alarm Clock",
        "keyword": "alarm clock",
        "icon": "alarm_clock.png"
    },
    {
        "title": "All our villas are strict non-smoking zones for the safety of guests",
        "keyword": "non-smoking",
        "icon": "non_smoking.png"
    },
    {
        "title": "AM/FM alarm clock",
        "keyword": "alarm clock",
        "icon": "alarm_clock.png"
    },
    {
        "title": "AM/FM Radio",
        "keyword": "radio",
        "icon": "radio.png"
    },
    {
        "title": "Balcony",
        "keyword": "balcony",
        "icon": "balcony.png"
    },
    {
        "title": "Balcony Garden View/Mountain View",
        "keyword": "balcony",
        "icon": "balcony.png"
    },
    {
        "title": "Balcony/Lanai/Terrace",
        "keyword": "balcony",
        "icon": "balcony.png"
    },
    {
        "title": "Bath Amenities",
        "keyword": "bath amenities",
        "icon": "toiletries.png"
    },
    {
        "title": "Bath amenities are Forest Essentials made from natural ingredients",
        "keyword": "bath amenities",
        "icon": "toiletries.png"
    },
    {
        "title": "Bath Robe",
        "keyword": "robe",
        "icon": "robe.png"
    },
    {
        "title": "Bath Tub with Spray Jets",
        "keyword": "bath",
        "icon": "bath.png"
    },
    {
        "title": "Bathrobe",
        "keyword": "robe",
        "icon": "robe.png"
    },
    {
        "title": "Bathrobes",
        "keyword": "robe",
        "icon": "robe.png"
    },
    {
        "title": "Bathroobe & Bath Slippers",
        "keyword": "robe",
        "icon": "robe.png"
    },
    {
        "title": "Bathroom",
        "keyword": "bathroom",
        "icon": "bathroom.png"
    },
    {
        "title": "Bathroom Amenities",
        "keyword": "bathroom amenities",
        "icon": "toiletries.png"
    },
    {
        "title": "Bathroom Telephone",
        "keyword": "telephone",
        "icon": "telephone.png"
    },
    {
        "title": "Bathtub Fully bathroom amenities32 inch LED TVWorking DeskThis Premier Deluxe accommodates a maximum of 2 adults  Size of Room: 28 SqmView: Window With City ViewBedding: 1 Double Bed or 1 Twind BedNon smoking rooms/smoking roomsAir conditioningTowelDeskHair dryerIn room safeTelevision LCDBathtubShowerMini barSatellite/cable TVComplimentary bottled waterFree LAN and WiFi accessTelephoneCable ChanelFree toiletriesToiletBathroomWake-up serviceLinensWooden Floor",
        "keyword": "sqm",
        "icon": "room_size.png"
    },
    {
        "title": "Bathtub Only",
        "keyword": "bath",
        "icon": "bath.png"
    },
    {
        "title": "Bathtub/Shower Combination",
        "keyword": "bath",
        "icon": "bath.png"
    },
    {
        "title": "Bed Linen Thread Count",
        "keyword": "linen",
        "icon": "bed.png"
    },
    {
        "title": "Blackout Curtain",
        "keyword": "curtain",
        "icon": "curtain.png"
    },
    {
        "title": "bottled water",
        "keyword": "water",
        "icon": "water_bottle.png"
    },
    {
        "title": "Bottled Water",
        "keyword": "water",
        "icon": "water_bottle.png"
    },
    {
        "title": "Cable TV",
        "keyword": "tv",
        "icon": "tv.png"
    },
    {
        "title": "Cable/ Satellite television",
        "keyword": "television",
        "icon": "tv.png"
    },
    {
        "title": "Cable/Satellite Television",
        "keyword": "television",
        "icon": "tv.png"
    },
    {
        "title": "Carpeted floor",
        "keyword": "carpet",
        "icon": "carpet.png"
    },
    {
        "title": "Ceiling Fan",
        "keyword": "fan",
        "icon": "fan.png"
    },
    {
        "title": "Chef Special cookies",
        "keyword": "cookies",
        "icon": "cookies.png"
    },
    {
        "title": "Child Rollaway",
        "keyword": "child rollaway",
        "icon": "crib.png"
    },
    {
        "title": "City view",
        "keyword": "city view",
        "icon": "city_view.png"
    },
    {
        "title": "closet",
        "keyword": "closet",
        "icon": "wardrobe.png"
    },
    {
        "title": "Closets in Room",
        "keyword": "closet",
        "icon": "wardrobe.png"
    },
    {
        "title": "Cofee & Tea ",
        "keyword": "coffee",
        "icon": "coffee_maker.png"
    },
    {
        "title": "Coffee machine",
        "keyword": "coffee",
        "icon": "coffee_maker.png"
    },
    {
        "title": "Coffee/Tea Maker",
        "keyword": "coffee",
        "icon": "coffee_maker.png"
    },
    {
        "title": "comfortable king sized bed facing the sea",
        "keyword": "coffee",
        "icon": "coffee_maker.png"
    },
    {
        "title": "Complimentary High-Speed Internet in Room",
        "keyword": "internet",
        "icon": "wifi.png"
    },
    {
        "title": "Complimentary mini bar replinished once a day",
        "keyword": "mini bar",
        "icon": "mini_bar.png"
    },
    {
        "title": "Cookies",
        "keyword": "cookies",
        "icon": "cookies.png"
    },
    {
        "title": "Cribs",
        "keyword": "crib",
        "icon": "crib.png"
    },
    {
        "title": "Cups/Glassware",
        "keyword": "cups",
        "icon": "cuttlery.png"
    },
    {
        "title": "Daily Maid service",
        "keyword": "maid",
        "icon": "housekeeping.png"
    },
    {
        "title": "DcotoronCall",
        "keyword": "doctor",
        "icon": "doctor.png"
    },
    {
        "title": "Deck rain shower and wide wooden ladder going into the lagoon",
        "keyword": "rain shower",
        "icon": "rain_shower.png"
    },
    {
        "title": "Desk",
        "keyword": "desk",
        "icon": "work_desk.png"
    },
    {
        "title": "Desk Chair",
        "keyword": "desk",
        "icon": "work_desk.png"
    },
    {
        "title": "Desk with Electrical Outlet",
        "keyword": "desk",
        "icon": "work_desk.png"
    },
    {
        "title": "Direct Beach access",
        "keyword": "beach",
        "icon": "default.png"
    },
    {
        "title": "Direct Dial Phone Number",
        "keyword": "phone",
        "icon": "telephone.png"
    },
    {
        "title": "Double Vanity",
        "keyword": "vanity",
        "icon": "toiletries.png"
    },
    {
        "title": "Down comforter",
        "keyword": "comforter",
        "icon": "duvet.png"
    },
    {
        "title": "Drinking Water Bottles",
        "keyword": "water",
        "icon": "water_bottle.png"
    },
    {
        "title": "Duvet",
        "keyword": "duvet",
        "icon": "duvet.png"
    },
    {
        "title": "DVD player. Coffee Tea maker",
        "keyword": "coffee",
        "icon": "coffee_maker.png"
    },
    {
        "title": "Electric Kettle",
        "keyword": "kettle",
        "icon": "coffee_maker.png"
    },
    {
        "title": "Electronic Room Key",
        "keyword": "key",
        "icon": "generic_icon_for_amenities.png"
    },
    {
        "title": "Emergency Info in Room",
        "keyword": "emergency",
        "icon": "generic_icon_for_amenities.png"
    },
    {
        "title": "En-suite Bathroom",
        "keyword": "bathroom",
        "icon": "bathroom.png"
    },
    {
        "title": "Ensuite bathroom",
        "keyword": "bathroom",
        "icon": "bathroom.png"
    },
    {
        "title": "Evening turn down service",
        "keyword": "service",
        "icon": "generic_service.png"
    },
    {
        "title": "Extra Adult Charge",
        "keyword": "charge",
        "icon": "paid_facility.png"
    },
    {
        "title": "Extra Child Charge",
        "keyword": "charge",
        "icon": "paid_facility.png"
    },
    {
        "title": "Extra Child Charge for Rollaway Use",
        "keyword": "charge",
        "icon": "paid_facility.png"
    },
    {
        "title": "Fan",
        "keyword": "fan",
        "icon": "fan.png"
    },
    {
        "title": "Fire Alarm",
        "keyword": "fire",
        "icon": "fire_alarm.png"
    },
    {
        "title": "Fire Extinguishers",
        "keyword": "fire",
        "icon": "fire_alarm.png"
    },
    {
        "title": "Fire Place",
        "keyword": "fire place",
        "icon": "fireplace.png"
    },
    {
        "title": "Flat screen TV",
        "keyword": "tv",
        "icon": "tv.png"
    },
    {
        "title": "Flat-screen",
        "keyword": "flat-screen",
        "icon": "tv.png"
    },
    {
        "title": "Flat-screen TV",
        "keyword": "tv",
        "icon": "tv.png"
    },
    {
        "title": "Fluffy cotton towels",
        "keyword": "towel",
        "icon": "towel.png"
    },
    {
        "title": "Foam Pillows",
        "keyword": "pillow",
        "icon": "pillow.png"
    },
    {
        "title": "four-fixture toilet with grab bars",
        "keyword": "toilet",
        "icon": "toilet.png"
    },
    {
        "title": "Free toiletries",
        "keyword": "toiletries",
        "icon": "toiletries.png"
    },
    {
        "title": "Free WiFi",
        "keyword": "wifi",
        "icon": "wifi.png"
    },
    {
        "title": "FreeParking",
        "keyword": "parking",
        "icon": "parking.png"
    },
    {
        "title": "fresh water bottles",
        "keyword": "water",
        "icon": "water_bottle.png"
    },
    {
        "title": "Fruit Basket",
        "keyword": "friuit",
        "icon": "fruit_basket.png"
    },
    {
        "title": "Full Size Mirror",
        "keyword": "mirror",
        "icon": "round_mirror.png"
    },
    {
        "title": "Fully room amenities in international standardNo of room : 58 roomsWindowsWooden floor",
        "keyword": "wooden floor",
        "icon": "generic_icon_for_amenities.png"
    },
    {
        "title": "fully stocked Mini bar",
        "keyword": "mini bar",
        "icon": "mini_bar.png"
    },
    {
        "title": "Garden view",
        "keyword": "garden view",
        "icon": "garden_view.png"
    },
    {
        "title": "Garden view/ Pool with a view",
        "keyword": "garden view",
        "icon": "garden_view.png"
    },
    {
        "title": "Glass enclosed shower stall",
        "keyword": "shower",
        "icon": "shower.png"
    },
    {
        "title": "Hair Dryer",
        "keyword": "dryer",
        "icon": "dryer.png"
    },
    {
        "title": "Hairdryer",
        "keyword": "dryer",
        "icon": "dryer.png"
    },
    {
        "title": "hand sanitizer",
        "keyword": "sanitizer",
        "icon": "hand_sanitizer.png"
    },
    {
        "title": "Heating ",
        "keyword": "heating",
        "icon": "heating.png"
    },
    {
        "title": "High Ceilings",
        "keyword": "high ceiling",
        "icon": "generic_icon_for_amenities.png"
    },
    {
        "title": "High Definition (HD) Flat Panel Television",
        "keyword": "Television",
        "icon": "tv.png"
    },
    {
        "title": "Housekeeping Service",
        "keyword": "housekeeping",
        "icon": "room_service.png"
    },
    {
        "title": "Housekeeping service twice daily – morning cleaning and evening turndown",
        "keyword": "housekeeping",
        "icon": "room_service.png"
    },
    {
        "title": "IDD cordless telephone",
        "keyword": "telephone",
        "icon": "telephone.png"
    },
    {
        "title": "In Room Lockers",
        "keyword": "locker",
        "icon": "safe.png"
    },
    {
        "title": "In room safe",
        "keyword": "safe",
        "icon": "safe.png"
    },
    {
        "title": "In Room Safe Box",
        "keyword": "safe",
        "icon": "safe.png"
    },
    {
        "title": "In Villa Safe",
        "keyword": "safe",
        "icon": "safe.png"
    },
    {
        "title": "In Villa Wifi Access",
        "keyword": "wifi",
        "icon": "wifi.png"
    },
    {
        "title": "In-house Phone",
        "keyword": "phone",
        "icon": "telephone.png"
    },
    {
        "title": "In-room air conditioning",
        "keyword": "condition",
        "icon": "air_conditioner.png"
    },
    {
        "title": "In-room safe",
        "keyword": "safe",
        "icon": "safe.png"
    },
    {
        "title": "Individual Air Conditioning",
        "keyword": "condition",
        "icon": "air_conditioner.png"
    },
    {
        "title": "Individual Balcony",
        "keyword": "balcony",
        "icon": "balcony.png"
    },
    {
        "title": "Individually Controlled Air-conditioning",
        "keyword": "condition",
        "icon": "air_conditioner.png"
    },
    {
        "title": "Indoor Showers",
        "keyword": "shower",
        "icon": "shower.png"
    },
    {
        "title": "Inner courtyard view",
        "keyword": "courtyard view",
        "icon": "courtyard_view.png"
    },
    {
        "title": "Instant Hot Water",
        "keyword": "hot water",
        "icon": "generic_icon_for_amenities.png"
    },
    {
        "title": "International Channels",
        "keyword": "channels",
        "icon": "tv.png"
    },
    {
        "title": "iPod dock and DVD player",
        "keyword": "dock",
        "icon": "phone_dock.png"
    },
    {
        "title": "iron",
        "keyword": "iron",
        "icon": "iron.png"
    },
    {
        "title": "Iron & Ironing board",
        "keyword": "iron",
        "icon": "iron.png"
    },
    {
        "title": "Iron Facilities",
        "keyword": "iron",
        "icon": "iron.png"
    },
    {
        "title": "Iron/Ironing Board",
        "keyword": "iron",
        "icon": "iron.png"
    },
    {
        "title": "Ironing Board",
        "keyword": "iron",
        "icon": "iron.png"
    },
    {
        "title": "Ironing facilities",
        "keyword": "iron",
        "icon": "iron.png"
    },
    {
        "title": "Kids club",
        "keyword": "kids club",
        "icon": "kids_activities.png"
    },
    {
        "title": "King Bed",
        "keyword": "bed",
        "icon": "bed.png"
    },
    {
        "title": "Large Flat Sceen LCD TV",
        "keyword": "tv",
        "icon": "tv.png"
    },
    {
        "title": "Large Flat screen LCD TV",
        "keyword": "tv",
        "icon": "tv.png"
    },
    {
        "title": "Laundry Basket/Clothes Hamper",
        "keyword": "laundry",
        "icon": "laundry.png"
    },
    {
        "title": "Laundry Service",
        "keyword": "laundry",
        "icon": "laundry.png"
    },
    {
        "title": "Locker Facility",
        "keyword": "locker",
        "icon": "safe.png"
    },
    {
        "title": "Marble Bathroom",
        "keyword": "marble",
        "icon": "generic_icon_for_amenities.png"
    },
    {
        "title": "Mineral Water",
        "keyword": "water",
        "icon": "water_bottle.png"
    },
    {
        "title": "Mini Bar",
        "keyword": "mini bar",
        "icon": "mini_bar.png"
    },
    {
        "title": "Mini Bar (chargeable)",
        "keyword": "mini bar",
        "icon": "mini_bar.png"
    },
    {
        "title": "Mini bar replishned once a day",
        "keyword": "mini bar",
        "icon": "mini_bar.png"
    },
    {
        "title": "Mini-bar",
        "keyword": "mini-bar",
        "icon": "mini_bar.png"
    },
    {
        "title": "Minibar",
        "keyword": "minibar",
        "icon": "mini_bar.png"
    },
    {
        "title": "MinibarChargeableBasis",
        "keyword": "minibar",
        "icon": "mini_bar.png"
    },
    {
        "title": "Mountain/Hill View",
        "keyword": "mountain",
        "icon": "hill_view.png"
    },
    {
        "title": "Non-Smoking",
        "keyword": "non smoking",
        "icon": "non_smoking.png"
    },
    {
        "title": "Notepads",
        "keyword": "Notepads",
        "icon": "stationary.png"
    },
    {
        "title": "Ocean view bathtub & rainshower",
        "keyword": "bathtub",
        "icon": "bathtub.png"
    },
    {
        "title": "one day bed",
        "keyword": "bed",
        "icon": "bed.png"
    },
    {
        "title": "Outdoor Rain shower",
        "keyword": "oudoor shower",
        "icon": "outdoor_shower.png"
    },
    {
        "title": "Outdoor Space",
        "keyword": "oudoor space",
        "icon": "deck.png"
    },
    {
        "title": "Outside deck over the water with day bed and dining area",
        "keyword": "deck",
        "icon": "outdoor_deck.png"
    },
    {
        "title": "Patio",
        "keyword": "patio",
        "icon": "patio.png"
    },
    {
        "title": "Patio Private bathroom Coffee machine Minibar Free WiFi",
        "keyword": "patio",
        "icon": "patio.png"
    },
    {
        "title": "Pens",
        "keyword": "pens",
        "icon": "stationary.png"
    },
    {
        "title": "Personal tea/coffee makers",
        "keyword": "coffee",
        "icon": "coffee_maker.png"
    },
    {
        "title": "Pillow Menu",
        "keyword": "pillow",
        "icon": "pillow.png"
    },
    {
        "title": "Plates and Bowls",
        "keyword": "plates",
        "icon": "cuttlery.png"
    },
    {
        "title": "Prayer Mats",
        "keyword": "prayer",
        "icon": "prayer.png"
    },
    {
        "title": "Private bathroom",
        "keyword": "bathroom",
        "icon": "bathroom.png"
    },
    {
        "title": "Private deck",
        "keyword": "deck",
        "icon": "outdoor_deck.png"
    },
    {
        "title": "Private Plunge Pool",
        "keyword": "pool",
        "icon": "swimming_pool.png"
    },
    {
        "title": "Private Pool",
        "keyword": "pool",
        "icon": "swimming_pool.png"
    },
    {
        "title": "Private Sun Deck with Sun Lounges",
        "keyword": "deck",
        "icon": "outdoor_deck.png"
    },
    {
        "title": "Rain shower",
        "keyword": "rain shower",
        "icon": "rain_shower.png"
    },
    {
        "title": "Robe & Slippers",
        "keyword": "robe",
        "icon": "robe.png"
    },
    {
        "title": "Rollaway Bed",
        "keyword": "bed",
        "icon": "bed.png"
    },
    {
        "title": "Room Service",
        "keyword": "room service",
        "icon": "room_service.png"
    },
    {
        "title": "Room Windows Open",
        "keyword": "windows",
        "icon": "windows.png"
    },
    {
        "title": "RoomServices",
        "keyword": "roomservice",
        "icon": "room_service.png"
    },
    {
        "title": "Safe",
        "keyword": "safe",
        "icon": "safe.png"
    },
    {
        "title": "Safe Large Enough to Accommodate A Laptop",
        "keyword": "safe",
        "icon": "safe.png"
    },
    {
        "title": "Safety Box",
        "keyword": "safe",
        "icon": "safe.png"
    },
    {
        "title": "Safety Chains on Guest Doors + See Through Glass Floor + Built in Overwater hammock + Outdoor Shower",
        "keyword": "hammock",
        "icon": "hammock.png"
    },
    {
        "title": "safety deposit box",
        "keyword": "safe",
        "icon": "safe.png"
    },
    {
        "title": "Sanitizers",
        "keyword": "sanitizer",
        "icon": "hand_sanitizer.png"
    },
    {
        "title": "Seating Area with Sofa/Chair",
        "keyword": "sofa",
        "icon": "sofa.png"
    },
    {
        "title": "Self-Controlled Heating/Cooling System",
        "keyword": "cooling system",
        "icon": "air_conditioner.png"
    },
    {
        "title": "Semi open air bathroom",
        "keyword": "bathroom",
        "icon": "bathroom.png"
    },
    {
        "title": "Separate Closet",
        "keyword": "closet",
        "icon": "wardrobe.png"
    },
    {
        "title": "Separate Toilet Area",
        "keyword": "toilet",
        "icon": "toilet.png"
    },
    {
        "title": "Separate Tub and Shower",
        "keyword": "tub",
        "icon": "bathtub.png"
    },
    {
        "title": "sewing kit",
        "keyword": "sewing kit",
        "icon": "sweing_kit.png"
    },
    {
        "title": "Shaving Kit",
        "keyword": "shaving kit",
        "icon": "shaving_kit.png"
    },
    {
        "title": "Shoeshine Kit",
        "keyword": "shoeshine",
        "icon": "default.png"
    },
    {
        "title": "Shower",
        "keyword": "shower",
        "icon": "shower.png"
    },
    {
        "title": "Shower Room",
        "keyword": "shower",
        "icon": "shower.png"
    },
    {
        "title": "Silverware/Utensils",
        "keyword": "utensils",
        "icon": "cuttlery.png"
    },
    {
        "title": "Sitting Area",
        "keyword": "sitting",
        "icon": "sofa.png"
    },
    {
        "title": "Slipper",
        "keyword": "slipper",
        "icon": "slippers.png"
    },
    {
        "title": "Slippers",
        "keyword": "slipper",
        "icon": "slippers.png"
    },
    {
        "title": "Smoke Sensor System",
        "keyword": "Smoke",
        "icon": "no_smoking.png"
    },
    {
        "title": "Smoking",
        "keyword": "Smoking",
        "icon": "smoking_room.png"
    },
    {
        "title": "SmokingFreeRooms",
        "keyword": "SmokingFree",
        "icon": "no_smoking.png"
    },
    {
        "title": "sofa",
        "keyword": "sofa",
        "icon": "sofa.png"
    },
    {
        "title": "Sofa Bed",
        "keyword": "bed",
        "icon": "bed.png"
    },
    {
        "title": "Steps to the Lagoon",
        "keyword": "steps to water",
        "icon": "steps_to_water.png"
    },
    {
        "title": "Storage Space",
        "keyword": "storage",
        "icon": "wardrobe.png"
    },
    {
        "title": "Swivel shaving mirror",
        "keyword": "mirror",
        "icon": "round_mirror.png"
    },
    {
        "title": "Tables and Chairs",
        "keyword": "table",
        "icon": "work_desk.png"
    },
    {
        "title": "Tea & Coffee",
        "keyword": "coffee",
        "icon": "coffee_maker.png"
    },
    {
        "title": "Tea & Coffee Makers",
        "keyword": "coffee",
        "icon": "coffee_maker.png"
    },
    {
        "title": "Tea and coffee facilities",
        "keyword": "coffee",
        "icon": "coffee_maker.png"
    },
    {
        "title": "tea/coffee maker",
        "keyword": "coffee",
        "icon": "coffee_maker.png"
    },
    {
        "title": "Tea/Coffee Making Facilities",
        "keyword": "coffee",
        "icon": "coffee_maker.png"
    },
    {
        "title": "Tea/Coffee making facility",
        "keyword": "coffee",
        "icon": "coffee_maker.png"
    },
    {
        "title": "Telephone",
        "keyword": "telephone",
        "icon": "telephone.png"
    },
    {
        "title": "Television",
        "keyword": "television",
        "icon": "tv.png"
    },
    {
        "title": "Treat Yourself minibar with a selection of organic food and beverage",
        "keyword": "minibar",
        "icon": "mini_bar.png"
    },
    {
        "title": "Trouser/Pant Press",
        "keyword": "press",
        "icon": "iron.png"
    },
    {
        "title": "Turn Down Service",
        "keyword": "service",
        "icon": "generic_service.png"
    },
    {
        "title": "TV",
        "keyword": "tv",
        "icon": "tv.png"
    },
    {
        "title": "TVFree WiFi",
        "keyword": "tv",
        "icon": "tv.png"
    },
    {
        "title": "Uniquely designed outdoor shower illuminated by natural light",
        "keyword": "outdoor shower",
        "icon": "outdoor_shower.png"
    },
    {
        "title": "Use of pool and fitness centre",
        "keyword": "pool",
        "icon": "swimming_pool.png"
    },
    {
        "title": "Vanity Kit",
        "keyword": "vanity",
        "icon": "toiletries.png"
    },
    {
        "title": "wake up Services",
        "keyword": "service",
        "icon": "generic_service.png"
    },
    {
        "title": "Wake-Up Calls",
        "keyword": "calls",
        "icon": "wake_up_call.png"
    },
    {
        "title": "wardrobe",
        "keyword": "wardrobe",
        "icon": "wardrobe.png"
    },
    {
        "title": "Wardrobe & Cloth Hanger",
        "keyword": "wardrobe",
        "icon": "wardrobe.png"
    },
    {
        "title": "Wardrobe & Cloth Hangers",
        "keyword": "wardrobe",
        "icon": "wardrobe.png"
    },
    {
        "title": "Weight Scale",
        "keyword": "weight",
        "icon": "weight_scale.png"
    },
    {
        "title": "welcome Drink",
        "keyword": "welcome drink",
        "icon": "welcome_drink.png"
    },
    {
        "title": "Welcome Gift",
        "keyword": "welcome gift",
        "icon": "gift.png"
    },
    {
        "title": "Well ventilated",
        "keyword": "ventilated",
        "icon": "generic_icon_for_amenities.png"
    },
    {
        "title": "WheelChaironRequest",
        "keyword": "wheel",
        "icon": "wheel_chair.png"
    },
    {
        "title": "Wi-Fi",
        "keyword": "wi-fi",
        "icon": "wifi.png"
    },
    {
        "title": "Wi-Fi in all room",
        "keyword": "wi-fi",
        "icon": "wifi.png"
    },
    {
        "title": "Wi-Fi services for resident guests (terms and conditions apply) Basic access – complimentary Premium access – at a nominal charge",
        "keyword": "wi-fi",
        "icon": "wifi.png"
    },
    {
        "title": "WIFI",
        "keyword": "wifi",
        "icon": "wifi.png"
    },
    {
        "title": "Wireless Internet Connection",
        "keyword": "wireless internet",
        "icon": "wifi.png"
    },
    {
        "title": "Work Desk & Chair",
        "keyword": "desk",
        "icon": "work_desk.png"
    }
];

export const facilitiesIconMapping = [
    {
        "title": "1 swimming pool",
        "keyword": "pool",
        "icon": "swimming_pool.png"
    },
    {
        "title": "24 hours room service",
        "keyword": "room service",
        "icon": "room_service_dining.png"
    },
    {
        "title": "24-hour IDD / Fax / Internet and email",
        "keyword": "internet",
        "icon": "business_center.png"
    },
    {
        "title": "24-hour in-villa dining",
        "keyword": "dining",
        "icon": "dining.png"
    },
    {
        "title": "24-hr Front Desk",
        "keyword": "Front Desk",
        "icon": "front_desk.png"
    },
    {
        "title": "Airport shuttle",
        "keyword": "shuttle",
        "icon": "default.png"
    },
    {
        "title": "and books in various languages",
        "keyword": "book",
        "icon": "library.png"
    },
    {
        "title": "Baby Sitting",
        "keyword": "Baby Sitting",
        "icon": "babysitting.png"
    },
    {
        "title": "Babysitting services (non-professional)",
        "keyword": "Babysitting",
        "icon": "babysitting.png"
    },
    {
        "title": "Balcony",
        "keyword": "balcony",
        "icon": "balcony.png"
    },
    {
        "title": "Bar",
        "keyword": "bar",
        "icon": "bar.png"
    },
    {
        "title": "Bath",
        "keyword": "bath",
        "icon": "bath.png"
    },
    {
        "title": "BBQ facilities",
        "keyword": "BBQ",
        "icon": "bbq.png"
    },
    {
        "title": "Boat Life & Beyond",
        "keyword": "Boat",
        "icon": "boat.png"
    },
    {
        "title": "Boats",
        "keyword": "boat",
        "icon": "boat.png"
    },
    {
        "title": "Buggy Ride",
        "keyword": "buggy",
        "icon": "buggy.png"
    },
    {
        "title": "Cafe",
        "keyword": "café",
        "icon": "coffee_shop.png"
    },
    {
        "title": "Changing area (shower and steam)",
        "keyword": "shower",
        "icon": "shower.png"
    },
    {
        "title": "Conceirge Services",
        "keyword": "concierge",
        "icon": "concierge.png"
    },
    {
        "title": "Currency Exchange Desk",
        "keyword": "currency",
        "icon": "currency_exchange.png"
    },
    {
        "title": "Dive Center (PADI Course & Certification)",
        "keyword": "dive",
        "icon": "diving.png"
    },
    {
        "title": "Diving",
        "keyword": "diving",
        "icon": "diving.png"
    },
    {
        "title": "Doctor on call",
        "keyword": "doctor",
        "icon": "doctor.png"
    },
    {
        "title": "DVDs",
        "keyword": "dvd",
        "icon": "entertainment.png"
    },
    {
        "title": "ELE|NA Spa Complex",
        "keyword": "spa",
        "icon": "spa.png"
    },
    {
        "title": "Element X (All-Day Dining Restaurant)",
        "keyword": "restaurant",
        "icon": "dining.png"
    },
    {
        "title": "Excursion & Cruises",
        "keyword": "cruise",
        "icon": "cruise.png"
    },
    {
        "title": "Excursions",
        "keyword": "excursion",
        "icon": "tour_excursion.png"
    },
    {
        "title": "Fishing",
        "keyword": "fishing",
        "icon": "fishing.png"
    },
    {
        "title": "Fitness",
        "keyword": "fitness",
        "icon": "gym.png"
    },
    {
        "title": "Fitness & Recreation ",
        "keyword": "fitness",
        "icon": "gym.png"
    },
    {
        "title": "Fitness centre",
        "keyword": "fitness",
        "icon": "gym.png"
    },
    {
        "title": "Fitness Studio",
        "keyword": "fitness",
        "icon": "gym.png"
    },
    {
        "title": "Foreign currency exchange",
        "keyword": "currency",
        "icon": "currency_exchange.png"
    },
    {
        "title": "Free parking",
        "keyword": "parking",
        "icon": "parking.png"
    },
    {
        "title": "Free WiFi",
        "keyword": "wifi",
        "icon": "wifi.png"
    },
    {
        "title": "Free WiFi",
        "keyword": "wi-fi",
        "icon": "wifi.png"
    },
    {
        "title": "Games Room",
        "keyword": "games",
        "icon": "games.png"
    },
    {
        "title": "Garden",
        "keyword": "garden",
        "icon": "garden.png"
    },
    {
        "title": "Gift Shop",
        "keyword": "shop",
        "icon": "shop.png"
    },
    {
        "title": "Golf course",
        "keyword": "golf",
        "icon": "golf.png"
    },
    {
        "title": "Gym ",
        "keyword": "gym",
        "icon": "gym.png"
    },
    {
        "title": "Gymnasium/Fitness Centre",
        "keyword": "fitness",
        "icon": "gym.png"
    },
    {
        "title": "Heritage Hotel",
        "keyword": "heritage",
        "icon": "heritage.png"
    },
    {
        "title": "Horse riding",
        "keyword": "horse",
        "icon": "horse_riding.png"
    },
    {
        "title": "In-house photographers",
        "keyword": "photographer",
        "icon": "photo.png"
    },
    {
        "title": "Infinity Pool",
        "keyword": "pool",
        "icon": "swimming_pool.png"
    },
    {
        "title": "Infinity swimming pool and Jacuzzi",
        "keyword": "pool",
        "icon": "swimming_pool.png"
    },
    {
        "title": "Jiva Spa",
        "keyword": "spa",
        "icon": "spa.png"
    },
    {
        "title": "Kid’s activities",
        "keyword": "kid",
        "icon": "kids_activities.png"
    },
    {
        "title": "Kids Club",
        "keyword": "kid",
        "icon": "kids_activities.png"
    },
    {
        "title": "Kids Play Area",
        "keyword": "play area",
        "icon": "play_area.png"
    },
    {
        "title": "Laundry and dry cleaning",
        "keyword": "laundry",
        "icon": "laundry.png"
    },
    {
        "title": "Lawn",
        "keyword": "lawn",
        "icon": "garden.png"
    },
    {
        "title": "Le Pondycherry Shop",
        "keyword": "shop",
        "icon": "shop.png"
    },
    {
        "title": "Library",
        "keyword": "library",
        "icon": "books.png"
    },
    {
        "title": "Lifestyle boutique",
        "keyword": "botique",
        "icon": "botique_shop.png"
    },
    {
        "title": "Lil Shark Kids Club",
        "keyword": "kids club",
        "icon": "kids_activities.png"
    },
    {
        "title": "Lounge with board games",
        "keyword": "lounge",
        "icon": "lounge.png"
    },
    {
        "title": "Lounge with board games DVDs and books in various languages",
        "keyword": "lounge",
        "icon": "lounge.png"
    },
    {
        "title": "Mountain view",
        "keyword": "mountain view",
        "icon": "mountain_view.png"
    },
    {
        "title": "Multi-cousine restaurant",
        "keyword": "restaurant",
        "icon": "dining.png"
    },
    {
        "title": "Multi-lingual staff",
        "keyword": "lingual",
        "icon": "multi_lingual.png"
    },
    {
        "title": "Non-smoking rooms",
        "keyword": "non smoking",
        "icon": "non_smoking.png"
    },
    {
        "title": "On Site Dining ",
        "keyword": "dining",
        "icon": "dining.png"
    },
    {
        "title": "Only BLU (Under Ocean Restaurant)",
        "keyword": "restaurant",
        "icon": "dining.png"
    },
    {
        "title": "Outdoor Sports",
        "keyword": "sports",
        "icon": "sports.png"
    },
    {
        "title": "Over water Spa",
        "keyword": "spa",
        "icon": "spa.png"
    },
    {
        "title": "PADI Certified Dive School",
        "keyword": "dive",
        "icon": "diving.png"
    },
    {
        "title": "PADI Dive Centre",
        "keyword": "dive",
        "icon": "diving.png"
    },
    {
        "title": "Pets allowed",
        "keyword": "pets",
        "icon": "pets.png"
    },
    {
        "title": "Pool table",
        "keyword": "pool table",
        "icon": "pool_table.png"
    },
    {
        "title": "Porter",
        "keyword": "porter",
        "icon": "porter.png"
    },
    {
        "title": "Private beach",
        "keyword": "beach",
        "icon": "beach.png"
    },
    {
        "title": "Recreation",
        "keyword": "recreation",
        "icon": "entertainment.png"
    },
    {
        "title": "Restaurant",
        "keyword": "restaurant",
        "icon": "dining.png"
    },
    {
        "title": "Roof Top Pool",
        "keyword": "pool",
        "icon": "swimming_pool.png"
    },
    {
        "title": "Room service",
        "keyword": "room service",
        "icon": "room_service_dining.png"
    },
    {
        "title": "Sauna",
        "keyword": "sauna",
        "icon": "sauna.png"
    },
    {
        "title": "Snorkeling Equipment",
        "keyword": "snorkelling",
        "icon": "water_sport.png"
    },
    {
        "title": "Snorkelling",
        "keyword": "snorkelling",
        "icon": "water_sport.png"
    },
    {
        "title": "Spa",
        "keyword": "spa",
        "icon": "spa.png"
    },
    {
        "title": "Spa and wellness centre",
        "keyword": "spa",
        "icon": "spa.png"
    },
    {
        "title": "Speed boat service",
        "keyword": "Boat",
        "icon": "boat.png"
    },
    {
        "title": "Steam Room",
        "keyword": "steam room",
        "icon": "sauna.png"
    },
    {
        "title": "Swimming Pool",
        "keyword": "pool",
        "icon": "swimming_pool.png"
    },
    {
        "title": "swimmming ",
        "keyword": "swimming",
        "icon": "swimming_pool.png"
    },
    {
        "title": "Tea/coffee maker in all rooms",
        "keyword": "coffee maker",
        "icon": "coffee_maker.png"
    },
    {
        "title": "Teacoffee maker in all rooms",
        "keyword": "coffee maker",
        "icon": "coffee_maker.png"
    },
    {
        "title": "The Coffee and Pastry Shop",
        "keyword": "coffee shop",
        "icon": "coffee_shop.png"
    },
    {
        "title": "The Copper Pot Food Truck",
        "keyword": "food truck",
        "icon": "dining.png"
    },
    {
        "title": "The Dome (Entertainment Center)",
        "keyword": "entertainment"
    },
    {
        "title": "The Essence OShop",
        "keyword": "shop",
        "icon": "shop.png"
    },
    {
        "title": "The Standard Wine market",
        "keyword": "market",
        "icon": "shop.png"
    },
    {
        "title": "The Wine and Liquor Shop",
        "keyword": "shop",
        "icon": "shop.png"
    },
    {
        "title": "Unique dining experience",
        "keyword": "dining",
        "icon": "dining.png"
    },
    {
        "title": "View",
        "keyword": "view",
        "icon": "nature_view.png"
    },
    {
        "title": "Water Sports",
        "keyword": "water sport",
        "icon": "water_sport.png"
    },
    {
        "title": "Water Sports Center",
        "keyword": "water sport",
        "icon": "water_sport.png"
    },
    {
        "title": "water sports centre",
        "keyword": "water sport",
        "icon": "water_sport.png"
    },
    {
        "title": "WIFI",
        "keyword": "wifi",
        "icon": "wifi.png"
    },
    {
        "title": "wine",
        "keyword": "wine",
        "icon": "wine.png"
    },
    {
        "title": "X360 Bar (With Pool)",
        "keyword": "bar",
        "icon": "bar.png"
    },
    {
        "title": "Yoga and Meditation Deck",
        "keyword": "yoga",
        "icon": "yoga.png"
    }
];