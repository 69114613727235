import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ReactPixel from 'react-facebook-pixel';

import "./TermsOfUse.scss";

function TermsOfUse() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Terms Of Use | Voyaah";
    ReactPixel.fbq('init', process.env.REACT_APP_FBPIXEL_ID);
    ReactPixel.fbq('track', 'PageView');
  }, []);

  return (
    <div className="inner-page details-page">
      <div className="section">
        <div className="breadcrum">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>›</li>
            <li>Terms Of Use</li>
          </ul>
        </div>

        <div className="inner-heading">
          <div className="head text-center">Terms Of Use</div>
        </div>

        <div className="privacy-blk">
          <p className="bold">Purpose</p>
          <p>
            Trip Stories Private Limited understands the importance of privacy
            of its users. This Privacy Policy explains how we handle personal
            information and comply with the requirements of applicable privacy
            laws. For the purpose of this Privacy Policy, wherever the context
            so requires "you" or "your" shall mean User and the term "we", "us",
            "our" shall mean Trip Stories Private Limited.
          </p>

          <p className="bold">Applicability</p>
          <p>
            This Privacy Policy is applicable to any person who visits,
            inquiries about our products or services or purchases any of our
            products or services made available by through our website
            (https://www.voyaah.com) and other offline channels (collectively
            referred herein as "Sales Channels"). This Privacy Policy does not
            apply to any website(s), mobile sites and mobile apps of third
            parties, even if their websites/products are linked to our Sales
            Channels. You may take note that information and privacy practices
            of our business partners, advertisers, sponsors or other sites to
            which we provide hyperlink(s), may be materially different from this
            privacy policy. We recommended that you review the privacy
            statements and policies of any such third parties with whom they
            interact.
          </p>

          <p className="bold">Consent</p>
          <p>
            Please read this Privacy Policy carefully because by using or
            accessing our Sales Channels, you agree with the terms of this
            Privacy Policy and the contents herein. If you disagree with this
            Privacy Policy please do not use or access our Sales Channels.
          </p>

          <p className="bold">Information We Collect</p>
          <p>
            We collect personal/ non-personal information to be able to provide
            you with the best of our services. This Information may be collected
            by us from the information you give to us while using our Sales
            Channels, information we derive from you or information we receive
            from other sources. Such information may, inter alia, include the
            following:
          </p>

          <ul>
            <li>
              Your personal identifiable information such as name, gender,
              marital status, religion, age, contact details such as your email
              address, postal addresses, frequent flyer number and telephone
              (mobile or otherwise).
            </li>
            <li>
              Your banking details (including credit/debit card) and any other
              information relating to your income and/or lifestyle; billing
              information payment history, etc. (as shared by you).
            </li>
            <li>
              Transactional history (other than banking details) about your
              e-commerce activities, buying behavior
            </li>
            <li>
              Your usernames, passwords, email addresses and other
              security-related information used by you while accessing our Sales
              Channels.
            </li>
            <li>
              Data created by you or third party which you wish to store on our
              servers such as image files, documents etc
            </li>
            <li>
              Data available in public domain or received from any third party
              including social media channels, including but not limited to
              personal or non-personal information from your linked social media
              channels (like name, email address, friend list, profile pictures
              or any other information that is permitted to be received as per
              your account settings) as a part of your account information.
            </li>
            <li>
              Information pertaining any other traveler(s) for who you make a
              booking through our Sales Channels. In such case, you must confirm
              and represent that each of the other traveler(s) for whom a
              booking has been made, has agreed to have the information shared
              by you, disclosed to us and further be shared by us with the
              concerned service provider(s).
            </li>
            <li>
              If you request us to provide visa related services, then copies of
              your passport, bank statements, originals of the filled in
              application forms, photographs, and any other information which
              may be required by the respective embassy to process your visa
              application.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default TermsOfUse;
