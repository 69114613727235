import { takeLatest, put, call } from "redux-saga/effects";

import { GET_PACKAGE_AVAILABILITY } from "./actionTypes";

import {
  getPackageAvailabilitySuccess,
  getPackageAvailabilityFail,
} from "./actions";

import {
  getHolidayPackageAvailabilty,
  getPackageAvailability,
} from "../../helpers/backend_helper";

function* onGetPackageAvailability({ payload: data, customerProfileId }) {
  try {
    let response;
    if (data.roomTypeId) {
      response = yield call(getPackageAvailability, data, customerProfileId);
    } else {
      response = yield call(
        getHolidayPackageAvailabilty,
        data,
        customerProfileId
      );
    }
    yield put(getPackageAvailabilitySuccess(response));
  } catch (error) {
    yield put(getPackageAvailabilityFail(error.response));
  }
}

function* PackageAvailabilitySaga() {
  yield takeLatest(GET_PACKAGE_AVAILABILITY, onGetPackageAvailability);
}

export default PackageAvailabilitySaga;
